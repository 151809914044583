export const chicaOperacionModel = {
  su: null,
  fo: null,
  tc: null,
  dc: null,
  co: null,
  is: '0',
  tm: '0',
  mo: null,
  ia: null,
  td: null,
  dd: null,
  nd: null,
  rz: null,
  rs: null,
  nu: null,
};
