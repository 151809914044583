import * as fromPerfil from '../actions/perfiles.action';
import { perfilesModal } from '../models/perfiles.model';

let initialState = {
  perfiles: [],
  search: null,
  total: 0,
  page: 0,
  rows: 30,
  processing: false,
  selected: null,
  openModal: false,
  openModalEdit: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromPerfil.PERFILES_PROCESS:
      return {
        ...state,
        processing: true,
        errors: perfilesModal,
      };
    case fromPerfil.PERFILES_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        perfiles: action.payload.data.sort((a, b) => a.or > b.or),
        total: action.payload.count,
      };
    case fromPerfil.PERFILES_LIST_FAIL:
      return {
        ...state,
        processing: false,
        perfiles: [],
        total: 0,
      };
    case fromPerfil.PERFILES_STATES:
      return {
        ...state,
        processing: false,
        estados: action.payload,
      };
    case fromPerfil.PERFILES_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: perfilesModal,
        openModal: true,
      };
    case fromPerfil.PERFILES_SELECTED_EDIT:
      return {
        ...state,
        selected: action.payload,
        errors: perfilesModal,
        openModalEdit: true,
      };
    case fromPerfil.PERFILES_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        openModalEdit: false,
        errors: perfilesModal,
      };
    case fromPerfil.PERFILES_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromPerfil.PERFILES_FILTER:
      return {
        ...state,
        search: action.payload.search,
        page: 0,
      };
    case fromPerfil.PERFILES_CLOSE:
      return {
        ...state,
        errors: perfilesModal,
        openModal: false,
        openModalEdit: false,
      };
    case fromPerfil.PERFILES_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
