import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getGuiasAction } from '../../redux/actions/guia.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import GuiaList from './GuiaList';
import GuiaFilter from './GuiaFilter';
import GuiaNew from './GuiaNew';
import GuiaView from './GuiaView';

const Guia = ({ openModal, openModalView }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGuiasAction());
    dispatch(getSucursalesAction({}));
    dispatch(
      getDetallesPorCabeceraAction({
        ca: 'TIPODOCUMENTOIDENTIDAD',
      })
    );
    dispatch(
      getDetallesPorCabeceraAction({
        ca: 'MOTIVOTRASLADO',
        page: 0,
        pageSize: 30,
      })
    );
  }, []);

  return (
    <div className="op-component">
      <GuiaList />
      <GuiaFilter />
      {openModal && <GuiaNew />}
      {openModalView && <GuiaView />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.guia.openModal,
    openModalView: state.guia.openModalView,
  };
};

export default connect(mapStateToProps)(Guia);
