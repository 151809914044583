import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import Icon from '../../commons/Icon';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import MoneyDown from '../../assets/icons/money-down.svg';
import MoneyCierre from '../../assets/icons/money-cierre.svg';

import {
  getCajaChicasAction,
  getSeleccionarCajaChicaAction,
  putCierreCajaChicaAction,
} from '../../redux/actions/chica.action';

import {
  actualizarFiltros as actualizarOperaciones,
  getOperacionesCajaChicaAction,
} from '../../redux/actions/chica.operacion.action';

import { agregarHeaderAction } from '../../redux/actions/app.action';

const ChicaList = ({
  chicas,
  processing,
  total,
  getCajaChicasAction,
  getSeleccionarCajaChicaAction,
  agregarHeaderAction,
  putCierreCajaChicaAction,
  actualizarOperaciones,
  getOperacionesCajaChicaAction,
}) => {
  const columns = [
    {
      key: 'ch',
      align: 'center',
      content: (item) => (
        <>
          {item.fc == null && (
            <div
              title="Cierre de caja"
              style={{ display: 'inline-block', marginRight: '5px' }}
            >
              <Icon
                svg={MoneyCierre}
                transparent
                onClick={() =>
                  confirmacion(
                    '¿Está seguro?',
                    `Realizar cierre de caja chica de ${item.ns}`
                  )
                    .then(() => {
                      putCierreCajaChicaAction({ is: item.is, ch: item.ch });
                    })
                    .catch(() => {})
                }
              />
            </div>
          )}
          <div title="Operaciones de caja" style={{ display: 'inline-block' }}>
            <Icon
              svg={MoneyDown}
              transparent
              onClick={() => {
                getSeleccionarCajaChicaAction(item.is, item.ch);
                actualizarOperaciones({ su: item.is, ch: item.ch });
                let head = {
                  mm: 'BAN_CACH_OPER',
                  nf: false,
                  op: 'Operaciones caja chica',
                  pp: 'BAN_INIC',
                  or: 3,
                  im: 'payments',
                  st: true,
                };
                agregarHeaderAction(head);
                getOperacionesCajaChicaAction({});
              }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'sn',
      label: 'Soles',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.sn)),
    },
    {
      key: 'se',
      label: 'Dolares',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.se)),
    },
    {
      key: 'fa',
      label: 'Apertura',
      align: 'right',
      content: (item) => formatoFecha(item.fa, '', false),
    },
    {
      key: 'fc',
      label: 'Cierre',
      align: 'right',
      content: (item) => formatoFecha(item.fc, 'Pendiente', false),
    },
    {
      key: 'nr',
      label: 'Responsable',
    },
  ];

  return (
    <Bloque titulo="Operaciones de caja">
      <Tabla
        columns={columns}
        data={chicas}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getCajaChicasAction({ page, pageSize: rows })
        }
        searcheable={false}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    chicas: state.chica.chicas,
    processing: state.chica.processing,
    total: state.chica.total,
  };
};

export default connect(mapStateToProps, {
  getCajaChicasAction,
  getSeleccionarCajaChicaAction,
  agregarHeaderAction,
  putCierreCajaChicaAction,
  actualizarOperaciones,
  getOperacionesCajaChicaAction,
})(ChicaList);
