import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import AdicionalList from './AdicionalList';
import AdicionalFilter from './AdicionalFilter';
import AdicionalItem from './AdicionalItem';

const Adicional = ({ openModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDetallesPorCabeceraAction({ ca: 'ELEMENTOSINSTALACION' }));
    dispatch(getDetallesPorCabeceraAction({ ca: 'ESTADOCONTRATO' }));
  }, []);

  return (
    <div className="op-component">
      <AdicionalList />
      <AdicionalFilter />
      {openModal && <AdicionalItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.adicional.openModal,
  };
};

export default connect(mapStateToProps)(Adicional);
