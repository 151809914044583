import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import { cajeroModel } from '../../redux/models/cajero.model';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  closeModal,
  putAperturaCajeroAction,
} from '../../redux/actions/cajero.action';

import { getUsuariosAction } from '../../redux/actions/usuario.action';

import {
  getMaquinasAction,
  actualizarFiltros,
} from '../../redux/actions/maquina.action';

import SaveIcon from '@material-ui/icons/Save';

const CajeroApertura = ({
  usuarios,
  processing,
  processingUser,
  errors,
  sucursales,
  maquinas,
  processingMaquina,
}) => {
  const dispatch = useDispatch();
  const [operacion, setOperacion] = useState(cajeroModel);

  useEffect(() => {
    return () => {
      dispatch(actualizarFiltros({ isf: null }));
    };
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOperacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Registrar apertura de caja`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={14}
              onClick={() =>
                confirmacion('¿Está seguro?', `Aperturar la caja`)
                  .then(() => {
                    dispatch(putAperturaCajeroAction(operacion));
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              dispatch(closeModal());
            })
            .catch(() => {})
        }
      >
        <Combo
          name="is"
          value={operacion.is}
          label="Sucursal"
          size={2}
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          tabIndex={10}
          error={errors.is}
          onChange={(item) => {
            handleChange(item);
            dispatch(getUsuariosAction(item.value));
            dispatch(actualizarFiltros({ isf: item.value }));
            dispatch(getMaquinasAction({}));
          }}
          info={'Sucursal donde se realizara la apertura de caja'}
        />
        <Combo
          name="ur"
          value={operacion.ur}
          disabled={processingUser}
          label="Responsable"
          size={2}
          data={usuarios.map((x) => ({ value: x.us, label: x.np }))}
          tabIndex={11}
          error={errors.ur}
          onChange={handleChange}
          info={'Usuario responsable de la caja'}
        />
        <Combo
          name="pv"
          value={operacion.pv}
          disabled={processingMaquina}
          label="Punto de venta"
          size={2}
          data={maquinas.map((x) => ({ value: x.pv, label: x.np }))}
          tabIndex={12}
          error={errors.pv}
          onChange={handleChange}
        />
        <Texto
          name="ss"
          value={operacion.ss}
          label={`Soles`}
          size={2}
          tabIndex={12}
          error={errors.ss}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'ss',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <Texto
          name="sd"
          value={operacion.sd}
          label={`Dolares`}
          size={2}
          tabIndex={13}
          error={errors.sd}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'sd',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.cajero.processing,
    errors: state.cajero.errors,
    sucursales: state.sucursal.sucursales,
    usuarios: state.usuario.usuarios,
    processingUser: state.usuario.processing,
    maquinas: state.maquina.maquinas,
    processingMaquina: state.maquina.processing,
  };
};

export default connect(mapStateToProps)(CajeroApertura);
