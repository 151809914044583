import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Bloque from '../../commons/Bloque';

import Tarea from './Tarea';
import StartEnd from './StartEnd';
import Conector from './Conector';

import '../../css/diagrama.css';

const Diagrama = () => {
  const [tasks, setTasks] = useState([
    {
      id: '00',
      x: 100,
      y: 200,
      init: true,
    },
  ]);

  const handleChangeLabelTask = (e) => {
    setTasks(
      tasks.map((x) =>
        x.id === e.name
          ? {
              ...x,
              lb: e.value,
            }
          : x
      )
    );
  };

  const handleNewTask = (item) => {
    setTasks([
      ...tasks,
      {
        id: new Date().getTime().toString(),
        lb: 'tarea',
        x: item.x + 250,
        y: item.y,
        ins: [
          {
            id: item.id,
            start: { x: item.x, y: item.y },
            end: { x: item.x + 250, y: item.y },
          },
        ],
      },
    ]);
  };

  const handleDeleteTask = (id) => {
    setTasks(tasks.filter((x) => x.id !== id));
  };

  const handleDeleteLine = (id) => {
    console.log('3sd');
    setLines(lines.filter((x) => x.id !== id));
  };

  return (
    <div className="op-component op-content-diagram">
      <Bloque titulo="Flujo de trabajo">
        {tasks.map((t) => {
          return t.init ? (
            <StartEnd
              key={t.id}
              id={t.id}
              posX={t.x}
              posY={t.y}
              start={true}
              onNewTask={handleNewTask}
              onDeleteTask={handleDeleteTask}
            />
          ) : (
            <Tarea
              key={t.id}
              id={t.id}
              name={t.id}
              label={t.lb}
              posX={t.x}
              posY={t.y}
              onChange={(e) => handleChangeLabelTask(e)}
              onNewTask={handleNewTask}
              onDeleteTask={handleDeleteTask}
              entradas={t.ins}
            />
          );
        })}
      </Bloque>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(Diagrama);
