import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { detalleModel } from '../models/detalle.model';

export const DETALLE_PROCESS = '[Detalle] Detalle procesando';
export const DETALLE_FAIL = '[Detalle] Detalle proceso fail';
export const DETALLE_SAVE_SUCCESS = '[Detalle] Detalle proceso exito';
export const DETALLE_LIST_SUCCESS = '[Detalle] Detalle lista exito';
export const DETALLE_LIST_FAIL = '[Detalle] Detalle lista error';
export const DETALLE_SELECTED = '[Detalle] Detalle seleccionado';
export const DETALLE_CLOSE = '[Detalle] Detalle cerrar modal';
export const DETALLE_FILTER = '[Detalle] Detalle filtros';
export const DETALLE_TIPOALMACEN = '[Detalle] Detalle lista tipo almacen';
export const DETALLE_UNIDADMEDIDA =
  '[Detalle] Detalle lista tipo unidad medida';
export const DETALLE_ESTADOORDEN =
  '[Detalle] Detalle lista estados orden compra';
export const DETALLE_CATEGORIAS = '[Detalle] Detalle lista categorias';
export const DETALLE_TIPOINGRESO = '[Detalle] Detalle lista tipo ingreso';
export const DETALLE_TIPOSALIDA = '[Detalle] Detalle lista tipo salida';
export const DETALLE_TIPOCAJAS = '[Detalle] Detalle lista tipo caja';
export const DETALLE_TIPODOCUS =
  '[Detalle] Detalle lista tipo documentos de compra';
export const DETALLE_TIPOADICIONAL = '[Detalle] Detalle lista tipo adicionales';
export const DETALLE_TIPODATO = '[Detalle] Detalle lista tipo datos';
export const DETALLE_ESTADOCONTRATO =
  '[Detalle] Detalle lista estados de contrato';
export const DETALLE_FORMAPAGO = '[Detalle] Detalle lista formas de pago';
export const DETALLE_TIPODOCUSIDENTIDAD =
  '[Detalle] Detalle lista tipo documentos de identidad';
export const DETALLE_ESTADOCOBRANZA =
  '[Detalle] Detalle lista estado de cobranzas';
export const DETALLE_TIPOSTRASLADO =
  '[Detalle] Detalle lista tipos de traslados';
export const DETALLE_TIPOBANCOS = '[Detalle] Detalle lista tipos bancos';
export const DETALLE_ESTADOPEDIDO = '[Detalle] Detalle lista estado de pedidos';

export const getDetallesAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { ca, st, search } = getState().detalle;

    dispatch({
      type: DETALLE_PROCESS,
    });

    const params = {
      ie: currentUser.ie,
      ca,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.DETALLE, { params })
      .then((response) => {
        dispatch({
          type: DETALLE_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: DETALLE_LIST_FAIL,
        });
      });
  };

export const getDetallesPorCabeceraAction =
  ({ ca, page, pageSize }) =>
  (dispatch, getState) => {
    let { currentUser } = getState().app;

    const params = {
      ie: currentUser.ie,
      page,
      pageSize,
    };

    api
      .get(`${servicios.DETALLE}/${ca}/cabecera`, { params })
      .then((response) => {
        switch (ca) {
          case 'TIPOALMACEN':
            dispatch({
              type: DETALLE_TIPOALMACEN,
              payload: response,
            });
            return;
          case 'TIPOUNIDADMEDIDA':
            dispatch({
              type: DETALLE_UNIDADMEDIDA,
              payload: response,
            });
            return;
          case 'ESTADOORDEN':
            dispatch({
              type: DETALLE_ESTADOORDEN,
              payload: response,
            });
            return;
          case 'CATEGORIAPRODUCTO':
            dispatch({
              type: DETALLE_CATEGORIAS,
              payload: response,
            });
            return;
          case 'TIPOINGRESO':
            dispatch({
              type: DETALLE_TIPOINGRESO,
              payload: response,
            });
            return;
          case 'TIPOSALIDA':
            dispatch({
              type: DETALLE_TIPOSALIDA,
              payload: response,
            });
            return;
          case 'TIPOCAJA':
            dispatch({
              type: DETALLE_TIPOCAJAS,
              payload: response,
            });
            return;
          case 'TIPODOCUMENTO':
            dispatch({
              type: DETALLE_TIPODOCUS,
              payload: response,
            });
            return;
          case 'TIPODATO':
            dispatch({
              type: DETALLE_TIPODATO,
              payload: response,
            });
            return;
          case 'ESTADOCONTRATO':
            dispatch({
              type: DETALLE_ESTADOCONTRATO,
              payload: response,
            });
            return;
          case 'FORMASPAGO':
            dispatch({
              type: DETALLE_FORMAPAGO,
              payload: response,
            });
            return;
          case 'TIPODOCUMENTOIDENTIDAD':
            dispatch({
              type: DETALLE_TIPODOCUSIDENTIDAD,
              payload: response,
            });
            return;
          case 'ESTADOCOBRANZA':
            dispatch({
              type: DETALLE_ESTADOCOBRANZA,
              payload: response,
            });
            return;
          case 'MOTIVOTRASLADO':
            dispatch({
              type: DETALLE_TIPOSTRASLADO,
              payload: response,
            });
            return;
          case 'TIPOBANCOS':
            dispatch({
              type: DETALLE_TIPOBANCOS,
              payload: response,
            });
            return;
          case 'ESTADOPEDIDO':
            dispatch({
              type: DETALLE_ESTADOPEDIDO,
              payload: response,
            });
            return;
          default:
            dispatch({
              type: DETALLE_TIPOADICIONAL,
              payload: response,
            });
            return;
        }
      });
  };

export const getSeleccionarDetalleAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: DETALLE_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: DETALLE_SELECTED,
      payload: detalleModel,
    });
  }
};

export const postDetalleAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: DETALLE_PROCESS,
  });

  let detalle = {
    ...item,
    ie: currentUser.ie,
  };

  api
    .post(servicios.DETALLE, detalle)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getDetallesAction({}));
      dispatch({ type: DETALLE_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: DETALLE_FAIL,
        payload: error.data.errors ? error.data.errors : detalleModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { ca, st, search } = getState().detalle;

  let item = {
    ca: filtros.ca !== undefined ? (filtros.ca === '' ? null : filtros.ca) : ca,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: DETALLE_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: DETALLE_CLOSE,
  });
};
