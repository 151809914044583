import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Report from '../app/Report';

import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

import {
  getOperacionesAction,
  actualizarFiltros,
} from '../../redux/actions/operacion.action';

import { servicios } from '../../redux/helpers/servicios';

const OperacionFilter = ({
  tipoIngresos,
  tipoSalidas,
  sucursales,
  empresa,
  search,
  getOperacionesAction,
  actualizarFiltros,
}) => {
  const [um, setUm] = useState('');
  const [su, setSu] = useState('');
  const [pr, setPr] = useState('');
  const [fi, setFi] = useState('');
  const [ff, setFf] = useState('');
  const [params, setParams] = useState({});

  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <>
      <Filtros
        onFilter={() => getOperacionesAction({})}
        showNewButton={false}
        actions={() => (
          <>
            <Boton
              className="op-filter"
              icon={() => <PrintIcon />}
              tabIndex={22}
              dropdownable
              style={{
                '--button-icon-color': '#1ab394',
              }}
              showDropdownOnTop
              actions={[
                {
                  key: '01',
                  label: 'PDF',
                  icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                  onClick: () => {
                    setOpenReporte(true);
                    setParams({ su, ct: um, is: pr, fi, ff, search });
                  },
                },
                {
                  key: '02',
                  label: 'Excel',
                  icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                  onClick: () => {
                    setOpenReporteExcel(true);
                    setParams({ su, ct: um, is: pr, fi, ff, search, xl: '0' });
                  },
                },
              ]}
            >
              Imprimir
            </Boton>
          </>
        )}
      >
        <Combo
          name="su"
          label="Sucursal"
          value={su}
          placeholder="Todos"
          tabIndex={20}
          size={3}
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          onChange={({ value }) => {
            setSu(value);
            actualizarFiltros({ su: value });
          }}
        />
        <Combo
          name="um"
          label="Tipo operaci&oacute;n"
          value={um}
          placeholder="Todos"
          tabIndex={21}
          size={3}
          data={tipoIngresos
            .concat(tipoSalidas)
            .map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          dropdownTop
          onChange={({ value }) => {
            setUm(value);
            actualizarFiltros({ ct: value });
          }}
        />
        <Combo
          name="pr"
          value={pr}
          label="Ingreso / Salida"
          placeholder="Ambos"
          size={3}
          tabIndex={22}
          data={[
            { value: '1', label: 'Ingreso' },
            { value: '0', label: 'Salida' },
          ]}
          onChange={({ value }) => {
            setPr(value);
            actualizarFiltros({ is: value });
          }}
        />
        <Texto
          name="fi"
          value={fi}
          label="Fecha de inicio operaci&oacute;n"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            setFi(value);
            actualizarFiltros({ fi: value });
          }}
        />
        <Texto
          name="ff"
          value={ff}
          label="Fecha fin operaci&oacute;n"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            setFf(value);
            actualizarFiltros({ ff: value });
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.OPERACION}/${empresa}/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.OPERACION}/${empresa}/imprimir`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteMovimientos'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoIngresos: state.detalle.tipoIngresos,
    tipoSalidas: state.detalle.tipoSalidas,
    sucursales: state.sucursal.sucursales,
    empresa: state.app.currentUser.ie,
    search: state.operacion.search,
  };
};

export default connect(mapStateToProps, {
  getOperacionesAction,
  actualizarFiltros,
})(OperacionFilter);
