import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';

import {
  getOperacionesCajaChicaAction,
  actualizarFiltros,
  openModal,
} from '../../redux/actions/chica.operacion.action';

import { getSeleccionarCajaChicaAction } from '../../redux/actions/chica.action';

const ChicaOperacionFilter = ({
  tipoCajas,
  selected,
  getOperacionesCajaChicaAction,
  actualizarFiltros,
  openModal,
  getSeleccionarCajaChicaAction,
}) => {
  const [tc, setTc] = useState('');
  const [tm, setTm] = useState('');
  const [is, setIs] = useState('');
  const [fi, setFi] = useState('');
  const [ff, setFf] = useState('');

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <Filtros
      onFilter={() => {
        getSeleccionarCajaChicaAction(selected.is, selected.ch);
        getOperacionesCajaChicaAction({});
      }}
      onNew={() => openModal()}
    >
      <Combo
        name="tc"
        label="Tipo caja"
        value={tc}
        placeholder="Todos"
        tabIndex={20}
        size={3}
        data={tipoCajas.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={({ value }) => {
          setTc(value);
          actualizarFiltros({ tc: value });
        }}
      />
      <Combo
        name="tm"
        value={tm}
        label="Moneda"
        placeholder="Ambos"
        size={3}
        tabIndex={21}
        data={[
          { value: '0', label: 'Soles' },
          { value: '1', label: 'Dolares' },
        ]}
        onChange={({ value }) => {
          setTm(value);
          actualizarFiltros({ tm: value });
        }}
      />
      <Combo
        name="is"
        value={is}
        label="Ingreso / Salida"
        placeholder="Ambos"
        size={3}
        tabIndex={22}
        data={[
          { value: '1', label: 'Ingreso' },
          { value: '0', label: 'Salida' },
        ]}
        onChange={({ value }) => {
          setIs(value);
          actualizarFiltros({ is: value });
        }}
      />
      <Texto
        name="fi"
        value={fi}
        label="Fecha de inicio operaci&oacute;n"
        tabIndex={23}
        size={3}
        type={'date'}
        max={`${year}-${month}-${day}`}
        onChange={({ value }) => {
          setFi(value);
          actualizarFiltros({ fi: value });
        }}
      />
      <Texto
        name="ff"
        value={ff}
        label="Fecha fin operaci&oacute;n"
        tabIndex={23}
        size={3}
        type={'date'}
        max={`${year}-${month}-${day}`}
        onChange={({ value }) => {
          setFf(value);
          actualizarFiltros({ ff: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoCajas: state.detalle.tipoCajas,
    selected: state.chica.selected,
  };
};

export default connect(mapStateToProps, {
  getOperacionesCajaChicaAction,
  actualizarFiltros,
  openModal,
  getSeleccionarCajaChicaAction,
})(ChicaOperacionFilter);
