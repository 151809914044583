import React from 'react';
import { connect } from 'react-redux';

import {
  eliminarHeaderAction,
  seleccionarHeaderAction,
  eliminarTodasHeaderAction,
} from '../../redux/actions/app.action';

import Icon from '@material-ui/core/Icon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';

import '../../css/info.css';

const Lista = ({
  headers,
  selected,
  open,
  eliminarHeaderAction,
  seleccionarHeaderAction,
  eliminarTodasHeaderAction,
  onClose,
}) => {
  let listado = headers.map((i) => {
    return (
      <div key={i.mm} className="op-lista-item">
        {i.im && (
          <Icon
            style={{
              color: `${
                selected && selected.mm === i.mm ? '#1a95d5' : '#cccccc'
              }`,
            }}
          >
            {i.im}
          </Icon>
        )}
        <label
          onClick={() => {
            seleccionarHeaderAction(i.mm);
            onClose();
          }}
          style={{
            cursor: 'pointer',
            color: `${
              selected && selected.mm === i.mm ? '#000000' : '#cccccc'
            }`,
          }}
        >
          {i.op}
        </label>
        <HighlightOffIcon
          onClick={() => {
            eliminarHeaderAction(i.mm);
          }}
          fontSize="small"
          title="cerrar"
          style={{
            cursor: 'pointer',
            color: `${selected && selected.mm === i.mm ? 'tomato' : '#cccccc'}`,
            paddingLeft: '10px',
          }}
        />
      </div>
    );
  });
  return (
    <div className={`op-content-info${open ? ' open' : ''}`}>
      <div className="op-lista-item">
        <DeleteSweepIcon style={{ color: '#cccccc' }} />
        <label
          onClick={() => eliminarTodasHeaderAction()}
          style={{ cursor: 'pointer' }}
        >
          Eliminar todos
        </label>
      </div>
      <div className="separador line"></div>
      {listado}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headers: state.app.headers,
    selected: state.app.selected,
  };
};

export default connect(mapStateToProps, {
  eliminarHeaderAction,
  seleccionarHeaderAction,
  eliminarTodasHeaderAction,
})(Lista);
