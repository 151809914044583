import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getCajerosAction } from '../../redux/actions/cajero.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import CajeroList from './CajeroList';
import CajeroFilter from './CajeroFilter';
import CajeroApertura from './CajeroApertura';

const Cajero = ({ openModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCajerosAction({}));
    dispatch(getSucursalesAction({}));
  }, []);

  return (
    <div className="op-component">
      <CajeroList />
      <CajeroFilter />
      {openModal && <CajeroApertura />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.cajero.openModal,
  };
};

export default connect(mapStateToProps)(Cajero);
