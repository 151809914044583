import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import { mensajeMini } from '../../commons/Mensajes';

import {
  getAdiconalesAction,
  getSeleccionarAdicionalAction,
  actualizarFiltros,
} from '../../redux/actions/adicional.action';

const AdicionalFilter = ({
  tipoAdicionales,
  estadoContrato,
  tipo,
  elemento,
  searchable,
}) => {
  const [st, setSt] = useState('');

  const dispatch = useDispatch();

  return (
    <>
      {searchable ? (
        <Combo
          name="tp"
          label="Tipo elemento"
          value={elemento}
          tabIndex={21}
          size={2}
          data={tipoAdicionales.map((i) => ({ value: i.dt, label: i.de }))}
          onChange={({ value, label }) => {
            dispatch(
              actualizarFiltros({ ta: value, nt: label, pa: null, pn: null })
            );
            dispatch(getAdiconalesAction());
          }}
        />
      ) : (
        <Filtros
          onFilter={() => dispatch(getAdiconalesAction({}))}
          onNew={() => {
            if (tipo) {
              dispatch(getSeleccionarAdicionalAction());
            } else {
              mensajeMini('Seleccione tipo de elemento');
            }
          }}
        >
          <Combo
            name="tp"
            label="Tipo elemento"
            value={elemento}
            tabIndex={21}
            size={2}
            data={tipoAdicionales
              .concat(estadoContrato)
              .map((i) => ({ value: i.dt, label: i.de }))}
            onChange={({ value, label }) => {
              dispatch(
                actualizarFiltros({ ta: value, nt: label, pa: null, pn: null })
              );
              dispatch(getAdiconalesAction());
            }}
          />
          <Combo
            name="st"
            value={st}
            label="Estado del registro"
            placeholder="Todos"
            size={2}
            tabIndex={22}
            data={[
              { value: '1', label: 'Vigente' },
              { value: '0', label: 'No vigente' },
            ]}
            onChange={({ value }) => {
              setSt(value);
              dispatch(actualizarFiltros({ st: value }));
            }}
          />
        </Filtros>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoAdicionales: state.detalle.tipoAdicionales,
    estadoContrato: state.detalle.estadoContrato,
    tipo: state.adicional.nt,
    elemento: state.adicional.ta,
  };
};

export default connect(mapStateToProps)(AdicionalFilter);
