export const proveedorModel = {
  pv: null,
  rz: null,
  nr: null,
  di: null,
  re: null,
  co: null,
  nf: null,
  nm: null,
  su: null,
  cs: null,
  pc: null,
  pt: null,
  st: true,
};
