import * as fromCobranza from '../actions/cobranza.action';
import { cobranzaModal } from '../models/cobranza.model';

let initialState = {
  cobranzas: [],
  cobranzasReportes: [],
  co: null,
  ec: null,
  fi: null,
  ff: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCobranza.COBRANZA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: cobranzaModal,
      };
    case fromCobranza.COBRANZA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        cobranzas: action.payload.data,
        total: action.payload.count,
      };
    case fromCobranza.COBRANZA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        cobranzas: [],
        total: 0,
      };
    case fromCobranza.COBRANZA_LIST_REPORT:
      return {
        ...state,
        processing: false,
        cobranzasReportes: action.payload,
      };
    case fromCobranza.COBRANZA_STATES:
      return {
        ...state,
        processing: false,
        estados: action.payload,
      };
    case fromCobranza.COBRANZA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: cobranzaModal,
        openModal: true,
      };
    case fromCobranza.COBRANZA_SELECTED_NEW:
      return {
        ...state,
        selected: action.payload,
        errors: cobranzaModal,
        openModal: true,
      };
    case fromCobranza.COBRANZA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: cobranzaModal,
      };
    case fromCobranza.COBRANZA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCobranza.COBRANZA_FILTER:
      return {
        ...state,
        co: action.payload.co,
        ec: action.payload.ec,
        fi: action.payload.fi,
        ff: action.payload.ff,
        page: 0,
      };
    case fromCobranza.COBRANZA_CLOSE:
      return {
        ...state,
        errors: cobranzaModal,
        openModal: false,
      };
    case fromCobranza.COBRANZA_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
