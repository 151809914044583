import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';
import Report from '../app/Report';
import {
  getCobranzasAction,
  getSeleccionarCobranzaAction,
  actualizarFiltros,
} from '../../redux/actions/cobranza.action';
import { servicios } from '../../redux/helpers/servicios';

import DebtIcon from '../../assets/icons/money-debt.svg';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

const CobranzaFilter = ({
  perfil,
  empresa,
  estado,
  inicio,
  fin,
  estadoCobranza,
  contrato,
}) => {
  const [params, setParams] = useState({});
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);
  const dispatch = useDispatch();

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <>
      <Filtros
        onFilter={() => dispatch(getCobranzasAction())}
        actions={() => (
          <>
            {perfil === 'NOBASICO' && contrato && (
              <>
                <Boton
                  className="op-filter"
                  icon={() => <Icon svg={DebtIcon} />}
                  tabIndex={21}
                  onClick={() => dispatch(getSeleccionarCobranzaAction())}
                >
                  Generar deuda
                </Boton>
              </>
            )}
            {perfil === 'NOBASICO' && (
              <>
                <Boton
                  className="op-filter"
                  icon={() => <PrintIcon />}
                  tabIndex={22}
                  dropdownable
                  style={{
                    '--button-icon-color': '#1ab394',
                  }}
                  showDropdownOnTop
                  actions={[
                    {
                      key: '01',
                      label: 'PDF',
                      icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                      onClick: () => {
                        setOpenReporte(true);
                        setParams({ ec: estado, fi: inicio, ff: fin });
                      },
                    },
                    {
                      key: '02',
                      label: 'Excel',
                      icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                      onClick: () => {
                        setOpenReporteExcel(true);
                        setParams({ ec: estado, fi: inicio, ff: fin, xl: '0' });
                      },
                    },
                  ]}
                >
                  Imprimir
                </Boton>
              </>
            )}
          </>
        )}
        showNewButton={false}
        showFilterButton={perfil === 'NOBASICO' && contrato === null}
      >
        <Combo
          name="ec"
          label="Estado"
          value={estado}
          placeholder="Todos"
          tabIndex={21}
          size={3}
          data={estadoCobranza.map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          dropdownTop
          onChange={({ value }) => {
            dispatch(actualizarFiltros({ ec: value }));
          }}
        />
        <Texto
          name="fi"
          value={inicio}
          label="Fecha de inicio vencimiento"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            dispatch(actualizarFiltros({ fi: value }));
          }}
        />
        <Texto
          name="ff"
          value={fin}
          label="Fecha fin vencimiento"
          tabIndex={23}
          size={3}
          type={'date'}
          onChange={({ value }) => {
            dispatch(actualizarFiltros({ ff: value }));
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.COBRANZA}/${empresa}/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.COBRANZA}/${empresa}/imprimir`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteCobranzas'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    perfil: state.app.currentUser.tr,
    empresa: state.app.currentUser.ie,
    contrato: state.cobranza.co,
    estado: state.cobranza.ec,
    inicio: state.cobranza.fi,
    fin: state.cobranza.ff,
    estadoCobranza: state.detalle.estadoCobranza,
  };
};

export default connect(mapStateToProps)(CobranzaFilter);
