import React, { useState } from 'react';
import { connect } from 'react-redux';

import HeaderItem from './HeaderItem';
import Info from './Info';
import Lista from './Lista';

import {
  abrirMenuAction,
  seleccionarInicioAction,
} from '../../redux/actions/app.action';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FilterListIcon from '@material-ui/icons/FilterList';
import HomeIcon from '@material-ui/icons/Home';

import '../../css/header.css';

const Header = ({
  headers,
  closeMenu,
  abrirMenuAction,
  seleccionarInicioAction,
}) => {
  const [open, setOpen] = useState(false);
  const [openLista, setOpenLista] = useState(false);
  const [checked, setChecked] = useState(closeMenu);

  let items = headers.map((item) => {
    return (
      <HeaderItem key={item.mm} id={item.mm} label={item.op} icon={item.im} />
    );
  });
  return (
    <div className={`op-header${closeMenu === true ? ' active' : ''}`}>
      <div className="op-header-app">{items}</div>
      <div className="op-header-actions">
        <HomeIcon
          fontSize="large"
          style={{ color: '#ffffff', cursor: 'pointer' }}
          onClick={() => seleccionarInicioAction()}
        />
        <FilterListIcon
          className="listado"
          fontSize="large"
          style={{ color: '#ffffff', cursor: 'pointer' }}
          onClick={() => setOpenLista(!openLista)}
        />
        <AccountBoxIcon
          fontSize="large"
          style={{ color: '#ffffff', cursor: 'pointer' }}
          onClick={() => setOpen(!open)}
        />
        <div className="op-bars">
          <input
            type="checkbox"
            id="checkMenubar"
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <label htmlFor="checkMenubar" onClick={abrirMenuAction}>
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <Info open={open} />
        <Lista open={openLista} onClose={() => setOpenLista(false)} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    headers: state.app.headers,
    closeMenu: state.app.closeMenu,
  };
};
export default connect(mapStateToProps, {
  abrirMenuAction,
  seleccionarInicioAction,
})(Header);
