export const perfilesModal = {
  pe: null,
  pfs: [],
  rls: [],
  ce: null,
  de: null,
  or: null,
  na: null,
  ep: 0,
  st: false,
};
