import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';

import {
  getMaterialesAction,
  getSeleccionarMaterialAction,
  actualizarFiltros,
} from '../../redux/actions/material.action';

const MaterialFilter = ({
  tipoUnidadMedida,
  getMaterialesAction,
  getSeleccionarMaterialAction,
  actualizarFiltros,
}) => {
  const [um, setUm] = useState('');
  const [st, setSt] = useState('');
  const [pr, setPr] = useState('');

  return (
    <Filtros
      onFilter={() => getMaterialesAction({})}
      onNew={() => getSeleccionarMaterialAction()}
    >
      <Combo
        name="um"
        label="Unidad medida"
        value={um}
        placeholder="Todos"
        tabIndex={20}
        size={3}
        data={tipoUnidadMedida.map((i) => ({ value: i.dt, label: i.de }))}
        searcheable
        dropdownTop
        onChange={({ value }) => {
          setUm(value);
          actualizarFiltros({ um: value });
        }}
      />
      <Combo
        name="pr"
        value={pr}
        label="Producto / Insumo"
        placeholder="Ambos"
        size={3}
        tabIndex={21}
        data={[
          { value: '1', label: 'Producto' },
          { value: '0', label: 'Insumo' },
        ]}
        onChange={({ value }) => {
          setPr(value);
          actualizarFiltros({ pr: value });
        }}
      />
      <Combo
        name="st"
        value={st}
        label="Estado del registro"
        placeholder="Todos"
        size={3}
        tabIndex={22}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No vigente' },
        ]}
        onChange={({ value }) => {
          setSt(value);
          actualizarFiltros({ st: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoUnidadMedida: state.detalle.tipoUnidadMedida,
  };
};

export default connect(mapStateToProps, {
  getMaterialesAction,
  getSeleccionarMaterialAction,
  actualizarFiltros,
})(MaterialFilter);
