import React from 'react';
import PropTypes from 'prop-types';

import Boton from './Boton';
import Icon from './Icon';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import CachedIcon from '@material-ui/icons/Cached';

import '../css/commons/wizard.css';

const WizardContext = React.createContext();

export const useWizardContext = () => {
  const context = React.useContext(WizardContext);
  if (!context) {
    throw new Error(
      `Un componente compuesto de Wizard no puede ser
       renderizado fuera del Wizard padre`
    );
  }
  return context;
};

const defaultInitialState = {
  activePageIndex: 0,
  steps: 0,
};

export const actions = {
  NEXT_PAGE: 'NEXT_PAGE',
  PREV_PAGE: 'PREV_PAGE',
  SET_STEPS: 'SET_STEPS',
};

const defaultReducer = (state, action) => state;

export const wizardReducer = (state, action) => {
  const { activePageIndex } = state;
  switch (action.type) {
    case actions.NEXT_PAGE:
      return { ...state, activePageIndex: activePageIndex + 1 };
    case actions.PREV_PAGE:
      return { ...state, activePageIndex: activePageIndex - 1 };
    case actions.SET_STEPS:
      return { ...state, steps: action.payload };
    default:
      return state;
  }
};

const combineReducers =
  (...reducers) =>
  (state, action) => {
    return reducers.reduce((acc, nextReducer) => {
      return nextReducer(acc, action);
    }, state);
  };

const Wizard = ({ children, reducer = defaultReducer, initialState }) => {
  const [{ activePageIndex, steps }, dispatch] = React.useReducer(
    combineReducers(wizardReducer, reducer),
    {
      ...defaultInitialState,
      ...initialState,
    }
  );

  const goNextPage = () => {
    dispatch({ type: actions.NEXT_PAGE });
  };

  const goPrevPage = () => {
    dispatch({ type: actions.PREV_PAGE });
  };

  const setSteps = React.useCallback(
    (n) => {
      dispatch({ type: actions.SET_STEPS, payload: n });
    },
    [dispatch]
  );

  const context = {
    activePageIndex,
    steps,
    goNextPage,
    goPrevPage,
    setSteps,
  };

  return (
    <WizardContext.Provider value={context}>
      <div className="op-wizard">{children}</div>
    </WizardContext.Provider>
  );
};

export const WizardHeader = ({ data }) => {
  const { activePageIndex } = useWizardContext();
  const headers = data.map((h, i) => (
    <li
      key={i}
      className={`${activePageIndex > i ? 'active' : ''} ${
        activePageIndex === i ? 'current' : ''
      }`}
    >
      <Icon
        svg={h.icon}
        color={`${h.color ? h.color : '#01ab97'}`}
        size={h.size ? h.size : 50}
      />
      <div className="icon">
        <div className="shadow"></div>
        {activePageIndex > i ? (
          <CheckIcon className="image" />
        ) : activePageIndex === i ? (
          <CachedIcon className="image" />
        ) : (
          <CloseIcon className="image" />
        )}
      </div>
      <label>{h.label}</label>
    </li>
  ));
  return <ul className="op-wizard__header">{headers}</ul>;
};

const WizardPages = (props) => {
  const { activePageIndex, setSteps } = useWizardContext();
  const pages = React.Children.toArray(props.children);
  const steps = React.Children.count(props.children);
  const currentPage = pages[activePageIndex];
  React.useEffect(() => {
    setSteps(steps);
  }, [steps, setSteps]);
  return (
    <>
      {pages.map((p, i) => (
        <div
          key={i}
          {...props}
          style={{ display: `${activePageIndex === i ? 'flex' : 'none'}` }}
        >
          {p}
        </div>
      ))}
    </>
  );
};

export const WizardButtonPrev = (props) => {
  const { goPrevPage, activePageIndex } = React.useContext(WizardContext);
  return activePageIndex > 0 ? (
    <Boton type="button" {...props} onClick={goPrevPage}>
      Atras
    </Boton>
  ) : null;
};

export const WizardButtonNext = (props) => {
  const { goNextPage, activePageIndex, steps } =
    React.useContext(WizardContext);
  return activePageIndex < steps - 1 ? (
    <Boton type="button" {...props} onClick={goNextPage}>
      Siguiente
    </Boton>
  ) : null;
};

export const WizardButtonFinish = (props) => {
  const { activePageIndex, steps } = React.useContext(WizardContext);
  return activePageIndex === steps - 1 ? (
    <Boton type="button" {...props}>
      {props.children}
    </Boton>
  ) : null;
};

WizardHeader.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
      color: PropTypes.string,
      size: PropTypes.number,
    })
  ),
};

WizardHeader.defaultProps = {
  data: [],
};

Wizard.Header = WizardHeader;
Wizard.Pages = WizardPages;
Wizard.ButtonNext = WizardButtonNext;
Wizard.ButtonPrev = WizardButtonPrev;
Wizard.ButtonFinish = WizardButtonFinish;

export default Wizard;
