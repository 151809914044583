import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../commons/Icon';

import Delete from '../../assets/icons/delete.svg';

import useDraggable from '../../hooks/useDraggable';
import Conector from './Conector';

const StartEnd = ({ id, start, posX, posY, onNewTask, onDeleteTask }) => {
  const taskRef = useRef(null);
  useDraggable(taskRef);

  const [show, setShow] = useState(false);
  return (
    <div
      className={`op-content-task ${start ? 'start' : 'end'}`}
      style={{ left: posX, top: posY }}
      ref={taskRef}
      onClick={(e) => {
        setShow(!show);
        e.stopPropagation();
      }}
    >
      <div className={`actions${show ? ' show' : ''}`}>
        <div
          className="new-task"
          onClick={() => {
            const style = window.getComputedStyle(taskRef.current);
            const matrix = new DOMMatrixReadOnly(style.transform);

            onNewTask({
              x: posX + matrix.m41,
              y: posY + matrix.m42,
            });
          }}
          title="Agregar nueva tarea"
        ></div>
        <Icon
          svg={Delete}
          size={15}
          transparent
          title="Eliminar tarea"
          onClick={() => onDeleteTask(id)}
        />
      </div>
    </div>
  );
};

StartEnd.propTypes = {
  id: PropTypes.string.isRequired,
  onNewTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  start: PropTypes.bool,
  posX: PropTypes.number,
  posY: PropTypes.number,
};

StartEnd.defaultProps = {
  start: false,
  posX: 100,
  posY: 200,
};

export default StartEnd;
