import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Boton from '../../commons/Boton';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import FileUpload from '../../commons/FileUpload';
import Report from '../../components/app/Report';

import { confirmacion } from '../../commons/Mensajes';
import { closeModal } from '../../redux/actions/sucursal.action';
import {
  patchAgregarPromocionAction,
  patchEditarArchivoAction,
  patchDarBajaArchivoAction,
} from '../../redux/actions/archivo.action';
import usePromocion from '../../hooks/usePromocion';
import { servicios } from '../../redux/helpers';

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import '../../css/components/promocion.css';

const SucursalPromotion = () => {
  const dispatch = useDispatch();
  const { selected, processing } = useSelector((state) => state.sucursal);
  const { currentUser } = useSelector((state) => state.app);
  const [sucursal] = useState(selected);
  const [documento, setDocumento] = useState(null);
  const [open, setopen] = useState(false);
  const [editar, setEditar] = useState(null);

  const { archivo, processingArchivo, getPromocion } = usePromocion();

  useEffect(() => {
    getPromocion(selected.pp, currentUser.ie);
  }, []);

  return (
    <>
      <Modal
        title={`Promociones sucursal ${sucursal.ns}`}
        processing={processing}
        size="small"
        onActions={() => (
          <Boton
            className="op-dinamic"
            icon={() => <AddIcon />}
            tabIndex={22}
            style={{ '--button-color': '#3285a8' }}
            onClick={() => setopen(true)}
          >
            Nueva promoci&oacute;n
          </Boton>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              dispatch(closeModal());
            })
            .catch(() => {})
        }
      >
        {processingArchivo && <i className="op-loading-mini"></i>}
        {archivo &&
          archivo.dtl &&
          archivo.dtl.map((x) => (
            <div key={x.cr} className="op-promocion-container">
              <div className="actions">
                <div title="Editar">
                  <EditIcon
                    className="icon"
                    style={{
                      color: '#1ab394',
                    }}
                    onClick={() => {
                      setEditar(x);
                      setopen(true);
                    }}
                  />
                </div>
                <div title="Dar de baja">
                  <CloseIcon
                    className="icon"
                    style={{
                      color: 'red',
                    }}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De anular la promoción ${x.na}.${x.ea}`
                      )
                        .then(() =>
                          dispatch(
                            patchDarBajaArchivoAction(
                              archivo.ar,
                              x.cr,
                              'sucursal'
                            )
                          )
                        )
                        .catch(() => {})
                    }
                  />
                </div>
              </div>
              <label
                className={`${x.st === false ? 'op-delete' : ''}`}
              >{`${x.na}.${x.ea}`}</label>
              <Report
                url={`${servicios.ARCHIVO}/${archivo.ar}/empresa/${currentUser.ie}/${x.cr}`}
                params={{}}
                extension={x.ea}
                showInline
                onClose={() => {}}
              />
            </div>
          ))}
      </Modal>
      {open && (
        <Modal
          title={`${editar ? 'Editar' : 'Nueva'} promoción`}
          size="small"
          processing={processing}
          loading="files"
          onActions={() => (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={501}
              onClick={() => {
                if (documento) {
                  confirmacion(
                    '¿Desea guardar?',
                    'Se agregara una nueva promoción'
                  ).then(() => {
                    if (editar) {
                      dispatch(
                        patchEditarArchivoAction(
                          archivo.ar,
                          editar.cr,
                          documento,
                          'sucursal'
                        )
                      );
                    } else {
                      dispatch(
                        patchAgregarPromocionAction(
                          { ar: sucursal.pp, is: sucursal.su },
                          documento
                        )
                      );
                    }
                  });
                } else {
                  mensajeMini('Seleccione archivo');
                }
              }}
            >
              Guardar
            </Boton>
          )}
          onClose={() => {
            setopen(false);
            setEditar(null);
          }}
        >
          {editar && (
            <Texto
              name="nd"
              value={`${editar.na}.${editar.ea}`}
              label="Nombre de archivo"
              tabIndex={500}
              disabled
              onChange={() => {}}
            />
          )}
          <FileUpload
            extensions={['jpg', 'jpeg', 'png']}
            onSelectedFile={(files) => {
              setDocumento(files.length > 0 ? files[0].file : null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default SucursalPromotion;
