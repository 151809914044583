import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { correlativoModel } from '../models/correlativo.model';

export const CORRELATIVO_PROCESS = '[Correlativo] Correlativo procesando';
export const CORRELATIVO_SAVE_SUCCESS =
  '[Correlativo] Correlativo guardar exito';
export const CORRELATIVO_LIST_SUCCESS = '[Correlativo] Correlativo lista exito';
export const CORRELATIVO_LIST_FAIL = '[Correlativo] Correlativo lista error';
export const CORRELATIVO_SELECTED = '[Correlativo] Correlativo seleccionado';
export const CORRELATIVO_CLOSE = '[Correlativo] Correlativo cerrar modal';
export const CORRELATIVO_NEW = '[Correlativo] Correlativo nuevo';
export const CORRELATIVO_FAIL = '[Correlativo] Correlativo proceso error';

export const getCorrelativosAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CORRELATIVO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
  };

  api
    .get(servicios.CORRELATIVO, { params })
    .then((response) => {
      dispatch({
        type: CORRELATIVO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CORRELATIVO_LIST_FAIL,
      });
    });
};

export const getSeleccionarCorrelativoAction =
  (item) => (dispatch, getState) => {
    if (item) {
      let { currentUser } = getState().app;

      dispatch({
        type: CORRELATIVO_PROCESS,
      });

      const params = {
        em: currentUser.ie,
      };

      api
        .get(`${servicios.CORRELATIVO}/${item.td}`, { params })
        .then((response) => {
          dispatch({
            type: CORRELATIVO_SELECTED,
            payload: response,
          });
        })
        .catch(() => {
          dispatch({
            type: CORRELATIVO_NEW,
            payload: correlativoModel,
          });
        });
    } else {
      dispatch({
        type: CORRELATIVO_NEW,
        payload: correlativoModel,
      });
    }
  };

export const postCorrelativoAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CORRELATIVO_PROCESS,
  });

  let correlativo = {
    ie: currentUser.ie,
    td: item.td,
    se: item.se,
    nc: item.nc,
  };

  api
    .post(servicios.CORRELATIVO, correlativo)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCorrelativosAction());
      dispatch({ type: CORRELATIVO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CORRELATIVO_FAIL,
        payload: error.data.errors ? error.data.errors : correlativoModel,
      });
    });
};

export const putCorrelativoAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CORRELATIVO_PROCESS,
  });

  let correlativo = {
    ie: currentUser.ie,
    td: item.td,
    se: item.se,
    sn: item.sn,
    nc: item.nc,
  };

  api
    .put(servicios.CORRELATIVO, correlativo)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCorrelativosAction());
      dispatch({ type: CORRELATIVO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CORRELATIVO_FAIL,
        payload: error.data.errors ? error.data.errors : correlativoModel,
      });
    });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CORRELATIVO_CLOSE,
  });
};
