import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import Icon from '../../commons/Icon';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import MoneyDown from '../../assets/icons/money-down.svg';

import {
  getCajerosAction,
  getOperacionesCajeroAction,
} from '../../redux/actions/cajero.action';

import { agregarHeaderAction } from '../../redux/actions/app.action';

const CajeroList = ({ cajeros, processing, total }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      key: 'cj',
      align: 'center',
      content: (item) => (
        <>
          <div title="Operaciones de caja" style={{ display: 'inline-block' }}>
            <Icon
              svg={MoneyDown}
              transparent
              onClick={() => {
                let head = {
                  mm: 'BAN_CAJA_OPER',
                  nf: false,
                  op: 'Operaciones cajero',
                  pp: 'BAN_INIC',
                  or: 3,
                  im: 'payments',
                  st: true,
                };
                dispatch(agregarHeaderAction(head));
                dispatch(getOperacionesCajeroAction(item));
              }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'np',
      label: 'Caja',
    },
    {
      key: 'ss',
      label: 'Soles',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.ss)),
    },
    {
      key: 'sd',
      label: 'Dolares',
      align: 'right',
      content: (item) => formatoMonetario(redondeoDecimales(item.sd)),
    },
    {
      key: 'fa',
      label: 'Apertura',
      align: 'right',
      content: (item) => formatoFecha(item.fa, '', false),
    },
    {
      key: 'fc',
      label: 'Cierre',
      align: 'right',
      content: (item) => formatoFecha(item.fc, 'Pendiente', false),
    },
    {
      key: 'ur',
      label: 'Responsable',
    },
  ];

  return (
    <Bloque titulo="Operaciones de caja">
      <Tabla
        columns={columns}
        data={cajeros}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          dispatch(getCajerosAction({ page, pageSize: rows }))
        }
        searcheable={false}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    cajeros: state.cajero.cajeros,
    processing: state.cajero.processing,
    total: state.cajero.total,
  };
};

export default connect(mapStateToProps)(CajeroList);
