import React from 'react';
import { useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';

import { getPagosAction } from '../../redux/actions/pagos.action';

const PagoProveedoresFilter = () => {
  const dispatch = useDispatch();
  return (
    <Filtros
      onFilter={() => dispatch(getPagosAction())}
      showNewButton={false}
      showFilterButton={false}
    ></Filtros>
  );
};

export default PagoProveedoresFilter;
