import { api } from '../axios/api';
import { servicios } from '../helpers/index';

export const VENTA_PROCESS = '[Venta] Venta procesando';
export const VENTA_SAVE_SUCCESS = '[Venta] Venta guardar exito';
export const VENTA_LIST_SUCCESS = '[Venta] Venta lista exito';
export const VENTA_LIST_FAIL = '[Venta] Venta lista error';
export const VENTA_SELECTED = '[Venta] Venta seleccionado';
export const VENTA_CLOSE = '[Venta] Venta cerrar modal';
export const VENTA_FILTER = '[Venta] Venta filtros';
export const VENTA_FAIL = '[Venta] Venta proceso error';

export const getVentasAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { search } = getState().venta;
    let { currentUser } = getState().app;

    dispatch({
      type: VENTA_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      su: currentUser.is,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.INVENTARIO, { params })
      .then((response) => {
        dispatch({
          type: VENTA_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: VENTA_LIST_FAIL,
        });
      });
  };

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { search } = getState().venta;

  let item = {
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: VENTA_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: VENTA_CLOSE,
  });
};
