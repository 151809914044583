import React, { useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import { chicaModel } from '../../redux/models/chica.model';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  closeModal,
  putAperturaCajaChicaAction,
} from '../../redux/actions/chica.action';

import { getUsuariosAction } from '../../redux/actions/usuario.action';

import SaveIcon from '@material-ui/icons/Save';

const ChicaApertura = ({
  usuarios,
  processing,
  processingUser,
  errors,
  closeModal,
  putAperturaCajaChicaAction,
  getUsuariosAction,
  sucursales,
}) => {
  const [operacion, setOperacion] = useState(chicaModel);

  const handleChange = (item) => {
    const { name, value } = item;
    setOperacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Registrar apertura de caja chica`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={14}
              onClick={() =>
                confirmacion('¿Está seguro?', `Aperturar la caja chica`)
                  .then(() => {
                    putAperturaCajaChicaAction(operacion);
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              closeModal();
            })
            .catch(() => {})
        }
      >
        <Combo
          name="is"
          value={operacion.is}
          label="Sucursal"
          size={2}
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          tabIndex={10}
          error={errors.is}
          onChange={(item) => {
            handleChange(item);
            getUsuariosAction(item.value);
          }}
          info={'Sucursal donde se realizara la apertura de caja chica'}
        />
        <Combo
          name="ur"
          value={operacion.ur}
          disabled={processingUser}
          label="Responsable"
          size={2}
          data={usuarios.map((x) => ({ value: x.us, label: x.np }))}
          tabIndex={11}
          error={errors.ur}
          onChange={handleChange}
          info={'Usuario responsable de la caja chica'}
        />
        <Texto
          name="sn"
          value={operacion.sn}
          label={`Soles`}
          size={2}
          tabIndex={12}
          error={errors.sn}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'sn',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <Texto
          name="se"
          value={operacion.se}
          label={`Dolares`}
          size={2}
          tabIndex={13}
          error={errors.se}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'se',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.chica.processing,
    errors: state.chica.errors,
    sucursales: state.sucursal.sucursales,
    usuarios: state.usuario.usuarios,
    processingUser: state.usuario.processing,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  putAperturaCajaChicaAction,
  getUsuariosAction,
})(ChicaApertura);
