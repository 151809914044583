import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TuneIcon from '@material-ui/icons/Tune';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import '../css/commons/filtro.css';

const Filtros = (props) => {
  const [open, setOpen] = useState(true);

  const actionsButtons = () => (
    <>
      {props.actions && props.actions()}
      {props.showSearchButton && (
        <YoutubeSearchedForIcon
          fontSize="large"
          onClick={() => props.onFilter()}
          style={{ cursor: 'pointer' }}
        />
      )}
      {props.showNewButton && (
        <AddCircleOutlineIcon
          fontSize="large"
          onClick={() => props.onNew()}
          style={{ cursor: 'pointer' }}
        />
      )}
    </>
  );
  return (
    <div className={`op-content-filtro${open ? '' : ' op-close'}`}>
      <div className="op-filtro">
        {props.showFilterButton ? (
          <div
            className="op-icono-filtro"
            title="Abrir filtros de b&uacute;squeda"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TuneIcon
              fontSize="large"
              onClick={() => setOpen(!open)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        ) : (
          <div></div>
        )}
        <div className="op-actions">{actionsButtons()}</div>
      </div>
      <div className={`op-contenido`}>
        <div className="op-form">{props.children}</div>
      </div>
    </div>
  );
};

Filtros.propTypes = {
  actions: PropTypes.func,
  showNewButton: PropTypes.bool,
  showSearchButton: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  onNew: PropTypes.func,
  onFilter: PropTypes.func,
};

Filtros.defaultProps = {
  showNewButton: true,
  showFilterButton: true,
  showSearchButton: true,
};

export default Filtros;
