import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getConductoresAction,
  getSeleccionarConductorAction,
} from '../../redux/actions/conductor.action';

const ConductorFilter = () => {
  const dispathc = useDispatch();
  return (
    <Filtros
      onFilter={() => dispathc(getConductoresAction())}
      onNew={() => dispathc(getSeleccionarConductorAction())}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ConductorFilter);
