import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import ProveedorList from '../proveedor/ProveedorList';

import { patchAprobarOrdenCompraAction } from '../../redux/actions/ordencompra.action';

import {
  getBuscarRucProveedorAction,
  closeModal,
} from '../../redux/actions/proveedor.action';

import SaveIcon from '@material-ui/icons/Save';

const OrdenCompraAprobar = ({
  selected,
  usuario,
  errors,
  processing,
  sucursales,
  patchAprobarOrdenCompraAction,
  closeModal,
  onClose,
}) => {
  const [orden, setOrden] = useState(selected);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOrden((prev) => ({
      ...prev,
      ua: usuario,
    }));
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOrden((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Aprobar orden N° ${selected.co}`}
        processing={processing}
        size="small"
        loading="supplies"
        onClose={() => {
          closeModal();
          onClose();
        }}
        onActions={() => (
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={21}
            onClick={() =>
              confirmacion(
                '¿Está seguro?',
                `De aprobar la orden de compra ${selected.co}`
              )
                .then(() => {
                  if (orden.nr) {
                    patchAprobarOrdenCompraAction(orden);
                  } else {
                    mensajeMini('Seleccionar proveedor');
                  }
                })
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        )}
      >
        <Texto
          name="ua"
          value={orden.ua}
          label="Usuario aprueba"
          tabIndex={9}
          error={errors.ua}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="so"
          value={orden.so}
          label="Sucursal de solicitud"
          tabIndex={10}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nr"
          value={orden.nr}
          label="Numero ruc"
          tabIndex={11}
          restrict={'int'}
          error={errors.nr}
          onChange={handleChange}
          search
          onSearch={() => setOpen(true)}
        />
        <Texto
          name="rz"
          value={orden.rz}
          label="Raz&oacute;n social"
          tabIndex={12}
          error={errors.rz}
          onChange={() => {}}
          disabled
        />
        <Combo
          name="sd"
          value={orden.sd}
          label="Tienda"
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          tabIndex={13}
          error={errors.sd}
          onChange={(item) => {
            handleChange(item);
            handleChange({ value: item.label, name: 'ns' });
          }}
          info={'Tienda donde se enviará la mercaderia'}
        />
      </Modal>
      {open && (
        <ProveedorList
          searchable
          onClose={() => setOpen(false)}
          onSend={(item) => {
            handleChange({ name: 'pv', value: item.pv });
            handleChange({ name: 'rz', value: item.rz });
            handleChange({ name: 'nr', value: item.nr });
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.ordenCompra.selected,
    processing: state.ordenCompra.processing,
    errors: state.ordenCompra.errors,
    usuario: state.app.currentUser.np,
    sucursales: state.sucursal.sucursales,
  };
};

export default connect(mapStateToProps, {
  patchAprobarOrdenCompraAction,
  getBuscarRucProveedorAction,
  closeModal,
})(OrdenCompraAprobar);
