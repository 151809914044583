import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Report from '../app/Report';

import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

import {
  actualizarFiltros,
  getSeleccionarCajaAction,
  getSeleccionarOperacionCajaAction,
} from '../../redux/actions/caja.action';

import { servicios } from '../../redux/helpers/servicios';

const CajaFilter = ({
  tipoCajas,
  getSeleccionarCajaAction,
  actualizarFiltros,
  getSeleccionarOperacionCajaAction,
  empresa,
  selected,
  search,
}) => {
  const [tc, setTc] = useState('');
  const [tm, setTm] = useState('');
  const [is, setIs] = useState('');
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);
  const [params, setParams] = useState({});

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <>
      <Filtros
        onFilter={() => getSeleccionarCajaAction()}
        onNew={() => getSeleccionarOperacionCajaAction()}
        actions={() => (
          <>
            <Boton
              className="op-filter"
              icon={() => <PrintIcon />}
              tabIndex={22}
              dropdownable
              style={{
                '--button-icon-color': '#1ab394',
              }}
              showDropdownOnTop
              actions={[
                {
                  key: '01',
                  label: 'PDF',
                  icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                  onClick: () => {
                    setOpenReporte(true);
                    setParams({ cj: selected.cj, tc, tm, is, search });
                  },
                },
                {
                  key: '02',
                  label: 'Excel',
                  icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                  onClick: () => {
                    setOpenReporteExcel(true);
                    setParams({ cj: selected.cj, tc, tm, is, search, xl: '0' });
                  },
                },
              ]}
            >
              Imprimir
            </Boton>
          </>
        )}
      >
        <Combo
          name="tc"
          label="Tipo caja"
          value={tc}
          placeholder="Todos"
          tabIndex={20}
          size={3}
          data={tipoCajas.map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          dropdownTop
          onChange={({ value }) => {
            setTc(value);
            actualizarFiltros({ tc: value });
          }}
        />
        <Combo
          name="tm"
          value={tm}
          label="Moneda"
          placeholder="Ambos"
          size={3}
          tabIndex={21}
          data={[
            { value: '0', label: 'Nacional' },
            { value: '1', label: 'Extranjera' },
          ]}
          onChange={({ value }) => {
            setTm(value);
            actualizarFiltros({ tm: value });
          }}
        />
        <Combo
          name="is"
          value={is}
          label="Ingreso / Egreso"
          placeholder="Ambos"
          size={3}
          tabIndex={22}
          data={[
            { value: '1', label: 'Ingreso' },
            { value: '0', label: 'Egreso' },
          ]}
          onChange={({ value }) => {
            setIs(value);
            actualizarFiltros({ is: value });
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.CAJA}/${empresa}/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.CAJA}/${empresa}/imprimir`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          extension={'xlsx'}
          filename={'ReporteCajaGeneral'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoCajas: state.detalle.tipoCajas,
    empresa: state.app.currentUser.ie,
    search: state.caja.search,
    selected: state.caja.selected,
  };
};

export default connect(mapStateToProps, {
  getSeleccionarCajaAction,
  actualizarFiltros,
  getSeleccionarOperacionCajaAction,
})(CajaFilter);
