import { constantes } from './constantes';
export const servicios = {
  LOGIN: `${constantes.MS_AUTENTICACION}/v1/autentificacion`,
  CAPTCHA: `${constantes.MS_AUTENTICACION}/v1/captchas`,
  MENU: `${constantes.MS_AUDITORIA}/v1/menues`,
  MAESTRO: `${constantes.MS_AUDITORIA}/v1/maestros`,
  USUARIO: `${constantes.MS_AUTENTICACION}/v1/usuarios`,
  EMPRESA: `${constantes.MS_AUTENTICACION}/v1/empresas`,
  ROL: `${constantes.MS_AUTENTICACION}/v1/roles`,
  ALMACEN: `${constantes.MS_LOGISTICA}/v1/almacenes`,
  CABECERA: `${constantes.MS_LOGISTICA}/v1/cabeceras`,
  DETALLE: `${constantes.MS_LOGISTICA}/v1/detalles`,
  MATERIAL: `${constantes.MS_LOGISTICA}/v1/materiales`,
  PROVEEDOR: `${constantes.MS_LOGISTICA}/v1/proveedores`,
  ORDENCOMPRA: `${constantes.MS_LOGISTICA}/v1/ordenescompra`,
  FILES: `${constantes.MS_LOGISTICA}/v1/files`,
  SUCURSAL: `${constantes.MS_AUTENTICACION}/v1/sucursales`,
  UBIGUEO: `${constantes.MS_AUTENTICACION}/v1/ubigueos`,
  INVENTARIO: `${constantes.MS_LOGISTICA}/v1/inventarios`,
  CLIENTE: `${constantes.MS_LOGISTICA}/v1/clientes`,
  OPERACION: `${constantes.MS_LOGISTICA}/v1/operaciones`,
  SERVICIO: `${constantes.MS_LOGISTICA}/v1/servicios`,
  CAJA: `${constantes.MS_LOGISTICA}/v1/cajas`,
  CHICA: `${constantes.MS_LOGISTICA}/v1/chicas`,
  PUNTOVENTA: `${constantes.MS_LOGISTICA}/v1/maquinas`,
  CAJERO: `${constantes.MS_LOGISTICA}/v1/cajeros`,
  ADICIONAL: `${constantes.MS_LOGISTICA}/v1/adicionales`,
  PLANTILLA: `${constantes.MS_LOGISTICA}/v1/plantillas`,
  PERFILES: `${constantes.MS_LOGISTICA}/v1/accionesperfil`,
  CONTRATO: `${constantes.MS_LOGISTICA}/v1/contratos`,
  COBRANZA: `${constantes.MS_LOGISTICA}/v1/cobranzas`,
  DOCUMENTO: `${constantes.MS_LOGISTICA}/v1/documentos`,
  ARCHIVO: `${constantes.MS_LOGISTICA}/v1/archivos`,
  CORRELATIVO: `${constantes.MS_LOGISTICA}/v1/correlativos`,
  GUIA: `${constantes.MS_LOGISTICA}/v1/guias`,
  CONDUCTOR: `${constantes.MS_LOGISTICA}/v1/conductores`,
  CUSTOMERS: `${constantes.MS_AUTENTICACION}/v1/clientes`,
  CATEGORIA: `${constantes.MS_AUTENTICACION}/v1/servicios`,
  APLICACION: `${constantes.MS_AUTENTICACION}/v1/aplicaciones`,
  PEDIDO: `${constantes.MS_LOGISTICA}/v1/pedidos`,
  REDSOCIAL: `${constantes.MS_AUTENTICACION}/v1/redes`,
  CANCELACION: `${constantes.MS_AUTENTICACION}/v1/cancelaciones`,
};
