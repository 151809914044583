import { useState } from 'react';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';
import { archivoModel } from '../redux/models/archivo.model';

const usePromocion = () => {
  const [archivo, setArchivo] = useState(null);
  const [processingArchivo, setProcessingArchivo] = useState(false);

  const getPromocion = (ar, em) => {
    setProcessingArchivo(true);
    api
      .get(`${servicios.ARCHIVO}/${ar}/promocion/${em}`)
      .then((response) => {
        setArchivo(response);
      })
      .catch(() => {
        setArchivo(archivoModel);
      })
      .finally(() => setProcessingArchivo(false));
  };
  return {
    archivo,
    processingArchivo,
    getPromocion,
  };
};

export default usePromocion;
