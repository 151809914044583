export const conductorModel = {
  ch: null,
  td: null,
  dd: null,
  nd: null,
  nc: null,
  lc: null,
  mh: null,
  ph: null,
  oh: null,
  st: null,
};
