import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getProveedoresAction } from '../../redux/actions/proveedor.action';

import ProveedorList from './ProveedorList';
import ProveedorItem from './ProveedorItem';
import ProveedorFilter from './ProveedorFilter';

const Proveedor = ({ openModal, getProveedoresAction }) => {
  useEffect(() => {
    getProveedoresAction({});
  }, []);
  return (
    <div className="op-component">
      <ProveedorList />
      <ProveedorFilter />
      {openModal && <ProveedorItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.proveedor.openModal,
  };
};

export default connect(mapStateToProps, { getProveedoresAction })(Proveedor);
