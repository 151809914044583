import { api } from '../axios/api';
import { servicios, constantes } from '../helpers/index';
import { mensaje, mensajeMini } from '../../commons/Mensajes';
import { categoriaModel } from '../models/categoria.model';

export const CATEGORIA_PROCESS = '[Categoria] Categoria procesando';
export const CATEGORIA_SAVE_SUCCESS = '[Categoria] Categoria guardar exito';
export const CATEGORIA_LIST_SUCCESS = '[Categoria] Categoria lista exito';
export const CATEGORIA_TIPO_LIST_SUCCESS =
  '[Categoria] Tipos categoria lista exito';
export const CATEGORIA_LIST_FAIL = '[Categoria] Categoria lista error';
export const CATEGORIA_SELECTED = '[Categoria] Categoria seleccionado';
export const CATEGORIA_CLOSE = '[Categoria] Categoria cerrar modal';
export const CATEGORIA_FILTER = '[Categoria] Categoria filtros';
export const CATEGORIA_FAIL = '[Categoria] Categoria proceso error';

export const getTipoCategoriaAction = () => (dispatch) => {
  dispatch({
    type: CATEGORIA_PROCESS,
  });

  const params = {
    aa: constantes.LAVANDERIA,
    page: 0,
    pageSize: 30,
  };

  api
    .get(servicios.CATEGORIA, { params })
    .then((response) => {
      dispatch({
        type: CATEGORIA_TIPO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CATEGORIA_TIPO_LIST_SUCCESS,
        payload: {
          count: 0,
          data: [],
        },
      });
    });
};

export const getCategoriaAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { ap } = getState().categoria;

  if (!ap) {
    mensajeMini('Seleccione aplicación');
    return;
  }

  dispatch({
    type: CATEGORIA_PROCESS,
  });

  const params = {
    ap,
  };

  api
    .get(`${servicios.CATEGORIA}/empresa/${currentUser.ie}`, { params })
    .then((response) => {
      dispatch({
        type: CATEGORIA_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CATEGORIA_LIST_FAIL,
      });
    });
};

export const getSeleccionarCategoriaAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: CATEGORIA_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: CATEGORIA_SELECTED,
      payload: categoriaModel,
    });
  }
};

export const postCategoriaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CATEGORIA_PROCESS,
  });

  let categoria = {
    ie: currentUser.ie,
    ct: item.ct,
    ai: item.ai,
  };

  api
    .post(`${servicios.CATEGORIA}/empresa`, categoria)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCategoriaAction());
      dispatch({ type: CATEGORIA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CATEGORIA_FAIL,
        payload: error.data.errors ? error.data.errors : categoriaModel,
      });
    });
};

export const actualizarFiltros = (ap) => (dispatch) => {
  dispatch({
    type: CATEGORIA_FILTER,
    payload: ap,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CATEGORIA_CLOSE,
  });
};
