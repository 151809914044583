import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getContratosAction } from '../../redux/actions/contrato.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';
import { getPlantillasAction } from '../../redux/actions/plantilla.action';
import { getServiciosAction } from '../../redux/actions/servicio.action';
import { getClientesAction } from '../../redux/actions/cliente.action';
import { getPerfilesAction } from '../../redux/actions/perfiles.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getEstadoContratoAction } from '../../redux/actions/adicional.action';

import ContratoFilter from './ContratoFilter';
import ContratoList from './ContratoList';
import ContratoNew from './ContratoNew';
import ContratoView from './ContratoView';
import ContratoEdit from './ContratoEdit';

const Contrato = ({ openModal, openModalView, openModalEdit }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContratosAction());
    dispatch(getSucursalesAction({}));
    dispatch(getPlantillasAction(100));
    dispatch(getServiciosAction({}));
    dispatch(getClientesAction({}));
    dispatch(getPerfilesAction());
    dispatch(getDetallesPorCabeceraAction({ ca: 'ELEMENTOSINSTALACION' }));
    dispatch(getDetallesPorCabeceraAction({ ca: 'FORMASPAGO' }));
    dispatch(getDetallesPorCabeceraAction({ ca: 'ESTADOCOBRANZA' }));
    dispatch(getEstadoContratoAction());
  }, []);

  return (
    <div className="op-component">
      <ContratoList />
      <ContratoFilter />
      {openModal && <ContratoNew />}
      {openModalView && <ContratoView />}
      {openModalEdit && <ContratoEdit />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.contrato.openModal,
    openModalView: state.contrato.openModalView,
    openModalEdit: state.contrato.openModalEdit,
  };
};

export default connect(mapStateToProps)(Contrato);
