import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import { confirmacion } from '../../commons/Mensajes';
import {
  getDepartamentosAction,
  getProvinciasAction,
  getDistritosAction,
} from '../../redux/actions/ubigueo.action';

import CheckIcon from '@material-ui/icons/Check';

const Ubigueo = ({ onClose, onSend }) => {
  const [departamento, setDepartamento] = useState();
  const [provincia, setProvincia] = useState();
  const [distrito, setDistrito] = useState();
  const [direccion, setDireccion] = useState();
  const [ubigueo, setUbigueo] = useState();
  const [enviar, setEnviar] = useState(false);
  const dispatch = useDispatch();
  const { departamentos, provincias, distritos, processing } = useSelector(
    (state) => state.ubigueo
  );

  useEffect(() => {
    dispatch(getDepartamentosAction());
  }, []);

  return (
    <Modal
      title="Ubigueo"
      onActions={() => (
        <Boton
          className="op-grabar"
          icon={() => <CheckIcon />}
          tabIndex={15}
          onClick={() => {
            onSend({ ub: ubigueo, di: direccion });
            onClose();
          }}
          disabled={!enviar}
        >
          Enviar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => onClose())
          .catch(() => {})
      }
      processing={processing}
      size="small"
      loading="ubication"
    >
      <Combo
        name="cd"
        label="Departamento"
        value={departamento}
        tabIndex={12}
        data={departamentos.map((i) => ({ value: i.cd, label: i.de }))}
        onChange={(item) => {
          setDepartamento(item.value);
          if (item.value) {
            dispatch(getProvinciasAction(item.value));
          }
          setDireccion(item.label);
          setEnviar(false);
        }}
      />
      <Combo
        name="cp"
        label="Provincia"
        value={provincia}
        tabIndex={13}
        data={provincias.map((i) => ({ value: i.cp, label: i.de }))}
        onChange={(item) => {
          setProvincia(item.value);
          if (item.value) {
            dispatch(getDistritosAction(departamento, item.value));
          }
          setDireccion(`${direccion} - ${item.label}`);
          setEnviar(false);
        }}
      />
      <Combo
        name="ci"
        label="Distrito"
        value={distrito}
        tabIndex={14}
        data={distritos.map((i) => ({
          value: i.ci,
          label: i.de,
        }))}
        onChange={(item) => {
          setDistrito(item.value);
          let ubigueo = distritos.find((x) => x.ci === item.value);
          setUbigueo(ubigueo ? ubigueo.ub : null);
          setDireccion(
            `${ubigueo.cd}${ubigueo.cp}${ubigueo.ci} - ${direccion} - ${item.label}`
          );
          setEnviar(true);
        }}
        searcheable
      />
    </Modal>
  );
};

export default Ubigueo;
