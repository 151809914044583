import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getCajaChicasAction } from '../../redux/actions/chica.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import ChicaList from './ChicaList';
import ChicaFilter from './ChicaFilter';
import ChicaApertura from './ChicaApertura';

const Chica = ({
  openModal,
  getSucursalesAction,
  getCajaChicasAction,
  getDetallesPorCabeceraAction,
}) => {
  useEffect(() => {
    getCajaChicasAction({});
    getSucursalesAction({});
    getDetallesPorCabeceraAction({
      ca: 'TIPOCAJA',
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPODOCUMENTO',
    });
  }, []);

  return (
    <div className="op-component">
      <ChicaList />
      <ChicaFilter />
      {openModal && <ChicaApertura />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.chica.openModal,
  };
};

export default connect(mapStateToProps, {
  getCajaChicasAction,
  getSucursalesAction,
  getDetallesPorCabeceraAction,
})(Chica);
