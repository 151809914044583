import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { cajeroModel } from '../models/cajero.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const CAJERO_PROCESS = '[Cajero] Cajero procesando';
export const CAJERO_SAVE_SUCCESS = '[Cajero] Cajero guardar exito';
export const CAJERO_LIST_SUCCESS = '[Cajero] Cajero lista exito';
export const CAJERO_LIST_OPERATION = '[Cajero] Cajero lista operaciones';
export const CAJERO_LIST_FAIL = '[Cajero] Cajero lista error';
export const CAJERO_SELECTED = '[Cajero] Cajero seleccionado';
export const CAJERO_CLOSE = '[Cajero] Cajero cerrar modal';
export const CAJERO_OPEN = '[Cajero] Cajero open modal';
export const CAJERO_FILTER = '[Cajero] Cajero filtros';
export const CAJERO_FAIL = '[Cajero] Cajero proceso error';

import { eliminarHeaderAction } from './app.action';
import { getState } from 'core-js/modules/web.url-search-params';

export const getCajerosAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { su, suf } = getState().cajero;
    let { currentUser } = getState().app;

    dispatch({
      type: CAJERO_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      su,
      suf,
      page,
      pageSize,
    };

    api
      .get(servicios.CAJERO, { params })
      .then((response) => {
        dispatch({
          type: CAJERO_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CAJERO_LIST_FAIL,
        });
      });
  };

export const getCajaAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CAJERO_PROCESS,
  });

  api
    .get(
      `${servicios.CAJERO}/empresa/${currentUser.ie}/usuario/${currentUser.us}`
    )
    .then((response) => {
      dispatch({
        type: CAJERO_SELECTED,
        payload: response,
      });
      dispatch(getOperacionesCajeroAction(response));
    })
    .catch(() => {
      dispatch({
        type: CAJERO_SELECTED,
        payload: cajeroModel,
      });
    });
};

export const getOperacionesCajeroAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CAJERO_PROCESS,
  });

  dispatch({
    type: CAJERO_SELECTED,
    payload: item,
  });

  const params = {
    em: currentUser.ie,
    suf: item.is,
    cj: item.cj,
    us: currentUser.us,
    rr: item.rr,
  };

  api
    .get(`${servicios.CAJERO}/operaciones`, { params })
    .then((response) => {
      dispatch({
        type: CAJERO_LIST_OPERATION,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CAJERO_LIST_OPERATION,
        payload: [],
      });
    });
};

export const refrescarOperacionesCajeroAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { selected } = getState().cajero;

  dispatch({
    type: CAJERO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    suf: selected.is,
    cj: selected.cj,
    us: currentUser.us,
    rr: selected.rr,
  };

  api
    .get(`${servicios.CAJERO}/operaciones`, { params })
    .then((response) => {
      dispatch({
        type: CAJERO_LIST_OPERATION,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CAJERO_LIST_OPERATION,
        payload: [],
      });
    });
};

export const putAperturaCajeroAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CAJERO_PROCESS,
  });

  let apertura = {
    ie: currentUser.ie,
    is: item.is,
    pv: item.pv,
    ss: quitarFormatoMonetario(item.ss),
    sd: quitarFormatoMonetario(item.sd),
    uc: currentUser.us,
    ur: item.ur,
  };

  api
    .put(`${servicios.CAJERO}/apertura`, apertura)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCajerosAction({}));
      dispatch({ type: CAJERO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CAJERO_FAIL,
        payload: error.data.errors ? error.data.errors : cajeroModel,
      });
    });
};

export const putCierreCajeroAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CAJERO_PROCESS,
  });

  let cierre = {
    ...item,
    ie: currentUser.ie,
    ur: currentUser.us,
    rr: currentUser.tr === 'NOBASICO' ? currentUser.us : '',
  };

  api
    .put(`${servicios.CAJERO}/cierre`, cierre)
    .then(() => {
      mensaje('Operación exitosa');
      if (currentUser.tr === 'NOBASICO') {
        dispatch(getCajerosAction({}));
        dispatch(eliminarHeaderAction('BAN_CAJA_OPER'));
      } else {
        dispatch({
          type: CAJERO_SELECTED,
          payload: cajeroModel,
        });
      }
      dispatch({ type: CAJERO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CAJERO_FAIL,
        payload: error.data.errors ? error.data.errors : cajeroModel,
      });
    });
};

export const patchConfirmarCajeroAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CAJERO_PROCESS,
  });

  let cierre = {
    cj: item.cj,
    ie: currentUser.ie,
    is: currentUser.is,
    pv: item.pv,
    ur: currentUser.us,
  };

  api
    .patch(`${servicios.CAJERO}/confirmar`, cierre)
    .then(() => {
      mensaje('Operación exitosa');
      if (currentUser.tr === 'NOBASICO') {
        dispatch(getCajerosAction({}));
        dispatch(eliminarHeaderAction('BAN_CAJA_OPER'));
      } else {
        dispatch(getCajaAction());
      }
      dispatch({ type: CAJERO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CAJERO_FAIL,
        payload: error.data.errors ? error.data.errors : cajeroModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, suf } = getState().cajero;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    suf:
      filtros.suf !== undefined
        ? filtros.suf === ''
          ? null
          : filtros.suf
        : suf,
  };

  dispatch({
    type: CAJERO_FILTER,
    payload: item,
  });
};

export const openModal = () => (dispatch) => {
  dispatch({
    type: CAJERO_OPEN,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CAJERO_CLOSE,
  });
};
