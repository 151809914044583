import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getPlantillasAction } from '../../redux/actions/plantilla.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getEstadoContratoAction } from '../../redux/actions/adicional.action';

import PlantillaList from './PlantillaList';
import PlantillaFilter from './PlantillaFilter';
import PlantillaItem from './PlantillaItem';

const Plantilla = ({ openModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlantillasAction());
    dispatch(getDetallesPorCabeceraAction({ ca: 'TIPODATO' }));
    dispatch(getEstadoContratoAction());
  }, []);

  return (
    <div className="op-component">
      <PlantillaList />
      <PlantillaFilter />
      {openModal && <PlantillaItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.plantilla.openModal,
  };
};

export default connect(mapStateToProps)(Plantilla);
