import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';
import Icon from '../../commons/Icon';
import Boton from '../../commons/Boton';
import Report from '../app/Report';
import {
  getContratosAction,
  getSeleccionarContratoAction,
  actualizarFiltros,
} from '../../redux/actions/contrato.action';

import {
  actualizarFiltros as filtroCobranzas,
  getCobranzasAction,
} from '../../redux/actions/cobranza.action';

import { agregarHeaderAction } from '../../redux/actions/app.action';

import { servicios } from '../../redux/helpers/servicios';

import ContratoIcon from '../../assets/icons/contract.svg';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

const ContratoFilter = ({
  sucursales,
  perfil,
  empresa,
  estadoContrato,
  estado,
  us,
  search,
}) => {
  const [su, setSu] = useState('');
  const [fi, setFi] = useState('');
  const [ff, setFf] = useState('');
  const [params, setParams] = useState({});
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (perfil === 'BASICO') {
      dispatch(actualizarFiltros({ us: us }));
    }
  }, []);

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <>
      <Filtros
        onFilter={() => dispatch(getContratosAction())}
        showNewButton={false}
        actions={() => (
          <>
            <Boton
              className="op-filter"
              icon={() => <Icon svg={ContratoIcon} size={30} />}
              tabIndex={21}
              onClick={() => dispatch(getSeleccionarContratoAction())}
            >
              Solicitar contrato
            </Boton>
            {perfil === 'NOBASICO' && (
              <>
                <Boton
                  className="op-filter"
                  tabIndex={20}
                  icon={() => <LibraryBooksIcon />}
                  onClick={() => {
                    dispatch(filtroCobranzas({ co: null }));
                    dispatch(getCobranzasAction());
                    let head = {
                      mm: 'COM_CON_COBR',
                      nf: false,
                      op: 'Cobranzas',
                      pp: 'COM_INIC',
                      or: 3,
                      im: 'payments',
                      st: true,
                    };
                    dispatch(agregarHeaderAction(head));
                  }}
                >
                  Cobranzas
                </Boton>
                <Boton
                  className="op-filter"
                  icon={() => <PrintIcon />}
                  tabIndex={22}
                  dropdownable
                  style={{
                    '--button-icon-color': '#1ab394',
                  }}
                  showDropdownOnTop
                  actions={[
                    {
                      key: '01',
                      label: 'PDF',
                      icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                      onClick: () => {
                        setOpenReporte(true);
                        setParams({ su, fi, ff, search });
                      },
                    },
                    {
                      key: '02',
                      label: 'Excel',
                      icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                      onClick: () => {
                        setOpenReporteExcel(true);
                        setParams({ su, fi, ff, search, xl: '0' });
                      },
                    },
                  ]}
                >
                  Imprimir
                </Boton>
              </>
            )}
          </>
        )}
      >
        {perfil === 'NOBASICO' && (
          <>
            <Combo
              name="su"
              label="Sucursal"
              value={su}
              placeholder="Todos"
              tabIndex={20}
              size={4}
              data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
              onChange={({ value }) => {
                setSu(value);
                dispatch(actualizarFiltros({ suf: value }));
              }}
            />
            <Combo
              name="ec"
              label="Estado contrato"
              value={estado}
              placeholder="Todos"
              tabIndex={21}
              size={4}
              data={estadoContrato.map((i) => ({ value: i.ad, label: i.na }))}
              onChange={({ value }) => {
                dispatch(actualizarFiltros({ ec: value }));
              }}
            />
          </>
        )}

        <Texto
          name="fi"
          value={fi}
          label="Fecha de inicio venta"
          tabIndex={23}
          size={4}
          type={'date'}
          onChange={({ value }) => {
            setFi(value);
            dispatch(actualizarFiltros({ fi: value }));
          }}
        />
        <Texto
          name="ff"
          value={ff}
          label="Fecha fin venta"
          tabIndex={24}
          size={4}
          type={'date'}
          onChange={({ value }) => {
            setFf(value);
            dispatch(actualizarFiltros({ ff: value }));
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.CONTRATO}/${empresa}/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.CONTRATO}/${empresa}/imprimir`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteContratos'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
    perfil: state.app.currentUser.tr,
    empresa: state.app.currentUser.ie,
    us: state.app.currentUser.us,
    sucursal: state.app.currentUser.is,
    search: state.contrato.search,
    estadoContrato: state.adicional.estados,
    estado: state.contrato.ec,
  };
};

export default connect(mapStateToProps)(ContratoFilter);
