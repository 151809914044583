import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getOperacionesAction } from '../../redux/actions/operacion.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import OperacionList from './OperacionList';
import OperacionFilter from './OperacionFilter';

const Operacion = ({
  getSucursalesAction,
  getOperacionesAction,
  getDetallesPorCabeceraAction,
}) => {
  useEffect(() => {
    getOperacionesAction({});
    getSucursalesAction({});
    getDetallesPorCabeceraAction({
      ca: 'TIPOINGRESO',
      page: 0,
      pageSize: 30,
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPOSALIDA',
      page: 0,
      pageSize: 30,
    });
  }, []);

  return (
    <div className="op-component">
      <OperacionList />
      <OperacionFilter />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.operacion.openModal,
  };
};

export default connect(mapStateToProps, {
  getOperacionesAction,
  getDetallesPorCabeceraAction,
  getSucursalesAction,
})(Operacion);
