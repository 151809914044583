import * as fromVenta from '../actions/venta.action';
import { ventaModel } from '../models/venta.model';

let initialState = {
  productos: [],
  total: 0,
  search: null,
  processing: false,
  selected: ventaModel,
  openModal: false,
  errors: ventaModel,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromVenta.VENTA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: ventaModel,
      };
    case fromVenta.VENTA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        productos: action.payload.data,
        total: action.payload.count,
      };
    case fromVenta.VENTA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        productos: [],
        total: 0,
      };
    case fromVenta.VENTA_FILTER:
      return {
        ...state,
        search: action.payload.search,
      };
    default:
      return state;
  }
}
