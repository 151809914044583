export const invetarioModel = {
  su: null,
  ns: null,
  mt: null,
  nm: null,
  cm: null,
  um: null,
  pv: null,
  tm: null,
  mg: null,
  ct: null,
  dt: null,
  sx: null,
  sd: null,
  si: null,
  fc: null,
  te: null,
  pr: null,
  np: null,
  md: null,
  mc: null,
  ud: null,
  cc: 0,
  co: null,
  cd: null,
  st: false,
  lts: [],
};
