import * as fromProveedor from '../actions/proveedor.action';
import { proveedorModel } from '../models/proveedor.model';

let initialState = {
  proveedores: [],
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromProveedor.PROVEEDOR_PROCESS:
      return {
        ...state,
        processing: true,
        errors: proveedorModel,
      };
    case fromProveedor.PROVEEDOR_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        proveedores: action.payload.data,
        total: action.payload.count,
      };
    case fromProveedor.PROVEEDOR_LIST_FAIL:
      return {
        ...state,
        processing: false,
        proveedores: [],
        total: 0,
      };
    case fromProveedor.PROVEEDOR_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: proveedorModel,
        openModal: true,
      };
    case fromProveedor.PROVEEDOR_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: proveedorModel,
        openModal: false,
      };
    case fromProveedor.PROVEEDOR_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromProveedor.PROVEEDOR_FILTER:
      return {
        ...state,
        search: action.payload.search,
      };
    case fromProveedor.PROVEEDOR_CLOSE:
      return {
        ...state,
        selected: null,
        errors: proveedorModel,
        openModal: false,
      };
    default:
      return state;
  }
}
