export const cobranzaModal = {
  cr: null,
  cu: null,
  nu: null,
  fv: null,
  us: null,
  fp: null,
  dc: null,
  mc: null,
  mp: null,
  sc: null,
  ec: null,
  de: null,
  te: null,
  ne: false,
  st: false,
};
