import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { maquinModel } from '../models/maquina.model';

export const MAQUINA_PROCESS = '[Maquina] Maquina procesando';
export const MAQUINA_SAVE_SUCCESS = '[Maquina] Maquina guardar exito';
export const MAQUINA_LIST_SUCCESS = '[Maquina] Maquina lista exito';
export const MAQUINA_LIST_FAIL = '[Maquina] Maquina lista error';
export const MAQUINA_SELECTED = '[Maquina] Maquina seleccionado';
export const MAQUINA_CLOSE = '[Maquina] Maquina cerrar modal';
export const MAQUINA_FILTER = '[Maquina] Maquina filtros';
export const MAQUINA_FAIL = '[Maquina] Maquina proceso error';

export const getMaquinasAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { is, isf, st, search } = getState().maquina;
    let { currentUser } = getState().app;

    dispatch({
      type: MAQUINA_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      is,
      isf,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.PUNTOVENTA, { params })
      .then((response) => {
        dispatch({
          type: MAQUINA_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: MAQUINA_LIST_FAIL,
        });
      });
  };

export const getSeleccionarMaquinaAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: MAQUINA_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: MAQUINA_SELECTED,
      payload: maquinModel,
    });
  }
};

export const postMaquinaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: MAQUINA_PROCESS,
  });

  let maquina = {
    pv: item.pv,
    ie: currentUser.ie,
    is: item.is,
    np: item.np,
    ns: item.ns,
    st: item.st,
  };

  api
    .post(servicios.PUNTOVENTA, maquina)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getMaquinasAction({}));
      dispatch({ type: MAQUINA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: MAQUINA_FAIL,
        payload: error.data.errors ? error.data.errors : maquinModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { is, isf, st, search } = getState().maquina;

  let item = {
    is: filtros.is !== undefined ? (filtros.is === '' ? null : filtros.is) : is,
    isf:
      filtros.isf !== undefined
        ? filtros.isf === ''
          ? null
          : filtros.isf
        : isf,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: MAQUINA_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: MAQUINA_CLOSE,
  });
};
