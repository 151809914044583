import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import Icon from '../../commons/Icon';
import Report from '../app/Report';
import { formatoMonetario, formatoFecha } from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import {
  getDocumentosAction,
  getDocumentosDetalleAction,
  getSeleccionarDocumentoAction,
  actualizarFiltros,
  paginado,
  patchAnularDocumentoAction,
} from '../../redux/actions/documento.action';
import { agregarHeaderAction } from '../../redux/actions/app.action';

import { servicios } from '../../redux/helpers/servicios';

import MoneyDown from '../../assets/icons/money-down.svg';
import TicketIcon from '../../assets/icons/ticket.svg';
import CancelIcon from '@material-ui/icons/Cancel';

const VentaList = () => {
  const [buscar, setBuscar] = useState('');
  const [openTicket, setOpenTicket] = useState(false);
  const [params, setParams] = useState({});
  const dispatch = useDispatch();

  const { ie } = useSelector((state) => state.app.currentUser);

  const { documentos, total, processing, rows } = useSelector(
    (state) => state.documento
  );

  const columns = [
    {
      key: 'dc',
      align: 'center',
      content: (item) => (
        <div className="op-centrar-componentes">
          <div title="Detalle de documento">
            <Icon
              svg={MoneyDown}
              transparent
              onClick={() => {
                dispatch(getSeleccionarDocumentoAction(item));
                dispatch(getDocumentosDetalleAction());
                let head = {
                  mm: 'COM_VEN_DETA',
                  nf: false,
                  op: 'Detalle de documento',
                  pp: 'COM_INI',
                  or: 3,
                  im: 'payments',
                  st: true,
                };
                dispatch(agregarHeaderAction(head));
              }}
            />
          </div>
          <div title="Ticket de venta">
            <Icon
              svg={TicketIcon}
              transparent
              onClick={() => {
                setOpenTicket(true);
                setParams({ su: item.is, nu: item.nd });
              }}
            />
          </div>
          <div title="Anular comprobante">
            <CancelIcon
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => {
                Swal.fire({
                  input: 'textarea',
                  inputLabel: 'Motivo de anulación',
                  inputPlaceholder: 'Ingrese el motivo del anulación ...',
                  inputAttributes: {
                    'aria-label': 'Ingrese el motivo del anulación',
                    style: { resize: 'none' },
                  },
                  showCancelButton: true,
                  confirmButtonColor: '#1ab394',
                  confirmButtonText: 'Confirmar',
                  cancelButtonColor: 'red',
                  cancelButtonText: 'Cancelar',
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    confirmacion(
                      '¿Está seguro?',
                      `De anular el comprobante ${item.nd}`
                    )
                      .then(() =>
                        dispatch(
                          patchAnularDocumentoAction(item.dc, result.value)
                        )
                      )
                      .catch(() => {});
                  }
                });
              }}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
      content: (item) => (
        <span style={{ color: `${item.st ? 'none' : 'red'}` }}>{item.ns}</span>
      ),
    },
    {
      key: 'nd',
      label: 'Número documento',
      content: (item) => (
        <span style={{ color: `${item.st ? 'none' : 'red'}` }}>{item.nd}</span>
      ),
    },
    {
      key: 'fe',
      align: 'right',
      label: 'Fecha emisión',
      content: (item) => `${formatoFecha(item.fe)}`,
    },
    {
      key: 'mt',
      align: 'right',
      label: 'Monto total',
      content: (item) =>
        `${item.tm === 0 ? 'S/.' : '$/'} ${formatoMonetario(item.mt)}`,
    },
    {
      key: 'md',
      align: 'right',
      label: 'Descuento global',
      content: (item) =>
        `${item.tm === 0 ? 'S/.' : '$/'} ${formatoMonetario(item.md)}`,
    },
  ];

  return (
    <>
      <Bloque titulo="Resultado de b&uacute;squeda">
        <Tabla
          columns={columns}
          data={documentos}
          processing={processing}
          total={total}
          rows={rows}
          onPagination={({ page }) => {
            dispatch(paginado(page));
            dispatch(getDocumentosAction());
          }}
          onSearching={() => {
            dispatch(actualizarFiltros({ search: buscar }));
            dispatch(getDocumentosAction());
          }}
          onChangeSearching={(search) => setBuscar(search)}
          onDoubleClick={(item) => {
            dispatch(getSeleccionarDocumentoAction(item));
            dispatch(getDocumentosDetalleAction());
            let head = {
              mm: 'COM_VEN_DETA',
              nf: false,
              op: 'Detalle de documento',
              pp: 'COM_INI',
              or: 3,
              im: 'payments',
              st: true,
            };
            dispatch(agregarHeaderAction(head));
          }}
        />
      </Bloque>
      {openTicket && (
        <Report
          url={`${servicios.DOCUMENTO}/${ie}/ticket`}
          params={params}
          extension="pdf"
          onClose={() => {
            setOpenTicket(false);
          }}
        />
      )}
    </>
  );
};

export default VentaList;
