import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { invetarioModel } from '../models/inventario.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const INVENTARIO_PROCESS = '[Inventario] inventario procesando';
export const INVENTARIO_SAVE_SUCCESS = '[Inventario] inventario guardar exito';
export const INVENTARIO_LIST_SUCCESS = '[Inventario] inventario lista exito';
export const INVENTARIO_LIST_FAIL = '[Inventario] inventario lista error';
export const INVENTARIO_SELECTED = '[Inventario] inventario seleccionado';
export const INVENTARIO_TURN_INTO = '[Inventario] inventario convertir';
export const INVENTARIO_CLOSE = '[Inventario] inventario cerrar modal';
export const INVENTARIO_FILTER = '[Inventario] inventario filtros';
export const INVENTARIO_FAIL = '[Inventario] inventario proceso error';
export const INVENTARIO_TRASLADO = '[Inventario] inventario traslado';

export const getInventariosAction = ({ page, pageSize }) => (
  dispatch,
  getState
) => {
  let { su, ct, mt, st, search } = getState().inventario;
  let { currentUser } = getState().app;

  dispatch({
    type: INVENTARIO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    su,
    ct,
    mt,
    st,
    search,
    page,
    pageSize,
  };

  api
    .get(servicios.INVENTARIO, { params })
    .then((response) => {
      dispatch({
        type: INVENTARIO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: INVENTARIO_LIST_FAIL,
      });
    });
};

export const getConvertirInventarioAction = (item) => (dispatch) => {
  dispatch({
    type: INVENTARIO_TURN_INTO,
    payload: item,
  });
};

export const getSeleccionarInventarioAction = (item) => (
  dispatch,
  getState
) => {
  if (item) {
    let { currentUser } = getState().app;
    dispatch({
      type: INVENTARIO_PROCESS,
    });

    api
      .get(
        `${servicios.INVENTARIO}/${currentUser.ie}/empresa/${item.su}/${item.mt}`
      )
      .then((response) => {
        dispatch({
          type: INVENTARIO_SELECTED,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: INVENTARIO_SELECTED,
          payload: invetarioModel,
        });
      });
  } else {
    dispatch({
      type: INVENTARIO_SELECTED,
      payload: invetarioModel,
    });
  }
};

export const putInventarioAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: INVENTARIO_PROCESS,
  });

  let inventario = {
    ie: currentUser.ie,
    su: item.su,
    mt: item.mt,
    pv: quitarFormatoMonetario(item.pv),
    tm: item.tm,
    mg: item.mg,
    ct: item.ct,
    sx: item.sx,
    si: item.si,
    fc: item.fc,
    te: item.te,
    pr: item.pr,
    np: item.np,
  };

  api
    .put(servicios.INVENTARIO, inventario)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getInventariosAction({}));
      dispatch({ type: INVENTARIO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: INVENTARIO_FAIL,
        payload: error.data.errors ? error.data.errors : invetarioModel,
      });
    });
};

export const patchConvertirInventarioAction = (item) => (
  dispatch,
  getState
) => {
  let { currentUser } = getState().app;

  dispatch({
    type: INVENTARIO_PROCESS,
  });

  let inventario = {
    ie: currentUser.ie,
    iu: item.su,
    mo: item.mt,
    md: item.md,
    cc: item.cc,
    co: item.co,
    cd: item.cd,
    us: currentUser.np,
  };

  api
    .patch(`${servicios.INVENTARIO}/convertir`, inventario)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getInventariosAction({}));
      dispatch({ type: INVENTARIO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: INVENTARIO_FAIL,
        payload: error.data.errors ? error.data.errors : invetarioModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, ct, mt, st, search } = getState().inventario;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    ct: filtros.ct !== undefined ? (filtros.ct === '' ? null : filtros.ct) : ct,
    mt: filtros.mt !== undefined ? (filtros.mt === '' ? null : filtros.mt) : mt,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: INVENTARIO_FILTER,
    payload: item,
  });
};

export const openTraslado = () => (dispatch) => {
  dispatch({
    type: INVENTARIO_TRASLADO,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: INVENTARIO_CLOSE,
  });
};
