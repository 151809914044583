import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Boton from '../../commons/Boton';
import Bloque from '../../commons/Bloque';
import Calendario from '../../commons/Calendario';
import Combo from '../../commons/Combo';
import Map from '../../commons/Map';

import { constantes } from '../../redux/helpers/index';
import { formatearFecha } from '../../commons/Utilitarios';
import { mensajeMini } from '../../commons/Mensajes';

import useDeliverys from '../../hooks/useDeliverys';
import useSucursal from '../../hooks/useSucursal';

import PedidoPick from './PedidoPick';
import { getSeleccionPorMapaPedidoAction } from '../../redux/actions/pedido.action';

import SearchIcon from '@material-ui/icons/Search';

import '../../css/components/pedido.css';

const PedidoMap = () => {
  const dispatch = useDispatch();
  const [recoger, setRecoger] = useState(0);
  const [fecha, setFecha] = useState();
  const [hora, setHora] = useState('0');
  const [view, setView] = useState({
    detail: false,
    sucursal: null,
    center: {
      lat: -12.070893071179738,
      lng: -76.93768943175394,
    },
    zoomLevel: 15,
  });
  const [locationAvailable, setLocationAvailable] = useState(false);
  const { ie, is } = useSelector((state) => state.app.currentUser);
  const { openModalPick } = useSelector((state) => state.pedido);

  const { processingDelivey, deliveries, getDeliveries } = useDeliverys();
  const { sucursal } = useSucursal(is);

  useEffect(() => {
    if (sucursal) {
      setView((prev) => ({
        ...prev,
        sucursal: sucursal.av,
        center: {
          lat: sucursal.la,
          lng: sucursal.lo,
        },
      }));
      setLocationAvailable(true);
    }
  }, [sucursal]);

  const handleGetDeliveries = () => {
    const horarios = [
      { id: '0', ini: 7, fin: 9 },
      { id: '1', ini: 9, fin: 11 },
      { id: '2', ini: 11, fin: 13 },
      { id: '3', ini: 13, fin: 15 },
      { id: '4', ini: 15, fin: 17 },
      { id: '5', ini: 17, fin: 19 },
    ];

    if (fecha) {
      getDeliveries(
        ie,
        formatearFecha(fecha, 'YYYY-MM-DD'),
        horarios.find((x) => x.id === hora).ini,
        horarios.find((x) => x.id === hora).fin,
        recoger
      );
    } else {
      mensajeMini('Seleccione fecha de búsqueda');
    }
  };

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <div>Procesando map</div>;
      case Status.FAILURE:
        return <div>Error de lectura mapa</div>;
      case Status.SUCCESS:
        return (
          <Map
            locations={deliveries}
            view={view}
            onSelected={(item) =>
              dispatch(getSeleccionPorMapaPedidoAction(item))
            }
          />
        );
    }
  };
  return (
    <div className="op-component view-pedido view-pedido-map">
      <div className="content-map">
        <Bloque
          titulo="B&uacute;squeda de pedidos"
          onActions={() => (
            <>
              <Boton
                className="op-grabar"
                disabled={processingDelivey}
                icon={() => <SearchIcon />}
                onClick={() => handleGetDeliveries()}
              >
                Buscar pedidos
              </Boton>
            </>
          )}
        >
          <div className="op-form">
            <Calendario
              name="fecha"
              value={fecha}
              label="Fecha b&uacute;squeda"
              tabIndex={12}
              size={3}
              minDate={new Date()}
              closeOnSelect
              onSelected={({ value }) => setFecha(value)}
            />
            <Combo
              name="recoger"
              value={recoger}
              label="Recoger / Entregar"
              size={3}
              tabIndex={13}
              data={[
                { value: '0', label: 'Recoger' },
                { value: '1', label: 'Entregar' },
              ]}
              onChange={({ value }) => setRecoger(value)}
            />
            <Combo
              name="Horario"
              value={hora}
              label="Horario"
              size={3}
              tabIndex={13}
              data={[
                { value: '0', label: '7 - 9' },
                { value: '1', label: '9 - 11' },
                { value: '2', label: '11 - 13' },
                { value: '3', label: '13 - 15' },
                { value: '4', label: '15 - 17' },
                { value: '5', label: '17 - 19' },
              ]}
              onChange={({ value }) => setHora(value)}
            />
          </div>
        </Bloque>
        {locationAvailable ? (
          <Wrapper apiKey={constantes.GOOGLE_KEY} render={render} />
        ) : (
          <div>Ubicando en el mapa</div>
        )}
      </div>
      {openModalPick && <PedidoPick />}
    </div>
  );
};

export default PedidoMap;
