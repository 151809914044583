import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import { formatoFecha } from '../../commons/Utilitarios';

import {
  getGuiasAction,
  getSeleccionarGuiaAction,
  paginado,
  actualizarFiltros,
} from '../../redux/actions/guia.action';

import EditIcon from '@material-ui/icons/Edit';

const GuiaList = ({ guias, processing, rows, total }) => {
  const [buscar, setBuscar] = useState();
  const dispatch = useDispatch();
  const columns = [
    {
      key: 'dc',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => dispatch(getSeleccionarGuiaAction(item))}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'nd',
      label: 'Número documento',
    },
    {
      key: 'fe',
      align: 'right',
      label: 'Fecha emisión',
      content: (item) => `${formatoFecha(item.fe)}`,
    },
    {
      key: 'guia.dm',
      label: 'Motivo',
      content: (item) => item.gui.dm,
    },
    {
      key: 'gui.de',
      label: 'Estado guía',
      content: (item) => item.gui.de,
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={guias}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getGuiasAction());
        }}
        onSearching={() => {
          dispatch(actualizarFiltros({ search: buscar }));
          dispatch(getGuiasAction());
        }}
        onChangeSearching={(search) => setBuscar(search)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    guias: state.guia.guias,
    processing: state.guia.processing,
    total: state.guia.total,
    rows: state.guia.rows,
  };
};

export default connect(mapStateToProps)(GuiaList);
