import React from 'react';
import { useDispatch, connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';

import {
  getDocumentosDetalleAction,
  paginadoDetalle,
} from '../../redux/actions/documento.action';

import {
  formatoMonetario,
  redondeoDecimales,
  formatoFecha,
} from '../../commons/Utilitarios';

import '../../css/components/venta.css';

import RestoreIcon from '@material-ui/icons/Restore';

const VentaDetalle = ({ detalles, selected, processing, totalDetalle }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'ci',
      label: 'Código item',
    },
    {
      key: 'di',
      label: 'Descripción item',
    },
    {
      key: 'du',
      label: 'Unidad de medida',
    },
    {
      key: 'cu',
      align: 'right',
      label: 'Cantidad unidades',
    },
    {
      key: 'pi',
      align: 'right',
      label: 'Precio venta',
      content: (item) =>
        `${selected.tm === 0 ? 'S/.' : '$/'} ${formatoMonetario(
          redondeoDecimales(item.pi)
        )}`,
    },
    {
      key: 'st',
      align: 'right',
      label: 'Subtotal',
      content: (item) =>
        `${selected.tm === 0 ? 'S/.' : '$/'} ${formatoMonetario(
          redondeoDecimales(item.pi * item.cu)
        )}`,
    },
    {
      key: 'ds',
      align: 'right',
      label: 'Descuento por item',
      content: (item) =>
        `${selected.tm === 0 ? 'S/.' : '$/'} ${formatoMonetario(
          redondeoDecimales(item.ds)
        )}`,
    },
  ];

  return (
    <Bloque
      titulo={`Número de documento: ${selected.nd}`}
      onActions={() => (
        <Boton
          className="op-dinamic"
          style={{ '--button-color': '#3285a8' }}
          icon={() => <RestoreIcon />}
          onClick={() => dispatch(getDocumentosDetalleAction())}
        >
          Refrescar operaciones
        </Boton>
      )}
    >
      <div className="op-content-informativo">
        <div className="op-informativo">
          <label className="titulo">Datos de documento</label>
          <div className="op-datos">
            <span>Sucursal</span>
            <b>{selected ? selected.ns : ''}</b>
          </div>
          <div className="op-datos">
            <span>Tipo documento</span>
            <b>{selected ? selected.dt : ''}</b>
          </div>
          <div className="op-datos">
            <span>Fecha de emisión</span>
            <b>{selected ? formatoFecha(selected.fe) : ''}</b>
          </div>
          <div className="op-datos">
            <span>Estado</span>
            <b>{selected ? selected.de : ''}</b>
          </div>
          {!selected.st && (
            <>
              <div className="op-datos">
                <span>Fecha anulaci&oacute;n</span>
                <b>{selected ? formatoFecha(selected.fa) : ''}</b>
              </div>
              <div className="op-datos">
                <span>Motivo anulaci&oacute;n</span>
                <b>{selected ? selected.ma : ''}</b>
              </div>
            </>
          )}
          <div className="op-datos">
            <span>Tipo moneda</span>
            <b>
              {selected ? `${selected.tm === 0 ? 'Soles' : 'Dolares'}` : ''}
            </b>
          </div>
          <div className="op-datos">
            <span>Tipo de cambio</span>
            <b>
              {selected ? formatoMonetario(redondeoDecimales(selected.tc)) : ''}
            </b>
          </div>
          <div className="op-datos">
            <span>Monto total</span>
            <b>
              {selected ? formatoMonetario(redondeoDecimales(selected.mt)) : ''}
            </b>
          </div>
          <div className="op-datos">
            <span>Total IGV</span>
            <b>
              {selected ? formatoMonetario(redondeoDecimales(selected.mi)) : ''}
            </b>
          </div>
        </div>
        {selected.com && (
          <div className="op-informativo">
            <label className="titulo">Datos del comprobante</label>
            <div className="op-datos">
              <span>Vendedor</span>
              <b>{selected.com.nv ? selected.com.nv : ''}</b>
            </div>
            <div className="op-datos">
              <span>Cliente</span>
              <b>{selected.com.nc ? selected.com.nc : ''}</b>
            </div>
            <div className="op-datos">
              <span>Documento cliente</span>
              <b>{selected.com.dc ? selected.com.dc : ''}</b>
            </div>
            <div className="op-datos">
              <span>Observaciones</span>
              <b>{selected.com.oc ? selected.com.oc : ''}</b>
            </div>
            <div className="op-datos">
              <span>Forma de pago</span>
              <b>
                {selected.com
                  ? `${selected.com.fp === 0 ? 'Efectivo' : 'Tarjeta'}`
                  : ''}
              </b>
            </div>
            {selected.com && selected.com.fp !== 0 && (
              <>
                <div className="op-datos">
                  <span>Banco</span>
                  <b>{selected.com.tb ? selected.com.tb : ''}</b>
                </div>
                <div className="op-datos">
                  <span>Información de pago</span>
                  <b>{selected.com.ip ? selected.com.ip : ''}</b>
                </div>
              </>
            )}
            {selected.com && selected.com.md > 0 && (
              <>
                <div className="op-datos">
                  <span>Descuento global</span>
                  <b>
                    {selected
                      ? formatoMonetario(redondeoDecimales(selected.md))
                      : ''}
                  </b>
                </div>
                <div className="op-datos">
                  <span>Motivo descuento global</span>
                  <b>{selected.com.md ? selected.com.md : ''}</b>
                </div>
              </>
            )}
            {selected.com && selected.com.ti > 0 && (
              <div className="op-datos">
                <span>Descuento total de items</span>
                <b>
                  {selected.com.ti
                    ? formatoMonetario(redondeoDecimales(selected.com.ti))
                    : ''}
                </b>
              </div>
            )}
            {selected.com && selected.com.pa && (
              <>
                <div className="op-datos">
                  <span>Monto de anticipo</span>
                  <b>
                    {selected.com.am
                      ? formatoMonetario(redondeoDecimales(selected.com.am))
                      : ''}
                  </b>
                </div>
              </>
            )}
            {selected.com && selected.com.tt !== 0 && (
              <>
                <div className="op-datos">
                  <span>Operaci&oacute;n al cr&eacute;dito</span>
                  <b>
                    {selected.com.pp
                      ? formatoMonetario(redondeoDecimales(selected.com.pp))
                      : ''}
                  </b>
                </div>
                {selected.com.cut.map((x, i) => (
                  <>
                    <div className="op-datos">
                      <span>{`Monto c-${i + 1}`}</span>
                      <b>{formatoMonetario(redondeoDecimales(x.mc))}</b>
                    </div>
                    <div className="op-datos">
                      <span>{`Fecha vencimiento c-${i + 1}`}</span>
                      <b>{formatoFecha(x.fv, '', false)}</b>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        )}
      </div>
      <Tabla
        columns={columns}
        data={detalles}
        processing={processing}
        total={totalDetalle}
        onPagination={({ page }) => {
          dispatch(paginadoDetalle(page));
          dispatch(getDocumentosDetalleAction());
        }}
        searcheable={false}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    detalles: state.documento.detalles,
    totalDetalle: state.documento.totalDetalle,
    processing: state.documento.processing,
    selected: state.documento.selected,
  };
};

export default connect(mapStateToProps)(VentaDetalle);
