import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import { formatoFecha } from '../../commons/Utilitarios';

import {
  getPagosAction,
  actualizarFiltros,
  paginado,
} from '../../redux/actions/pagos.action';

import { getSeleccionarOrdenCompraAction } from '../../redux/actions/ordencompra.action';

import VisibilityIcon from '@material-ui/icons/Visibility';

const PagoProveedoresList = ({ pagos, processing, total, rows }) => {
  const [buscar, setBuscar] = useState();
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'oc',
      align: 'center',
      content: (item) => (
        <div title="Ver" style={{ display: 'inline-block' }}>
          <VisibilityIcon
            onClick={() => dispatch(getSeleccionarOrdenCompraAction(item))}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'nu',
      label: 'Usuario',
      content: (item) => handleColor(item.vm, item.nu),
    },
    {
      key: 'co',
      align: 'right',
      label: 'Código',
      content: (item) => handleColor(item.vm, item.co),
    },
    {
      key: 'fr',
      align: 'right',
      label: 'Fecha registro',
      content: (item) => handleColor(item.vm, formatoFecha(item.fr)),
    },
    {
      key: 'de',
      label: 'Estado',
      content: (item) => handleColor(item.vm, item.de),
    },
    {
      key: 'cc',
      label: 'Condición pago',
      content: (item) =>
        handleColor(item.vm, item.cc === '0' ? 'Contado' : 'Credito'),
    },
    {
      key: 'fe',
      label: 'Fecha entrega',
      align: 'right',
      content: (item) =>
        handleColor(item.vm, formatoFecha(item.fe, 'Sin confirmar')),
    },
    {
      key: 'fp',
      label: 'Fecha pago',
      align: 'right',
      content: (item) =>
        handleColor(item.vm, formatoFecha(item.fp, 'Pendiente')),
    },
    {
      key: 'dd',
      label: 'Plazo (días)',
      content: (item) =>
        handleColor(
          item.vm,
          item.fp ? 'Pagado' : item.dd === 0 ? 'Contado' : item.dd
        ),
    },
  ];

  const handleColor = (estado, texto) => {
    let color = '#000000';
    switch (estado) {
      case 'ENTORD':
        color = '#0d6efd';
        break;
      case 'PAGAORD':
        color = '#1ab394';
        break;
      default:
        break;
    }

    return <span style={{ color: color }}>{texto}</span>;
  };
  return (
    <Bloque titulo="Pago proveedores">
      <Tabla
        columns={columns}
        data={pagos}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getPagosAction());
        }}
        onSearching={() => {
          dispatch(actualizarFiltros({ search: buscar }));
          dispatch(getPagosAction());
        }}
        onChangeSearching={(search) => setBuscar(search)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    pagos: state.pagos.pagos,
    processing: state.pagos.processing,
    total: state.pagos.total,
    rows: state.pagos.rows,
  };
};

export default connect(mapStateToProps)(PagoProveedoresList);
