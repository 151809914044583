import * as React from 'react';

function Map({ locations, view, onSelected }) {
  const mapRef = React.useRef();
  let map;
  let infoWindow = new google.maps.InfoWindow();

  function showMarkers(deliveries) {
    const markerInicial = new window.google.maps.Marker({
      position: {
        lat: view.center.lat,
        lng: view.center.lng,
      },
    });
    markerInicial.addListener('click', () => {
      infoWindow.setContent(
        `<div className='flex flex-col content-start p-2'>` +
          `<span>${view.sucursal}</span>`
      );
      infoWindow.open({
        anchor: markerInicial,
        map,
        shouldFocus: true,
      });
    });
    infoWindow.setContent(
      `<div className='flex flex-col content-start p-2'>` +
        `<span>${view.sucursal}</span>`
    );
    infoWindow.open({
      anchor: markerInicial,
      map,
      shouldFocus: true,
    });
    markerInicial.setMap(map);

    deliveries.forEach((location, i) => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: Number(location.la),
          lng: Number(location.lo),
        },
        // icon: mapIcon,
      });

      //   window.google.maps.event.addDomListener(clinicView, "click", () => {
      //     infoWindow.setContent(
      //       `<div className='flex flex-col content-start p-2'>` +
      //         `<h2 className='font-semibold text-lg mb-2'>` +
      //         `${location.locationName}` +
      //         `</h2>` +
      //         `<p>${line1}</p>` +
      //         `<p>${city}, ${state} ${postcode}</p>`
      //     );

      //     infoWindow.open({
      //       anchor: marker,
      //       map,
      //       shouldFocus: true,
      //     });
      //   });
      marker.addListener('click', () => {
        // selectedLocation = location;
        infoWindow.setContent(
          `<div className='flex flex-col content-start p-2'>` +
            `<h2 className='font-semibold text-lg mb-2'>` +
            `${location.np}` +
            `</h2>` +
            `<span>Cliente: <strong>${location.nc}</strong></span><br />` +
            `<span>Dirección: <strong>${location.dp}</strong></span>`
        );

        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: true,
        });
        onSelected(location);
      });

      marker.setMap(map);
    });
  }

  function calcRoute(deliveries, directionsService, directionsDisplay) {
    const waypts = [];

    for (let i = 0; i < deliveries.length; i++) {
      waypts.push({
        location: {
          lat: Number(deliveries[i].la),
          lng: Number(deliveries[i].lo),
        },
        stopover: true,
      });
    }

    //create request
    var request = {
      origin: view.center,
      destination: view.center,
      waypoints: waypts,
      optimizeWaypoints: true,
      travelMode: google.maps.TravelMode.DRIVING, //WALKING, BYCYCLING, TRANSIT
      unitSystem: google.maps.UnitSystem.IMPERIAL,
    };

    //pass the request to the route method
    directionsService.route(request, function (result, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        //display route
        directionsDisplay.setDirections(result);
      } else {
        //delete route from map
        directionsDisplay.setDirections({ routes: [] });
        //center map in London
        map.setCenter(view.center);
      }
    });
  }

  React.useEffect(() => {
    map = new window.google.maps.Map(mapRef.current, {
      zoom: view.zoomLevel,
      center: view.center,
      mapTypeControl: false,
    });
    //create a DirectionsService object to use the route method and get a result for our request
    let directionsService = new google.maps.DirectionsService();
    //create a DirectionsRenderer object which we will use to display the route
    let directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
    });
    directionsDisplay.setMap(map);
    if (mapRef.current) {
      if (locations.length > 0) {
        showMarkers(locations);
        calcRoute(locations, directionsService, directionsDisplay);
      }
    }
  }, [locations.length]);

  return (
    <div
      ref={mapRef}
      id="map"
      style={{ width: '100%', height: 'calc(100vh - 100px)' }}
    />
  );
}

export default Map;
