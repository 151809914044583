import * as fromCorrelativo from '../actions/correlativo.action';
import { correlativoModel } from '../models/correlativo.model';

let initialState = {
  correlativos: [],
  processing: false,
  selected: null,
  openModal: false,
  openModalNew: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCorrelativo.CORRELATIVO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: correlativoModel,
      };
    case fromCorrelativo.CORRELATIVO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        correlativos: action.payload,
      };
    case fromCorrelativo.CORRELATIVO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        correlativos: [],
      };
    case fromCorrelativo.CORRELATIVO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: correlativoModel,
        processing: false,
        openModal: true,
      };
    case fromCorrelativo.CORRELATIVO_NEW:
      return {
        ...state,
        openModalNew: true,
        selected: action.payload,
      };
    case fromCorrelativo.CORRELATIVO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        openModalNew: false,
        errors: correlativoModel,
      };
    case fromCorrelativo.CORRELATIVO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCorrelativo.CORRELATIVO_CLOSE:
      return {
        ...state,
        errors: correlativoModel,
        openModal: false,
        openModalNew: false,
      };
    default:
      return state;
  }
}
