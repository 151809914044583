import * as fromMaterial from '../actions/material.action';
import { materialModel } from '../models/material.model';

let initialState = {
  materiales: [],
  pr: null,
  um: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
  openModalImagen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromMaterial.MATERIAL_PROCESS:
      return {
        ...state,
        processing: true,
        errors: materialModel,
      };
    case fromMaterial.MATERIAL_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        materiales: action.payload.data,
        total: action.payload.count,
      };
    case fromMaterial.MATERIAL_LIST_FAIL:
      return {
        ...state,
        processing: false,
        materiales: [],
        total: 0,
      };
    case fromMaterial.MATERIAL_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: materialModel,
        openModal: true,
        openModalImagen: false,
      };
    case fromMaterial.MATERIAL_IMAGE:
      return {
        ...state,
        selected: action.payload,
        errors: materialModel,
        openModal: false,
        openModalImagen: true,
      };
    case fromMaterial.MATERIAL_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: materialModel,
        openModal: false,
        openModalImagen: false,
      };
    case fromMaterial.MATERIAL_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromMaterial.MATERIAL_FILTER:
      return {
        ...state,
        pr: action.payload.pr,
        um: action.payload.um,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromMaterial.MATERIAL_CLOSE:
      return {
        ...state,
        openModal: false,
        openModalImagen: false,
        errors: materialModel,
      };
    default:
      return state;
  }
}
