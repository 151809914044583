export const cajaModel = {
  fo: null,
  tc: null,
  dt: null,
  co: null,
  is: '1',
  tm: '0',
  mo: null,
  ia: null,
  iu: null,
  nu: null,
};
