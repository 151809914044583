import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getRedesSocialesAction,
  getSeleccionarRedSocialAction,
} from '../../redux/actions/redsocial.action';

import EditIcon from '@material-ui/icons/Edit';

const RedSocialList = ({ redes, processing }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'rs',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => dispatch(getSeleccionarRedSocialAction(item))}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'dr',
      label: 'Red social',
    },
    {
      key: 'ap',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.ap} disabled />,
    },
  ];
  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={redes}
        processing={processing}
        pagination={false}
        searcheable={false}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    redes: state.red.redes,
    processing: state.red.processing,
  };
};

export default connect(mapStateToProps)(RedSocialList);
