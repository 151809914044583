import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  getPagosAction,
  actualizarFiltros,
} from '../../redux/actions/pagos.action';

import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import PagoProveedoresList from './PagoProveedoresList';
import PagoProveedoresFilter from './PagoProveedoresFilter';
import OrdenCompraVer from '../ordencompra/OrdenCompraVer';

const PagoProveedores = ({ openVer }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPagosAction());
    dispatch(getSucursalesAction({}));
    getDetallesPorCabeceraAction({
      ca: 'ESTADOORDEN',
    });
    dispatch(
      getDetallesPorCabeceraAction({
        ca: 'TIPOCAJA',
      })
    );
    dispatch(
      getDetallesPorCabeceraAction({
        ca: 'TIPODOCUMENTO',
      })
    );
    return () => {
      dispatch(
        actualizarFiltros({ su: null, es: null, st: null, search: null })
      );
    };
  }, []);
  return (
    <div className="op-component view-ordencompra">
      <PagoProveedoresList />
      <PagoProveedoresFilter />
      {openVer && <OrdenCompraVer />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openVer: state.ordenCompra.openVer,
  };
};

export default connect(mapStateToProps)(PagoProveedores);
