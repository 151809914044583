import React, { useState } from 'react';
import { connect } from 'react-redux';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';

import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import Bloque from '../../commons/Bloque';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import {
  formatearFecha,
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import {
  getSeleccionarCajaAction,
  getOperacionesCajaAction,
  actualizarFiltros,
  patchCierreCajaAction,
  openModalView,
} from '../../redux/actions/caja.action';

import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import FinancialIcon from '../../assets/icons/financial.svg';

const CajaList = ({
  selected,
  detalles,
  processing,
  total,
  getSeleccionarCajaAction,
  getOperacionesCajaAction,
  actualizarFiltros,
  patchCierreCajaAction,
  openModalView,
}) => {
  const [buscar, setBuscar] = useState();
  const [selectedDate, handleDateChange] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: '#3498db', contrastText: '#fff' },
    },
  });

  const handleClass = (item) => {
    if (!item.st) {
      return 'op-delete';
    } else if (item.is === 1) {
      return 'op-success';
    } else {
      return 'op-error';
    }
  };

  const columns = [
    {
      key: 'fo',
      label: 'Fecha',
      align: 'right',
      width: '160',
      content: (item) => (
        <span className={handleClass(item)}>
          {formatoFecha(item.fo, 'Indefinido')}
        </span>
      ),
    },
    {
      key: 'dt',
      label: 'Tipo caja',
      content: (item) => <span className={handleClass(item)}>{item.dt}</span>,
    },
    {
      key: 'co',
      label: 'Concepto',
      content: (item) => (
        <div
          className="op-centrar-componentes"
          style={{ justifyContent: 'flex-start' }}
        >
          {item.is === 1 ? (
            <TrendingUpIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <TrendingDownIcon fontSize="small" style={{ color: 'red' }} />
          )}
          <span className={handleClass(item)}>{item.co}</span>
        </div>
      ),
    },
    {
      key: 'mo',
      label: 'Monto',
      align: 'right',
      content: (item) => (
        <div
          className={`op-arrow-${item.is === 1 ? 'up' : 'down'} ${handleClass(
            item
          )}`}
          style={{
            justifyContent: 'flex-end',
          }}
        >
          {item.tm === 0 ? 'S/' : '$'}
          {formatoMonetario(redondeoDecimales(item.mo))}
        </div>
      ),
    },
  ];

  return (
    <Bloque titulo="Operaciones de caja">
      <div className="op-form" style={{ padding: '20px' }}>
        <Texto
          name="in"
          value={
            selected ? formatoMonetario(redondeoDecimales(selected.in)) : ''
          }
          label="Saldo inicial soles"
          size={4}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ie"
          value={
            selected ? formatoMonetario(redondeoDecimales(selected.ie)) : ''
          }
          label="Saldo inicial dolares"
          size={4}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="sn"
          value={
            selected ? formatoMonetario(redondeoDecimales(selected.sn)) : ''
          }
          label="Saldo actual soles"
          size={4}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="sn"
          value={
            selected ? formatoMonetario(redondeoDecimales(selected.se)) : ''
          }
          label="Saldo actual dolares"
          size={4}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fa"
          value={selected ? formatoFecha(selected.fa) : ''}
          label="Fecha apertura"
          size={4}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fc"
          value={selected ? formatoFecha(selected.fc) : ''}
          label="Fecha cierre"
          size={4}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <div className="op-form-group op-col-4">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <DatePicker
                variant="inline"
                openTo="year"
                views={['year', 'month']}
                helperText="Ciclo mensual"
                value={selectedDate}
                autoOk={true}
                format="YYYY-MM"
                maxDate={
                  new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                }
                onChange={handleDateChange}
                onMonthChange={(item) => {
                  actualizarFiltros({
                    fo: formatearFecha(item._d, 'YYYY-MM-DD'),
                  });
                  getSeleccionarCajaAction();
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </div>
        <div
          className="op-form-group op-col-4"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            height: '49px',
          }}
        >
          <Boton
            className="op-grabar"
            icon={() => <Icon svg={FinancialIcon} transparent />}
            tabIndex={21}
            disabled={processing}
            onClick={() =>
              confirmacion('¿Está seguro?', `De realizar el cierre mensual`)
                .then(() => patchCierreCajaAction())
                .catch(() => {})
            }
          >
            {` Cierre mensual`}
          </Boton>
        </div>
      </div>
      <Tabla
        columns={columns}
        data={detalles}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getOperacionesCajaAction({ page, pageSize: rows })
        }
        onSearching={() => {
          actualizarFiltros({ search: buscar });
          getOperacionesCajaAction({});
        }}
        onChangeSearching={(search) => setBuscar(search)}
        onDoubleClick={(item) => openModalView(item)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.caja.selected,
    detalles: state.caja.detalles,
    processing: state.caja.processing,
    total: state.caja.total,
  };
};

export default connect(mapStateToProps, {
  getOperacionesCajaAction,
  actualizarFiltros,
  patchCierreCajaAction,
  getSeleccionarCajaAction,
  openModalView,
})(CajaList);
