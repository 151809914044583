import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { operacionModel } from '../models/operacion.model';
import { getInventariosAction } from './inventario.action';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const OPERACION_PROCESS = '[Operacion] Operacion procesando';
export const OPERACION_SAVE_SUCCESS = '[Operacion] Operacion guardar exito';
export const OPERACION_LIST_SUCCESS = '[Operacion] Operacion lista exito';
export const OPERACION_LIST_FAIL = '[Operacion] Operacion lista error';
export const OPERACION_SELECTED = '[Operacion] Operacion seleccionado';
export const OPERACION_CLOSE = '[Operacion] Operacion cerrar modal';
export const OPERACION_FILTER = '[Operacion] Operacion filtros';
export const OPERACION_FAIL = '[Operacion] Operacion proceso error';

export const getOperacionesAction = ({ page, pageSize }) => (
  dispatch,
  getState
) => {
  let { su, ct, fi, ff, is, search } = getState().operacion;
  let { currentUser } = getState().app;

  dispatch({
    type: OPERACION_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    su,
    ct,
    fi,
    ff,
    is,
    search,
    page,
    pageSize,
  };

  api
    .get(servicios.OPERACION, { params })
    .then((response) => {
      dispatch({
        type: OPERACION_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: OPERACION_LIST_FAIL,
      });
    });
};

export const getSeleccionarOperacionAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  if (item) {
    dispatch({
      type: OPERACION_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: OPERACION_SELECTED,
      payload: operacionModel,
    });
  }
};

export const postRegistrarOperacionAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: OPERACION_PROCESS,
  });

  let operacion = {
    ie: currentUser.ie,
    iu: item.su,
    ct: item.ct,
    mo: item.mo,
    cm: item.cm,
    pm: quitarFormatoMonetario(item.pm),
    nu: currentUser.np,
  };

  api
    .post(servicios.OPERACION, operacion)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getOperacionesAction({}));
      dispatch(getInventariosAction({}));
      dispatch({ type: OPERACION_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: OPERACION_FAIL,
        payload: error.data.errors ? error.data.errors : operacionModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, ct, fi, ff, is, search } = getState().operacion;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    ct: filtros.ct !== undefined ? (filtros.ct === '' ? null : filtros.ct) : ct,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
    is: filtros.is !== undefined ? (filtros.is === '' ? null : filtros.is) : is,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: OPERACION_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: OPERACION_CLOSE,
  });
};
