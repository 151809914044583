import { useEffect, useState } from 'react';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useSucursal = (is) => {
  const [processingSucursal, setProcessingSucursal] = useState(false);
  const [sucursal, setSucursal] = useState();

  const getSucursal = () => {
    setProcessingSucursal(true);
    api
      .get(`${servicios.SUCURSAL}/${is}`)
      .then((response) => {
        setSucursal(response);
      })
      .catch(() => {
        setSucursal([]);
      })
      .finally(() => setProcessingSucursal(false));
  };

  useEffect(() => {
    getSucursal();
  }, []);

  return {
    sucursal,
    processingSucursal,
  };
};

export default useSucursal;
