import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import ServicioReporte from '../components/dashboard/ServicioReporte';
import ReporteriaMensual from './dashboard/ReporteriaMensual';
import TopVentas from './dashboard/TopVentas';
import IngresosReport from './dashboard/IngresosReport';
import Home from './dashboard/Home';

import { getCobranzasReporteAction } from '../redux/actions/cobranza.action';
import { getPedidosReporteAction } from '../redux/actions/pedido.action';

import '../css/components/inicio.css';

const Inicio = ({ perfil }) => {
  const dispatch = useDispatch();
  const { cobranzasReportes } = useSelector((state) => state.cobranza);
  const { pedidosReporte } = useSelector((state) => state.pedido);

  useEffect(() => {
    dispatch(getCobranzasReporteAction());
    dispatch(getPedidosReporteAction());
  }, []);

  const getReporteriaMensual = () => {
    let total = cobranzasReportes.reduce((a, b) => a + b.to, 0);
    if (total === 0) {
      return (
        <ReporteriaMensual
          title={'Pedidos'}
          data={pedidosReporte}
          colors={['#174aa1', '#858585', '#48977b', '#f1280c', '#394264']}
          onRefresh={() => dispatch(getPedidosReporteAction())}
        />
      );
    } else {
      return (
        <ReporteriaMensual
          title={'Cobranzas'}
          data={cobranzasReportes}
          onRefresh={() => dispatch(getCobranzasReporteAction)}
        />
      );
    }
  };

  return (
    <div className="op-component view-inicio">
      {perfil === 'NOBASICO' ? (
        <div className="grid-container">
          <IngresosReport />
          {getReporteriaMensual()}
          <TopVentas />
          <ServicioReporte />
        </div>
      ) : (
        <Home />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps)(Inicio);
