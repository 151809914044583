export const pedidoModel = {
  pd: null,
  em: null,
  av: null,
  is: null,
  ns: null,
  cl: null,
  ll: null,
  nc: null,
  ap: null,
  uc: null,
  np: null,
  fu: null,
  iu: null,
  nu: null,
  fp: null,
  ce: null,
  de: null,
  me: null,
  la: 0,
  lo: 0,
  dp: null,
  fc: null,
  fa: 0,
  fr: null,
  mp: 0,
  md: 0,
  mc: null,
  adj: null,
  st: null,
  ds: null,
  mg: null,
  td: 0,
  pe: null,
  vu: null,
  pp: null,
  tb: null,
  tc: null,
  ip: null,
  oc: null,
  rr: null,
  no: null,
  nt: null,
  rf: null,
  ob: null,
  det: [],
  sts: [],
};
