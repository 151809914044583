export const materialModel = {
  mt: null,
  cm: null,
  nm: null,
  um: '',
  du: null,
  pc: null,
  pr: true,
  st: false,
  gc: true,
};
