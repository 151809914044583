import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import {
  closeModal,
  postSolicitarOrdenCompraAction,
} from '../../redux/actions/ordencompra.action';

import {
  getMaterialesAction,
  actualizarFiltros,
} from '../../redux/actions/material.action';

import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import '../../css/ordencompra.css';

const OrdenCompraSolicitar = ({
  processing,
  errors,
  usuario,
  sucursal,
  materiales,
  total,
  processingList,
  closeModal,
  postSolicitarOrdenCompraAction,
  getMaterialesAction,
  actualizarFiltros,
}) => {
  const [tipo, setTipo] = useState('');
  const [detalle, setDetalle] = useState([]);
  const [openProductos, setOpenProductos] = useState(false);

  useEffect(() => {
    getMaterialesAction({});

    return () => {
      actualizarFiltros({ pr: null, um: null, st: null, search: null });
    };
  }, []);

  const columns = [
    {
      key: 'mt',
      align: 'center',
      content: (item) => (
        <div title="Eliminar" style={{ display: 'inline-block' }}>
          <DeleteIcon
            onClick={() =>
              confirmacion(
                '¿Desea eliminar?',
                `${item.nm} de la orden de compra`
              )
                .then(() => handleEliminarMaterial(item.mt))
                .catch(() => {})
            }
            style={{ color: 'red', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Material / Producto',
    },
    {
      key: 'du',
      label: 'Unidad medida',
    },
    {
      key: 'ct',
      align: 'center',
      label: 'Cantidad',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}>
              {item.ct}
            </span>
            <input
              type="text"
              value={item.ct}
              onChange={(e) => handleEditarCantidad(item.mt, e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.mt, !item.edit);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
              onFocus={(e) => e.target.select()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={() => handleHabilitarEdicion(item.mt, !item.edit)}
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, -1)}
          />
        </div>
      ),
    },
  ];

  const columnsProductos = [
    {
      key: 'mt',
      label: 'Enviar',
      align: 'center',
      content: (item) => (
        <div title="Enviar">
          <CheckIcon
            onClick={() => {
              handleAgregarMaterial(item);
              setOpenProductos(false);
            }}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'cm',
      align: 'center',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
    },
    {
      key: 'du',
      label: 'Unidad de medida',
    },
  ];

  const handleAgregarMaterial = (item) => {
    let existe = detalle.find((x) => x.mt === item.mt);
    if (existe === undefined) {
      item.ct = 1;
      item.pr = item.pc;
      item.edit = false;
      setDetalle([...detalle, item]);
    }
  };

  const handleEliminarMaterial = (mt) => {
    const currents = detalle.filter((x) => x.mt !== mt);
    setDetalle(currents);
  };

  const handleCantidadMaterial = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              ct:
                valor === -1 && x.ct <= 1
                  ? 1
                  : parseInt(x.ct ? x.ct : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (mt, valor) => {
    setDetalle(detalle.map((x) => (x.mt === mt ? { ...x, edit: valor } : x)));
  };

  const handleEditarCantidad = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              ct: valor <= 0 ? 1 : valor,
            }
          : x
      )
    );
  };

  const handleSolicitarOrden = () => {
    if (detalle.length === 0) {
      mensajeMini('Agregue productos o insumos a la solicitud');
    } else {
      postSolicitarOrdenCompraAction(detalle);
    }
  };

  return (
    <>
      <Modal
        title={`Solicitar orden compra`}
        processing={processing}
        loading="supplies"
        onActions={() => (
          <>
            <Boton
              className="op-dinamic"
              icon={() => <AddIcon />}
              tabIndex={22}
              style={{ '--button-color': '#3285a8' }}
              onClick={() => setOpenProductos(true)}
            >
              Agregar producto
            </Boton>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion(
                  '¿Desea guardar?',
                  `Una nueva solicitud de orden de compra`
                )
                  .then(() => handleSolicitarOrden())
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              closeModal();
            })
            .catch(() => {})
        }
      >
        <Texto
          name="nu"
          value={usuario}
          label="Usuario"
          tabIndex={10}
          size={2}
          error={errors.nu}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ns"
          value={sucursal}
          label="Sucursal"
          tabIndex={11}
          size={2}
          onChange={() => {}}
          disabled
        />
        <div className="op-form-group op-col-1">
          <Tabla
            columns={columns}
            data={detalle}
            pagination={false}
            searcheable={false}
            hoverSelectedRow={false}
          />
        </div>
      </Modal>
      {openProductos && (
        <Modal
          title="B&uacute;squeda de producto / insumo"
          closePressEscape={false}
          onClose={() => setOpenProductos(false)}
        >
          <Tabla
            columns={columnsProductos}
            data={materiales}
            total={total}
            processing={processingList}
            onPagination={({ page, rows }) =>
              getMaterialesAction({ page, pageSize: rows })
            }
            onSearching={() => getMaterialesAction({})}
            onChangeSearching={(search) => actualizarFiltros({ search })}
            onDoubleClick={(item) => {
              handleAgregarMaterial(item);
              setOpenProductos(false);
            }}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    processing: state.ordenCompra.processing,
    errors: state.ordenCompra.errors,
    materiales: state.material.materiales,
    total: state.material.total,
    processingList: state.material.processing,
    usuario: state.app.currentUser.np,
    sucursal: state.app.currentUser.ns,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  postSolicitarOrdenCompraAction,
  getMaterialesAction,
  actualizarFiltros,
})(OrdenCompraSolicitar);
