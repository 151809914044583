import { useState } from 'react';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useMaestro = () => {
  const [maestros, setMaestros] = useState([]);
  const [processingMaestro, setProcessingMaestro] = useState(false);

  const getMaestros = (valor) => {
    setProcessingMaestro(true);
    api
      .get(`${servicios.MAESTRO}/${valor}/detalles`)
      .then((response) => {
        setMaestros(response);
      })
      .catch(() => {
        setMaestros([]);
      })
      .finally(() => setProcessingMaestro(false));
  };
  return {
    maestros,
    processingMaestro,
    getMaestros,
  };
};

export default useMaestro;
