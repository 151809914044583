import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import { confirmacion } from '../../commons/Mensajes';
import { formatoFecha } from '../../commons/Utilitarios';

import {
  getContratosAction,
  getSeleccionarContratoAction,
  getEditarContratoAction,
  actualizarFiltros,
  patchAnularContratoAction,
  patchCambiarEstadoContratoAction,
  paginado,
} from '../../redux/actions/contrato.action';

import {
  actualizarFiltros as filtroCobranzas,
  getCobranzasAction,
} from '../../redux/actions/cobranza.action';

import { agregarHeaderAction } from '../../redux/actions/app.action';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BlockIcon from '@material-ui/icons/Block';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

const ContratoList = ({ contratos, processing, total, rows, perfil }) => {
  const [buscar, setBuscar] = useState('');
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'co',
      align: 'center',
      content: (item) => (
        <>
          <div title="Ver" style={{ display: 'inline-block' }}>
            <VisibilityIcon
              onClick={() => dispatch(getSeleccionarContratoAction(item))}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
          {item.ep !== 1 && item.fn === null && perfil === 'NOBASICO' && (
            <div title="Editar" style={{ display: 'inline-block' }}>
              <EditIcon
                onClick={() => dispatch(getEditarContratoAction(item))}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          )}
          {item.ep === 2 && (
            <>
              <div title="Cobranzas" style={{ display: 'inline-block' }}>
                <LibraryBooksIcon
                  onClick={() => {
                    dispatch(filtroCobranzas({ co: item.co }));
                    dispatch(getCobranzasAction());
                    let head = {
                      mm: 'COM_CON_COBR',
                      nf: false,
                      op: 'Cobranzas',
                      pp: 'COM_INIC',
                      or: 3,
                      im: 'payments',
                      st: true,
                    };
                    dispatch(agregarHeaderAction(head));
                  }}
                  style={{ color: 'blue', cursor: 'pointer' }}
                />
              </div>
              {perfil === 'NOBASICO' && item.fn === null && (
                <div title="Anular" style={{ display: 'inline-block' }}>
                  <BlockIcon
                    onClick={() =>
                      Swal.fire({
                        input: 'textarea',
                        inputLabel: 'Motivo de anulación',
                        inputPlaceholder: 'Ingrese el motivo de anulación...',
                        inputAttributes: {
                          'aria-label': 'Ingrese el motivo de anulación',
                          style: { resize: 'none' },
                        },
                        showCancelButton: true,
                        confirmButtonColor: '#1ab394',
                        confirmButtonText: 'Confirmar',
                        cancelButtonColor: 'red',
                        cancelButtonText: 'Cancelar',
                        reverseButtons: true,
                      }).then((result) => {
                        if (result.value) {
                          confirmacion(
                            '¿Está seguro?',
                            `De anular el contrato ${item.ot}`
                          )
                            .then(() =>
                              dispatch(
                                patchAnularContratoAction(item, result.value)
                              )
                            )
                            .catch(() => {});
                        }
                      })
                    }
                    style={{ color: 'red', cursor: 'pointer' }}
                  />
                </div>
              )}
              {(item.se === 0 && perfil === 'NOBASICO' && (
                <div
                  title="Cortar servicio"
                  style={{ display: 'inline-block' }}
                >
                  <GetAppIcon
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De cortar el servicio del contrato`
                      )
                        .then(() =>
                          dispatch(patchCambiarEstadoContratoAction(item, 1))
                        )
                        .catch(() => {})
                    }
                    style={{ color: 'red', cursor: 'pointer' }}
                  />
                </div>
              )) ||
                (item.se === 1 && perfil === 'NOBASICO' && (
                  <div
                    title="Activar servicio"
                    style={{ display: 'inline-block' }}
                  >
                    <PublishIcon
                      onClick={() =>
                        confirmacion(
                          '¿Está seguro?',
                          `De activar el servicio del contrato`
                        )
                          .then(() =>
                            dispatch(patchCambiarEstadoContratoAction(item, 0))
                          )
                          .catch(() => {})
                      }
                      style={{ color: 'green', cursor: 'pointer' }}
                    />
                  </div>
                ))}
            </>
          )}
        </>
      ),
    },
    {
      key: 'fv',
      label: 'Fecha venta',
      content: (item) => (
        <span className={`${item.ep === 1 ? 'op-error' : ''}`}>
          {formatoFecha(item.fv)}
        </span>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
      content: (item) => (
        <span className={handleGetColorClass(item)}>{item.ns}</span>
      ),
    },
    {
      key: 'nu',
      label: 'Vendedor',
      content: (item) => (
        <span className={handleGetColorClass(item)}>{item.nu}</span>
      ),
    },
    {
      key: 'nc',
      label: 'Cliente',
      content: (item) => (
        <span className={handleGetColorClass(item)}>{item.nc}</span>
      ),
    },
    {
      key: 'ss',
      label: 'Servicio',
      content: (item) => (
        <span className={handleGetColorClass(item)}>{`${item.ss}${
          item.se === 1 ? ' - en corte' : item.se === 2 ? ' - en baja' : ''
        }`}</span>
      ),
    },
    {
      key: 'de',
      label: 'Estado contrato',
      content: (item) => (
        <span className={`${item.ep === 1 ? 'op-error' : ''}`}>{item.de}</span>
      ),
    },
    {
      key: 'nt',
      label: 'Última acción',
      content: (item) => (
        <span className={`${item.ep === 1 ? 'op-error' : ''}`}>{item.nt}</span>
      ),
    },
    {
      key: 'fn',
      label: 'Anulación',
      content: (item) => (
        <span className={handleGetColorClass(item)}>
          {formatoFecha(item.fn, 'Activo')}
        </span>
      ),
    },
  ];

  const handleGetColorClass = (item) => {
    if (item.se === 1) {
      return 'op-warning';
    } else if (item.se === 2 || item.ep === 1 || item.fn) {
      return 'op-error';
    } else {
      return '';
    }
  };

  return (
    <Bloque titulo={'Resultado de búsqueda'}>
      <Tabla
        columns={columns}
        data={contratos}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getContratosAction());
        }}
        onSearching={() => {
          dispatch(actualizarFiltros({ search: buscar }));
          dispatch(getContratosAction({}));
        }}
        onChangeSearching={(search) => setBuscar(search)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    contratos: state.contrato.contratos,
    processing: state.contrato.processing,
    total: state.contrato.total,
    rows: state.contrato.rows,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps)(ContratoList);
