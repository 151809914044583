import * as fromGuia from '../actions/guia.action';
import { guiaModel } from '../models/guia.model';

let initialState = {
  guias: [],
  iu: null,
  sp: null,
  fi: null,
  ff: null,
  search: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
  openModalView: false,
  errors: guiaModel,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromGuia.GUIA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: guiaModel,
      };
    case fromGuia.GUIA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        guias: action.payload.data,
        total: action.payload.count,
      };
    case fromGuia.GUIA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        guias: [],
        total: 0,
      };
    case fromGuia.GUIA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: guiaModel,
        openModalView: false,
        openModal: true,
        processing: false,
      };
    case fromGuia.GUIA_VIEW:
      return {
        ...state,
        selected: action.payload,
        errors: guiaModel,
        openModalView: true,
        processing: false,
      };
    case fromGuia.GUIA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        openModalView: false,
        errors: guiaModel,
      };
    case fromGuia.GUIA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromGuia.GUIA_FILTER:
      return {
        ...state,
        iu: action.payload.iu,
        sp: action.payload.sp,
        fi: action.payload.fi,
        ff: action.payload.ff,
        search: action.payload.search,
        page: 0,
      };
    case fromGuia.GUIA_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    case fromGuia.GUIA_CLOSE:
      return {
        ...state,
        openModal: false,
        openModalView: false,
      };
    default:
      return state;
  }
}
