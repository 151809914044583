import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Report from '../app/Report';

import ProductMove from '../../assets/icons/move-product.svg';
import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

import {
  getInventariosAction,
  actualizarFiltros,
} from '../../redux/actions/inventario.action';

import { servicios } from '../../redux/helpers/servicios';

const InventarioFilter = ({
  tipoCategorias,
  sucursales,
  search,
  getInventariosAction,
  actualizarFiltros,
  empresa,
  sucursal,
  categoria,
}) => {
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);
  const [params, setParams] = useState({});

  return (
    <>
      <Filtros
        onFilter={() => getInventariosAction({})}
        showNewButton={false}
        actions={() => (
          <>
            <Boton
              className="op-filter"
              icon={() => <PrintIcon />}
              tabIndex={22}
              dropdownable
              style={{
                '--button-icon-color': '#1ab394',
              }}
              showDropdownOnTop
              actions={[
                {
                  key: '01',
                  label: 'PDF',
                  icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                  onClick: () => {
                    setOpenReporte(true);
                    setParams({ su, ct: ca, search });
                  },
                },
                {
                  key: '02',
                  label: 'Excel',
                  icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                  onClick: () => {
                    setOpenReporteExcel(true);
                    setParams({ su, ct: ca, search, xl: '0' });
                  },
                },
              ]}
            >
              Imprimir
            </Boton>
          </>
        )}
      >
        <Combo
          name="su"
          label="Sucursal"
          value={sucursal}
          placeholder="Todos"
          tabIndex={20}
          size={3}
          data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
          onChange={({ value }) => {
            actualizarFiltros({ su: value });
          }}
        />
        <Combo
          name="ca"
          label="Tipo categoria"
          value={categoria}
          placeholder="Todos"
          tabIndex={21}
          size={3}
          data={tipoCategorias.map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          dropdownTop
          onChange={({ value }) => {
            actualizarFiltros({ ct: value });
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.INVENTARIO}/${empresa}/empresa/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.INVENTARIO}/${empresa}/empresa/imprimir`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteInventario'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tipoCategorias: state.detalle.tipoCategorias,
    sucursales: state.sucursal.sucursales,
    empresa: state.app.currentUser.ie,
    search: state.inventario.search,
    sucursal: state.inventario.su,
    categoria: state.inventario.ct,
  };
};

export default connect(mapStateToProps, {
  getInventariosAction,
  actualizarFiltros,
})(InventarioFilter);
