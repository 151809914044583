import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { cobranzaModal } from '../models/cobranza.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const COBRANZA_PROCESS = '[Cobranza] Cobranza procesando';
export const COBRANZA_SAVE_SUCCESS = '[Cobranza] Cobranza guardar exito';
export const COBRANZA_LIST_SUCCESS = '[Cobranza] Cobranza lista exito';
export const COBRANZA_LIST_FAIL = '[Cobranza] Cobranza lista error';
export const COBRANZA_LIST_REPORT = '[Cobranza] Cobranza lista reporte';
export const COBRANZA_SELECTED = '[Cobranza] Cobranza seleccionado';
export const COBRANZA_SELECTED_NEW = '[Cobranza] Cobranza nuevo';
export const COBRANZA_CLOSE = '[Cobranza] Cobranza cerrar modal';
export const COBRANZA_FILTER = '[Cobranza] Cobranza filtros';
export const COBRANZA_FAIL = '[Cobranza] Cobranza proceso error';
export const COBRANZA_PAGINATE = '[Cobranza] Cobranza paginado';
export const COBRANZA_STATES = '[Cobranza] Cobranza lista estados Cobranza';

export const getCobranzasAction = () => (dispatch, getState) => {
  let { co, ec, fi, ff, page, rows } = getState().cobranza;
  let { currentUser } = getState().app;

  dispatch({
    type: COBRANZA_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    co,
    ec,
    fi,
    ff,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.COBRANZA, { params })
    .then((response) => {
      dispatch({
        type: COBRANZA_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: COBRANZA_LIST_FAIL,
      });
    });
};

export const getCobranzasReporteAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: COBRANZA_PROCESS,
  });

  api
    .get(`${servicios.COBRANZA}/${currentUser.ie}/reporte`)
    .then((response) => {
      dispatch({
        type: COBRANZA_LIST_REPORT,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: COBRANZA_LIST_REPORT,
        payload: [],
      });
    });
};

export const getSeleccionarCobranzaAction = () => (dispatch) => {
  dispatch({
    type: COBRANZA_SELECTED_NEW,
    payload: cobranzaModal,
  });
};

export const postCobranzaAction = (item) => (dispatch, getState) => {
  let { co } = getState().cobranza;
  let { currentUser } = getState().app;

  dispatch({
    type: COBRANZA_PROCESS,
  });

  let cobranza = {
    co,
    ie: currentUser.ie,
    dc: item.dc,
    mc: quitarFormatoMonetario(item.mc),
  };

  api
    .post(servicios.COBRANZA, cobranza)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(paginado(0));
      dispatch(getCobranzasAction({}));
      dispatch({ type: COBRANZA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: COBRANZA_FAIL,
        payload: error.data.errors ? error.data.errors : cobranzaModal,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { co, ec, fi, ff } = getState().cobranza;

  let item = {
    co: filtros.co !== undefined ? (filtros.co === '' ? null : filtros.co) : co,
    ec: filtros.ec !== undefined ? (filtros.ec === '' ? null : filtros.ec) : ec,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
  };

  dispatch({
    type: COBRANZA_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: COBRANZA_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: COBRANZA_CLOSE,
  });
};
