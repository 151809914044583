import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import RadioButton from '../../commons/RadioButton';
import { operacionModel } from '../../redux/models/operacion.model';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  closeModal,
  postRegistrarOperacionAction,
} from '../../redux/actions/operacion.action';

import SaveIcon from '@material-ui/icons/Save';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

const InventarioMovimiento = ({
  selected,
  processing,
  errors,
  closeModal,
  postRegistrarOperacionAction,
  tipoSalidas,
  tipoIngresos,
}) => {
  const [inventario] = useState(selected);
  const [operacion, setOperacion] = useState(operacionModel);
  const [arrow, setArrow] = useState(false);

  useEffect(() => {
    handleChange({ name: 'mo', value: inventario.mt });
    handleChange({ name: 'su', value: inventario.su });
    handleChange({ name: 'pm', value: formatoMonetario(inventario.pv) });
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOperacion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Registrar movimiento - ${selected.nm}`}
        processing={processing}
        onActions={() => (
          <>
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={21}
              onClick={() =>
                confirmacion('¿Está seguro?', `Realizar el movimiento`)
                  .then(() => {
                    postRegistrarOperacionAction(operacion);
                  })
                  .catch(() => {})
              }
            >
              Guardar
            </Boton>
          </>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              closeModal();
            })
            .catch(() => {})
        }
      >
        <Texto
          name="ns"
          value={inventario.ns}
          label="Sucursal"
          size={3}
          tabIndex={10}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="cm"
          value={inventario.cm}
          label="C&oacute;digo"
          size={3}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="um"
          value={inventario.um}
          label="Unidad de medida"
          size={3}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="sd"
          value={inventario.sd}
          label="Stock disponible"
          size={3}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="cm"
          value={operacion.cm}
          label={`Cantidad`}
          size={3}
          tabIndex={14}
          error={errors.cm}
          restrict={'int'}
          onChange={handleChange}
          info={`Cantidad de ${inventario.um}(s) a registrar como ${
            arrow ? 'ingreso' : 'salida'
          }`}
          autoFocus
        />
        <Texto
          name="pm"
          value={operacion.pm}
          label={`Precio de ${arrow ? 'compra' : 'venta'}`}
          size={3}
          tabIndex={15}
          error={errors.pm}
          restrict={'money'}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'pm',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
        <div className="op-form-group op-col-3 op-checkbox-group">
          <RadioButton
            name="pk"
            data={[
              {
                name: 'in',
                label: 'Ingreso',
                checked: arrow,
                icon: () => <TrendingUpIcon style={{ color: 'green' }} />,
              },
              {
                name: 'out',
                label: 'Salida',
                checked: !arrow,
                icon: () => <TrendingDownIcon style={{ color: 'red' }} />,
              },
            ]}
            onChange={(item) => {
              setArrow(item.value === 'in');
              handleChange({ name: 'ct', value: '' });
            }}
          />
        </div>
        <Combo
          name="cti"
          label={`Tipo de ingresos`}
          value={operacion.cti}
          tabIndex={16}
          size={3}
          data={tipoIngresos.map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          error={errors.ct}
          onChange={(item) => {
            handleChange(item);
            handleChange({ name: 'ct', value: item.value });
          }}
          disabled={!arrow}
        />
        <Combo
          name="cto"
          label={`Tipo de salidas`}
          value={operacion.cto}
          tabIndex={17}
          size={3}
          data={tipoSalidas.map((i) => ({ value: i.dt, label: i.de }))}
          searcheable
          error={errors.ct}
          onChange={(item) => {
            handleChange(item);
            handleChange({ name: 'ct', value: item.value });
          }}
          disabled={arrow}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.operacion.selected,
    processing: state.operacion.processing,
    errors: state.operacion.errors,
    tipoIngresos: state.detalle.tipoIngresos,
    tipoSalidas: state.detalle.tipoSalidas,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  postRegistrarOperacionAction,
})(InventarioMovimiento);
