import * as fromPagos from '../actions/pagos.action';
import { ordenCompraModel } from '../models/ordencompra.model';

let initialState = {
  pagos: [],
  iu: null,
  su: null,
  es: null,
  st: null,
  search: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openVer: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromPagos.PAGOS_PROCESS:
      return {
        ...state,
        processing: true,
        errors: ordenCompraModel,
      };
    case fromPagos.PAGOS_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        pagos: action.payload.data,
        total: action.payload.count,
      };
    case fromPagos.PAGOS_LIST_FAIL:
      return {
        ...state,
        processing: false,
        pagos: [],
        total: 0,
      };
    case fromPagos.PAGOS_FILTER:
      return {
        ...state,
        su: action.payload.su,
        iu: action.payload.iu,
        es: action.payload.es,
        st: action.payload.st,
        search: action.payload.search,
        page: 0,
      };
    case fromPagos.PAGOS_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
