import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { patchConfirmarClienteAction } from '../../redux/actions/usuario.action';

import Loading from '../../commons/Loading';

import '../../css/confirmar.css';

const ConfirmarCliente = (props) => {
  const params = new URLSearchParams(props.location.search);
  const cl = params.get('cl');
  const au = params.get('au');

  const { processing, message } = useSelector((state) => state.usuario);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(patchConfirmarClienteAction(cl, au));
  }, []);
  return (
    <div className="op-content-confirmar">
      <div className="op-confirmar">
        <h1>Confirmaci&oacute;n de registro</h1>
        <h3>Bienvenido a soluciones OP</h3>
        <p>{message}</p>
        <h5>@ 2022 Soluciones OP - Todos los derechos reservados</h5>
      </div>
      {processing && <Loading />}
    </div>
  );
};

export default ConfirmarCliente;
