import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Calendario from '../../commons/Calendario';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import { patchConfirmarOrdenCompraAction } from '../../redux/actions/ordencompra.action';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';

import '../../css/ordencompra.css';

const OrdenCompraConfirmar = ({
  selected,
  processing,
  errors,
  onClose,
  patchConfirmarOrdenCompraAction,
}) => {
  const [orden, setOrden] = useState(selected);
  const [openTipoCambio, setOpenTipoCambio] = useState(false);
  const [detalle, setDetalle] = useState(selected.lst);

  useEffect(() => {
    setOrden((prev) => ({
      ...prev,
      tt: formatoMonetario(redondeoDecimales(prev.tt)),
    }));
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setOrden((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      key: 'cm',
      align: 'center',
      content: (item) => (
        <div
          title={`${item.st === true ? 'Quitar' : 'Agregar'}`}
          style={{ display: 'inline-block' }}
        >
          {item.st === true ? (
            <CheckIcon
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De quitar ${item.nm} de la orden de compra`
                )
                  .then(() => handleTacharMaterial(item.mt, false))
                  .catch(() => {})
              }
              style={{ color: '#1ab394', cursor: 'pointer' }}
            />
          ) : (
            <CloseIcon
              onClick={() =>
                confirmacion(
                  '¿Está seguro?',
                  `De volver agregar ${item.nm} a la orden de compra`
                )
                  .then(() => handleTacharMaterial(item.mt, true))
                  .catch(() => {})
              }
              style={{ color: 'red', cursor: 'pointer' }}
            />
          )}
        </div>
      ),
    },
    {
      key: 'nm',
      label: 'Insumo / Producto',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.nm}
        </span>
      ),
    },
    {
      key: 'du',
      label: 'Unidad medida',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.du}
        </span>
      ),
    },
    {
      key: 'tm',
      label: 'Tipo de moneda',
      align: 'center',
      content: (item) => (
        <div className="op-content-tipomoneda">
          <div
            className={`${item.tm === 0 ? 'on' : 'off'}`}
            onClick={() => handleCambiarMoneda(item.mt, 1)}
          >
            Soles
          </div>
          <div
            className={`${item.tm === 1 ? 'on' : 'off'}`}
            onClick={() => handleCambiarMoneda(item.mt, 0)}
          >
            Dolares
          </div>
        </div>
      ),
    },
    {
      key: 'pr',
      label: 'Precio unitario compra',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          <div className={`cantidad${item.editPrecio ? ' edit' : ''}`}>
            <span
              style={{ color: `${item.st === true ? 'black' : 'red'}` }}
              onClick={(e) =>
                handleHabilitarEdicion(
                  item.mt,
                  !item.editPrecio,
                  'editPrecio',
                  e
                )
              }
            >
              {formatoMonetario(redondeoDecimales(item.pr))}
            </span>
            <input
              type="text"
              value={item.pr}
              onChange={(e) => handleEditarValor(item.mt, e.target.value, 'pr')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(
                    item.mt,
                    !item.editPrecio,
                    'editPrecio',
                    e
                  );
                } else {
                  let re = new RegExp('[0-9.]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onBlur={(e) => {
                handleEditarValor(
                  item.mt,
                  formatoMonetario(redondeoDecimales(e.target.value)),
                  'pr'
                );
              }}
              onPaste={(e) => e.preventDefault()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleHabilitarEdicion(
                    item.mt,
                    !item.editPrecio,
                    'editPrecio',
                    e
                  )
                }
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: 'ct',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          <AddIcon
            style={{ color: 'green', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, 1)}
          />
          <div className={`cantidad${item.edit ? ' edit' : ''}`}>
            <span
              style={{ color: `${item.st === true ? 'black' : 'red'}` }}
              onClick={(e) =>
                handleHabilitarEdicion(item.mt, !item.edit, 'edit', e)
              }
            >
              {item.ct}
            </span>
            <input
              type="text"
              value={item.ct}
              onChange={(e) => handleEditarValor(item.mt, e.target.value, 'ct')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleHabilitarEdicion(item.mt, !item.edit, 'edit', e);
                } else {
                  let re = new RegExp('[0-9]');
                  if (!re.test(e.key)) {
                    e.preventDefault();
                  }
                }
              }}
              onPaste={(e) => e.preventDefault()}
            />
            <div className="edicion">
              <EditIcon
                style={{ fontSize: '15px' }}
                onClick={(e) =>
                  handleHabilitarEdicion(item.mt, !item.edit, 'edit', e)
                }
              />
            </div>
          </div>
          <RemoveIcon
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => handleCantidadMaterial(item.mt, -1)}
          />
        </div>
      ),
    },
  ];

  const handleTacharMaterial = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              st: valor,
            }
          : x
      )
    );
  };

  const handleCantidadMaterial = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              ct:
                valor === -1 && x.ct <= 1
                  ? 1
                  : parseInt(x.ct ? x.ct : 1) + valor,
            }
          : x
      )
    );
  };

  const handleHabilitarEdicion = (mt, valor, name, e) => {
    setDetalle(detalle.map((x) => (x.mt === mt ? { ...x, [name]: valor } : x)));
    let caja = e.target
      .closest('.op-centrar-componentes')
      .querySelector('input');
    let label = e.target
      .closest('.op-centrar-componentes')
      .querySelector('span');
    caja.style.width = `${label.offsetWidth}px`;
  };

  const handleEditarValor = (mt, valor, name) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              [name]: valor < 0 ? 0 : valor,
            }
          : x
      )
    );
  };

  const handleCambiarMoneda = (mt, valor) => {
    setDetalle(
      detalle.map((x) =>
        x.mt === mt
          ? {
              ...x,
              tm: valor,
            }
          : x
      )
    );
    setOpenTipoCambio(
      valor === 1 || detalle.filter((x) => x.mt !== mt).find((d) => d.tm === 1)
    );
  };

  return (
    <Modal
      title={`Confirmar orden N°: ${selected.co}`}
      processing={processing}
      loading="supplies"
      onClose={() => {
        onClose();
      }}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Está seguro?',
              `De confirmar la orden de compra ${selected.co}`
            )
              .then(() => {
                patchConfirmarOrdenCompraAction(orden, detalle);
              })
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
    >
      <Texto
        name="pc"
        value={orden.pc}
        label="Contacto de proveedor"
        tabIndex={10}
        size={2}
        error={errors.pc}
        onChange={handleChange}
      />
      <Texto
        name="pt"
        value={orden.pt}
        label="Celular de contacto de proveedor"
        tabIndex={11}
        size={2}
        restrict={'int'}
        error={errors.pt}
        onChange={handleChange}
      />
      <Texto
        name="rc"
        value={orden.rc}
        label="Contacto que recepcionara"
        tabIndex={12}
        size={2}
        error={errors.rc}
        onChange={handleChange}
      />
      <Texto
        name="rt"
        value={orden.rt}
        label="Celular de contacto que recepcionara"
        tabIndex={13}
        size={2}
        restrict={'int'}
        error={errors.rt}
        onChange={handleChange}
      />
      <Calendario
        name="fe"
        value={orden.fe}
        label="Fecha entrega"
        tabIndex={14}
        size={2}
        minDate={new Date()}
        error={errors.fe}
        showTime
        onSelected={handleChange}
      />
      {openTipoCambio && (
        <Texto
          name="tt"
          value={orden.tt}
          label="Tipo de cambio del d&iacute;a"
          tabIndex={15}
          size={2}
          restrict={'money'}
          error={errors.tt}
          onChange={handleChange}
          onBlur={(e) => {
            handleChange({
              name: 'tt',
              value: formatoMonetario(redondeoDecimales(e.target.value)),
            });
          }}
        />
      )}
      <div className="op-form-group op-col-1">
        <Tabla
          columns={columns}
          data={detalle}
          pagination={false}
          searcheable={false}
          hoverSelectedRow={false}
          style={{ padding: '0px' }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.ordenCompra.selected,
    processing: state.ordenCompra.processing,
    errors: state.ordenCompra.errors,
  };
};

export default connect(mapStateToProps, { patchConfirmarOrdenCompraAction })(
  OrdenCompraConfirmar
);
