import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import {
  getOperacionesAction,
  getSeleccionarOperacionAction,
  actualizarFiltros,
} from '../../redux/actions/operacion.action';

import EditIcon from '@material-ui/icons/Edit';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const OperacionList = ({
  operaciones,
  processing,
  total,
  getOperacionesAction,
  getSeleccionarOperacionAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'fo',
      label: 'Fecha operación',
      align: 'center',
      content: (item) => `${formatoFecha(item.fo, 'Indefinido', false)}`,
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'dt',
      label: 'Tipo operación',
      content: (item) => (
        <div
          className="op-centrar-componentes"
          style={{ justifyContent: 'flex-start' }}
        >
          {item.is === 1 ? (
            <TrendingUpIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <TrendingDownIcon fontSize="small" style={{ color: 'red' }} />
          )}
          <span
            style={{
              color: `${item.is === 1 ? 'green' : 'red'}`,
            }}
          >
            {item.dt}
          </span>
        </div>
      ),
    },
    {
      key: 'nm',
      label: 'Producto / Insumo',
      content: (item) => (
        <div
          className="op-centrar-componentes"
          style={{ justifyContent: 'flex-start' }}
        >
          <span
            style={{
              color: `${item.is === 1 ? 'green' : 'red'}`,
            }}
          >
            {item.nm}
          </span>
        </div>
      ),
    },
    {
      key: 'um',
      label: 'Unidad de medida',
    },
    {
      key: 'cm',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {item.is === 1 ? (
            <AddIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <RemoveIcon fontSize="small" style={{ color: 'red' }} />
          )}
          <span
            style={{
              color: `${item.is === 1 ? 'green' : 'red'}`,
            }}
          >
            {item.cm}
          </span>
        </div>
      ),
    },
    {
      key: 'pm',
      label: 'Precio',
      align: 'right',
      content: (item) => (
        <div className="op-centrar-componentes">
          {item.tm === 0 ? 'S/' : '$'}
          {formatoMonetario(redondeoDecimales(item.pm))}
        </div>
      ),
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={operaciones}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getOperacionesAction({ page, pageSize: rows })
        }
        onSearching={() => getOperacionesAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    operaciones: state.operacion.operaciones,
    processing: state.operacion.processing,
    total: state.operacion.total,
  };
};

export default connect(mapStateToProps, {
  getOperacionesAction,
  getSeleccionarOperacionAction,
  actualizarFiltros,
})(OperacionList);
