import * as fromDetalle from '../actions/detalle.action';
import { detalleModel } from '../models/detalle.model';

let initialState = {
  detalles: [],
  tipoAlmacenes: [],
  tipoUnidadMedida: [],
  estadoOrden: [],
  tipoCategorias: [],
  tipoIngresos: [],
  tipoSalidas: [],
  tipoCajas: [],
  tipoDocumentos: [],
  tipoAdicionales: [],
  tipoDatos: [],
  estadoContrato: [],
  formasPago: [],
  tipoDocumentoIdentidad: [],
  estadoCobranza: [],
  tiposTraslados: [],
  tipoBancos: [],
  estadoPedido: [],
  ca: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromDetalle.DETALLE_PROCESS:
      return {
        ...state,
        processing: true,
        errors: detalleModel,
      };
    case fromDetalle.DETALLE_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        detalles: action.payload.data,
        total: action.payload.count,
      };
    case fromDetalle.DETALLE_LIST_FAIL:
      return {
        ...state,
        processing: false,
        detalles: [],
        total: 0,
      };
    case fromDetalle.DETALLE_TIPOALMACEN:
      return {
        ...state,
        tipoAlmacenes: action.payload,
      };
    case fromDetalle.DETALLE_UNIDADMEDIDA:
      return {
        ...state,
        tipoUnidadMedida: action.payload,
      };
    case fromDetalle.DETALLE_ESTADOORDEN:
      return {
        ...state,
        estadoOrden: action.payload,
      };
    case fromDetalle.DETALLE_CATEGORIAS:
      return {
        ...state,
        tipoCategorias: action.payload,
      };
    case fromDetalle.DETALLE_TIPOINGRESO:
      return {
        ...state,
        tipoIngresos: action.payload,
      };
    case fromDetalle.DETALLE_TIPOSALIDA:
      return {
        ...state,
        tipoSalidas: action.payload,
      };
    case fromDetalle.DETALLE_TIPOCAJAS:
      return {
        ...state,
        tipoCajas: action.payload,
      };
    case fromDetalle.DETALLE_TIPODOCUS:
      return {
        ...state,
        tipoDocumentos: action.payload,
      };
    case fromDetalle.DETALLE_TIPOADICIONAL:
      return {
        ...state,
        tipoAdicionales: action.payload,
      };
    case fromDetalle.DETALLE_TIPODATO:
      return {
        ...state,
        tipoDatos: action.payload,
      };
    case fromDetalle.DETALLE_ESTADOCONTRATO:
      return {
        ...state,
        estadoContrato: action.payload,
      };
    case fromDetalle.DETALLE_FORMAPAGO:
      return {
        ...state,
        formasPago: action.payload,
      };
    case fromDetalle.DETALLE_TIPODOCUSIDENTIDAD:
      return {
        ...state,
        tipoDocumentoIdentidad: action.payload,
      };
    case fromDetalle.DETALLE_ESTADOCOBRANZA:
      return {
        ...state,
        estadoCobranza: action.payload,
      };
    case fromDetalle.DETALLE_TIPOSTRASLADO:
      return {
        ...state,
        tiposTraslados: action.payload,
      };
    case fromDetalle.DETALLE_TIPOBANCOS:
      return {
        ...state,
        tipoBancos: action.payload,
      };
    case fromDetalle.DETALLE_ESTADOPEDIDO:
      return {
        ...state,
        estadoPedido: action.payload,
      };
    case fromDetalle.DETALLE_SELECTED:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
      };
    case fromDetalle.DETALLE_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: detalleModel,
      };
    case fromDetalle.DETALLE_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromDetalle.DETALLE_FILTER:
      return {
        ...state,
        ca: action.payload.ca,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromDetalle.DETALLE_CLOSE:
      return {
        ...state,
        openModal: false,
        errors: detalleModel,
      };
    default:
      return state;
  }
}
