import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';

import Workflow from '../../assets/icons/workflow.svg';

import {
  getServiciosAction,
  getSeleccionarServicioAction,
  actualizarFiltros,
} from '../../redux/actions/servicio.action';

import { agregarHeaderAction } from '../../redux/actions/app.action';

const ServicioFilter = ({
  getServiciosAction,
  getSeleccionarServicioAction,
  actualizarFiltros,
  agregarHeaderAction,
}) => {
  const [st, setSt] = useState('');

  return (
    <Filtros
      onFilter={() => getServiciosAction({})}
      onNew={() => getSeleccionarServicioAction()}
      /*actions={() => (
        <Boton
          className="op-filter"
          icon={() => <Icon svg={Workflow} transparent />}
          tabIndex={22}
          onClick={() => {
            let head = {
              mm: 'SUM_SERV_DIAGRA',
              nf: false,
              op: 'Fujo de trabajo',
              pp: 'SUM_INIC',
              or: 4,
              im: 'design_services',
              st: true,
            };
            agregarHeaderAction(head);
          }}
        >
          Crear flujo
        </Boton>
      )}*/
    >
      <Combo
        name="st"
        value={st}
        label="Estado del registro"
        placeholder="Todos"
        size={3}
        tabIndex={22}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No vigente' },
        ]}
        onChange={({ value }) => {
          setSt(value);
          actualizarFiltros({ st: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  getServiciosAction,
  getSeleccionarServicioAction,
  actualizarFiltros,
  agregarHeaderAction,
})(ServicioFilter);
