import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Icon from './Icon';
import Report from '../components/app/Report';

import { servicios } from '../redux/helpers/servicios';

import { obtenerIcono } from './Utilitarios';

const AdjuntoList = ({ extensions, data, file }) => {
  const [openFile, setOpenFile] = useState(false);
  const [filename, setFilename] = useState(null);
  const [downloadname, setDownloadname] = useState(null);
  const [extension, setExtension] = useState(null);

  const empresa = useSelector((state) => state.app.currentUser.ie);

  return (
    <>
      <div className="op-form-group" style={{ gridColumn: '1/3' }}>
        {data &&
          data.map((d) => (
            <div
              key={d.id}
              style={{
                display: 'grid',
                gridTemplateColumns: '20px auto',
                columnGap: '5px',
              }}
            >
              <Icon svg={obtenerIcono(d.extension)} transparent />
              <div
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  setFilename(d.id);
                  setExtension(d.extension);
                  setOpenFile(true);
                  setDownloadname(d.name);
                }}
              >
                <span
                  style={{
                    textDecoration: `${d.state ? 'none' : 'line-through'}`,
                    color: `${d.state ? 'inherit' : 'red'}`,
                  }}
                >{`${d.name}.${d.extension}`}</span>
              </div>
            </div>
          ))}
      </div>
      {openFile && (
        <Report
          url={`${servicios.ARCHIVO}/${file}/empresa/${empresa}/${filename}`}
          params={{}}
          extension={extension}
          filename={downloadname}
          onClose={() => setOpenFile(false)}
        />
      )}
    </>
  );
};

AdjuntoList.propTypes = {
  extensions: PropTypes.arrayOf(
    PropTypes.oneOf(['pdf', 'docx', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'])
  ),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      extension: PropTypes.string.isRequired,
      state: PropTypes.bool.isRequired,
    })
  ),
  file: PropTypes.string,
};

AdjuntoList.defaultProps = {
  extensions: ['pdf', 'jpg', 'jpeg', 'png'],
};

export default AdjuntoList;
