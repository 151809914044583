import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';

import {
  getOrdenesCompraAction,
  getSeleccionarOrdenCompraAction,
  actualizarFiltros,
} from '../../redux/actions/ordencompra.action';

import PostAddIcon from '@material-ui/icons/PostAdd';

const OrdenCompraFilter = ({
  estadoOrden,
  sucursales,
  sucursal,
  perfil,
  getOrdenesCompraAction,
  getSeleccionarOrdenCompraAction,
  actualizarFiltros,
}) => {
  const [ca, setCa] = useState('');
  const [su, setSu] = useState('');

  useEffect(() => {
    if (perfil === 'BASICO') {
      actualizarFiltros({ su: sucursal });
    }
  }, []);

  return (
    <Filtros
      onFilter={() => getOrdenesCompraAction({})}
      showNewButton={false}
      actions={() => (
        <>
          <Boton
            className="op-filter"
            icon={() => <PostAddIcon fontSize="large" />}
            tabIndex={21}
            onClick={() => getSeleccionarOrdenCompraAction()}
          >
            Solicitar orden compra
          </Boton>
        </>
      )}
    >
      {perfil === 'NOBASICO' && (
        <Combo
          name="su"
          label="Sucursal"
          value={su}
          placeholder="Todos"
          tabIndex={20}
          size={2}
          data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
          onChange={({ value }) => {
            setSu(value);
            actualizarFiltros({ su: value });
          }}
        />
      )}
      <Combo
        name="ca"
        label="Estado orden compra"
        value={ca}
        placeholder="Todos"
        tabIndex={21}
        size={2}
        data={estadoOrden.map((i) => ({ value: i.dt, label: i.de }))}
        onChange={({ value }) => {
          setCa(value);
          actualizarFiltros({ es: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    estadoOrden: state.detalle.estadoOrden,
    sucursales: state.sucursal.sucursales,
    perfil: state.app.currentUser.tr,
    sucursal: state.app.currentUser.is,
  };
};

export default connect(mapStateToProps, {
  getOrdenesCompraAction,
  getSeleccionarOrdenCompraAction,
  actualizarFiltros,
})(OrdenCompraFilter);
