export const documentoModel = {
  is: null,
  ns: null,
  dt: null,
  nd: null,
  fe: null,
  tm: null,
  tc: null,
  de: null,
  ne: null,
  mi: null,
  ms: null,
  mt: null,
  md: null,
};
