import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postProveedorAction,
} from '../../redux/actions/proveedor.action';

import SaveIcon from '@material-ui/icons/Save';

const ProveedorItem = ({
  selected,
  processing,
  errors,
  closeModal,
  postProveedorAction,
}) => {
  const [proveedor, setProveedor] = useState(selected);

  const handleChange = (item) => {
    const { name, value } = item;
    setProveedor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${proveedor.pv ? 'Actualizar' : 'Nuevo'} proveedor`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${proveedor.pv ? 'Actualizar' : 'Un nuevo'} proveedor`
            )
              .then(() => postProveedorAction(proveedor))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            closeModal();
          })
          .catch(() => {})
      }
    >
      <Texto
        name="rz"
        value={proveedor.rz}
        label="Raz&oacute;n social"
        tabIndex={10}
        size={2}
        error={errors.rz}
        onChange={handleChange}
        autoFocus
      />
      <Texto
        name="nr"
        value={proveedor.nr}
        label="N&uacute;mero de RUC"
        tabIndex={11}
        size={2}
        error={errors.nr}
        restrict={'int'}
        onChange={handleChange}
      />
      <Texto
        name="nm"
        value={proveedor.nm}
        label="N&uacute;mero movil"
        tabIndex={12}
        size={2}
        error={errors.nm}
        restrict={'int'}
        onChange={handleChange}
        info="N&uacute;mero movil de la empresa"
      />
      <Texto
        name="nf"
        value={proveedor.nf}
        label="N&uacute;mero de fijo"
        tabIndex={13}
        size={2}
        error={errors.nf}
        restrict={'int'}
        onChange={handleChange}
        info="N&uacute;mero fijo de la empresa"
      />
      <Texto
        name="co"
        value={proveedor.co}
        label="Correo"
        tabIndex={14}
        size={2}
        error={errors.co}
        onChange={handleChange}
      />
      <Texto
        name="pc"
        value={proveedor.pc}
        label="Contacto de proveedor"
        tabIndex={15}
        size={2}
        error={errors.pc}
        onChange={handleChange}
        info="Nombre del contacto que llevara la mercader&iacute;a."
      />
      <Texto
        name="pt"
        value={proveedor.pt}
        label="Celular de contacto de proveedor"
        tabIndex={16}
        size={2}
        restrict={'int'}
        error={errors.pt}
        onChange={handleChange}
        info="N&uacute;mero de celular del contacto que llevara la mercader&iacute;a."
      />
      <Texto
        name="di"
        value={proveedor.di}
        label="Direcci&oacute;n"
        tabIndex={17}
        size={2}
        error={errors.di}
        onChange={handleChange}
      />
      <Texto
        name="re"
        value={proveedor.re}
        label="Referencia de direcci&oacute;n"
        tabIndex={18}
        error={errors.re}
        onChange={handleChange}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.proveedor.selected,
    processing: state.proveedor.processing,
    errors: state.proveedor.errors,
  };
};

export default connect(mapStateToProps, { closeModal, postProveedorAction })(
  ProveedorItem
);
