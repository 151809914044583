import * as fromCategoria from '../actions/categoria.action';
import { categoriaModel } from '../models/categoria.model';

let initialState = {
  categorias: [],
  tipos: [],
  ap: null,
  st: null,
  search: null,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCategoria.CATEGORIA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: categoriaModel,
      };
    case fromCategoria.CATEGORIA_TIPO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        tipos: action.payload.data,
      };
    case fromCategoria.CATEGORIA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        categorias: action.payload,
      };
    case fromCategoria.CATEGORIA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        categorias: [],
      };
    case fromCategoria.CATEGORIA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: categoriaModel,
        openModal: true,
      };
    case fromCategoria.CATEGORIA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: categoriaModel,
      };
    case fromCategoria.CATEGORIA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCategoria.CATEGORIA_FILTER:
      return {
        ...state,
        ap: action.payload,
      };
    case fromCategoria.CATEGORIA_CLOSE:
      return {
        ...state,
        errors: categoriaModel,
        openModal: false,
      };
    default:
      return state;
  }
}
