import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion, mensajeMini } from '../../commons/Mensajes';

import {
  closeModal,
  postAdicionalAction,
} from '../../redux/actions/adicional.action';

import SaveIcon from '@material-ui/icons/Save';

const AdicionalItem = ({
  selected,
  processing,
  errors,
  tipo,
  padre,
  idPadre,
}) => {
  const [adicional, setAdicional] = useState(selected);
  const [autogenerar, setAutogenerar] = useState(true);

  const dispatch = useDispatch();
  const handleChange = (item) => {
    const { name, value } = item;
    setAdicional((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'gc') {
      setAutogenerar(!autogenerar);
    }
  };

  const textDefinition = () => {
    let re = new RegExp('.*as$');
    if (!re.test(tipo)) {
      return 'Nuevo';
    } else {
      return 'Nueva';
    }
  };

  const handlePreviewValidate = () => {
    let valid = false;
    if (adicional.ad) {
      valid = true;
    } else {
      if (['Zonas', 'Estados'].includes(tipo)) {
        valid = true;
      } else if (idPadre === null) {
        mensajeMini('Seleccione elemento padre');
      } else {
        valid = true;
      }
    }

    if (valid) {
      confirmacion(
        '¿Desea guardar?',
        `${adicional.ad ? 'Una actualización de' : textDefinition()} ${tipo
          .slice(0, -1)
          .toLowerCase()}`
      )
        .then(() => dispatch(postAdicionalAction(adicional)))
        .catch(() => {});
    }
  };

  return (
    <Modal
      title={`${padre ? padre + ' - ' : ''}${
        adicional.ad ? 'Actualizar' : textDefinition()
      } ${tipo.slice(0, -1).toLowerCase()}`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() => handlePreviewValidate()}
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Texto
        name="ca"
        value={adicional.ca}
        label="C&oacute;digo"
        tabIndex={10}
        size={2}
        error={errors.ca}
        onChange={handleChange}
        disabled={autogenerar}
      />
      <Texto
        name="na"
        value={adicional.na}
        label="Nombre"
        tabIndex={11}
        size={2}
        error={errors.na}
        onChange={handleChange}
        autoFocus
      />
      {['Módulos', 'Cajas', 'Estados'].includes(tipo) && (
        <Texto
          name="va"
          value={adicional.va}
          label={`${tipo === 'Estados' ? 'Orden' : 'Capacidad'}`}
          tabIndex={12}
          restrict="int"
          size={2}
          error={errors.va}
          onChange={handleChange}
        />
      )}
      {['Puertos'].includes(tipo) && (
        <Texto
          name="va"
          value={adicional.va}
          label={`Asignado`}
          tabIndex={12}
          size={2}
          error={errors.va}
          onChange={handleChange}
        />
      )}
      {adicional.ad ? (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={14}
            checked={adicional.st}
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>¿Desea autogenerar el c&oacute;digo?</span>
          <CheckBox
            name="gc"
            tabIndex={15}
            checked={adicional.gc}
            onChange={handleChange}
          />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.adicional.selected,
    processing: state.adicional.processing,
    errors: state.adicional.errors,
    tipo: state.adicional.nt,
    padre: state.adicional.pn,
    idPadre: state.adicional.pa,
  };
};

export default connect(mapStateToProps)(AdicionalItem);
