import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';

import { getSeleccionarCorrelativoAction } from '../../redux/actions/correlativo.action';

const CorrelativoList = ({ correlativos, processing }) => {
  const dispatch = useDispatch();
  const columns = [
    {
      key: 'td',
      label: 'Tipo documento',
    },
    {
      key: 'se',
      label: 'Serie',
      align: 'right',
    },
    {
      key: 'nc',
      label: 'Correlativo',
      align: 'right',
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={correlativos}
        processing={processing}
        pagination={false}
        searcheable={false}
        onDoubleClick={(item) => {
          dispatch(getSeleccionarCorrelativoAction(item));
        }}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    correlativos: state.correlativo.correlativos,
    processing: state.correlativo.processing,
  };
};

export default connect(mapStateToProps)(CorrelativoList);
