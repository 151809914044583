import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getOrdenesCompraAction,
  actualizarFiltros,
} from '../../redux/actions/ordencompra.action';

import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getProveedoresAction } from '../../redux/actions/proveedor.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import OrdenCompraList from './OrdenCompraList';
import OrdenCompraFilter from './OrdenCompraFilter';
import OrdenCompraSolicitar from './OrdenCompraSolicitar';
import OrdenCompraVer from './OrdenCompraVer';

const OrdenCompra = ({
  openSolicitar,
  openVer,
  getOrdenesCompraAction,
  getDetallesPorCabeceraAction,
  getSucursalesAction,
  getProveedoresAction,
  actualizarFiltros,
}) => {
  useEffect(() => {
    getOrdenesCompraAction({});
    getSucursalesAction({});
    getProveedoresAction({});
    getDetallesPorCabeceraAction({
      ca: 'ESTADOORDEN',
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPOCAJA',
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPODOCUMENTO',
    });
    return () => {
      actualizarFiltros({ su: null, es: null, st: null, search: null });
    };
  }, []);
  return (
    <div className="op-component view-ordencompra">
      <OrdenCompraList />
      <OrdenCompraFilter />
      {openSolicitar && <OrdenCompraSolicitar />}
      {openVer && <OrdenCompraVer />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openSolicitar: state.ordenCompra.openSolicitar,
    openVer: state.ordenCompra.openVer,
  };
};

export default connect(mapStateToProps, {
  getOrdenesCompraAction,
  getDetallesPorCabeceraAction,
  getSucursalesAction,
  getProveedoresAction,
  actualizarFiltros,
})(OrdenCompra);
