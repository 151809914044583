import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { ordenCompraModel } from '../models/ordencompra.model';
import { proveedorModel } from '../models/proveedor.model';
import { PROVEEDOR_SELECTED } from '../actions/proveedor.action';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

import * as fromPagos from './pagos.action';

export const ORDENCOMPRA_PROCESS = '[OrdenCompra] orden compra procesando';
export const ORDENCOMPRA_SAVE_SUCCESS =
  '[OrdenCompra] orden compra guardar exito';
export const ORDENCOMPRA_LIST_SUCCESS =
  '[OrdenCompra] orden compra lista exito';
export const ORDENCOMPRA_LIST_FAIL = '[OrdenCompra] orden compra lista error';
export const ORDENCOMPRA_SELECTED = '[OrdenCompra] orden compra seleccionado';
export const ORDENCOMPRA_NEW = '[OrdenCompra] nueva orden compra';
export const ORDENCOMPRA_CLOSE = '[OrdenCompra] orden compra cerrar modal';
export const ORDENCOMPRA_FILTER = '[OrdenCompra] orden compra filtros';
export const ORDENCOMPRA_FAIL = '[OrdenCompra] orden compra proceso error';
export const ORDENCOMPRA_PRODUCTOS = '[OrdenCompra] orden compra productos';

export const getOrdenesCompraAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { su, es, st, search } = getState().ordenCompra;
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      su,
      es,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.ORDENCOMPRA, { params })
      .then((response) => {
        dispatch({
          type: ORDENCOMPRA_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: ORDENCOMPRA_LIST_FAIL,
        });
      });
  };

export const getMaterialesAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  const params = {
    em: currentUser.ie,
    page: '0',
    pageSize: '100',
  };

  api
    .get(servicios.MATERIAL, { params })
    .then((response) => {
      dispatch({
        type: ORDENCOMPRA_PRODUCTOS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: ORDENCOMPRA_PRODUCTOS,
        payload: { data: [], count: 0 },
      });
    });
};

export const getSeleccionarOrdenCompraAction =
  (item) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    if (item) {
      dispatch({
        type: ORDENCOMPRA_PROCESS,
      });

      api
        .get(`${servicios.ORDENCOMPRA}/${currentUser.ie}/empresa/${item.oc}`)
        .then((response) => {
          dispatch({
            type: ORDENCOMPRA_SELECTED,
            payload: response,
          });
        })
        .catch(() => {
          dispatch({
            type: ORDENCOMPRA_NEW,
            payload: ordenCompraModel,
          });
        });
    } else {
      dispatch({
        type: ORDENCOMPRA_NEW,
        payload: ordenCompraModel,
      });
    }
  };

export const postSolicitarOrdenCompraAction =
  (item) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    let orden = {
      ie: currentUser.ie,
      is: currentUser.is,
      iu: currentUser.us,
      nu: currentUser.np,
      lst: item,
    };

    api
      .post(servicios.ORDENCOMPRA, orden)
      .then((response) => {
        mensaje(`orden de compra creada : ${response}`);
        dispatch(getOrdenesCompraAction({}));
        dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ORDENCOMPRA_FAIL,
          payload: error.data.errors ? error.data.errors : ordenCompraModel,
        });
      });
  };

export const patchAnularOrdenCompraAction =
  (item, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    let orden = {
      oc: item.oc,
      ie: currentUser.ie,
      mt: motivo,
      ua: item.ua,
    };

    api
      .patch(`${servicios.ORDENCOMPRA}/anular`, orden)
      .then((response) => {
        mensaje(`orden de compra ${item.co} anulada`);
        dispatch(getOrdenesCompraAction({}));
        dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ORDENCOMPRA_FAIL,
          payload: error.data.errors ? error.data.errors : ordenCompraModel,
        });
      });
  };

export const patchAprobarOrdenCompraAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: ORDENCOMPRA_PROCESS,
  });

  let orden = {
    oc: item.oc,
    ie: currentUser.ie,
    pv: item.pv,
    ua: item.ua,
    sd: item.sd,
    ns: item.ns,
  };

  api
    .patch(`${servicios.ORDENCOMPRA}/aprobar`, orden)
    .then((response) => {
      mensaje(`orden de compra ${item.co} aprobada`);
      dispatch(getOrdenesCompraAction({}));
      dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      dispatch({ type: PROVEEDOR_SELECTED, payload: proveedorModel });
    })
    .catch((error) => {
      dispatch({
        type: ORDENCOMPRA_FAIL,
        payload: error.data.errors ? error.data.errors : ordenCompraModel,
      });
    });
};

export const patchConfirmarOrdenCompraAction =
  (item, detalle) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    let orden = {
      ...item,
      ie: currentUser.ie,
      ua: currentUser.np,
      tt: quitarFormatoMonetario(item.tt),
      lst: detalle.map((x) => ({ ...x, pr: quitarFormatoMonetario(x.pr) })),
    };

    api
      .patch(`${servicios.ORDENCOMPRA}/confirmar`, orden)
      .then((response) => {
        mensaje(`orden de compra ${item.co} confirmada`);
        dispatch(getOrdenesCompraAction({}));
        dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ORDENCOMPRA_FAIL,
          payload: error.data.errors ? error.data.errors : ordenCompraModel,
        });
      });
  };

export const patchRechazarOrdenCompraAction =
  (item, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    let orden = {
      oc: item.oc,
      ie: currentUser.ie,
      mt: motivo,
      ua: item.ua,
    };

    api
      .patch(`${servicios.ORDENCOMPRA}/rechazar`, orden)
      .then(() => {
        mensaje(`orden de compra ${item.co} rechazada`);
        dispatch(getOrdenesCompraAction({}));
        dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ORDENCOMPRA_FAIL,
          payload: error.data.errors ? error.data.errors : ordenCompraModel,
        });
      });
  };

export const patchRecepcionarOrdenCompraAction =
  (item, detalle) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    let orden = {
      ...item,
      ie: currentUser.ie,
      tt: quitarFormatoMonetario(item.tt),
      lst: detalle.map((x) => ({ ...x, pr: quitarFormatoMonetario(x.pr) })),
    };

    api
      .patch(`${servicios.ORDENCOMPRA}/recepcionar`, orden)
      .then(() => {
        mensaje(`orden de compra ${item.co} recepcionada`);
        dispatch(getOrdenesCompraAction({}));
        dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
        dispatch(fromPagos.getPagosAction());
      })
      .catch((error) => {
        dispatch({
          type: ORDENCOMPRA_FAIL,
          payload: error.data.errors ? error.data.errors : ordenCompraModel,
        });
      });
  };

export const patchAdjuntarArchivoOrdenAction =
  (orden, archivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: ORDENCOMPRA_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', archivo);
    form_data.append('oc', orden);
    form_data.append('ie', currentUser.ie);

    api
      .patch(`${servicios.ORDENCOMPRA}/adjuntar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getOrdenesCompraAction({}));
        dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: ORDENCOMPRA_FAIL,
          payload: error.data.errors ? error.data.errors : ordenCompraModel,
        });
      });
  };

export const patchPagarOrdenCompraAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: ORDENCOMPRA_PROCESS,
  });

  let orden = {
    ...item,
    ie: currentUser.ie,
    is: currentUser.is,
    iu: currentUser.us,
    ua: currentUser.np,
    tt: quitarFormatoMonetario(item.tt),
  };

  api
    .patch(`${servicios.ORDENCOMPRA}/pagar`, orden)
    .then(() => {
      mensaje(`orden de compra ${item.co} pagada`);
      dispatch(getOrdenesCompraAction({}));
      dispatch({ type: ORDENCOMPRA_SAVE_SUCCESS });
      dispatch(fromPagos.getPagosAction());
    })
    .catch((error) => {
      dispatch({
        type: ORDENCOMPRA_FAIL,
        payload: error.data.errors ? error.data.errors : ordenCompraModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, es, st, search } = getState().ordenCompra;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    es: filtros.es !== undefined ? (filtros.es === '' ? null : filtros.es) : es,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: ORDENCOMPRA_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ORDENCOMPRA_CLOSE,
  });
};
