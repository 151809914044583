import React from 'react';
import Proptypes from 'prop-types';
import { Line } from 'react-chartjs-2';

import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import SyncIcon from '@material-ui/icons/Sync';

const ReporteriaMensual = ({ title, data, onRefresh, colors, bcolors }) => {
  const handleGetReport = () => {
    let dataSet = [...new Set(data.map((x) => x.es))].map((x, i) => ({
      label: x,
      data: data
        .filter((d) => d.es === x)
        .map((d) => formatoMonetario(redondeoDecimales(d.to))),
      borderColor: colors[i],
      //backgroundColor: bcolors[i],
      fill: true,
      hoverOffset: 6,
    }));

    let reporteriaData = {
      labels: [...new Set(data.map((x) => x.ms))],
      datasets: dataSet,
    };
    return (
      <Line
        data={reporteriaData}
        options={{
          responsive: true,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
          },
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: false,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Montos totales',
              },
            },
          },
        }}
      />
    );
  };

  return (
    <div className="grid-item col-3 action reporteria">
      <label className="title">{title}</label>
      <div className="sync" title="Refrescar">
        <SyncIcon onClick={() => onRefresh()} />
      </div>
      {handleGetReport()}
    </div>
  );
};

ReporteriaMensual.propTypes = {
  title: Proptypes.string.isRequired,
  data: Proptypes.arrayOf(
    Proptypes.shape({
      ms: Proptypes.string.isRequired,
      es: Proptypes.string.isRequired,
      to: Proptypes.number.isRequired,
    })
  ).isRequired,
  onRefresh: Proptypes.func.isRequired,
  colors: Proptypes.arrayOf(Proptypes.string.isRequired),
  bcolors: Proptypes.arrayOf(Proptypes.string.isRequired),
};

ReporteriaMensual.defaultProps = {
  colors: ['#f30412', '#f57e05', '#05e4f5', '#27a117', '#174aa1'],
  bcolors: ['#f86871', '#fbcb9b', '#cdfafd', '#7dc774', '#5d80bd'],
};

export default ReporteriaMensual;
