import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getConductoresAction } from '../../redux/actions/conductor.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import ConductorList from './ConductorList';
import ConductorFilter from './ConductorFilter';
import ConductorItem from './ConductorItem';

const Conductor = ({ openModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getConductoresAction());
    dispatch(getDetallesPorCabeceraAction({ ca: 'TIPODOCUMENTOIDENTIDAD' }));
  }, []);

  return (
    <div className="op-component">
      <ConductorList />
      <ConductorFilter />
      {openModal && <ConductorItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.conductor.openModal,
  };
};

export default connect(mapStateToProps)(Conductor);
