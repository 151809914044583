import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { cajaModel } from '../models/caja.model';
import {
  formatearFecha,
  quitarFormatoMonetario,
} from '../../commons/Utilitarios';

export const CAJA_PROCESS = '[Caja] Caja procesando';
export const CAJA_SAVE_SUCCESS = '[Caja] Caja guardar exito';
export const CAJA_LIST_SUCCESS = '[Caja] Caja lista exito';
export const CAJA_LIST_FAIL = '[Caja] Caja lista error';
export const CAJA_SELECTED = '[Caja] Caja seleccionado';
export const CAJA_OPERATION = '[Caja] Caja seleccionar operacion';
export const CAJA_CLOSE = '[Caja] Caja cerrar modal';
export const CAJA_OPEN = '[Caja] Caja open modal';
export const CAJA_VIEW = '[Caja] Caja open view modal';
export const CAJA_FILTER = '[Caja] Caja filtros';
export const CAJA_FAIL = '[Caja] Caja proceso error';

export const getSeleccionarCajaAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { fo } = getState().caja;

  dispatch({
    type: CAJA_PROCESS,
  });

  const params = {
    fo,
  };

  api
    .get(`${servicios.CAJA}/${currentUser.ie}/empresa`, { params })
    .then((response) => {
      dispatch({
        type: CAJA_SELECTED,
        payload: response,
      });
      dispatch(getOperacionesCajaAction({}));
    })
    .catch(() => {
      dispatch({
        type: CAJA_SELECTED,
        payload: { sn: null, se: null, fa: null, fc: null },
      });
    });
};

export const getOperacionesCajaAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { tc, tm, is, search, selected } = getState().caja;
    let { currentUser } = getState().app;

    dispatch({
      type: CAJA_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      cj: selected.cj,
      tc,
      tm,
      is,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.CAJA, { params })
      .then((response) => {
        dispatch({
          type: CAJA_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CAJA_LIST_FAIL,
        });
      });
  };

export const getSeleccionarOperacionCajaAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: CAJA_OPERATION,
      payload: item,
    });
  } else {
    dispatch({
      type: CAJA_OPERATION,
      payload: cajaModel,
    });
  }
};

export const postOperacionCajaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { selected } = getState().caja;

  dispatch({
    type: CAJA_PROCESS,
  });

  let operacion = {
    ie: currentUser.ie,
    cj: selected.cj,
    td: item.td,
    ns: item.ns,
    nd: item.nd,
    rp: item.rp,
    up: item.up,
    tc: item.tc,
    co: item.co,
    is: item.is,
    tm: item.tm,
    mo: quitarFormatoMonetario(item.mo),
    ic: quitarFormatoMonetario(item.ic),
    pc: quitarFormatoMonetario(item.pc),
    ia: item.ia,
    iu: currentUser.us,
    nu: currentUser.np,
  };

  api
    .post(servicios.CAJA, operacion)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getSeleccionarCajaAction());
      dispatch({ type: CAJA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CAJA_FAIL,
        payload: error.data.errors ? error.data.errors : cajaModel,
      });
    });
};

export const putOperacionCajaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { selected } = getState().caja;

  dispatch({
    type: CAJA_PROCESS,
  });

  let operacion = {
    ie: currentUser.ie,
    cj: selected.cj,
    fo: item.fo,
    td: item.td,
    ns: item.ns,
    nd: item.nd,
    rp: item.rp,
    up: item.up,
    tc: item.tc,
    co: item.co,
    is: item.is,
    tm: item.tm,
    mo: quitarFormatoMonetario(item.mo),
    ic: quitarFormatoMonetario(item.ic),
    pc: quitarFormatoMonetario(item.pc),
    ia: item.ia,
    iu: currentUser.us,
    nu: currentUser.np,
  };

  api
    .put(servicios.CAJA, operacion)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getSeleccionarCajaAction());
      dispatch({ type: CAJA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CAJA_FAIL,
        payload: error.data.errors ? error.data.errors : cajaModel,
      });
    });
};

export const patchAdjuntarArchivoOperacionCajaAction =
  (item, archivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { selected } = getState().caja;

    dispatch({
      type: CAJA_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', archivo);
    form_data.append('cj', selected.cj);
    form_data.append('ie', currentUser.ie);
    form_data.append('fo', item.fo);

    api
      .patch(`${servicios.CAJA}/adjuntar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getSeleccionarCajaAction());
        dispatch({ type: CAJA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: CAJA_FAIL,
          payload: error.data.errors ? error.data.errors : cajaModel,
        });
      });
  };

export const patchEliminarOperacionCajaAction =
  (item) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { selected } = getState().caja;

    dispatch({
      type: CAJA_PROCESS,
    });

    let operacion = {
      ie: currentUser.ie,
      cj: selected.cj,
      fo: item.fo,
    };

    api
      .patch(`${servicios.CAJA}/eliminar`, operacion)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getSeleccionarCajaAction());
        dispatch({ type: CAJA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: CAJA_FAIL,
          payload: error.data.errors ? error.data.errors : cajaModel,
        });
      });
  };

export const patchCierreCajaAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CAJA_PROCESS,
  });

  api
    .patch(`${servicios.CAJA}/cierre/${currentUser.ie}`)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getSeleccionarCajaAction());
      dispatch({ type: CAJA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CAJA_FAIL,
        payload: error.data.errors ? error.data.errors : cajaModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { tc, tm, is, fo, fi, ff, search } = getState().caja;

  let item = {
    tc: filtros.tc !== undefined ? (filtros.tc === '' ? null : filtros.tc) : tc,
    tm: filtros.tm !== undefined ? (filtros.tm === '' ? null : filtros.tm) : tm,
    is: filtros.is !== undefined ? (filtros.is === '' ? null : filtros.is) : is,
    fo: filtros.fo !== undefined ? (filtros.fo === '' ? null : filtros.fo) : fo,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: CAJA_FILTER,
    payload: item,
  });
};

export const openModal = () => (dispatch) => {
  dispatch({
    type: CAJA_OPEN,
  });
};

export const openModalView = (item) => (dispatch) => {
  dispatch({
    type: CAJA_VIEW,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CAJA_CLOSE,
  });
};
