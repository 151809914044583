import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';

import { servicios } from '../../redux/helpers/servicios';
import { api } from '../../redux/axios/api';

import '../../css/commons/modal.css';

const File = ({ filename, onClose, dir, showInline, service }) => {
  const [url, setUrl] = useState('');

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    let params = new URLSearchParams();

    if (dir) {
      dir.forEach((x) => {
        params.append('di', x);
      });
    }

    if (filename) {
      api
        .get(service ? service : `${servicios.FILES}/${filename}`, {
          responseType: 'blob',
          params,
        })
        .then((response) => {
          setUrl(window.URL.createObjectURL(new Blob([response])));
        })
        .catch(() => {
          onClose();
        });
    }
  }, []);

  const handleLoad = () => {
    if (filename) {
      return (
        <>
          {/[^.]+$/.exec(filename)[0].toLowerCase() === 'pdf' ? (
            <>
              {url && (
                <>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={url}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </>
              )}
            </>
          ) : (
            <img
              src={url}
              alt="Imagen"
              style={{ width: '100%', objectFit: 'cover' }}
            />
          )}
        </>
      );
    } else {
      return <span>No ha seleccionado ning&uacute;n archivo</span>;
    }
  };

  return (
    <>
      {showInline ? (
        handleLoad()
      ) : (
        <div className="op-content-modal">
          <div className="op-modal op-big content-imagen">
            <div className="op-modal-header">
              <div className="op-modal-close" onClick={() => onClose()}></div>
            </div>
            <div className="op-modal-body">{handleLoad()}</div>
          </div>
        </div>
      )}
    </>
  );
};

File.propTypes = {
  filename: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  dir: PropTypes.array,
  showInline: PropTypes.bool,
  service: PropTypes.string,
};

File.defaultProps = {
  dir: [],
};

export default File;
