import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { proveedorModel } from '../models/proveedor.model';

export const PROVEEDOR_PROCESS = '[Proveedor] Proveedor procesando';
export const PROVEEDOR_SAVE_SUCCESS = '[Proveedor] Proveedor guardar exito';
export const PROVEEDOR_LIST_SUCCESS = '[Proveedor] Proveedor lista exito';
export const PROVEEDOR_LIST_FAIL = '[Proveedor] Proveedor lista error';
export const PROVEEDOR_SELECTED = '[Proveedor] Proveedor seleccionado';
export const PROVEEDOR_CLOSE = '[Proveedor] Proveedor cerrar modal';
export const PROVEEDOR_FILTER = '[Proveedor] Proveedor filtros';
export const PROVEEDOR_FAIL = '[Proveedor] Proveedor proceso error';

export const getProveedoresAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { search } = getState().proveedor;

    dispatch({
      type: PROVEEDOR_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.PROVEEDOR, { params })
      .then((response) => {
        dispatch({
          type: PROVEEDOR_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: PROVEEDOR_LIST_FAIL,
        });
      });
  };

export const getSeleccionarProveedorAction = (item) => (dispatch, getState) => {
  if (item) {
    let { currentUser } = getState().app;
    api
      .get(`${servicios.PROVEEDOR}/${item.pv}/empresa/${currentUser.ie}`)
      .then((response) => {
        dispatch({
          type: PROVEEDOR_SELECTED,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: PROVEEDOR_SELECTED,
          payload: proveedorModel,
        });
      });
  } else {
    dispatch({
      type: PROVEEDOR_SELECTED,
      payload: proveedorModel,
    });
  }
};

export const getBuscarRucProveedorAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  api
    .get(`${servicios.PROVEEDOR}/${item}/numeroruc/${currentUser.ie}`)
    .then((response) => {
      dispatch({
        type: PROVEEDOR_SELECTED,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: PROVEEDOR_SELECTED,
        payload: proveedorModel,
      });
    });
};

export const postProveedorAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: PROVEEDOR_PROCESS,
  });

  let proveedor = {
    pv: item.pv,
    em: currentUser.ie,
    iu: currentUser.us,
    rz: item.rz,
    nr: item.nr,
    di: item.di,
    re: item.re,
    co: item.co,
    nf: item.nf,
    nm: item.nm,
    su: item.su,
    cs: item.cs,
    pc: item.pc,
    pt: item.pt,
    st: item.st,
  };

  api
    .post(servicios.PROVEEDOR, proveedor)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getProveedoresAction({}));
      dispatch({ type: PROVEEDOR_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: PROVEEDOR_FAIL,
        payload: error.data.errors ? error.data.errors : proveedorModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { search } = getState().proveedor;

  let item = {
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: PROVEEDOR_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: PROVEEDOR_CLOSE,
  });
};
