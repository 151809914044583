import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';
import { mensajeMini } from '../../commons/Mensajes';

import {
  getAdiconalesAction,
  getSeleccionarAdicionalAction,
  actualizarFiltros,
  paginado,
} from '../../redux/actions/adicional.action';

import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';
import CheckIcon from '@material-ui/icons/Check';
import Modal from '../../commons/Modal';
import AdicionalFilter from './AdicionalFilter';
import Boton from '../../commons/Boton';

import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

const AdicionalList = ({
  adicionales,
  processing,
  total,
  rows,
  tipo,
  tipoAdicionales,
  padre,
  searchable,
  onClose,
  onSend,
}) => {
  const [buscar, setBuscar] = useState('');
  const dispatch = useDispatch();

  const textDefinition = () => {
    switch (tipo) {
      case 'Zonas':
        return 'Módulos';
      case 'Módulos':
        return 'Cajas';
      case 'Cajas':
        return 'Puertos';
      default:
        return '';
    }
  };

  const handleSelectType = (item) => {
    let value = '';
    switch (tipo) {
      case 'Zonas':
        value = 'Módulos';
        break;
      case 'Módulos':
        value = 'Cajas';
        break;
      case 'Cajas':
        value = 'Puertos';
        break;
      default:
        break;
    }
    let type = tipoAdicionales.find((s) => s.de === value);
    if (type) {
      dispatch(
        actualizarFiltros({
          pa: item.ad,
          pn: item.na,
          ta: type.dt,
          nt: type.de,
        })
      );
      dispatch(getAdiconalesAction());
    } else {
      mensajeMini(`No existe ${value} como tipo de elemento`);
    }
  };

  const columns = [
    {
      key: 'ad',
      align: 'center',
      content: (item) => (
        <>
          {searchable ? (
            <>
              {tipo === 'Puertos' && (
                <div title="Enviar" style={{ display: 'inline-block' }}>
                  <CheckIcon
                    onClick={() => {
                      onSend(item);
                      onClose();
                    }}
                    style={{ color: 'green', cursor: 'pointer' }}
                  />
                </div>
              )}
            </>
          ) : (
            <div title="Editar" style={{ display: 'inline-block' }}>
              <EditIcon
                onClick={() => dispatch(getSeleccionarAdicionalAction(item))}
                style={{ color: 'green', cursor: 'pointer' }}
              />
            </div>
          )}
          {['Zonas', 'Módulos', 'Cajas'].includes(tipo) && (
            <div title={textDefinition()} style={{ display: 'inline-block' }}>
              <ListAltIcon
                onClick={() => handleSelectType(item)}
                style={{ color: '##115293', cursor: 'pointer' }}
              />
            </div>
          )}
        </>
      ),
    },
    {
      key: 'ca',
      label: 'Código',
    },
    {
      key: 'na',
      label: 'Nombre',
    },
  ];

  if (tipo === 'Zonas') {
    columns.push({
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    });
  } else if (tipo === 'Puertos') {
    columns.push(
      {
        key: 'va',
        label: 'Asignado',
      },
      {
        key: 'st',
        label: 'Estado de registro',
        align: 'center',
        content: (item) => <CheckBox checked={item.st} disabled />,
      }
    );
  } else if (tipo === 'Estados') {
    columns.push(
      {
        key: 'va',
        label: 'Orden',
        align: 'center',
      },
      {
        key: 'st',
        label: 'Estado de registro',
        align: 'center',
        content: (item) => <CheckBox checked={item.st} disabled />,
      }
    );
  } else {
    columns.push(
      {
        key: 'pn',
        label: 'Contenedor',
      },
      {
        key: 'va',
        label: 'Capacidad',
        align: 'center',
      },
      {
        key: 'st',
        label: 'Estado de registro',
        align: 'center',
        content: (item) => <CheckBox checked={item.st} disabled />,
      }
    );
  }

  return (
    <>
      {searchable ? (
        <Modal
          title="B&uacute;squeda de elementos"
          closePressEscape={false}
          onActions={() => (
            <Boton
              className="op-dinamic"
              icon={() => <YoutubeSearchedForIcon />}
              style={{ '--button-color': 'skyblue' }}
              onClick={() => dispatch(getAdiconalesAction())}
            >
              Buscar
            </Boton>
          )}
          onClose={() => onClose()}
        >
          <AdicionalFilter searchable />
          <Tabla
            columns={columns}
            data={adicionales}
            processing={processing}
            total={total}
            rows={rows}
            onPagination={({ page }) => {
              dispatch(paginado(page));
              dispatch(getAdiconalesAction());
            }}
            onSearching={() => {
              dispatch(actualizarFiltros({ search: buscar }));
              dispatch(getAdiconalesAction({}));
            }}
            onChangeSearching={(search) => setBuscar(search)}
            style={{ flexBasis: '100%' }}
          />
        </Modal>
      ) : (
        <Bloque
          titulo={`${
            tipo
              ? padre
                ? padre + ' - ' + tipo
                : tipo
              : 'Resultado de búsqueda'
          }`}
        >
          <Tabla
            columns={columns}
            data={adicionales}
            processing={processing}
            total={total}
            rows={rows}
            onPagination={({ page }) => {
              dispatch(paginado(page));
              dispatch(getAdiconalesAction());
            }}
            onSearching={() => {
              dispatch(actualizarFiltros({ search: buscar }));
              dispatch(getAdiconalesAction({}));
            }}
            onChangeSearching={(search) => setBuscar(search)}
          />
        </Bloque>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    adicionales: state.adicional.adicionales,
    processing: state.adicional.processing,
    total: state.adicional.total,
    rows: state.adicional.rows,
    tipo: state.adicional.nt,
    tipoAdicionales: state.detalle.tipoAdicionales,
    padre: state.adicional.pn,
  };
};

export default connect(mapStateToProps)(AdicionalList);
