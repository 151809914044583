export const adicionalModel = {
  ad: null,
  ca: null,
  na: null,
  pa: null,
  pn: null,
  ta: null,
  nt: null,
  va: null,
  st: false,
  gc: true,
};
