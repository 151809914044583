import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { caracteresUnicode } from '../../commons/Utilitarios';
import Icon from '../../commons/Icon';

import Delete from '../../assets/icons/delete.svg';
import Settings from '../../assets/icons/settings.svg';

import useDraggable from '../../hooks/useDraggable';
import Conector from './Conector';

const Tarea = ({
  id,
  label,
  name,
  posX,
  posY,
  onChange,
  onNewTask,
  onDeleteTask,
  entradas,
}) => {
  const taskRef = useRef(null);
  useDraggable(taskRef);

  const [editName, setEditName] = useState(false);
  const [show, setShow] = useState(false);
  const [{ x, y, tx, ty }, setCurrent] = useState({
    x: posX,
    y: posY,
    tx: 0,
    ty: 0,
  });

  return (
    <>
      {entradas.map((item, index) => {
        return (
          <Conector
            id={item.id}
            key={index}
            start={item.start}
            end={{ x, y, tx, ty }}
          />
        );
      })}
      <div
        className={`op-content-task ${editName ? 'edit' : ''}`}
        style={{ left: posX, top: posY }}
        ref={taskRef}
        onDoubleClick={() => setEditName(true)}
        onMouseMove={() => {
          const style = window.getComputedStyle(taskRef.current);
          const matrix = new DOMMatrixReadOnly(style.transform);
          setCurrent({
            x: posX + matrix.m41,
            y: posY + matrix.m42,
            tx: matrix.m41,
            ty: matrix.m42,
          });
        }}
        onMouseOver={(e) => {
          if (e.relatedTarget.classList.contains('op-conector')) {
            console.log('conetor');
          }
        }}
      >
        <label>{label}</label>
        <input
          type="text"
          name={name}
          value={label}
          onChange={(e) => onChange(e.target)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setEditName(false);
            } else {
              let re = new RegExp(
                `[a-zA-Z0-9-/.:+,;\_\\s${caracteresUnicode()}]`
              );
              if (!re.test(e.key)) {
                e.preventDefault();
              }
            }
          }}
          onFocus={(e) => e.target.select()}
          autoFocus
        ></input>
        <div className="settings">
          <Icon
            svg={Settings}
            size={15}
            transparent
            onClick={() => setShow(!show)}
          />
        </div>
        <div className={`actions${show ? ' show' : ''}`}>
          <div
            className="new-task"
            onClick={() => {
              const style = window.getComputedStyle(taskRef.current);
              const matrix = new DOMMatrixReadOnly(style.transform);
              setShow(!show);
              onNewTask({
                id: new Date().getTime().toString(),
                x: posX + matrix.m41,
                y: posY + matrix.m42,
                tx: matrix.m41,
                ty: matrix.m42,
              });
            }}
            title="Agregar nueva tarea"
          ></div>
          <Icon
            svg={Delete}
            size={15}
            transparent
            title="Eliminar tarea"
            onClick={() => onDeleteTask(id)}
          />
        </div>
      </div>
    </>
  );
};

Tarea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onNewTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  posX: PropTypes.number,
  posY: PropTypes.number,
  entradas: PropTypes.array,
};

Tarea.defaultProps = {
  posX: 100,
  posY: 200,
};

export default Tarea;
