import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { servicioModel } from '../models/servicio.model';

export const SERVICO_PROCESS = '[Servicio] Servicio procesando';
export const SERVICO_SAVE_SUCCESS = '[Servicio] Servicio guardar exito';
export const SERVICO_LIST_SUCCESS = '[Servicio] Servicio lista exito';
export const SERVICO_LIST_FAIL = '[Servicio] Servicio lista error';
export const SERVICO_SELECTED = '[Servicio] Servicio seleccionado';
export const SERVICO_IMAGE = '[Servicio] Servicio imagen';
export const SERVICO_CLOSE = '[Servicio] Servicio cerrar modal';
export const SERVICO_FILTER = '[Servicio] Servicio filtros';
export const SERVICO_FAIL = '[Servicio] Servicio proceso error';

export const getServiciosAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { st, search } = getState().servicio;

    dispatch({
      type: SERVICO_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.SERVICIO, { params })
      .then((response) => {
        dispatch({
          type: SERVICO_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: SERVICO_LIST_FAIL,
        });
      });
  };

export const getSeleccionarServicioAction = (item, imagen) => (dispatch) => {
  if (item) {
    dispatch({
      type: imagen ? SERVICO_IMAGE : SERVICO_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: SERVICO_SELECTED,
      payload: servicioModel,
    });
  }
};

export const postServicioAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: SERVICO_PROCESS,
  });

  let servicio = {
    ...item,
    em: currentUser.ie,
  };

  api
    .post(servicios.SERVICIO, servicio)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getServiciosAction({}));
      dispatch({ type: SERVICO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: SERVICO_FAIL,
        payload: error.data.errors ? error.data.errors : servicioModel,
      });
    });
};

export const patchImagenServicioAction =
  (item, imagen) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: SERVICO_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('sv', item.sv);
    form_data.append('ie', currentUser.ie);
    form_data.append('file', imagen);

    api
      .patch(`${servicios.SERVICIO}/imagen`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getServiciosAction({}));
        dispatch({
          type: SERVICO_SAVE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: SERVICO_FAIL,
          payload: error.data.errors ? error.data.errors : servicioModel,
        });
      });
  };

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { st, search } = getState().servicio;

  let item = {
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: SERVICO_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: SERVICO_CLOSE,
  });
};
