export const guiaModel = {
  tt: null,
  ce: null,
  de: null,
  mt: null,
  dm: null,
  do: null,
  ft: null,
  dr: null,
  dd: null,
  td: null,
  te: null,
  nt: null,
  sp: null,
  up: null,
  upl: null,
  ip: null,
  sl: null,
  ul: null,
  ull: null,
  il: null,
  nc: null,
  lc: null,
  vh: null,
  ch: null,
  tr: null,
  to: null,
  ts: null,
  tu: null,
  fe: null,
  oe: null,
  adj: null,
  st: false,
};
