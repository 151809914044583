import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import Calendario from '../../commons/Calendario';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import ContratoDetalle from './ContratoDetalle';

import {
  closeModal,
  patchEditarContratoAction,
} from '../../redux/actions/contrato.action';

import SaveIcon from '@material-ui/icons/Save';

const ContratoEdit = () => {
  const [responsable, setResponsable] = useState({ np: null, vd: null });
  const [detalle, setDetalle] = useState([]);
  const dispatch = useDispatch();

  const { selected, processing, errors } = useSelector(
    (state) => state.contrato
  );

  const [contrato, setContrato] = useState(selected);
  const { formasPago } = useSelector((state) => state.detalle);

  useEffect(() => {
    let forma = formasPago.find((x) => x.de === selected.dm);
    if (forma) {
      handleChange({ name: 'mp', value: forma.dt });
    }

    let user = contrato.dtl.find((x) => x.vt === '07' && x.va === '01');
    if (user) {
      setResponsable({ np: user.np, vd: user.vd });
    }

    setDetalle(
      contrato.dtl.filter((x) => x.vt != '06').map((x) => ({ ...x, nd: x.np }))
    );
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setContrato((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Modal
        title={`Modificar Contrato - ${selected.ot}`}
        processing={processing}
        onActions={() => (
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={21}
            onClick={() =>
              confirmacion(
                '¿Está seguro de grabar?',
                `Los datos del contrato seran actualizados`
              )
                .then(() =>
                  dispatch(patchEditarContratoAction(contrato, detalle))
                )
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        )}
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              dispatch(closeModal());
            })
            .catch(() => {})
        }
      >
        <Texto
          name="ns"
          value={selected.ns}
          label="Sucursal"
          size={2}
          tabIndex={10}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nu"
          value={selected.nu}
          label="Vendedor"
          size={2}
          tabIndex={11}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fv"
          value={formatoFecha(selected.fv)}
          label="Fecha de venta"
          size={2}
          tabIndex={12}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="ss"
          value={selected.ss}
          label="Servicio"
          size={2}
          tabIndex={13}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fa"
          value={formatoFecha(selected.fa)}
          label="Fecha de acci&oacute;n"
          size={2}
          tabIndex={14}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nt"
          value={selected.nt}
          label="&Uacute;ltima acci&oacute;n"
          size={2}
          tabIndex={15}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="an"
          value={selected.an}
          label="Usuario responsable de la acci&oacute;n"
          size={2}
          tabIndex={16}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="de"
          value={selected.de}
          label="Estado contrato"
          size={2}
          tabIndex={17}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nc"
          value={selected.nc}
          label="Cliente"
          size={2}
          tabIndex={18}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="dc"
          value={selected.dc}
          label="Documento del cliente"
          size={2}
          tabIndex={19}
          onChange={() => {}}
          disabled
        />
        <Combo
          name="mp"
          label="Forma de pago"
          value={contrato.mp}
          tabIndex={20}
          size={2}
          data={formasPago.map((i) => ({ value: i.dt, label: i.de }))}
          error={errors.mp}
          onChange={handleChange}
        />
        <Calendario
          name={'fi'}
          value={contrato.fi}
          tabIndex={21}
          label={'Inicio de contrato'}
          size={2}
          error={errors.fi}
          onSelected={(item) => {
            handleChange(item);
            handleChange({ name: 'ci', value: item.value.getDate() });
            const date = new Date(item.value);
            date.setMonth(date.getMonth() + parseInt(contrato.cu));
            handleChange({ name: 'ff', value: date });
          }}
        />
        <Texto
          name="ci"
          value={contrato.ci}
          label="Ciclo de facturaci&oacute;n"
          size={2}
          tabIndex={22}
          restrict="int"
          error={errors.ci}
          onChange={handleChange}
        />
        <Texto
          name="cu"
          value={contrato.cu}
          label="N&uacute;mero de cuotas del contrato"
          size={2}
          tabIndex={23}
          restrict="int"
          error={errors.cu}
          onChange={(item) => {
            handleChange(item);
            const date = new Date(contrato.fi);
            date.setMonth(date.getMonth() + parseInt(item.value));
            handleChange({ name: 'ff', value: date });
          }}
        />
        <Texto
          name={'ff'}
          value={formatoFecha(contrato.ff, 'Indefinido', false)}
          tabIndex={24}
          label={'Final de contrato'}
          size={2}
          disabled
          onChange={() => {}}
        />
        <Texto
          name="si"
          value={formatoMonetario(redondeoDecimales(contrato.si))}
          label="Costo de instalaci&oacute;n"
          size={2}
          tabIndex={25}
          restrict="money"
          error={errors.si}
          onChange={handleChange}
        />
        <Texto
          name="ic"
          value={contrato.ic}
          label="N&uacute;mero de cuotas de la instalaci&oacute;n"
          size={2}
          tabIndex={21}
          restrict="int"
          error={errors.ic}
          onChange={handleChange}
        />
        <ContratoDetalle
          lista={detalle}
          editable={true}
          onChange={(item) => {
            setDetalle(
              detalle.map((d) =>
                d.pd === item.name
                  ? { ...d, vd: item.value, lb: item.label }
                  : d
              )
            );
          }}
        />
      </Modal>
    </>
  );
};

export default ContratoEdit;
