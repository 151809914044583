import React, { useState } from 'react';
import { connect } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getSeleccionarProveedorAction,
  getProveedoresAction,
} from '../../redux/actions/proveedor.action';

const ProveedorFilter = ({
  getProveedoresAction,
  getSeleccionarProveedorAction,
}) => {
  return (
    <Filtros
      onFilter={() => getProveedoresAction({})}
      onNew={() => getSeleccionarProveedorAction()}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  getSeleccionarProveedorAction,
  getProveedoresAction,
})(ProveedorFilter);
