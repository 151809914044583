import * as fromContrato from '../actions/contrato.action';
import { contratoModel } from '../models/contrato.model';

let initialState = {
  contratos: [],
  contratosReportes: [],
  su: null,
  suf: null,
  us: null,
  ec: null,
  fi: null,
  ff: null,
  search: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
  openModalView: false,
  openModalEdit: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromContrato.CONTRATO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: contratoModel,
      };
    case fromContrato.CONTRATO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        contratos: action.payload.data,
        total: action.payload.count,
      };
    case fromContrato.CONTRATO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        contratos: [],
        total: 0,
      };
    case fromContrato.CONTRATO_LIST_REPORT:
      return {
        ...state,
        processing: false,
        contratosReportes: action.payload,
      };
    case fromContrato.CONTRATO_STATES:
      return {
        ...state,
        processing: false,
        estados: action.payload,
      };
    case fromContrato.CONTRATO_SELECTED:
      return {
        ...state,
        processing: false,
        selected: action.payload,
        errors: contratoModel,
        openModal: true,
      };
    case fromContrato.CONTRATO_SELECTED_VIEW:
      return {
        ...state,
        processing: false,
        selected: action.payload,
        errors: contratoModel,
        openModalView: true,
      };
    case fromContrato.CONTRATO_SELECTED_EDIT:
      return {
        ...state,
        processing: false,
        selected: action.payload,
        errors: contratoModel,
        openModalEdit: true,
      };
    case fromContrato.CONTRATO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        openModalView: false,
        openModalEdit: false,
        errors: contratoModel,
      };
    case fromContrato.CONTRATO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromContrato.CONTRATO_FILTER:
      return {
        ...state,
        su: action.payload.su,
        suf: action.payload.suf,
        us: action.payload.us,
        ec: action.payload.ec,
        fi: action.payload.fi,
        ff: action.payload.ff,
        search: action.payload.search,
        page: 0,
      };
    case fromContrato.CONTRATO_CLOSE:
      return {
        ...state,
        errors: contratoModel,
        openModal: false,
        openModalView: false,
        openModalEdit: false,
      };
    case fromContrato.CONTRATO_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
