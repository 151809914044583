import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postConductorAction,
} from '../../redux/actions/conductor.action';

import SaveIcon from '@material-ui/icons/Save';

const ConductorItem = ({
  selected,
  processing,
  errors,
  tipoDocumentoIdentidad,
}) => {
  const [conductor, setConductor] = useState(selected);

  const dispatch = useDispatch();

  useEffect(() => {
    let searchTipo = tipoDocumentoIdentidad.find((s) => s.de === selected.dd);
    if (searchTipo) {
      handleChange({ name: 'td', value: searchTipo.dt });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setConductor((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${conductor.ch ? 'Actualizar' : 'Nuevo'} conductor`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${conductor.ch ? 'Actualizar' : 'Un nuevo'} conductor`
            )
              .then(() => dispatch(postConductorAction(conductor)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Combo
        name="td"
        label="Tipo documento"
        value={conductor.td}
        tabIndex={10}
        size={2}
        data={tipoDocumentoIdentidad.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.td}
        onChange={handleChange}
      />
      <Texto
        name="nd"
        value={conductor.nd}
        label="N&uacute;mero de documento"
        tabIndex={11}
        size={2}
        error={errors.nd}
        onChange={handleChange}
        restrict={'int'}
      />
      <Texto
        name="nc"
        value={conductor.nc}
        label="Nombres completos"
        tabIndex={12}
        size={2}
        error={errors.nc}
        onChange={handleChange}
      />
      <Texto
        name="lc"
        value={conductor.lc}
        label="Licencia"
        tabIndex={13}
        size={2}
        error={errors.lc}
        onChange={handleChange}
      />
      <Texto
        name="mh"
        value={conductor.mh}
        label="Marca veh&iacute;culo"
        tabIndex={14}
        size={2}
        error={errors.mh}
        onChange={handleChange}
      />
      <Texto
        name="ph"
        value={conductor.ph}
        label="Placa veh&iacute;culo"
        tabIndex={14}
        size={2}
        error={errors.ph}
        onChange={handleChange}
      />
      <Texto
        name="oh"
        value={conductor.oh}
        label="Constancia veh&iacute;culo"
        tabIndex={15}
        size={2}
        error={errors.oh}
        onChange={handleChange}
      />
      {conductor.ch && (
        <div className="op-form-group op-col-2 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox
            name="st"
            tabIndex={16}
            checked={conductor.st}
            onChange={handleChange}
          />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.conductor.selected,
    processing: state.conductor.processing,
    errors: state.conductor.errors,
    tipoDocumentoIdentidad: state.detalle.tipoDocumentoIdentidad,
  };
};

export default connect(mapStateToProps)(ConductorItem);
