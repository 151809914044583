import * as fromChica from '../actions/chica.operacion.action';
import { chicaOperacionModel } from '../models/chica.operacion.model';

let initialState = {
  operaciones: [],
  su: null,
  ch: null,
  tc: null,
  tm: null,
  is: null,
  fo: null,
  fi: null,
  ff: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
  openModalView: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromChica.CHICAOPERACION_PROCESS:
      return {
        ...state,
        processing: true,
        errors: chicaOperacionModel,
      };
    case fromChica.CHICAOPERACION_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        operaciones: action.payload.data,
        total: action.payload.count,
      };
    case fromChica.CHICAOPERACION_LIST_FAIL:
      return {
        ...state,
        processing: false,
        operaciones: [],
        total: 0,
      };
    case fromChica.CHICAOPERACION_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: chicaOperacionModel,
        openModalView: true,
      };
    case fromChica.CHICAOPERACION_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: chicaOperacionModel,
        openModal: false,
        openModalView: false,
      };
    case fromChica.CHICAOPERACION_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromChica.CHICAOPERACION_FILTER:
      return {
        ...state,
        su: action.payload.su,
        ch: action.payload.ch,
        tc: action.payload.tc,
        tm: action.payload.tm,
        is: action.payload.is,
        fo: action.payload.fo,
        fi: action.payload.fi,
        ff: action.payload.ff,
      };

    case fromChica.CHICAOPERACION_OPEN:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
        openModalView: false,
        errors: chicaOperacionModel,
      };
    case fromChica.CHICAOPERACION_CLOSE:
      return {
        ...state,
        openModal: false,
        openModalView: false,
        errors: chicaOperacionModel,
      };
    default:
      return state;
  }
}
