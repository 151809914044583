import * as fromOrden from '../actions/ordencompra.action';
import { ordenCompraModel } from '../models/ordencompra.model';

let initialState = {
  ordenes: [],
  productos: [],
  iu: null,
  su: null,
  es: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openSolicitar: false,
  openVer: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromOrden.ORDENCOMPRA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: ordenCompraModel,
      };
    case fromOrden.ORDENCOMPRA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        ordenes: action.payload.data,
        total: action.payload.count,
      };
    case fromOrden.ORDENCOMPRA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        ordenes: [],
        total: 0,
      };
    case fromOrden.ORDENCOMPRA_PRODUCTOS:
      return {
        ...state,
        productos: action.payload.data,
      };
    case fromOrden.ORDENCOMPRA_SELECTED:
      return {
        ...state,
        processing: false,
        selected: action.payload,
        errors: ordenCompraModel,
        openVer: true,
      };
    case fromOrden.ORDENCOMPRA_NEW:
      return {
        ...state,
        processing: false,
        selected: action.payload,
        errors: ordenCompraModel,
        openSolicitar: true,
      };
    case fromOrden.ORDENCOMPRA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: ordenCompraModel,
        openSolicitar: false,
        openVer: false,
      };
    case fromOrden.ORDENCOMPRA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromOrden.ORDENCOMPRA_FILTER:
      return {
        ...state,
        su: action.payload.su,
        iu: action.payload.iu,
        es: action.payload.es,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromOrden.ORDENCOMPRA_CLOSE:
      return {
        ...state,
        errors: ordenCompraModel,
        openSolicitar: false,
        openVer: false,
      };
    default:
      return state;
  }
}
