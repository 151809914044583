import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Proptypes from 'prop-types';

import Modal from './Modal';
import Boton from './Boton';
import Texto from './Texto';
import Icon from './Icon';
import FileUpload from './FileUpload';
import Report from '../components/app/Report';

import { obtenerIcono } from './Utilitarios';
import { confirmacion, mensajeMini } from './Mensajes';

import {
  patchDarBajaArchivoAction,
  patchEditarArchivoAction,
} from '../redux/actions/archivo.action';
import { servicios } from '../redux/helpers/servicios';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';

const Adjunto = ({
  onNew,
  extensions,
  data,
  stateParent,
  nameParent,
  file,
  processing,
}) => {
  const [openFile, setOpenFile] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [filename, setFilename] = useState(null);
  const [downloadname, setDownloadname] = useState(null);
  const [extension, setExtension] = useState(null);
  const [documento, setDocumento] = useState(null);
  const [archivo, setArchivo] = useState(null);

  const dispatch = useDispatch();

  const empresa = useSelector((state) => state.app.currentUser.ie);

  return (
    <>
      <div
        className="op-form-group"
        style={{ gridColumn: '1/3', borderTop: '1px solid #3498db' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '5px',
          }}
        >
          <span>Archivos adjuntos</span>
          <Boton
            className="op-dinamic"
            disabled={processing}
            icon={() => <AttachFileIcon />}
            tabIndex={25}
            style={{ '--button-color': '#3285a8' }}
            onClick={() => setOpenNew(true)}
          >
            nuevo archivo
          </Boton>
        </div>
        {data &&
          data.map((d) => (
            <div
              key={d.id}
              style={{
                display: 'grid',
                gridTemplateColumns: '30px 20px auto',
                columnGap: '5px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  marginRight: '5px',
                }}
              >
                {stateParent && (
                  <EditIcon
                    style={{
                      color: '#1ab394',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setArchivo(d);
                      setOpenNew(true);
                    }}
                  />
                )}
                {stateParent && d.state && (
                  <CloseIcon
                    style={{
                      color: 'red',
                      fontSize: '18px',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      confirmacion(
                        '¿Está seguro?',
                        `De anular el archivo ${d.name}.${d.extension}`
                      )
                        .then(() =>
                          dispatch(
                            patchDarBajaArchivoAction(file, d.id, nameParent)
                          )
                        )
                        .catch(() => {})
                    }
                  />
                )}
              </div>
              <Icon svg={obtenerIcono(d.extension)} transparent />
              <div
                style={{ cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  setFilename(d.id);
                  setExtension(d.extension);
                  setOpenFile(true);
                  setDownloadname(d.name);
                }}
              >
                <span
                  style={{
                    textDecoration: `${d.state ? 'none' : 'line-through'}`,
                    color: `${d.state ? 'inherit' : 'red'}`,
                  }}
                >{`${d.name}.${d.extension}`}</span>
              </div>
            </div>
          ))}
      </div>
      {openFile && (
        <Report
          url={`${servicios.ARCHIVO}/${file}/empresa/${empresa}/${filename}`}
          params={{}}
          extension={extension}
          filename={downloadname}
          onClose={() => setOpenFile(false)}
        />
      )}
      {openNew && (
        <Modal
          title={`${archivo ? 'Editar' : 'Adjuntar'} archivo`}
          size="small"
          processing={processing}
          loading="files"
          onActions={() => (
            <Boton
              className="op-grabar"
              disabled={processing}
              icon={() => <SaveIcon />}
              tabIndex={501}
              onClick={() => {
                if (documento) {
                  confirmacion(
                    '¿Desea guardar?',
                    `Se agregara un nuevo archivo`
                  )
                    .then(() => {
                      if (archivo) {
                        dispatch(
                          patchEditarArchivoAction(
                            file,
                            archivo.id,
                            documento,
                            nameParent
                          )
                        );
                      } else {
                        onNew(documento);
                      }
                    })
                    .catch(() => {});
                } else {
                  mensajeMini('Seleccione archivo');
                }
              }}
            >
              Guardar
            </Boton>
          )}
          onClose={() => {
            setArchivo(null);
            setOpenNew(false);
          }}
        >
          {archivo && (
            <Texto
              name="nd"
              value={`${archivo.name}.${archivo.extension}`}
              label="Nombre de archivo"
              tabIndex={500}
              disabled
              onChange={() => {}}
            />
          )}
          <FileUpload
            extensions={extensions}
            onSelectedFile={(files) => {
              setDocumento(files.length > 0 ? files[0].file : null);
            }}
          />
        </Modal>
      )}
    </>
  );
};

Adjunto.propTypes = {
  onNew: Proptypes.func.isRequired,
  extensions: Proptypes.arrayOf(
    Proptypes.oneOf(['pdf', 'docx', 'doc', 'xls', 'xlsx', 'jpg', 'jpeg', 'png'])
  ),
  data: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.string.isRequired,
      name: Proptypes.string.isRequired,
      extension: Proptypes.string.isRequired,
      state: Proptypes.bool.isRequired,
    })
  ),
  stateParent: Proptypes.bool.isRequired,
  nameParent: Proptypes.string.isRequired,
  file: Proptypes.string,
  processing: Proptypes.bool,
};

Adjunto.defaultProps = {
  extensions: ['pdf', 'jpg', 'jpeg', 'png'],
  processing: false,
};

export default Adjunto;
