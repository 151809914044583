import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Texto from '../../commons/Texto';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';
import Report from '../app/Report';
import {
  getDocumentosAction,
  actualizarFiltros,
} from '../../redux/actions/documento.action';

import { servicios } from '../../redux/helpers/servicios';

import PrintIcon from '@material-ui/icons/Print';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import XlsFile from '../../assets/icons/xls-file.svg';

const VentaFilter = ({ sucursales, empresa, search }) => {
  const [su, setSu] = useState('');
  const [tm, setTm] = useState('');
  const [fi, setFi] = useState('');
  const [ff, setFf] = useState('');
  const [params, setParams] = useState({});
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);

  const dispatch = useDispatch();

  let date = new Date();
  let day =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate().toString();
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month.toString();
  let year = date.getFullYear();

  return (
    <>
      <Filtros
        onFilter={() => dispatch(getDocumentosAction())}
        showNewButton={false}
        actions={() => (
          <>
            <Boton
              className="op-filter"
              icon={() => <PrintIcon />}
              tabIndex={22}
              dropdownable
              style={{
                '--button-icon-color': '#1ab394',
              }}
              showDropdownOnTop
              actions={[
                {
                  key: '01',
                  label: 'PDF',
                  icon: () => <PictureAsPdfIcon style={{ color: 'red' }} />,
                  onClick: () => {
                    setOpenReporte(true);
                    setParams({ su, tm, fi, ff, search });
                  },
                },
                {
                  key: '02',
                  label: 'Excel',
                  icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                  onClick: () => {
                    setOpenReporteExcel(true);
                    setParams({ su, tm, fi, ff, search, xl: '0' });
                  },
                },
              ]}
            >
              Imprimir
            </Boton>
          </>
        )}
      >
        <Combo
          name="su"
          label="Sucursal"
          value={su}
          placeholder="Todos"
          tabIndex={20}
          size={3}
          data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
          onChange={({ value }) => {
            setSu(value);
            dispatch(actualizarFiltros({ suf: value }));
          }}
        />
        <Combo
          name="tm"
          value={tm}
          label="Moneda"
          placeholder="Ambos"
          size={3}
          tabIndex={21}
          data={[
            { value: '0', label: 'Soles' },
            { value: '1', label: 'Dolares' },
          ]}
          onChange={({ value }) => {
            setTm(value);
            dispatch(actualizarFiltros({ tm: value }));
          }}
        />
        <Texto
          name="fi"
          value={fi}
          label="Fecha de inicio operaci&oacute;n"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            setFi(value);
            dispatch(actualizarFiltros({ fi: value }));
          }}
        />
        <Texto
          name="ff"
          value={ff}
          label="Fecha fin operaci&oacute;n"
          tabIndex={23}
          size={3}
          type={'date'}
          max={`${year}-${month}-${day}`}
          onChange={({ value }) => {
            setFf(value);
            dispatch(actualizarFiltros({ ff: value }));
          }}
        />
      </Filtros>
      {openReporte && (
        <Report
          url={`${servicios.DOCUMENTO}/${empresa}/imprimir`}
          params={params}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.DOCUMENTO}/${empresa}/imprimir`}
          params={params}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={'ReporteComprobantes'}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
    empresa: state.app.currentUser.ie,
    search: state.documento.search,
  };
};

export default connect(mapStateToProps)(VentaFilter);
