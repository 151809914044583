export const chicaModel = {
  ch: null,
  is: null,
  ns: null,
  sn: null,
  se: null,
  fa: null,
  fc: null,
  nc: null,
  nr: null,
};
