import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '../../commons/Icon';

import Delete from '../../assets/icons/delete.svg';

const Conector = ({ id, label, onDeletLine, start, end }) => {
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(20);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [startLine, setStartLine] = useState({ x: 0, y: 0, sx: '%', sy: '%' });
  const [endLine, setEndLine] = useState({ x: 100, y: 100, sx: '%', sy: '%' });
  const [show, setShow] = useState(false);

  useEffect(() => {
    let posY = end.y - start.y;
    let posX = end.x - start.x;

    if (-20 < end.ty && end.ty < 20 && end.tx > -240) {
      //Arrow right
      setWidth(posX - 100);
      setHeight(20);
      setLeft(start.x + 101);
      setTop(start.y + 20);
      setStartLine({ x: 0, y: 10, sx: '%', sy: 'px' });
      setEndLine({ x: 100, y: 10, sx: '%', sy: 'px' });
    } else if (end.tx > -70 && end.ty >= 20) {
      //4to cuadrante - I
      setWidth(posX - 100);
      setHeight(posY);
      setLeft(start.x + 101);
      setTop(start.y + 30);
      setStartLine({ x: 0, y: 0, sx: '%', sy: '%' });
      setEndLine({ x: 100, y: 100, sx: '%', sy: '%' });
    } else if (end.tx > -120 && end.ty >= 20) {
      //4to cuadrante - II
      setWidth(posX - 50);
      setHeight(posY - 30);
      setLeft(start.x + 101);
      setTop(start.y + 30);
      setStartLine({ x: 0, y: 0, sx: '%', sy: '%' });
      setEndLine({ x: 100, y: 100, sx: '%', sy: '%' });
    } else if (end.tx > -240 && end.ty >= 20) {
      //4to cuadrante - III
      setWidth(posX);
      setHeight(posY - 60);
      setLeft(start.x + 50);
      setTop(start.y + 60);
      setStartLine({ x: 0, y: 0, sx: '%', sy: '%' });
      setEndLine({ x: 100, y: 100, sx: '%', sy: '%' });
    } else if (end.tx > -260 && end.ty >= 20) {
      //Arrow botom
      setWidth(20);
      setHeight(posY - 60);
      setLeft(start.x + 40);
      setTop(start.y + 60);
      setStartLine({ x: 10, y: 0, sx: 'px', sy: '%' });
      setEndLine({ x: 10, y: 100, sx: 'px', sy: '%' });
    } else if (end.tx > -330 && end.ty >= 20) {
      //3er cuadrante - I
      setWidth(Math.abs(posX));
      setHeight(posY - 60);
      setLeft(end.x + 50);
      setTop(start.y + 60);
      setStartLine({ x: 100, y: 0, sx: '%', sy: '%' });
      setEndLine({ x: 0, y: 100, sx: '%', sy: '%' });
    } else if (end.tx > -420 && end.ty >= 20) {
      //3er cuadrante - II
      setWidth(Math.abs(posX) - 50);
      setHeight(posY - 30);
      setLeft(end.x + 50);
      setTop(start.y + 30);
      setStartLine({ x: 100, y: 0, sx: '%', sy: '%' });
      setEndLine({ x: 0, y: 100, sx: '%', sy: '%' });
    } else if (end.tx <= -420 && end.ty >= 20) {
      //3er cuadrante - III
      setWidth(Math.abs(posX) - 100);
      setHeight(posY);
      setLeft(end.x + 100);
      setTop(start.y + 30);
      setStartLine({ x: 100, y: 0, sx: '%', sy: '%' });
      setEndLine({ x: 0, y: 100, sx: '%', sy: '%' });
    } else if (-20 < end.ty && end.ty < 20 && end.tx < -260) {
      //Arrow left
      setWidth(Math.abs(posX) - 100);
      setHeight(20);
      setLeft(start.x - Math.abs(posX) + 100);
      setTop(start.y + 20);
      setStartLine({ x: 100, y: 10, sx: '%', sy: 'px' });
      setEndLine({ x: 0, y: 10, sx: '%', sy: 'px' });
    } else if (end.tx > -70 && end.ty <= -20) {
      //1er cuadrante - III
      setWidth(posX - 100);
      setHeight(Math.abs(posY));
      setLeft(start.x + 100);
      setTop(end.y + 30);
      setStartLine({ x: 0, y: 100, sx: '%', sy: '%' });
      setEndLine({ x: 100, y: 0, sx: '%', sy: '%' });
    } else if (end.tx > -120 && end.ty <= -20) {
      //1er cuadrante - II
      setWidth(posX - 50);
      setHeight(Math.abs(posY) - 30);
      setLeft(start.x + 100);
      setTop(end.y + 60);
      setStartLine({ x: 0, y: 100, sx: '%', sy: '%' });
      setEndLine({ x: 100, y: 0, sx: '%', sy: '%' });
    } else if (end.tx > -240 && end.ty <= -20) {
      //1er cuadrante - I
      setWidth(posX);
      setHeight(Math.abs(posY) - 60);
      setLeft(start.x + 50);
      setTop(end.y + 60);
      setStartLine({ x: 0, y: 100, sx: '%', sy: '%' });
      setEndLine({ x: 100, y: 0, sx: '%', sy: '%' });
    } else if (end.tx > -260 && end.ty <= -20) {
      //Arrow top
      setWidth(20);
      setHeight(Math.abs(posY) - 60);
      setLeft(start.x + 40);
      setTop(end.y + 60);
      setStartLine({ x: 10, y: 100, sx: 'px', sy: '%' });
      setEndLine({ x: 10, y: 0, sx: 'px', sy: '%' });
    } else if (end.tx > -330 && end.ty <= -20) {
      //2do cuadrante - III
      setWidth(Math.abs(posX));
      setHeight(Math.abs(posY) - 60);
      setLeft(end.x + 50);
      setTop(end.y + 60);
      setStartLine({ x: 100, y: 100, sx: '%', sy: '%' });
      setEndLine({ x: 0, y: 0, sx: '%', sy: '%' });
    } else if (end.tx > -420 && end.ty <= -20) {
      //2do cuadrante - II
      setWidth(Math.abs(posX) - 50);
      setHeight(Math.abs(posY) - 30);
      setLeft(start.x - Math.abs(posX) + 50);
      setTop(end.y + 60);
      setStartLine({ x: 100, y: 100, sx: '%', sy: '%' });
      setEndLine({ x: 0, y: 0, sx: '%', sy: '%' });
    } else {
      //2do cuadrante - I
      setWidth(Math.abs(posX) - 100);
      setHeight(Math.abs(posY));
      setLeft(start.x - Math.abs(posX) + 100);
      setTop(end.y + 30);
      setStartLine({ x: 100, y: 100, sx: '%', sy: '%' });
      setEndLine({ x: 0, y: 0, sx: '%', sy: '%' });
    }
  }, [start, end]);

  return (
    <div
      className="op-conector"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      }}
      onClick={() => setShow(!show)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        strokeWidth="1"
        stroke="#000"
      >
        <defs>
          <marker
            id="triangle"
            viewBox="0 0 10 10"
            refX="11"
            refY="5"
            markerUnits="strokeWidth"
            markerWidth="10"
            markerHeight="10"
            orient="auto"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" fill="#f00" />
          </marker>
        </defs>
        <line
          x1={`${startLine.x}${startLine.sx}`}
          y1={`${startLine.y}${startLine.sy}`}
          x2={`${endLine.x}${endLine.sx}`}
          y2={`${endLine.y}${endLine.sy}`}
          markerEnd="url(#triangle)"
        />
      </svg>
      {label && <label>{label}</label>}
      <div className={`actions${show ? ' show' : ''}`}>
        <Icon
          svg={Delete}
          size={15}
          transparent
          title="Eliminar"
          onClick={() => onDeletLine(id)}
        />
      </div>
    </div>
  );
};

Conector.propTypes = {
  id: PropTypes.string.isRequired,
  start: PropTypes.exact({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    tx: PropTypes.number,
    ty: PropTypes.number,
  }),
  end: PropTypes.exact({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    tx: PropTypes.number.isRequired,
    ty: PropTypes.number.isRequired,
  }),
  onDeletLine: PropTypes.func,
  label: PropTypes.string,
};

export default Conector;
