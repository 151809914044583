import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Combo from '../../commons/Combo';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postGrabarRedSocialAction,
} from '../../redux/actions/redsocial.action';

import useMaestro from '../../hooks/useMaestro';

import SaveIcon from '@material-ui/icons/Save';

const RedSocialItem = () => {
  const dispatch = useDispatch();
  const { selected, processing, errors } = useSelector((state) => state.red);

  const { maestros, processingMaestro, getMaestros } = useMaestro();

  const [red, setRed] = useState(selected);
  const handleChange = (item) => {
    const { name, value } = item;
    setRed((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    getMaestros('REDESSOCIALES');
  }, []);

  useEffect(() => {
    let social = maestros.find((x) => x.de === red.dr);
    if (social) {
      handleChange({
        name: 'rs',
        value: social.dt,
      });
    }
  }, [maestros]);

  return (
    <Modal
      title={`Grabar red social`}
      size="medium"
      processing={processing}
      onActions={() => (
        <>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            onClick={() =>
              confirmacion('¿Está seguro?', `grabar la red social`)
                .then(() => dispatch(postGrabarRedSocialAction(red)))
                .catch(() => {})
            }
          >
            Grabar
          </Boton>
        </>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Combo
        name="rs"
        value={red.rs}
        label="Red social"
        disabled={processingMaestro}
        tabIndex={10}
        size={2}
        data={maestros.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.rs}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <span>Aplica</span>
        <CheckBox
          name="ap"
          tabIndex={11}
          checked={red.ap}
          onChange={handleChange}
        />
      </div>
      <Texto
        name="lr"
        value={red.lr}
        label="Link"
        tabIndex={12}
        error={errors.lr}
        enabledPaste
        onChange={handleChange}
      />
    </Modal>
  );
};

export default RedSocialItem;
