import React from 'react';
import { useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getRedesSocialesAction,
  getSeleccionarRedSocialAction,
} from '../../redux/actions/redsocial.action';

const RedSocialFilter = () => {
  const dispatch = useDispatch();

  return (
    <Filtros
      onFilter={() => dispatch(getRedesSocialesAction({}))}
      onNew={() => dispatch(getSeleccionarRedSocialAction())}
      showFilterButton={false}
    ></Filtros>
  );
};

export default RedSocialFilter;
