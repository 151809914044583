import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getPerfilesAction,
  getSeleccionarPerfilesAction,
  actualizarFiltros,
  paginado,
} from '../../redux/actions/perfiles.action';

import EditIcon from '@material-ui/icons/Edit';

const PerfilesList = ({ perfiles, processing, total, rows }) => {
  const [buscar, setBuscar] = useState('');
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'pe',
      align: 'center',
      content: (item) => (
        <>
          <div title="Editar" style={{ display: 'inline-block' }}>
            <EditIcon
              onClick={() => dispatch(getSeleccionarPerfilesAction(item))}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'na',
      label: 'Acción',
    },
    {
      key: 'de',
      label: 'Estado contrato',
    },
    {
      key: 'ep',
      label: 'Flujo de proceso',
      content: (item) => {
        if (item.ep === 0) {
          return 'Continuar con flujo';
        } else if (item.ep === 2) {
          return 'Finalizar flujo exitoso';
        } else {
          return 'Terminar flujo';
        }
      },
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo={'Resultado de búsqueda'}>
      <Tabla
        columns={columns}
        data={perfiles}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getPerfilesAction());
        }}
        onSearching={() => {
          dispatch(actualizarFiltros({ search: buscar }));
          dispatch(getPerfilesAction({}));
        }}
        onChangeSearching={(search) => setBuscar(search)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    perfiles: state.perfiles.perfiles,
    processing: state.perfiles.processing,
    total: state.perfiles.total,
    rows: state.perfiles.rows,
  };
};

export default connect(mapStateToProps)(PerfilesList);
