import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Boton from '../../commons/Boton';
import Modal from '../../commons/Modal';
import Texto from '../../commons/Texto';

import { confirmacion } from '../../commons/Mensajes';

import {
  patchNotificacionAction,
  openModalNotificacion,
} from '../../redux/actions/app.action';

import AddAlertIcon from '@material-ui/icons/AddAlert';

const EmpresaNotificacion = () => {
  const dispatch = useDispatch();
  const { errors, processing, notificacion } = useSelector(
    (state) => state.app
  );
  const [notificaion, setNotificaion] = useState(notificacion);

  const handleChange = (item) => {
    const { name, value } = item;
    setNotificaion((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Modal
      title={'Notificar'}
      size="medium"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <AddAlertIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion('¿Está seguro?', `Enviar la notificación`)
              .then(() => dispatch(patchNotificacionAction(notificaion)))
              .catch(() => {})
          }
        >
          Notificar
        </Boton>
      )}
      onClose={() => dispatch(openModalNotificacion(false))}
    >
      <Texto
        name="ti"
        value={notificaion.ti}
        label="Titulo"
        tabIndex={41}
        error={errors.ti}
        onChange={handleChange}
      />
      <Texto
        name="no"
        value={notificaion.no}
        label="Mensaje"
        tabIndex={42}
        error={errors.no}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default EmpresaNotificacion;
