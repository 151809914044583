import { useState } from 'react';
import { api } from '../redux/axios/api';
import { servicios } from '../redux/helpers';

const useDeliverys = () => {
  const [processingDelivey, setProcessingDelivey] = useState(false);
  const [deliveries, setDeliveries] = useState([]);

  const getDeliveries = (em, fc, bi, bf, re) => {
    let params = {
      em,
      ie: em,
      fc,
      bi,
      bf,
      re,
    };
    setProcessingDelivey(true);
    api
      .get(`${servicios.PEDIDO}/delivery`, { params })
      .then((response) => {
        setDeliveries(response);
      })
      .catch(() => {
        setDeliveries([]);
      })
      .finally(() => setProcessingDelivey(false));
  };

  return {
    processingDelivey,
    deliveries,
    getDeliveries,
  };
};

export default useDeliverys;
