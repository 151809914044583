import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import Icon from '../../commons/Icon';

import MoneyUp from '../../assets/icons/money-up.svg';

import {
  getCajerosAction,
  actualizarFiltros,
  openModal,
} from '../../redux/actions/cajero.action';

const CajeroFilter = ({ sucursales }) => {
  const dispatch = useDispatch();
  const [su, setSu] = useState('');
  return (
    <Filtros
      onFilter={() => dispatch(getCajerosAction({}))}
      showNewButton={false}
      actions={() => (
        <Boton
          className="op-filter"
          icon={() => <Icon svg={MoneyUp} />}
          tabIndex={21}
          onClick={() => {
            dispatch(openModal());
          }}
        >
          Aperturar caja
        </Boton>
      )}
    >
      <Combo
        name="su"
        label="Sucursal"
        value={su}
        placeholder="Todos"
        tabIndex={20}
        size={3}
        data={sucursales.map((x) => ({ value: x.su, label: x.ns }))}
        onChange={({ value }) => {
          setSu(value);
          dispatch(actualizarFiltros({ suf: value }));
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
  };
};

export default connect(mapStateToProps)(CajeroFilter);
