import { mensaje, mensajeMini } from '../../commons/Mensajes';
import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { documentoModel } from '../models/documento.model';

export const DOCUMENTO_PROCESS = '[Documento] Documento procesando';
export const DOCUMENTO_SAVE_SUCCESS = '[Documento] Documento guardar exito';
export const DOCUMENTO_LIST_SUCCESS = '[Documento] Documento lista exito';
export const DOCUMENTO_LIST_FAIL = '[Documento] Documento lista error';
export const DOCUMENTO_LIST_DETAILS = '[Documento] Documento lista detalle';
export const DOCUMENTO_LIST_REPORT = '[Documento] Documento lista reporte';
export const DOCUMENTO_SELECTED = '[Documento] Documento seleccionado';
export const DOCUMENTO_SELECTED_VIEW = '[Documento] Documento ver seleccionado';
export const DOCUMENTO_CLOSE = '[Documento] Documento cerrar modal';
export const DOCUMENTO_FILTER = '[Documento] Documento filtros';
export const DOCUMENTO_FAIL = '[Documento] Documento proceso error';
export const DOCUMENTO_PAGINATE = '[Documento] Documento paginado';
export const DOCUMENTO_PAGINATE_DETAILs =
  '[Documento] Documento paginado detalles';
export const DOCUMENTO_STATES = '[Documento] Documento lista estados Documento';
export const DOCUMENTO_CANCEL = '[Documento] Documento anulacion';

export const getDocumentosAction = () => (dispatch, getState) => {
  let { su, suf, tm, fi, ff, search, page, rows } = getState().documento;
  let { currentUser } = getState().app;

  dispatch({
    type: DOCUMENTO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    su,
    suf,
    tm,
    fi,
    ff,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.DOCUMENTO, { params })
    .then((response) => {
      dispatch({
        type: DOCUMENTO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: DOCUMENTO_LIST_FAIL,
      });
    });
};

export const getTopVentasAction = (su, tm, fi, ff) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: DOCUMENTO_PROCESS,
  });

  const params = {
    su,
    tm,
    fi,
    ff,
  };

  api
    .get(`${servicios.DOCUMENTO}/${currentUser.ie}/reporte`, { params })
    .then((response) => {
      dispatch({
        type: DOCUMENTO_LIST_REPORT,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: DOCUMENTO_LIST_REPORT,
        payload: [],
      });
    });
};

export const getDocumentosDetalleAction = () => (dispatch, getState) => {
  let { selected, pageDetalle, rows } = getState().documento;
  let { currentUser } = getState().app;

  dispatch({
    type: DOCUMENTO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    su: selected.is,
    co: selected.dc,
    page: pageDetalle,
    pageSize: rows,
  };

  api
    .get(`${servicios.DOCUMENTO}/detalles`, { params })
    .then((response) => {
      dispatch({
        type: DOCUMENTO_LIST_DETAILS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: DOCUMENTO_LIST_DETAILS,
        payload: { data: [], count: 0 },
      });
    });
};

export const patchAnularDocumentoAction = (dc, ma) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: DOCUMENTO_PROCESS,
  });

  let documento = {
    ie: currentUser.ie,
    us: currentUser.us,
    dc,
    ma,
  };

  api
    .patch(`${servicios.DOCUMENTO}/anular`, documento)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getDocumentosAction());
      dispatch({ type: DOCUMENTO_SAVE_SUCCESS });
    })
    .catch((error) => {
      if (error.data.errors) {
        mensajeMini(Object.values(error.data.errors)[0]);
      }
    });
};

export const getSeleccionarDocumentoAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: DOCUMENTO_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: DOCUMENTO_SELECTED,
      payload: documentoModel,
    });
  }
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, suf, tm, fi, ff, search } = getState().documento;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    suf:
      filtros.suf !== undefined
        ? filtros.suf === ''
          ? null
          : filtros.suf
        : suf,
    tm: filtros.tm !== undefined ? (filtros.tm === '' ? null : filtros.tm) : tm,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: DOCUMENTO_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: DOCUMENTO_PAGINATE,
    payload: page,
  });
};

export const paginadoDetalle = (page) => (dispatch) => {
  dispatch({
    type: DOCUMENTO_PAGINATE_DETAILs,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: DOCUMENTO_CLOSE,
  });
};
