import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import { confirmacion } from '../../commons/Mensajes';
import { formatoMonetario, redondeoDecimales } from '../../commons/Utilitarios';

import {
  closeModal,
  postCobranzaAction,
} from '../../redux/actions/cobranza.action';

import SaveIcon from '@material-ui/icons/Save';

const CobranzaDebt = () => {
  const [cobranza, setCobranza] = useState({ dc: null, mc: null });

  const dispatch = useDispatch();

  const { processing, errors } = useSelector((state) => state.cobranza);

  const handleChange = (item) => {
    const { name, value } = item;
    setCobranza((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`Nueva deuda en cobranza`}
      processing={processing}
      size={'small'}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion('¿Desea guardar?', `Una nueva deuda en cobranza`)
              .then(() => dispatch(postCobranzaAction(cobranza)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Texto
        name="dc"
        value={cobranza.dc}
        label="Descripci&oacute;n"
        tabIndex={10}
        error={errors.dc}
        onChange={handleChange}
        autoFocus
      />
      <Texto
        name="mc"
        value={cobranza.mc}
        label="Monto deuda"
        tabIndex={11}
        restrict={'money'}
        error={errors.mc}
        onChange={handleChange}
        onBlur={(e) => {
          handleChange({
            name: 'mc',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
    </Modal>
  );
};

export default CobranzaDebt;
