import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import VentaList from './VentaList';
import VentaFilter from './VentaFilter';

import { getDocumentosAction } from '../../redux/actions/documento.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

const Venta = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDocumentosAction());
    dispatch(getSucursalesAction({}));
  }, []);

  return (
    <div className="op-component op-view-venta">
      <VentaList />
      <VentaFilter />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(Venta);
