import * as fromChica from '../actions/chica.action';
import { chicaModel } from '../models/chica.model';

let initialState = {
  chicas: [],
  su: null,
  suf: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromChica.CHICA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: chicaModel,
      };
    case fromChica.CHICA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        chicas: action.payload.data,
        total: action.payload.count,
      };
    case fromChica.CHICA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        chicas: [],
        total: 0,
      };
    case fromChica.CHICA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: chicaModel,
        processing: false,
      };
    case fromChica.CHICA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: chicaModel,
        openModal: false,
      };
    case fromChica.CHICA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromChica.CHICA_FILTER:
      return {
        ...state,
        su: action.payload.su,
        suf: action.payload.suf,
      };
    case fromChica.CHICA_OPEN:
      return {
        ...state,
        openModal: true,
        errors: chicaModel,
      };
    case fromChica.CHICA_CLOSE:
      return {
        ...state,
        openModal: false,
        errors: chicaModel,
      };
    default:
      return state;
  }
}
