import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';

import {
  getPedidosAction,
  actualizarFiltros,
  getSeleccionarPedidoAction,
  paginado,
  restartNotificacion,
} from '../../redux/actions/pedido.action';

import EditIcon from '@material-ui/icons/Edit';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

const PedidoList = ({ pedidos, total, processing, rows, notificaciones }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'pd',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => dispatch(getSeleccionarPedidoAction(item))}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'np',
      label: 'Pedido',
      content: (item) => handleColor(item.me, item.np),
    },
    {
      key: 'ns',
      label: 'Sucursal',
      content: (item) => handleColor(item.me, item.ns),
    },
    {
      key: 'nc',
      label: 'Cliente',
      content: (item) => handleColor(item.me, item.nc),
    },
    {
      key: 'de',
      label: 'Estado del pedido',
      content: (item) => handleColor(item.me, item.de),
    },
    {
      key: 'fg',
      label: 'Fecha de pago',
      content: (item) =>
        handleColor(item.me, item.me === 'CANPED' ? 'Cancelado' : item.fg),
    },
    {
      key: 'fp',
      label: 'Fecha pedido',
      align: 'right',
    },
    {
      key: 'fe',
      label: 'Fecha entrega',
      align: 'right',
    },
  ];

  const handleColor = (estado, texto) => {
    let color = '#000000';
    switch (estado) {
      case 'CANPED':
        color = 'red';
        break;
      case 'LISPED':
        color = 'orange';
        break;
      case 'NUEPED':
        color = '#0d6efd';
        break;
      case 'DELPED':
        color = '#198754';
        break;
      default:
        color = '#1ab394';
        break;
    }

    return <span style={{ color: color }}>{texto}</span>;
  };
  return (
    <Bloque
      titulo="Resultado de b&uacute;squeda"
      onActions={() => (
        <div className="op-notificacion">
          <NotificationsActiveIcon
            style={{ color: '#1ab394' }}
            onClick={() => {
              dispatch(restartNotificacion());
              dispatch(getPedidosAction());
            }}
          />
          <span>{notificaciones}</span>
        </div>
      )}
    >
      <Tabla
        columns={columns}
        data={pedidos}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getPedidosAction());
        }}
        onSearching={() => {
          dispatch(actualizarFiltros({ search: buscar }));
          dispatch(getPedidosAction());
          dispatch(restartNotificacion());
        }}
        onChangeSearching={(search) => setBuscar(search)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    pedidos: state.pedido.pedidos,
    total: state.pedido.total,
    processing: state.pedido.processing,
    rows: state.pedido.rows,
    notificaciones: state.pedido.notificaciones,
  };
};

export default connect(mapStateToProps)(PedidoList);
