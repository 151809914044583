import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { clienteModel } from '../models/cliente.model';

export const CLIENTE_PROCESS = '[Cliente] cliente procesando';
export const CLIENTE_SAVE_SUCCESS = '[Cliente] cliente guardar exito';
export const CLIENTE_LIST_SUCCESS = '[Cliente] cliente lista exito';
export const CLIENTE_LIST_FAIL = '[Cliente] cliente lista error';
export const CLIENTE_SELECTED = '[Cliente] cliente seleccionado';
export const CLIENTE_CLOSE = '[Cliente] cliente cerrar modal';
export const CLIENTE_FILTER = '[Cliente] cliente filtros';
export const CLIENTE_FAIL = '[Cliente] cliente proceso error';

export const getClientesAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { st, search } = getState().cliente;
    let { currentUser } = getState().app;

    dispatch({
      type: CLIENTE_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.CLIENTE, { params })
      .then((response) => {
        dispatch({
          type: CLIENTE_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CLIENTE_LIST_FAIL,
        });
      });
  };

export const getSeleccionarClienteAction = (item) => (dispatch, getState) => {
  if (item) {
    let { currentUser } = getState().app;

    dispatch({
      type: CLIENTE_PROCESS,
    });

    api
      .get(`${servicios.CLIENTE}/${item.cl}/empresa/${currentUser.ie}`)
      .then((response) => {
        dispatch({
          type: CLIENTE_SELECTED,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CLIENTE_SELECTED,
          payload: clienteModel,
        });
      });
  } else {
    dispatch({
      type: CLIENTE_SELECTED,
      payload: clienteModel,
    });
  }
};

export const postClienteAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CLIENTE_PROCESS,
  });

  let cliente = {
    ...item,
    ie: currentUser.ie,
  };

  api
    .post(servicios.CLIENTE, cliente)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getClientesAction({}));
      dispatch({ type: CLIENTE_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CLIENTE_FAIL,
        payload: error.data.errors ? error.data.errors : clienteModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { st, search } = getState().cliente;

  let item = {
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: CLIENTE_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CLIENTE_CLOSE,
  });
};
