import React from 'react';
import PropTypes from 'prop-types';

import '../css/commons/radiobutton.css';

const RadioButton = ({ name, data, onChange, tabIndex }) => {
  const elementos = data.map((x) => (
    <React.Fragment key={x.name}>
      <div className="element">
        {x.icon && <div className="icon">{x.icon()}</div>}
        <input
          className="op-checkbox-tools"
          type="radio"
          name={name}
          id={x.name}
          defaultChecked={x.checked}
          onChange={(e) => onChange({ name: name, value: x.name })}
        />
        <label htmlFor={x.name}>{x.label}</label>
      </div>
    </React.Fragment>
  ));

  return <div className={`op-content-radiobutton`}>{elementos}</div>;
};

RadioButton.propTypes = {
  name: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired,
      checked: PropTypes.bool,
      icon: PropTypes.func,
    })
  ).isRequired,
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default RadioButton;
