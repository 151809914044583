import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PolarArea } from 'react-chartjs-2';
import CountUp from 'react-countup';

import Texto from '../../commons/Texto';
import {
  obtenerColorAleatorio,
  formatearFecha,
} from '../../commons/Utilitarios';

import { getTopVentasAction } from '../../redux/actions/documento.action';

const TopVentas = () => {
  const [inicio, setInicio] = useState(`${new Date().getFullYear()}-01-01`);
  const [fin, setFin] = useState(formatearFecha(new Date(), 'YYYY-MM-DD'));
  const [sucursal, setSucursal] = useState('');
  const [tipo, setTipo] = useState(0);
  const dispatch = useDispatch();
  const { topVentas } = useSelector((state) => state.documento);

  useEffect(() => {
    dispatch(getTopVentasAction(sucursal, tipo, inicio, fin));
  }, []);

  const handleGetTopVentasReport = () => {
    let topData = {
      labels: topVentas.map((x) => x.it),
      datasets: [
        {
          label: 'Top ventas',
          data: topVentas.map((x) => x.to),
          backgroundColor: topVentas.map(() => obtenerColorAleatorio()),
        },
      ],
      hoverOffset: 4,
    };
    return (
      <PolarArea
        data={topData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          },
          chartArea: {
            backgroundColor: 'rgba(251, 85, 85, 0.4)',
          },
        }}
      />
    );
  };
  return (
    <div className="grid-item col-2 action top">
      <label className="title">Top 10 m&aacute;s vendidos</label>
      <div className="actions">
        <Texto
          name="fi"
          value={inicio}
          label=""
          tabIndex={23}
          size={3}
          type={'date'}
          onChange={({ value }) => {
            setInicio(value);
            dispatch(getTopVentasAction(sucursal, tipo, value, fin));
          }}
        />
        <Texto
          name="ff"
          value={fin}
          label=""
          tabIndex={23}
          size={3}
          type={'date'}
          onChange={({ value }) => {
            setFin(value);
            dispatch(getTopVentasAction(sucursal, tipo, inicio, value));
          }}
        />
        <span
          className={`tipo ${tipo === 0 ? 'activo' : ''}`}
          onClick={() => {
            setTipo(0);
            dispatch(getTopVentasAction(sucursal, 0, inicio, fin));
          }}
        >
          Soles
        </span>
        <span
          className={`tipo ${tipo === 1 ? 'activo' : ''}`}
          onClick={() => {
            setTipo(1);
            dispatch(getTopVentasAction(sucursal, 1, inicio, fin));
          }}
        >
          Dolares
        </span>
      </div>
      <div className="actual">
        <CountUp
          prefix={`${tipo === 0 ? 'S/' : '$'}`}
          end={topVentas.reduce((a, b) => a + b.to, 0)}
          decimals={2}
          duration={2}
        >
          {({ countUpRef }) => <span ref={countUpRef} />}
        </CountUp>
        <span>*No incluye descuentos globales</span>
      </div>
      {handleGetTopVentasReport()}
    </div>
  );
};

export default TopVentas;
