import { api } from '../axios/api';
import { constantes, servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { perfilesModal } from '../models/perfiles.model';

export const PERFILES_PROCESS = '[Perfiles] Perfiles procesando';
export const PERFILES_SAVE_SUCCESS = '[Perfiles] Perfiles guardar exito';
export const PERFILES_LIST_SUCCESS = '[Perfiles] Perfiles lista exito';
export const PERFILES_LIST_FAIL = '[Perfiles] Perfiles lista error';
export const PERFILES_SELECTED = '[Perfiles] Perfiles seleccionado';
export const PERFILES_SELECTED_EDIT = '[Perfiles] Perfiles editar seleccionado';
export const PERFILES_CLOSE = '[Perfiles] Perfiles cerrar modal';
export const PERFILES_FILTER = '[Perfiles] Perfiles filtros';
export const PERFILES_FAIL = '[Perfiles] Perfiles proceso error';
export const PERFILES_PAGINATE = '[Perfiles] Perfiles paginado';
export const PERFILES_STATES = '[Perfiles] Perfiles lista estados contrato';

export const getPerfilesAction = () => (dispatch, getState) => {
  let { search, page, rows } = getState().perfiles;
  let { currentUser } = getState().app;

  dispatch({
    type: PERFILES_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.PERFILES, { params })
    .then((response) => {
      dispatch({
        type: PERFILES_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: PERFILES_LIST_FAIL,
      });
    });
};

export const getSeleccionarPerfilesAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  if (item) {
    api
      .get(
        `${servicios.PERFILES}/${currentUser.ie}/empresa?pe=${item.pe}&ap=${constantes.APLICACION}`
      )
      .then((response) => {
        dispatch({
          type: PERFILES_SELECTED_EDIT,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: PERFILES_SELECTED,
          payload: perfilesModal,
        });
      });
  } else {
    dispatch({
      type: PERFILES_SELECTED,
      payload: perfilesModal,
    });
  }
};

export const postPerfilesAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: PERFILES_PROCESS,
  });

  let perfil = {
    pe: item.pe,
    ie: currentUser.ie,
    pfs: item.pfs,
    ce: item.ce,
    na: item.na,
    ep: item.ep,
    st: item.st,
  };

  api
    .post(servicios.PERFILES, perfil)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(paginado(0));
      dispatch(getPerfilesAction({}));
      dispatch({ type: PERFILES_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: PERFILES_FAIL,
        payload: error.data.errors ? error.data.errors : perfilesModal,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { search } = getState().perfiles;

  let item = {
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: PERFILES_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: PERFILES_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: PERFILES_CLOSE,
  });
};
