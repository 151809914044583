import React from 'react';
import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import Tabla from '../../commons/Tabla';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Adjunto from '../../commons/Adjunto';
import { confirmacion } from '../../commons/Mensajes';
import { formatoFecha } from '../../commons/Utilitarios';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import {
  closeModal,
  patchAdjuntarArchivoGuiaAction,
  patchAprobarGuiaAction,
  patchRechazarGuiaAction,
  getSeleccionarGuiaAction,
} from '../../redux/actions/guia.action';

import '../../css/components/guia.css';

const GuiaView = ({ selected, processing }) => {
  const dispatch = useDispatch();

  const columnsPreview = [
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Bien',
    },
    {
      key: 'um',
      label: 'Unidad medida',
    },
    {
      key: 'un',
      align: 'center',
      label: 'Cantidad',
    },
    {
      key: 'pb',
      align: 'center',
      label: 'Peso',
    },
  ];

  return (
    <>
      <Modal
        title={`Guía - ${selected.nd}`}
        processing={processing}
        onActions={() =>
          selected.gui.vm === 'REGGUI' && (
            <>
              <Boton
                className="op-dinamic"
                disabled={processing}
                icon={() => <DeleteForeverIcon />}
                tabIndex={19}
                style={{ '--button-color': 'red' }}
                onClick={() =>
                  Swal.fire({
                    input: 'textarea',
                    inputLabel: 'Motivo de rechazo',
                    inputPlaceholder: 'Ingrese el motivo de rechazo...',
                    inputAttributes: {
                      'aria-label': 'Ingrese el motivo de rechazo',
                      style: { resize: 'none' },
                    },
                    showCancelButton: true,
                    confirmButtonColor: '#1ab394',
                    confirmButtonText: 'Rechazar',
                    cancelButtonColor: 'red',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.value) {
                      confirmacion(
                        '¿Está seguro?',
                        `De rechazar la guía ${selected.nd}`
                      )
                        .then(() =>
                          dispatch(
                            patchRechazarGuiaAction(selected, result.value)
                          )
                        )
                        .catch(() => {});
                    }
                  })
                }
              >
                Rechazar gu&iacute;a
              </Boton>
              <Boton
                className="op-dinamic"
                disabled={processing}
                icon={() => <SaveIcon />}
                tabIndex={19}
                style={{ '--button-color': '#3285a8' }}
                onClick={() =>
                  confirmacion(
                    '¿Está seguro de aprobar la guía?',
                    'Ya no podra revertir los cambios'
                  )
                    .then(() => dispatch(patchAprobarGuiaAction(selected)))
                    .catch(() => {})
                }
              >
                Aprobar gu&iacute;a
              </Boton>
              <Boton
                className="op-grabar"
                disabled={processing}
                icon={() => <EditIcon />}
                tabIndex={21}
                onClick={() =>
                  dispatch(getSeleccionarGuiaAction(selected, true))
                }
              >
                Rectificar gu&iacute;a
              </Boton>
            </>
          )
        }
        onClose={() =>
          confirmacion(
            '¿Deseas cerrar?',
            'La información ingresada se perdera al cerrar la ventana'
          )
            .then(() => {
              dispatch(closeModal());
            })
            .catch(() => {})
        }
      >
        <div className="op-content-preliminar">
          <div className="grupo">
            <label>Datos del documento</label>
            <span>Sucursal creaci&oacute;n</span>
            <span>{selected.ns}</span>
            <span>Domicilio fiscal</span>
            <span>{selected.gui.dr}</span>
            <span>Usuario emitio</span>
            <span>{selected.ue}</span>
            <span>Fecha de emisi&oacute;n</span>
            <span>{formatoFecha(selected.fe)}</span>
            <span>Estado documento</span>
            <span>{selected.de}</span>
            <span>Estado de la gu&iacute;a</span>
            <span>{selected.gui.de}</span>
            {selected.gui.fa && (
              <>
                <span>Usuario de aprobaci&oacute;n</span>
                <span>{selected.gui.ua}</span>
                <span>Fecha de aprobaci&oacute;n</span>
                <span>{formatoFecha(selected.gui.fa)}</span>
              </>
            )}
            {selected.gui.fn && (
              <>
                <span>Motivo de rechazo</span>
                <span>{selected.gui.ma}</span>
                <span>Usuario de rechazo</span>
                <span>{selected.gui.un}</span>
                <span>Fecha de rechazo</span>
                <span>{formatoFecha(selected.gui.fn)}</span>
              </>
            )}
          </div>
          <div className="grupo">
            <label>Datos del inicio traslado</label>
            <span>Fecha y hora de entrega de bienes del transportista</span>
            <span>{formatoFecha(selected.gui.fe)}</span>
            <span>Sucursal de partida</span>
            <span>{selected.gui.pp}</span>
            <span>Ubigueo de partida</span>
            <span>{selected.gui.ups}</span>
            <span>Direcci&oacute;n de punto de partida</span>
            <span>{selected.gui.ip}</span>
            <span>Motivo del traslado</span>
            <span>{selected.gui.dm}</span>
            <span>Modalidad de traslado</span>
            <span>{`${selected.gui.tt === '1' ? 'Público' : 'Privado'}`}</span>
          </div>
          <div className="grupo">
            <label>Datos del transportista</label>
            {selected.gui.tt === 1 ? (
              <>
                <span>
                  Apellidos y nombres, denominaci&oacute;n o raz&oacute;n social
                </span>
                <span>{selected.gui.tr}</span>
                <span>{`Número de ${selected.gui.ts}`}</span>
                <span>{selected.gui.tu}</span>
              </>
            ) : (
              <>
                <span>Nombre del conductor</span>
                <span>{selected.gui.nc ? selected.gui.nc : ''}</span>
                <span>Licencia del conductor</span>
                <span>{selected.gui.lc ? selected.gui.lc : ''}</span>
                <span>Marca y placa del veh&iacute;culo</span>
                <span>{selected.gui.vh ? selected.gui.vh : ''}</span>
                <span>Constancia del veh&iacute;culo</span>
                <span>{selected.gui.ch ? selected.gui.ch : ''}</span>
              </>
            )}
          </div>
          <div className="grupo">
            <label>Datos del punto de destino</label>
            {selected.gui.ll && (
              <>
                <span>Sucursal de destino</span>
                <span>{selected.gui.ll}</span>
              </>
            )}
            <span>Ubigueo de llegada</span>
            <span>{selected.gui.uls}</span>
            <span>Direcci&oacute;n de punto de llegada</span>
            <span>{selected.gui.il}</span>
          </div>
          <div className="grupo">
            <label>Datos del destinatario</label>
            <span>
              Apellidos y nombres, denominaci&oacute;n o raz&oacute;n social
            </span>
            <span>{selected.gui.dd ? selected.gui.dd : ''}</span>
            <span>Documento de identidad</span>
            <span>{`${selected.gui.te} ${selected.gui.nt}`}</span>
          </div>
          <div className="grupo">
            <label>Datos de los bienes</label>
            <Tabla
              columns={columnsPreview}
              data={selected.gui.dtl}
              pagination={false}
              searcheable={false}
              hoverSelectedRow={false}
              style={{ gridColumn: '1 / 3', padding: '20px 0' }}
            />
            <span>Observaciones</span>
            <span>{selected.gui.oe ? selected.gui.oe : ''}</span>
          </div>
          <div className="grupo">
            <Adjunto
              stateParent={selected.vm === 'REGDOC'}
              nameParent={'guia'}
              processing={processing}
              data={
                selected.gui.adj &&
                selected.gui.adj.dtl &&
                selected.gui.adj.dtl.map((a) => ({
                  id: a.cr,
                  name: a.na,
                  extension: a.ea,
                  state: a.st,
                }))
              }
              file={selected.gui.adj && selected.gui.adj.ar}
              onNew={(documento) =>
                dispatch(patchAdjuntarArchivoGuiaAction(selected, documento))
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.guia.selected,
    processing: state.guia.processing,
  };
};

export default connect(mapStateToProps)(GuiaView);
