import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import { formatoFecha } from '../../commons/Utilitarios';

import {
  getOrdenesCompraAction,
  getSeleccionarOrdenCompraAction,
  actualizarFiltros,
} from '../../redux/actions/ordencompra.action';

import VisibilityIcon from '@material-ui/icons/Visibility';

const OrdenCompraList = ({
  ordenes,
  processing,
  total,
  getOrdenesCompraAction,
  getSeleccionarOrdenCompraAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'oc',
      align: 'center',
      content: (item) => (
        <div title="Ver" style={{ display: 'inline-block' }}>
          <VisibilityIcon
            onClick={() => getSeleccionarOrdenCompraAction(item)}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'nu',
      label: 'Usuario',
      content: (item) => handleColor(item.vm, item.nu),
    },
    {
      key: 'co',
      align: 'right',
      label: 'Código',
      content: (item) => handleColor(item.vm, item.co),
    },
    {
      key: 'fr',
      align: 'right',
      label: 'Fecha registro',
      content: (item) => handleColor(item.vm, formatoFecha(item.fr)),
    },
    {
      key: 'de',
      label: 'Estado',
      content: (item) => handleColor(item.vm, item.de),
    },
    {
      key: 'fe',
      label: 'Fecha entrega',
      align: 'right',
      content: (item) =>
        handleColor(item.vm, formatoFecha(item.fe, 'Sin confirmar')),
    },
  ];

  const handleColor = (estado, texto) => {
    let color = '#000000';
    switch (estado) {
      case 'ANUORD':
        color = 'red';
        break;
      case 'RECHORD':
        color = 'orange';
        break;
      case 'APRORD':
        color = '#34baeb';
        break;
      case 'CONORD':
        color = '#0d6efd';
        break;
      case 'ENTORD':
        color = '#198754';
        break;
      case 'PAGAORD':
        color = '#1ab394';
        break;
      default:
        break;
    }

    return <span style={{ color: color }}>{texto}</span>;
  };

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={ordenes}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getOrdenesCompraAction({ page, pageSize: rows })
        }
        onSearching={() => getOrdenesCompraAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    ordenes: state.ordenCompra.ordenes,
    processing: state.ordenCompra.processing,
    total: state.ordenCompra.total,
  };
};

export default connect(mapStateToProps, {
  getOrdenesCompraAction,
  getSeleccionarOrdenCompraAction,
  actualizarFiltros,
})(OrdenCompraList);
