export const contratoModel = {
  co: null,
  ot: null,
  su: null,
  ns: null,
  us: null,
  nu: null,
  ua: null,
  an: null,
  fv: null,
  fa: null,
  cc: null,
  nc: null,
  dc: null,
  cs: null,
  ss: null,
  ec: null,
  de: null,
  or: null,
  ct: null,
  nt: null,
  ci: null,
  fi: null,
  ff: null,
  fr: null,
  cu: null,
  si: null,
  ic: null,
  do: null,
  st: false,
  pd: null,
  mp: null,
  dm: null,
  gc: true,
  al: null,
  se: null,
  fn: null,
  un: null,
  ma: null,
};
