import * as fromRed from '../actions/redsocial.action';
import { redSocialModel } from '../models/redsocial.model';

let initialState = {
  redes: [],
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromRed.REDSOCIAL_PROCESS:
      return {
        ...state,
        processing: true,
      };
    case fromRed.REDSOCIAL_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        redes: action.payload,
      };
    case fromRed.REDSOCIAL_LIST_FAIL:
      return {
        ...state,
        processing: false,
        redes: [],
      };
    case fromRed.REDSOCIAL_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: redSocialModel,
        openModal: true,
      };
    case fromRed.REDSOCIAL_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: redSocialModel,
        openModal: false,
      };
    case fromRed.REDSOCIAL_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromRed.REDSOCIAL_CLOSE:
      return {
        ...state,
        openModal: false,
      };
    default:
      return state;
  }
}
