import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';

import { api } from '../../redux/axios/api';

import Loading from '../../commons/Loading';

import '../../css/commons/modal.css';

const Report = ({
  url,
  params,
  onClose,
  downloadable,
  filename,
  extension,
  showInline,
}) => {
  const [processing, setProcessing] = useState(true);
  const [urlReport, setUrlReport] = useState('');
  const modalRef = useRef(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    api
      .get(`${url}`, { responseType: 'blob', params })
      .then((response) => {
        setProcessing(false);
        let fileUrl = window.URL.createObjectURL(new Blob([response]));
        setUrlReport(fileUrl);
      })
      .catch((error) => {
        console.log(error);
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: error.status === 500 ? 'error' : 'warning',
          title:
            error.status === 500
              ? 'Error al generar reporte'
              : 'No hay registros',
        });
        onClose();
      });
    if (showInline === undefined) {
      modalRef.current.focus();
    }
  }, []);

  const hanldeType = () => {
    if (extension === 'pdf' && urlReport) {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer fileUrl={urlReport} plugins={[defaultLayoutPluginInstance]} />
        </Worker>
      );
    } else if (['png', 'jpg', 'jpeg'].includes(extension) && urlReport) {
      return (
        <img
          src={urlReport}
          alt="Imagen"
          style={{ width: '100%', objectFit: 'cover' }}
        />
      );
    } else if (
      ['doc', 'docx', 'xls', 'xlsx'].includes(extension) &&
      urlReport
    ) {
      return (
        <a
          href={urlReport}
          download={`${filename}.${extension}`}
          onClick={() => onClose()}
        >
          {`Descargar ${filename}.${extension}`}
        </a>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {showInline ? (
        hanldeType()
      ) : (
        <div className="op-content-modal" ref={modalRef}>
          <div
            className={`op-modal ${
              ['doc', 'docx', 'xls', 'xlsx'].includes(extension)
                ? 'op-small'
                : 'op-medium'
            } content-imagen`}
          >
            <div className="op-modal-header">
              <div className="op-modal-close" onClick={() => onClose()}></div>
            </div>
            <div className="op-modal-body">{hanldeType()}</div>
            {processing && <Loading tipo="images" />}
          </div>
        </div>
      )}
    </>
  );
};

Report.propTypes = {
  url: PropTypes.string.isRequired,
  params: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  downloadable: PropTypes.bool,
  filename: PropTypes.string,
  extension: PropTypes.string,
  showInline: PropTypes.bool,
};

Report.defaultProps = {
  downloadable: false,
  filename: 'reporte',
  extension: 'xlsx',
};

export default Report;
