import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Combo from '../../commons/Combo';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postPlantillaAction,
} from '../../redux/actions/plantilla.action';

import SaveIcon from '@material-ui/icons/Save';

const PlantillaItem = ({
  selected,
  processing,
  errors,
  tipoDatos,
  estados,
}) => {
  const [plantilla, setPlantilla] = useState(selected);
  const [lista, setLista] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let tipo = tipoDatos.find((x) => x.de === selected.nt);
    if (tipo) {
      handleChange({ name: 'td', value: tipo.dt });
    }
    let esta = estados.find((x) => x.na === selected.de);
    if (esta) {
      handleChange({ name: 'ec', value: esta.ad });
    }
    if (selected.nt === 'Lista') {
      setLista(true);
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setPlantilla((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${plantilla.pl ? 'Actualizar' : 'Nueva'} plantilla`}
      processing={processing}
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={16}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${
                plantilla.pl ? 'La actualización de la' : 'Una nueva'
              } plantilla`
            )
              .then(() => dispatch(postPlantillaAction(plantilla)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Texto
        name="nd"
        value={plantilla.nd}
        label="Nombre"
        tabIndex={10}
        size={2}
        error={errors.nd}
        onChange={handleChange}
        autoFocus
      />
      <Combo
        name="td"
        value={plantilla.td}
        label="Tipo de dato"
        tabIndex={11}
        size={2}
        data={tipoDatos.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.td}
        onChange={(item) => {
          handleChange(item);
          setLista(item.label === 'Lista');
        }}
      />
      {lista && (
        <Combo
          name="va"
          value={plantilla.va}
          label="Tipo de lista"
          size={2}
          tabIndex={21}
          data={[
            { value: '01', label: 'Usuarios' },
            { value: '02', label: 'Elementos adicionales' },
          ]}
          onChange={handleChange}
        />
      )}
      <Combo
        name="ec"
        value={plantilla.ec}
        label="Estado contrato"
        tabIndex={13}
        size={2}
        data={estados.map((i) => ({ value: i.ad, label: i.na }))}
        error={errors.ec}
        onChange={handleChange}
        info="Este elemento se mostrara cuando el estado del contrato seleccionado este activo"
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <span>Estado del registro</span>
        <CheckBox
          name="st"
          tabIndex={14}
          checked={plantilla.st}
          onChange={handleChange}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.plantilla.selected,
    processing: state.plantilla.processing,
    errors: state.plantilla.errors,
    tipoDatos: state.detalle.tipoDatos,
    estados: state.adicional.estados,
  };
};

export default connect(mapStateToProps)(PlantillaItem);
