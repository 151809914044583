import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { conductorModel } from '../models/conductor.model';

export const CONDUCTOR_PROCESS = '[Conductor] Conductor procesando';
export const CONDUCTOR_SAVE_SUCCESS = '[Conductor] Conductor guardar exito';
export const CONDUCTOR_LIST_SUCCESS = '[Conductor] Conductor lista exito';
export const CONDUCTOR_LIST_FAIL = '[Conductor] Conductor lista error';
export const CONDUCTOR_SELECTED = '[Conductor] Conductor seleccionado';
export const CONDUCTOR_CLOSE = '[Conductor] Conductor cerrar modal';
export const CONDUCTOR_FILTER = '[Conductor] Conductor filtros';
export const CONDUCTOR_PAGINATE = '[Conductor] Conductor paginado';
export const CONDUCTOR_FAIL = '[Conductor] Conductor proceso error';

export const getConductoresAction = () => (dispatch, getState) => {
  let { st, search, page, rows } = getState().conductor;
  let { currentUser } = getState().app;

  dispatch({
    type: CONDUCTOR_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    st,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.CONDUCTOR, { params })
    .then((response) => {
      dispatch({
        type: CONDUCTOR_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CONDUCTOR_LIST_FAIL,
      });
    });
};

export const getSeleccionarConductorAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: CONDUCTOR_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: CONDUCTOR_SELECTED,
      payload: conductorModel,
    });
  }
};

export const postConductorAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CONDUCTOR_PROCESS,
  });

  let conductor = {
    ...item,
    ie: currentUser.ie,
  };

  api
    .post(servicios.CONDUCTOR, conductor)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getConductoresAction());
      dispatch({ type: CONDUCTOR_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CONDUCTOR_FAIL,
        payload: error.data.errors ? error.data.errors : conductorModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { st, search } = getState().conductor;

  let item = {
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: CONDUCTOR_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: CONDUCTOR_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CONDUCTOR_CLOSE,
  });
};
