import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { chicaModel } from '../models/chica.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const CHICA_PROCESS = '[Chica] Chica procesando';
export const CHICA_SAVE_SUCCESS = '[Chica] Chica guardar exito';
export const CHICA_LIST_SUCCESS = '[Chica] Chica lista exito';
export const CHICA_LIST_FAIL = '[Chica] Chica lista error';
export const CHICA_SELECTED = '[Chica] Chica seleccionado';
export const CHICA_CLOSE = '[Chica] Chica cerrar modal';
export const CHICA_OPEN = '[Chica] Chica open modal';
export const CHICA_FILTER = '[Chica] Chica filtros';
export const CHICA_FAIL = '[Chica] Chica proceso error';

export const getSeleccionarCajaChicaAction =
  (su, ch) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: CHICA_PROCESS,
    });

    api
      .get(`${servicios.CHICA}/${ch}/empresa/${currentUser.ie}/sucursal/${su}`)
      .then((response) => {
        dispatch({
          type: CHICA_SELECTED,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CHICA_SELECTED,
          payload: chicaModel,
        });
      });
  };

export const getCajaChicasAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { su, suf } = getState().chica;
    let { currentUser } = getState().app;

    dispatch({
      type: CHICA_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      su,
      suf,
      page,
      pageSize,
    };

    api
      .get(servicios.CHICA, { params })
      .then((response) => {
        dispatch({
          type: CHICA_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CHICA_LIST_FAIL,
        });
      });
  };

export const putAperturaCajaChicaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CHICA_PROCESS,
  });

  let apertura = {
    ie: currentUser.ie,
    is: item.is,
    sn: quitarFormatoMonetario(item.sn),
    se: quitarFormatoMonetario(item.se),
    uc: currentUser.us,
    ur: item.ur,
  };

  api
    .put(`${servicios.CHICA}/apertura`, apertura)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCajaChicasAction({}));
      dispatch({ type: CHICA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CHICA_FAIL,
        payload: error.data.errors ? error.data.errors : chicaModel,
      });
    });
};

export const putCierreCajaChicaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CHICA_PROCESS,
  });

  let cierre = {
    ie: currentUser.ie,
    is: item.is,
    ch: item.ch,
    ur: currentUser.us,
  };

  api
    .put(`${servicios.CHICA}/cierre`, cierre)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCajaChicasAction({}));
      dispatch({ type: CHICA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CHICA_FAIL,
        payload: error.data.errors ? error.data.errors : chicaModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, suf } = getState().chica;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    suf:
      filtros.suf !== undefined
        ? filtros.suf === ''
          ? null
          : filtros.suf
        : suf,
  };

  dispatch({
    type: CHICA_FILTER,
    payload: item,
  });
};

export const openModal = () => (dispatch) => {
  dispatch({
    type: CHICA_OPEN,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CHICA_CLOSE,
  });
};
