export const categoriaModel = {
  ct: null,
  cte: {
    ct: null,
    nc: null,
    ca: null,
    na: null,
    co: null,
    st: false,
  },
  ie: null,
  ai: false,
};
