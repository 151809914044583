import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getPlantillasAction,
  getSeleccionarPlantillaAction,
  actualizarFiltros,
  paginado,
} from '../../redux/actions/plantilla.action';

import EditIcon from '@material-ui/icons/Edit';
import ListAltIcon from '@material-ui/icons/ListAlt';

const PlantillaList = ({ plantillas, processing, total, rows }) => {
  const [buscar, setBuscar] = useState('');
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'pl',
      align: 'center',
      content: (item) => (
        <>
          <div title="Editar" style={{ display: 'inline-block' }}>
            <EditIcon
              onClick={() => dispatch(getSeleccionarPlantillaAction(item))}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'nd',
      label: 'Nombre',
    },
    {
      key: 'nt',
      label: 'Tipo dato',
    },
    {
      key: 'de',
      label: 'Estado de contrato',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo={`Resultado de búsqueda`}>
      <Tabla
        columns={columns}
        data={plantillas}
        processing={processing}
        total={total}
        rows={rows}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getPlantillasAction());
        }}
        onSearching={() => {
          dispatch(actualizarFiltros({ search: buscar }));
          dispatch(getPlantillasAction());
        }}
        onChangeSearching={(search) => setBuscar(search)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    plantillas: state.plantilla.plantillas,
    processing: state.plantilla.processing,
    total: state.plantilla.total,
    rows: state.plantilla.rows,
  };
};

export default connect(mapStateToProps)(PlantillaList);
