import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getMaterialesAction } from '../../redux/actions/material.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import MaterialList from './MaterialList';
import MaterialFilter from './MaterialFilter';
import MaterialItem from './MaterialItem';
import MaterialImage from './MaterialImage';

const Material = ({
  openModal,
  openModalImagen,
  getMaterialesAction,
  getDetallesPorCabeceraAction,
}) => {
  useEffect(() => {
    getMaterialesAction({});
    getDetallesPorCabeceraAction({
      ca: 'TIPOUNIDADMEDIDA',
      page: 0,
      pageSize: 100,
    });
  }, []);

  return (
    <div className="op-component">
      <MaterialList />
      <MaterialFilter />
      {openModal && <MaterialItem />}
      {openModalImagen && <MaterialImage />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.material.openModal,
    openModalImagen: state.material.openModalImagen,
  };
};

export default connect(mapStateToProps, {
  getMaterialesAction,
  getDetallesPorCabeceraAction,
})(Material);
