import * as fromDocumento from '../actions/documento.action';
import { documentoModel } from '../models/documento.model';

let initialState = {
  documentos: [],
  detalles: [],
  topVentas: [],
  su: null,
  suf: null,
  tm: null,
  fi: null,
  ff: null,
  search: null,
  total: 0,
  totalDetalle: 0,
  page: 0,
  pageDetalle: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromDocumento.DOCUMENTO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: documentoModel,
      };
    case fromDocumento.DOCUMENTO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        documentos: action.payload.data,
        total: action.payload.count,
      };
    case fromDocumento.DOCUMENTO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        documentos: [],
        total: 0,
      };
    case fromDocumento.DOCUMENTO_LIST_REPORT:
      return {
        ...state,
        processing: false,
        topVentas: action.payload,
      };
    case fromDocumento.DOCUMENTO_LIST_DETAILS:
      return {
        ...state,
        processing: false,
        detalles: action.payload.data,
        totalDetalle: action.payload.count,
      };
    case fromDocumento.DOCUMENTO_STATES:
      return {
        ...state,
        processing: false,
        estados: action.payload,
      };
    case fromDocumento.DOCUMENTO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: documentoModel,
        openModal: true,
      };
    case fromDocumento.DOCUMENTO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: documentoModel,
      };
    case fromDocumento.DOCUMENTO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromDocumento.DOCUMENTO_FILTER:
      return {
        ...state,
        su: action.payload.su,
        suf: action.payload.suf,
        tm: action.payload.tm,
        fi: action.payload.fi,
        ff: action.payload.ff,
        search: action.payload.search,
        page: 0,
      };
    case fromDocumento.DOCUMENTO_CLOSE:
      return {
        ...state,
        errors: documentoModel,
        openModal: false,
      };
    case fromDocumento.DOCUMENTO_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    case fromDocumento.DOCUMENTO_PAGINATE_DETAILs:
      return {
        ...state,
        pageDetalle: action.payload,
      };
    default:
      return state;
  }
}
