import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getMaquinasAction } from '../../redux/actions/maquina.action';
import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import MaquinaFilter from './MaquinaFilter';
import MaquinaList from './MaquinaList';
import MaquinaItem from './MaquinaItem';

const Maquina = ({ openModal }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMaquinasAction({}));
    dispatch(getSucursalesAction({}));
  }, []);

  return (
    <div className="op-component">
      <MaquinaList />
      <MaquinaFilter />
      {openModal && <MaquinaItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.maquina.openModal,
  };
};

export default connect(mapStateToProps)(Maquina);
