import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  getOperacionesCajaAction,
  getSeleccionarCajaAction,
} from '../../redux/actions/caja.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';
import { getProveedoresAction } from '../../redux/actions/proveedor.action';

import CajaList from './CajaList';
import CajaFilter from './CajaFilter';
import CajaOperacion from './CajaItem';
import CajaView from './CajaView';

const Caja = ({
  openModal,
  openModalEdit,
  getSeleccionarCajaAction,
  getDetallesPorCabeceraAction,
  getProveedoresAction,
}) => {
  useEffect(() => {
    getSeleccionarCajaAction();
    getProveedoresAction({});
    getDetallesPorCabeceraAction({
      ca: 'TIPOCAJA',
    });
    getDetallesPorCabeceraAction({
      ca: 'TIPODOCUMENTO',
    });
  }, []);

  return (
    <div className="op-component">
      <CajaList />
      <CajaFilter />
      {openModal && <CajaOperacion />}
      {openModalEdit && <CajaView />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.caja.openModal,
    openModalEdit: state.caja.openModalEdit,
  };
};

export default connect(mapStateToProps, {
  getSeleccionarCajaAction,
  getDetallesPorCabeceraAction,
  getProveedoresAction,
})(Caja);
