import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import appReducer from './reducers/app.reducer';
import usuarioReducer from './reducers/usuario.reducer';
import almacenReducer from './reducers/almacen.reducer';
import cabeceraReducer from './reducers/cabecera.reducer';
import detalleReducer from './reducers/detalle.reducer';
import materialReducer from './reducers/material.reducer';
import proveedorReducer from './reducers/proveedor.reducer';
import ordenCompraReducer from './reducers/ordencompra.reducer';
import sucursalReducer from './reducers/sucursal.reducer';
import ubigueoReducer from './reducers/ubigueo.reducer';
import inventarioReducer from './reducers/inventario.reducer';
import clienteReducer from './reducers/cliente.reducer';
import ventaReducer from './reducers/venta.reducer';
import operacionReducer from './reducers/operacion.reducer';
import servicioReducer from './reducers/servicio.reducer';
import cajaReducer from './reducers/caja.reducer';
import chicaReducer from './reducers/chica.reducer';
import chicaOperacionReducer from './reducers/chica.operacion.reducer';
import maquinaReducer from './reducers/maquina.reducer';
import cajeroReducer from './reducers/cajero.reducer';
import adicionalReducer from './reducers/adicional.reducer';
import plantillaReducer from './reducers/plantilla.reducer';
import perfilesReducer from './reducers/perfiles.reducer';
import contratoReducer from './reducers/contrato.reducer';
import cobranzaReducer from './reducers/cobranza.reducer';
import documentoReducer from './reducers/documento.reducer';
import archivoReducer from './reducers/archivo.reducer';
import correlativoReducer from './reducers/correlativo.reducer';
import guiaReducer from './reducers/guia.reducer';
import conductorReducer from './reducers/conductor.reducer';
import pagosReducer from './reducers/pagos.reducer';
import categoriaReducer from './reducers/categoria.reducer';
import pedidoReducer from './reducers/pedido.reducer';
import redReducer from './reducers/redsocial.reducer';
import { isLogginIn } from './actions/app.action';

let rootReducer = combineReducers({
  app: appReducer,
  usuario: usuarioReducer,
  almacen: almacenReducer,
  cabecera: cabeceraReducer,
  detalle: detalleReducer,
  material: materialReducer,
  proveedor: proveedorReducer,
  ordenCompra: ordenCompraReducer,
  sucursal: sucursalReducer,
  ubigueo: ubigueoReducer,
  inventario: inventarioReducer,
  cliente: clienteReducer,
  venta: ventaReducer,
  operacion: operacionReducer,
  servicio: servicioReducer,
  caja: cajaReducer,
  chica: chicaReducer,
  chicaOperacion: chicaOperacionReducer,
  maquina: maquinaReducer,
  cajero: cajeroReducer,
  adicional: adicionalReducer,
  plantilla: plantillaReducer,
  perfiles: perfilesReducer,
  contrato: contratoReducer,
  cobranza: cobranzaReducer,
  documento: documentoReducer,
  archivo: archivoReducer,
  correlativo: correlativoReducer,
  guia: guiaReducer,
  conductor: conductorReducer,
  pagos: pagosReducer,
  categoria: categoriaReducer,
  pedido: pedidoReducer,
  red: redReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export default function generateStore() {
  let store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  );

  isLogginIn()(store.dispatch, store.getState);
  return store;
}
