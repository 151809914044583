import * as fromConductor from '../actions/conductor.action';
import { conductorModel } from '../models/conductor.model';

let initialState = {
  conductores: [],
  st: null,
  search: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromConductor.CONDUCTOR_PROCESS:
      return {
        ...state,
        processing: true,
        errors: conductorModel,
      };
    case fromConductor.CONDUCTOR_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        conductores: action.payload.data,
        total: action.payload.count,
      };
    case fromConductor.CONDUCTOR_LIST_FAIL:
      return {
        ...state,
        processing: false,
        conductores: [],
        total: 0,
      };
    case fromConductor.CONDUCTOR_SELECTED:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
        errors: conductorModel,
      };
    case fromConductor.CONDUCTOR_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        selected: null,
        errors: conductorModel,
      };
    case fromConductor.CONDUCTOR_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromConductor.CONDUCTOR_FILTER:
      return {
        ...state,
        st: action.payload.st,
        search: action.payload.search,
        page: 0,
      };
    case fromConductor.CONDUCTOR_CLOSE:
      return {
        ...state,
        errors: conductorModel,
        openModal: false,
      };
    case fromConductor.CONDUCTOR_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
