import { mensaje } from '../../commons/Mensajes';
import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { redSocialModel } from '../models/redsocial.model';

export const REDSOCIAL_PROCESS = '[RedSocial] RedSocial procesando';
export const REDSOCIAL_SAVE_SUCCESS = '[RedSocial] RedSocial guardar exito';
export const REDSOCIAL_FAIL = '[RedSocial] RedSocial guardar error';
export const REDSOCIAL_SELECTED = '[RedSocial] RedSocial seleccionado';
export const REDSOCIAL_LIST_SUCCESS = '[RedSocial] RedSocial lista exito';
export const REDSOCIAL_LIST_FAIL = '[RedSocial] RedSocial lista error';
export const REDSOCIAL_CLOSE = '[RedSocial] RedSocial close';

export const getRedesSocialesAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: REDSOCIAL_PROCESS,
  });

  const params = {
    em: currentUser.ie,
  };

  api
    .get(servicios.REDSOCIAL, { params })
    .then((response) => {
      dispatch({
        type: REDSOCIAL_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: REDSOCIAL_LIST_FAIL,
      });
    });
};

export const postGrabarRedSocialAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: REDSOCIAL_PROCESS,
  });

  let red = {
    ...item,
    em: currentUser.ie,
  };

  api
    .post(`${servicios.REDSOCIAL}`, red)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getRedesSocialesAction({}));
      dispatch({ type: REDSOCIAL_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: REDSOCIAL_FAIL,
        payload: error.data.errors ? error.data.errors : redSocialModel,
      });
    });
};

export const getSeleccionarRedSocialAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: REDSOCIAL_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: REDSOCIAL_SELECTED,
      payload: redSocialModel,
    });
  }
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: REDSOCIAL_CLOSE,
  });
};
