import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import PedidoList from './PedidoList';
import PedidoFilter from './PedidoFilter';
import PedidoItem from './PedidoItem';
import PedidoNew from './PedidoNew';

import { getPedidosAction } from '../../redux/actions/pedido.action';
import { getDetallesPorCabeceraAction } from '../../redux/actions/detalle.action';

import '../../css/components/pedido.css';

const Pedido = ({ openModal, openModalNuevo }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPedidosAction());
    dispatch(getDetallesPorCabeceraAction({ ca: 'TIPOBANCOS' }));
    dispatch(getDetallesPorCabeceraAction({ ca: 'ESTADOPEDIDO' }));
  }, []);

  return (
    <div className="op-component view-pedido">
      <PedidoList />
      <PedidoFilter />
      {openModal && <PedidoItem />}
      {openModalNuevo && <PedidoNew />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.pedido.openModal,
    openModalNuevo: state.pedido.openModalNuevo,
  };
};

export default connect(mapStateToProps)(Pedido);
