import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TodayIcon from '@material-ui/icons/Today';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonIcon from '@material-ui/icons/Person';
import StateIcon from '../assets/icons/state.svg';

import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';

import Icon from './Icon';
import { formatoFecha } from './Utilitarios';
import CalendarPage from '../assets/icons/calendar-page.svg';
import '../css/commons/formulario.css';
import '../css/commons/modal.css';
import '../css/commons/calendar.css';

const Calendario = ({
  name,
  label,
  tabIndex,
  size,
  value,
  onSelected,
  onSelectedMonth,
  minDate,
  maxDate,
  disableWeekend,
  showMini,
  showTime,
  showInline,
  closeOnSelect,
  tasks,
  className,
  error,
  ...rest
}) => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState({
    d: value ? new Date(value) : new Date(),
  });
  const [days, setDays] = useState([]);
  const [open, setOpen] = useState(false);
  const [openMonths, setOpenMonths] = useState(false);
  const [openEvents, setOpenEvents] = useState(false);
  const [tarea, setTarea] = useState(null);
  const [superior, setSuperior] = useState(20);

  let meses = [
    'ENERO',
    'FEBRERO',
    'MARZO',
    'ABRIL',
    'MAYO',
    'JUNIO',
    'JULIO',
    'AGOSTO',
    'SETIEMBRE',
    'OCTUBRE',
    'NOVIEMBRE',
    'DICIEMBRE',
  ];

  let dias = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado',
  ];

  const defaultMaterialTheme = createMuiTheme({
    palette: {
      primary: { main: '#3498db', contrastText: '#fff' },
    },
    overrides: {
      MuiInputBase: {
        input: {
          color: '#fff',
        },
      },
    },
  });

  const handleSelectedMonth = (month) => {
    setCurrentMonth(month);
    setDays([]);
    createCalendar(new Date(currentYear, month, 1));
    if (onSelectedMonth) {
      onSelectedMonth({ month, year: currentYear });
    }
  };

  const handleSelectedYear = (direccion) => {
    setCurrentYear(currentYear + direccion);
    setDays([]);
    createCalendar(new Date(currentYear + direccion, currentMonth, 1));
  };

  const createCalendar = (current) => {
    let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
    let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);

    let beforeDays = firstDay.getDay();
    let lastDayBefore = new Date(
      firstDay.getFullYear(),
      firstDay.getMonth(),
      0
    );
    lastDayBefore.setDate(lastDayBefore.getDate() - beforeDays + 1);

    for (let i = lastDayBefore.getDate(); beforeDays > 0; beforeDays--, i++) {
      setDays((days) => [
        ...days,
        {
          day: i.toString(),
          month: lastDayBefore.getMonth(),
          date: new Date(),
          isEnable: false,
          isSelected: false,
          isCurrent: false,
        },
      ]);
    }

    for (let i = firstDay.getDate(); i <= lastDay.getDate(); i++) {
      const fecha = new Date(
        firstDay.getFullYear(),
        firstDay.getMonth(),
        i,
        selectedDate.d.getHours(),
        selectedDate.d.getMinutes()
      );
      let selected = false;
      let enable = false;

      if (
        fecha.getFullYear() === selectedDate.d.getFullYear() &&
        fecha.getMonth() === selectedDate.d.getMonth() &&
        i === selectedDate.d.getDate()
      ) {
        selected = true;
      }

      if (minDate && maxDate) {
        let minimo = new Date(minDate);
        minimo.setHours(0, 0, 0);
        let maximo = new Date(maxDate);
        maximo.setHours(23, 59, 0);

        if (
          minimo.getTime() <= fecha.getTime() &&
          fecha.getTime() <= maximo.getTime()
        ) {
          enable = true;
        }
      } else if (minDate) {
        let minimo = new Date(minDate);
        minimo.setHours(0, 0, 0);
        if (fecha.getTime() >= minimo.getTime()) {
          enable = true;
        }
      } else if (maxDate) {
        let maximo = new Date(maxDate);
        maximo.setHours(23, 59, 0);
        if (fecha.getTime() <= maximo.getTime()) {
          enable = true;
        }
      } else {
        enable = true;
      }

      if (
        disableWeekend === true &&
        (fecha.getDay() === 0 || fecha.getDay() === 6)
      ) {
        enable = false;
      }

      if (selected === true && enable === true && value) {
        onSelected({ name, value: fecha });
      }

      setDays((days) => [
        ...days,
        {
          day: i < 10 ? '0' + i : i.toString(),
          date: fecha,
          month: firstDay.getMonth(),
          isEnable: enable,
          isSelected: selected,
          isCurrent: true,
        },
      ]);
    }

    let afterDays = lastDay.getDay();
    const firstDayAfter = new Date(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      1
    );

    for (let i = firstDayAfter.getDate(); afterDays < 6; afterDays++, i++) {
      setDays((days) => [
        ...days,
        {
          day: i < 10 ? '0' + i : i.toString(),
          date: new Date(),
          month: firstDayAfter.getMonth(),
          isEnable: false,
          isSelected: false,
          isCurrent: false,
        },
      ]);
    }
  };

  const padLef = (n) => {
    return ('00' + n).slice(-2);
  };

  useEffect(() => {
    createCalendar(selectedDate.d);
  }, []);

  const calendarDiseign = () => (
    <div
      className={`op-content-calendar${openMonths ? ' on' : ''}${
        showInline ? ' inline' : ''
      }${openEvents ? ' openEvent' : ''}`}
    >
      <div className="content-months">
        <div className="content-year">
          <ArrowBackIosIcon
            onClick={() => handleSelectedYear(-1)}
            style={{ cursor: 'pointer' }}
          />
          {currentYear}
          <ArrowForwardIosIcon
            onClick={() => handleSelectedYear(1)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <div className="months">
          {meses.map((m, i) => {
            return (
              <div
                key={i}
                className={`${currentMonth === i ? 'selected' : ''}`}
                onClick={() => {
                  handleSelectedMonth(i);
                  setOpenMonths(false);
                }}
              >
                {m}
              </div>
            );
          })}
        </div>
      </div>
      <div className="content-main">
        <div className="calendar-icon">
          <Icon
            svg={CalendarPage}
            color="#00adb5"
            size={30}
            onClick={() => {
              setOpenMonths(true);
            }}
          />
        </div>
        <div className="calendar-icon event">
          <ArrowBackIosIcon
            style={{ color: '#00adb5', size: '30px' }}
            onClick={() => setOpenEvents(true)}
          />
        </div>
        <div className="content-month">
          {meses.map((m, i) => {
            return (
              <div key={i} className={`${currentMonth === i ? 'on' : 'off'}`}>
                {m}
              </div>
            );
          })}
        </div>
        <div className="content-week">
          <div>{`${showInline ? 'Domingo' : 'Do'}`}</div>
          <div>{`${showInline ? 'Lunes' : 'Lu'}`}</div>
          <div>{`${showInline ? 'Martes' : 'Ma'}`}</div>
          <div>{`${showInline ? 'Miércoles' : 'Mi'}`}</div>
          <div>{`${showInline ? 'Jueves' : 'Ju'}`}</div>
          <div>{`${showInline ? 'Viernes' : 'Vi'}`}</div>
          <div>{`${showInline ? 'Sábado' : 'Sa'}`}</div>
        </div>
        <div className="content-days">
          {days.map((d) => {
            return (
              <div
                key={`${d.day}-${d.month}`}
                className={`day${d.isCurrent ? ' current' : ''}`}
              >
                {d.isEnable ? (
                  <>
                    <input
                      type="radio"
                      id={`${d.day}-${tabIndex}`}
                      defaultChecked={
                        d.date.getFullYear() === selectedDate.d.getFullYear() &&
                        d.date.getMonth() === selectedDate.d.getMonth() &&
                        d.date.getDate() === selectedDate.d.getDate()
                      }
                      name={`calendar-op-${tabIndex}`}
                    />
                    <label
                      htmlFor={`${d.day}-${tabIndex}`}
                      className={`${showInline ? 'label-task' : 'label-day'}${
                        d.isEnable ? ' enabled' : ''
                      }`}
                      onClick={(e) => {
                        setSelectedDate((p) => ({ ...p, d: d.date }));
                        onSelected({ name, value: d.date });
                        setTarea(null);
                        if (closeOnSelect) {
                          setOpen(false);
                        }
                      }}
                    >
                      {showInline ? (
                        <>
                          <span className="dia">{d.day}</span>
                          <div className="content-tasks">
                            {tasks
                              .filter(
                                (f) =>
                                  d.date.getFullYear() === f.fa.getFullYear() &&
                                  d.date.getMonth() === f.fa.getMonth() &&
                                  d.date.getDate() === f.fa.getDate()
                              )
                              .map((t, i) => (
                                <div
                                  key={i}
                                  className="task"
                                  style={{ backgroundColor: `${t.co}` }}
                                ></div>
                              ))}
                          </div>
                        </>
                      ) : (
                        d.day
                      )}
                    </label>
                  </>
                ) : (
                  <label>{d.day}</label>
                )}
              </div>
            );
          })}
        </div>
        {showInline === false && (
          <div className="content-footer">
            {showTime ? (
              <div className="content-time">
                <AccessAlarmIcon style={{ marginRight: '5px' }} />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <TimePicker
                      cancelLabel={'Cancelar'}
                      okLabel={'Ok'}
                      showTodayButton
                      todayLabel={'Ahora'}
                      value={selectedDate.d}
                      minutesStep={5}
                      onChange={(item) => {
                        onSelected({ name, value: item._d });
                        setSelectedDate((p) => ({ ...p, d: item._d }));
                        if (closeOnSelect) {
                          setOpen(false);
                        }
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
            ) : (
              <div></div>
            )}
            <button
              className="op-boton"
              type="button"
              onClick={() => setOpen(false)}
            >
              Cerrar
            </button>
          </div>
        )}
      </div>
      {showInline && (
        <div className="content-events">
          <div className="calendar-icon event-close">
            <ArrowForwardIosIcon
              style={{ color: '#00adb5', size: '30px' }}
              onClick={() => setOpenEvents(false)}
            />
          </div>
          <div className="content-day">
            {dias.map((m, i) => {
              return (
                <div
                  key={i}
                  className={`${selectedDate.d.getDay() === i ? 'on' : 'off'}`}
                >
                  {`${m}, ${selectedDate.d.getDate()}`}
                </div>
              );
            })}
          </div>

          <div className="events">
            {tasks
              .filter(
                (f) =>
                  selectedDate.d.getFullYear() === f.fa.getFullYear() &&
                  selectedDate.d.getMonth() === f.fa.getMonth() &&
                  selectedDate.d.getDate() === f.fa.getDate()
              )
              .map((t, i) => (
                <div
                  key={i}
                  className={`task ${
                    tarea && tarea.ta === t.ta ? 'selected' : ''
                  }`}
                  onClick={(e) => {
                    setTarea(t);
                    setSuperior(e.target.offsetTop - 30);
                  }}
                >
                  <span style={{ backgroundColor: `${t.co}` }}></span>
                  <span>{`${padLef(t.fa.getHours())}:${padLef(
                    t.fa.getMinutes()
                  )} `}</span>
                  <span
                    title={`${
                      t.ve === 'ACTCOM'
                        ? 'completado'
                        : t.ve === 'REGDOC'
                        ? ''
                        : 'anulado'
                    }`}
                  >
                    {t.dt}
                  </span>
                  <span
                    className={`${
                      t.ve === 'ACTCOM'
                        ? 'completo'
                        : t.ve === 'REGDOC'
                        ? ''
                        : 'anulado'
                    }`}
                  ></span>
                </div>
              ))}
          </div>
          {tarea && (
            <div className="content-task" style={{ top: `${superior}px` }}>
              <span className="close" onClick={() => setTarea(null)}></span>
              <label
                className="title"
                style={{ borderBottom: `1px solid ${tarea.co}` }}
              >
                Actividad
              </label>
              <div className="info">
                <span>
                  <AccessTimeIcon />
                </span>
                <span>{`${formatoFecha(tarea.fa)}`}</span>
              </div>
              <div className="info">
                <span>
                  <PersonIcon />
                </span>
                <span>{`${tarea.nu} (Responsable)`}</span>
              </div>
              <div className="info">
                <span>
                  <CalendarTodayIcon />
                </span>
                <span>Calendario</span>
              </div>
              <div className="info">
                <span>
                  <FormatAlignLeftIcon />
                </span>
                <span>{tarea.dt}</span>
              </div>
              <div className="separador"></div>
              <div className="info">
                <span>
                  <PersonIcon />
                </span>
                <span>{`${tarea.na} (Aprobador)`}</span>
              </div>
              <div className="info">
                <span>
                  <Icon svg={StateIcon} color="#222831" />
                </span>
                <span>{tarea.ds}</span>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  const handleType = () => {
    if (showMini) {
      return (
        <div className={`op-content-calendar mini`}>
          <div className="content-mini-month">
            {meses.map((m, i) => {
              return (
                <div
                  key={i}
                  className={`${
                    selectedDate.d.getMonth() === i ? 'on' : 'off'
                  }`}
                >
                  {`${m}, ${selectedDate.d.getFullYear()}`}
                </div>
              );
            })}
          </div>
          <div className="content-mini-date">
            <CountUp end={selectedDate.d.getDate()} duration={1} />
          </div>
          <div className={`content-mini-day ${showTime ? 'time' : ''}`}>
            {dias.map((m, i) => {
              return (
                <div
                  key={i}
                  className={`${selectedDate.d.getDay() === i ? 'on' : 'off'}`}
                >
                  {m}
                </div>
              );
            })}
          </div>
          {showTime && (
            <div className="content-mini-time">
              {`${selectedDate.d.getHours()}:${selectedDate.d.getMinutes()}`}
            </div>
          )}
        </div>
      );
    } else if (showInline) {
      return <>{calendarDiseign()}</>;
    } else {
      return (
        <div
          className={`op-form-group${size ? ' op-col-' + size : ''}
  ${error ? ' error' : ''} op-calendar`}
          {...rest}
        >
          <input
            name={name}
            className={`op-form${className ? ' ' + className : ''}`}
            value={value == null ? '' : formatoFecha(value, '', showTime)}
            tabIndex={tabIndex}
            required="required"
            type={'text'}
            onChange={() => {}}
            onPaste={(e) => e.preventDefault()}
            onClick={() => setOpen(true)}
          />
          <label>{label}</label>
          <TodayIcon
            fontSize="large"
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(true)}
          />
          <span className="bar bottom"></span>
          <span className="bar left"></span>
          <span className="bar right"></span>
          <span className="bar top"></span>
          {error && <span className="op-form-error">{error}</span>}
        </div>
      );
    }
  };

  return (
    <>
      <>{handleType()}</>
      {open && (
        <div className="op-content-modal">
          <div className="op-modal op-calendar">
            <div className="op-modal-body">{calendarDiseign()}</div>
          </div>
        </div>
      )}
    </>
  );
};

Calendario.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  tabIndex: PropTypes.number,
  onSelected: PropTypes.func.isRequired,
  onSelectedMonth: PropTypes.func,
  size: PropTypes.oneOf([2, 3, 4]),
  value: PropTypes.any,
  className: PropTypes.string,
  error: PropTypes.string,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disableWeekend: PropTypes.bool,
  showTime: PropTypes.bool,
  showMini: PropTypes.bool,
  showInline: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  tasks: PropTypes.array,
};

Calendario.defaultProps = {
  showMini: false,
  disableWeekend: false,
  showTime: false,
  showInline: false,
  tabIndex: 0,
};

export default Calendario;
