export const cajeroModel = {
  cj: null,
  is: null,
  ns: null,
  pv: null,
  np: null,
  ss: null,
  sd: null,
  fa: null,
  fc: null,
  uc: null,
  ur: null,
  bs: 0,
  ms: 0,
  ts: 0,
  bd: 0,
  md: 0,
  td: 0,
  mr: false,
  st: false,
};
