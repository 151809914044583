import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getServiciosAction } from '../../redux/actions/servicio.action';
import { getTipoCategoriaAction } from '../../redux/actions/categoria.action';

import ServicioList from './ServicioList';
import ServicioFilter from './ServicioFilter';
import ServicioItem from './ServicioItem';
import ServicioImage from './ServicioImage';

const Servicio = ({ openModal, openModalImagen }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getServiciosAction({}));
    dispatch(getTipoCategoriaAction());
  }, []);

  return (
    <div className="op-component">
      <ServicioList />
      <ServicioFilter />
      {openModal && <ServicioItem />}
      {openModalImagen && <ServicioImage />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.servicio.openModal,
    openModalImagen: state.servicio.openModalImagen,
  };
};

export default connect(mapStateToProps)(Servicio);
