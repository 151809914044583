import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { chicaOperacionModel } from '../models/chica.operacion.model';
import {
  quitarFormatoMonetario,
  formatearFecha,
} from '../../commons/Utilitarios';

export const CHICAOPERACION_PROCESS = '[ChicaOper] ChicaOper procesando';
export const CHICAOPERACION_SAVE_SUCCESS =
  '[ChicaOper] ChicaOper guardar exito';
export const CHICAOPERACION_LIST_SUCCESS = '[ChicaOper] ChicaOper lista exito';
export const CHICAOPERACION_LIST_FAIL = '[ChicaOper] ChicaOper lista error';
export const CHICAOPERACION_SELECTED = '[ChicaOper] ChicaOper seleccionado';
export const CHICAOPERACION_CLOSE = '[ChicaOper] ChicaOper cerrar modal';
export const CHICAOPERACION_OPEN = '[ChicaOper] ChicaOper open modal';
export const CHICAOPERACION_FILTER = '[ChicaOper] ChicaOper filtros';
export const CHICAOPERACION_FAIL = '[ChicaOper] ChicaOper proceso error';

import {
  getSeleccionarCajaChicaAction,
  getCajaChicasAction,
} from './chica.action';

export const getOperacionesCajaChicaAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { su, ch, tc, tm, is, fo, fi, ff } = getState().chicaOperacion;
    let { currentUser } = getState().app;

    dispatch({
      type: CHICAOPERACION_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      su,
      ch,
      tc,
      tm,
      is,
      fo,
      fi,
      ff,
      page,
      pageSize,
    };

    api
      .get(`${servicios.CHICA}/operaciones`, { params })
      .then((response) => {
        dispatch({
          type: CHICAOPERACION_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CHICAOPERACION_LIST_FAIL,
        });
      });
  };

export const getSeleccionarOperacionAction = (item) => (dispatch) => {
  dispatch({
    type: CHICAOPERACION_SELECTED,
    payload: item,
  });
};

export const postOperacionCajaChicaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  let { selected } = getState().chica;
  dispatch({
    type: CHICAOPERACION_PROCESS,
  });

  let operacion = {
    ie: currentUser.ie,
    ch: selected.ch,
    su: selected.is,
    tc: item.tc,
    co: item.co,
    is: item.is,
    tm: item.tm,
    mo: quitarFormatoMonetario(item.mo),
    ia: item.ia,
    td: item.td,
    nd: item.nd,
    rz: item.rz,
    rs: item.rs,
    iu: currentUser.us,
  };

  api
    .post(servicios.CHICA, operacion)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getCajaChicasAction({}));
      dispatch(getSeleccionarCajaChicaAction(selected.is, selected.ch));
      dispatch(getOperacionesCajaChicaAction({}));
      dispatch({ type: CHICAOPERACION_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CHICAOPERACION_FAIL,
        payload: error.data.errors ? error.data.errors : chicaOperacionModel,
      });
    });
};

export const putRectificarOperacionCajaChicaAction =
  (item) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { selected } = getState().chica;
    dispatch({
      type: CHICAOPERACION_PROCESS,
    });

    let operacion = {
      ie: currentUser.ie,
      ch: selected.ch,
      fo: item.fo,
      su: selected.is,
      tc: item.tc,
      co: item.co,
      is: item.is,
      tm: item.tm,
      mo: quitarFormatoMonetario(item.mo),
      ia: item.ia,
      td: item.td,
      nd: item.nd,
      rz: item.rz,
      rs: item.rs,
      iu: currentUser.us,
    };

    api
      .put(servicios.CHICA, operacion)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getCajaChicasAction({}));
        dispatch(getSeleccionarCajaChicaAction(selected.is, selected.ch));
        dispatch(getOperacionesCajaChicaAction({}));
        dispatch({ type: CHICAOPERACION_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: CHICAOPERACION_FAIL,
          payload: error.data.errors ? error.data.errors : chicaOperacionModel,
        });
      });
  };

export const patchEliminarOperacionCajaChicaAction =
  (item) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { selected } = getState().chica;
    dispatch({
      type: CHICAOPERACION_PROCESS,
    });

    let operacion = {
      ie: currentUser.ie,
      ch: selected.ch,
      su: selected.is,
      fo: item.fo,
      iu: currentUser.us,
    };

    api
      .patch(`${servicios.CHICA}/eliminar`, operacion)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getCajaChicasAction({}));
        dispatch(getSeleccionarCajaChicaAction(selected.is, selected.ch));
        dispatch(getOperacionesCajaChicaAction({}));
        dispatch({ type: CHICAOPERACION_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: CHICAOPERACION_FAIL,
          payload: error.data.errors ? error.data.errors : chicaOperacionModel,
        });
      });
  };

export const patchAdjuntarOperacionCajaChicaAction =
  (item, archivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    let { selected } = getState().chica;
    dispatch({
      type: CHICAOPERACION_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', archivo);
    form_data.append('ch', selected.ch);
    form_data.append('is', selected.is);
    form_data.append('ie', currentUser.ie);
    form_data.append('iu', currentUser.us);
    form_data.append('fo', item.fo);

    api
      .patch(`${servicios.CHICA}/adjuntar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getCajaChicasAction({}));
        dispatch(getSeleccionarCajaChicaAction(selected.is, selected.ch));
        dispatch(getOperacionesCajaChicaAction({}));
        dispatch({ type: CHICAOPERACION_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: CHICAOPERACION_FAIL,
          payload: error.data.errors ? error.data.errors : chicaOperacionModel,
        });
      });
  };

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, ch, tc, tm, is, fo, fi, ff } = getState().chicaOperacion;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    ch: filtros.ch !== undefined ? (filtros.ch === '' ? null : filtros.ch) : ch,
    tc: filtros.tc !== undefined ? (filtros.tc === '' ? null : filtros.tc) : tc,
    tm: filtros.tm !== undefined ? (filtros.tm === '' ? null : filtros.tm) : tm,
    is: filtros.is !== undefined ? (filtros.is === '' ? null : filtros.is) : is,
    fo: filtros.fo !== undefined ? (filtros.fo === '' ? null : filtros.fo) : fo,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
  };

  dispatch({
    type: CHICAOPERACION_FILTER,
    payload: item,
  });
};

export const openModal = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: CHICAOPERACION_OPEN,
      payload: item,
    });
  } else {
    dispatch({
      type: CHICAOPERACION_OPEN,
      payload: chicaOperacionModel,
    });
  }
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CHICAOPERACION_CLOSE,
  });
};
