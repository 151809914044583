import * as fromAdicion from '../actions/adicional.action';
import { adicionalModel } from '../models/adicional.model';

let initialState = {
  adicionales: [],
  estados: [],
  ta: null,
  nt: null,
  pa: null,
  pn: null,
  st: null,
  search: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromAdicion.ADICIONAL_PROCESS:
      return {
        ...state,
        processing: true,
        errors: adicionalModel,
      };
    case fromAdicion.ADICIONAL_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        adicionales: action.payload.data,
        total: action.payload.count,
      };
    case fromAdicion.ADICIONAL_LIST_FAIL:
      return {
        ...state,
        processing: false,
        adicionales: [],
        total: 0,
      };
    case fromAdicion.ADICIONAL_STATES:
      return {
        ...state,
        processing: false,
        estados: action.payload,
      };
    case fromAdicion.ADICIONAL_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: adicionalModel,
        openModal: true,
      };
    case fromAdicion.ADICIONAL_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: adicionalModel,
      };
    case fromAdicion.ADICIONAL_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromAdicion.ADICIONAL_FILTER:
      return {
        ...state,
        pa: action.payload.pa,
        pn: action.payload.pn,
        ta: action.payload.ta,
        nt: action.payload.nt,
        st: action.payload.st,
        search: action.payload.search,
        page: 0,
      };
    case fromAdicion.ADICIONAL_CLOSE:
      return {
        ...state,
        errors: adicionalModel,
        openModal: false,
      };
    case fromAdicion.ADICIONAL_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
}
