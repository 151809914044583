import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { ordenCompraModel } from '../models/ordencompra.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const PAGOS_PROCESS = '[Pagos] Pagos procesando';
export const PAGOS_SAVE_SUCCESS = '[Pagos] Pagos guardar exito';
export const PAGOS_LIST_SUCCESS = '[Pagos] Pagos lista exito';
export const PAGOS_LIST_FAIL = '[Pagos] Pagos lista error';
export const PAGOS_FILTER = '[Pagos] Pagos filtros';
export const PAGOS_PAGINATE = '[Pagos] Pagos paginado';

export const getPagosAction = () => (dispatch, getState) => {
  let { iu, su, es, st, search, page, rows } = getState().pagos;
  let { currentUser } = getState().app;

  dispatch({
    type: PAGOS_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    iu,
    su,
    es,
    st,
    pp: 1,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.ORDENCOMPRA, { params })
    .then((response) => {
      dispatch({
        type: PAGOS_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: PAGOS_LIST_FAIL,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, es, st, search } = getState().pagos;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    es: filtros.es !== undefined ? (filtros.es === '' ? null : filtros.es) : es,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: PAGOS_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: PAGOS_PAGINATE,
    payload: page,
  });
};
