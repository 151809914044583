import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Texto from '../../commons/Texto';
import { formatearFecha } from '../../commons/Utilitarios';
import {
  getGuiasAction,
  getSeleccionarGuiaAction,
  actualizarFiltros,
} from '../../redux/actions/guia.action';

const GuiaFilter = ({ inicio, fin }) => {
  const dispatch = useDispatch();

  return (
    <Filtros
      onFilter={() => dispatch(getGuiasAction())}
      onNew={() => dispatch(getSeleccionarGuiaAction())}
    >
      <Texto
        name="fi"
        value={inicio}
        label="Fecha inicio operaci&oacute;n"
        tabIndex={23}
        size={3}
        type={'date'}
        onChange={({ value }) => {
          dispatch(actualizarFiltros({ fi: value }));
        }}
      />
      <Texto
        name="ff"
        value={fin}
        label="Fecha fin operaci&oacute;n"
        tabIndex={23}
        size={3}
        type={'date'}
        onChange={({ value }) => {
          dispatch(actualizarFiltros({ ff: value }));
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    inicio: state.guia.fi,
    fin: state.guia.ff,
  };
};

export default connect(mapStateToProps)(GuiaFilter);
