import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import Icon from '../../commons/Icon';
import AdjuntoList from '../../commons/AdjuntoList';
import Report from '../app/Report';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  patchAnularOrdenCompraAction,
  patchRechazarOrdenCompraAction,
} from '../../redux/actions/ordencompra.action';

import { getSucursalesAction } from '../../redux/actions/sucursal.action';

import { servicios } from '../../redux/helpers/servicios';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CancelFile from '../../assets/icons/file-cancel.svg';
import AprobarFile from '../../assets/icons/file-edit.svg';
import ConfirmarFile from '../../assets/icons/file-confirm.svg';
import SaveFile from '../../assets/icons/file-save.svg';
import XlsFile from '../../assets/icons/xls-file.svg';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PrintIcon from '@material-ui/icons/Print';

import OrdenCompraAprobar from './OrdenCompraAprobar';
import OrdenCompraConfirmar from './OrdenCompraConfirmar';
import OrdenCompraRecepcionar from './OrdenCompraRecepcionar';
import OrdenCompraPagar from './OrdenCompraPagar';

const OrdenCompraVer = ({
  selected,
  processing,
  usuario,
  empresa,
  perfil,
  closeModal,
  patchAnularOrdenCompraAction,
  patchRechazarOrdenCompraAction,
  getSucursalesAction,
}) => {
  const [orden, setOrden] = useState(selected);
  const [openAprobar, setOpenAprobar] = useState(false);
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [openRecepcionar, setOpenRecepcionar] = useState(false);
  const [openPagar, setOpenPagar] = useState(false);
  const [limite, setLimite] = useState(new Date());
  const [openReporte, setOpenReporte] = useState(false);
  const [openReporteExcel, setOpenReporteExcel] = useState(false);

  useEffect(() => {
    setOrden((prev) => ({
      ...prev,
      ua: usuario,
      to: formatoMonetario(redondeoDecimales(prev.to)),
      su: formatoMonetario(redondeoDecimales(prev.su)),
      im: formatoMonetario(redondeoDecimales(prev.im)),
      bt: formatoMonetario(redondeoDecimales(prev.bt)),
      md: formatoMonetario(redondeoDecimales(prev.md)),
      td: formatoMonetario(redondeoDecimales(prev.td)),
      tt: formatoMonetario(redondeoDecimales(prev.tt)),
    }));
    getSucursalesAction({});
    let recepcion = new Date(selected.fe);
    recepcion.setDate(recepcion.getDate() + parseInt(selected.dd));
    setLimite(recepcion);
  }, []);

  const columns = [
    {
      key: 'cm',
      label: 'Código',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.cm}
        </span>
      ),
    },
    {
      key: 'nm',
      label: 'Material / Producto',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.nm}
        </span>
      ),
    },
    {
      key: 'du',
      label: 'Unidad medida',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.du}
        </span>
      ),
    },
  ];

  if (
    selected.vm === 'CONORD' ||
    selected.vm === 'RECHORD' ||
    selected.vm === 'ENTORD' ||
    selected.vm === 'PAGAORD'
  ) {
    columns.push({
      key: 'pr',
      label: 'Precio unitario compra',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {`${formatoMonetario(item.pr)} ${item.tm === 0 ? 'PEN' : 'USD'}`}
        </span>
      ),
    });
    columns.push({
      key: 'ct',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.ct}
        </span>
      ),
    });
    columns.push({
      key: 'su',
      label: 'SubTotales',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {`${formatoMonetario(
            redondeoDecimales(parseFloat(item.pr) * parseInt(item.ct))
          )} ${item.tm === 0 ? 'PEN' : 'USD'}`}
        </span>
      ),
    });
  } else {
    columns.push({
      key: 'ct',
      label: 'Cantidad',
      align: 'right',
      content: (item) => (
        <span
          style={{
            color: `${item.st === true ? 'black' : 'red'}`,
            textDecoration: `${item.st === true ? 'none' : 'line-through'}`,
          }}
        >
          {item.ct}
        </span>
      ),
    });
  }

  const handleTexto = (estado) => {
    let texto = '#000000';
    switch (estado) {
      case 'ANUORD':
        texto = 'anulación';
        break;
      case 'RECHORD':
        texto = 'rechazo';
        break;
      case 'APRORD':
        texto = 'aprobación';
        break;
      case 'CONORD':
        texto = 'confirmación';
        break;
      case 'ENTORD':
        texto = 'recepción';
        break;
      case 'PAGAORD':
        texto = 'pago';
        break;
      default:
        break;
    }

    return texto;
  };

  return (
    <>
      <Modal
        title={`Orden compra N°: ${selected.co}`}
        processing={processing}
        onClose={() =>
          confirmacion('¿Deseas cerrar?', '')
            .then(() => {
              closeModal();
            })
            .catch(() => {})
        }
        onActions={() => (
          <>
            {(selected.vm === 'SOLORD' || selected.vm === 'APRORD') &&
              perfil === 'NOBASICO' && (
                <Boton
                  className="op-dinamic"
                  icon={() => <Icon svg={CancelFile} color="red" />}
                  tabIndex={22}
                  style={{ '--button-color': 'red' }}
                  onClick={() => {
                    Swal.fire({
                      input: 'textarea',
                      inputLabel: 'Motivo de anulación',
                      inputPlaceholder: 'Ingrese el motivo de anulación...',
                      inputAttributes: {
                        'aria-label': 'Ingrese el motivo de anulación',
                        style: { resize: 'none' },
                      },
                      showCancelButton: true,
                      confirmButtonColor: '#1ab394',
                      confirmButtonText: 'Confirmar',
                      cancelButtonColor: 'red',
                      cancelButtonText: 'Cancelar',
                      reverseButtons: true,
                    }).then((result) => {
                      if (result.value) {
                        confirmacion(
                          '¿Está seguro?',
                          `De anular la orden de compra ${orden.co}`
                        )
                          .then(() =>
                            patchAnularOrdenCompraAction(orden, result.value)
                          )
                          .catch(() => {});
                      }
                    });
                  }}
                >
                  Anular orden compra
                </Boton>
              )}
            {selected.vm === 'CONORD' && perfil === 'NOBASICO' && (
              <Boton
                className="op-dinamic"
                icon={() => <Icon svg={CancelFile} color="red" />}
                tabIndex={22}
                style={{ '--button-color': 'red' }}
                onClick={() => {
                  Swal.fire({
                    input: 'textarea',
                    inputLabel: 'Motivo de rechazo',
                    inputPlaceholder: 'Ingrese el motivo del rechazo ...',
                    inputAttributes: {
                      'aria-label': 'Ingrese el motivo del rechazo',
                      style: { resize: 'none' },
                    },
                    showCancelButton: true,
                    confirmButtonColor: '#1ab394',
                    confirmButtonText: 'Confirmar',
                    cancelButtonColor: 'red',
                    cancelButtonText: 'Cancelar',
                    reverseButtons: true,
                  }).then((result) => {
                    if (result.value) {
                      confirmacion(
                        '¿Está seguro?',
                        `De rechazar la orden de compra ${orden.co}`
                      )
                        .then(() =>
                          patchRechazarOrdenCompraAction(orden, result.value)
                        )
                        .catch(() => {});
                    }
                  });
                }}
              >
                Rechazar orden compra
              </Boton>
            )}
            {selected.vm === 'SOLORD' && perfil === 'NOBASICO' && (
              <Boton
                className="op-dinamic"
                icon={() => <Icon svg={AprobarFile} color="green" />}
                tabIndex={22}
                style={{ '--button-color': 'green' }}
                onClick={() => {
                  setOpenAprobar(true);
                }}
              >
                Aprobar orden compra
              </Boton>
            )}
            {selected.vm === 'APRORD' && perfil === 'NOBASICO' && (
              <Boton
                className="op-dinamic"
                icon={() => <Icon svg={ConfirmarFile} color="#1ab394" />}
                tabIndex={22}
                style={{ '--button-color': '#1ab394' }}
                onClick={() => {
                  setOpenConfirmar(true);
                }}
              >
                Confirmar orden compra
              </Boton>
            )}
            {selected.vm === 'CONORD' && perfil === 'NOBASICO' && (
              <Boton
                className="op-dinamic"
                icon={() => <Icon svg={SaveFile} color="#3498db" />}
                tabIndex={22}
                style={{ '--button-color': '#3498db' }}
                onClick={() => {
                  setOpenRecepcionar(true);
                }}
              >
                Recepcionar orden compra
              </Boton>
            )}
            {selected.vm === 'ENTORD' && perfil === 'NOBASICO' && (
              <Boton
                className="op-dinamic"
                icon={() => <MonetizationOnIcon />}
                tabIndex={22}
                style={{ '--button-color': '#1ab394' }}
                onClick={() => {
                  setOpenPagar(true);
                }}
              >
                Pagar orden compra
              </Boton>
            )}
            {!(
              selected.vm === 'ENTORD' ||
              selected.vm === 'ANUORD' ||
              selected.vm === 'RECHORD' ||
              selected.vm === 'PAGAORD' ||
              selected.vm === 'SOLORD'
            ) &&
              perfil === 'NOBASICO' && (
                <>
                  <Boton
                    className="op-dinamic"
                    icon={() => <PrintIcon />}
                    tabIndex={22}
                    style={{
                      '--button-color': '#3285a8',
                      '--button-icon-color': '#1ab394',
                    }}
                    dropdownable
                    showDropdownOnTop
                    actions={[
                      {
                        key: '01',
                        label: 'PDF',
                        icon: () => (
                          <PictureAsPdfIcon style={{ color: 'red' }} />
                        ),
                        onClick: () => setOpenReporte(true),
                      },
                      {
                        key: '02',
                        label: 'Excel',
                        icon: () => <Icon svg={XlsFile} color="#1ab394" />,
                        onClick: () => setOpenReporteExcel(true),
                      },
                    ]}
                  >
                    Imprimir
                  </Boton>
                </>
              )}
          </>
        )}
      >
        <Texto
          name="nu"
          value={selected.nu}
          label={`Usuario solicito`}
          tabIndex={9}
          size={3}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="so"
          value={selected.so}
          label={`Sucursal de solicitud`}
          tabIndex={10}
          size={3}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="de"
          value={selected.de}
          label="Estado"
          tabIndex={11}
          size={3}
          onChange={() => {}}
          disabled
        />

        <Texto
          name="fr"
          value={formatoFecha(selected.fr)}
          label="Fecha de solicitud"
          tabIndex={13}
          size={3}
          onChange={() => {}}
          disabled
        />

        {(selected.vm === 'APRORD' ||
          selected.vm === 'CONORD' ||
          selected.vm === 'RECHORD' ||
          selected.vm === 'ENTORD' ||
          selected.vm === 'PAGAORD') && (
          <>
            <Texto
              name="fa"
              value={formatoFecha(selected.fa)}
              label={`Fecha de ${handleTexto(selected.vm)}`}
              tabIndex={13}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="ua"
              value={selected.ua}
              label={`Usuario ${handleTexto(selected.vm)}`}
              tabIndex={13}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="rz"
              value={selected.rz}
              label="Proveedor"
              tabIndex={14}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="ns"
              value={selected.ns}
              label="Tienda destino"
              tabIndex={15}
              size={3}
              onChange={() => {}}
              disabled
            />
          </>
        )}
        {(selected.vm === 'CONORD' ||
          selected.vm === 'RECHORD' ||
          selected.vm === 'ENTORD' ||
          selected.vm === 'PAGAORD') && (
          <>
            <Texto
              name="pc"
              value={selected.pc}
              label="Proveedor contacto"
              tabIndex={16}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="pt"
              value={selected.pt}
              label="Tel&eacute;fono contacto proveedor"
              tabIndex={17}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="rc"
              value={selected.rc}
              label="Recepci&oacute;n contacto"
              tabIndex={18}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="rt"
              value={selected.rt}
              label="Tel&eacute;fono contacto recepci&oacute;n"
              tabIndex={19}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="fe"
              value={formatoFecha(selected.fe)}
              label="Fecha de entrega"
              tabIndex={20}
              size={3}
              onChange={() => {}}
              disabled
            />
            {selected.tt > 1 && (
              <Texto
                name="tt"
                value={formatoMonetario(selected.tt)}
                label="Tipo de cambio"
                tabIndex={21}
                size={3}
                onChange={() => {}}
                disabled
              />
            )}
          </>
        )}
        {(selected.vm === 'ENTORD' || selected.vm === 'PAGAORD') && (
          <>
            <Texto
              name="du"
              value={selected.du}
              label="Tipo documento pago"
              tabIndex={21}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="dp"
              value={`${selected.ip ? selected.ip : ''}-${
                selected.dp ? selected.dp : ''
              }`}
              label="Documento de pago"
              tabIndex={21}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="cc"
              value={selected.cc === '0' ? 'Contado' : 'Crédito'}
              label="Condici&oacute; de pago"
              tabIndex={21}
              size={3}
              onChange={() => {}}
              disabled
            />
            {selected.cc === '1' && (
              <>
                <Texto
                  name="dd"
                  value={selected.dd}
                  label="D&iacute;as de plazo"
                  tabIndex={22}
                  size={3}
                  onChange={() => {}}
                  disabled
                />
                <Texto
                  name="limite"
                  value={formatoFecha(limite)}
                  label="Fecha de l&iacute;mite"
                  tabIndex={22}
                  size={3}
                  onChange={() => {}}
                  disabled
                />
              </>
            )}
            <AdjuntoList
              data={
                selected.adj &&
                selected.adj.dtl &&
                selected.adj.dtl.map((a) => ({
                  id: a.cr,
                  name: a.na,
                  extension: a.ea,
                  state: a.st,
                }))
              }
              file={selected.adj && selected.adj.ar}
            />
          </>
        )}
        {(selected.vm === 'RECHORD' || selected.vm === 'ANUORD') && (
          <>
            <Texto
              name="mt"
              value={selected.mt}
              label={`Motivo de ${
                selected.vm === 'RECHORD' ? 'rechazo' : 'anulación'
              }`}
              tabIndex={21}
              size={3}
              onChange={() => {}}
              disabled
            />
            <Texto
              name="ua"
              value={selected.ua}
              label={`Usuario ${handleTexto(selected.vm)}`}
              tabIndex={22}
              size={3}
              onChange={() => {}}
              disabled
            />
          </>
        )}
        <div className="op-form-group op-col-1">
          <Tabla
            columns={columns}
            data={selected.lst}
            pagination={false}
            searcheable={false}
            hoverSelectedRow={false}
            style={{ padding: '0px' }}
          />
          {(selected.vm === 'ENTORD' || selected.vm === 'PAGAORD') && (
            <div className="op-totales">
              <label>Importe Bruto soles</label>
              <span>{orden.su}</span>
              <label>I.G.V soles</label>
              <span>{orden.im}</span>
              <label>
                <strong>Importe Total soles</strong>
              </label>
              <span>
                <strong>{orden.to}</strong>
              </span>
              {orden.tt > 1 && (
                <>
                  <div className="separador"></div>
                  <label>Importe Bruto dolares</label>
                  <span>{orden.bt}</span>
                  <label>I.G.V dolares</label>
                  <span>{orden.md}</span>
                  <label>
                    <strong>Importe Total dolares</strong>
                  </label>
                  <span>
                    <strong>{orden.td}</strong>
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
      {openAprobar && (
        <OrdenCompraAprobar
          onClose={() => {
            setOpenAprobar(false);
          }}
        />
      )}
      {openConfirmar && (
        <OrdenCompraConfirmar
          onClose={() => {
            setOpenConfirmar(false);
          }}
        />
      )}
      {openRecepcionar && (
        <OrdenCompraRecepcionar
          onClose={() => {
            setOpenRecepcionar(false);
          }}
        />
      )}
      {openPagar && (
        <OrdenCompraPagar
          onClose={() => {
            setOpenPagar(false);
          }}
        />
      )}
      {openReporte && (
        <Report
          url={`${servicios.ORDENCOMPRA}/${empresa}/empresa/${selected.oc}/imprimir`}
          extension={'pdf'}
          onClose={() => {
            setOpenReporte(false);
          }}
        />
      )}
      {openReporteExcel && (
        <Report
          url={`${servicios.ORDENCOMPRA}/${empresa}/empresa/${selected.oc}/imprimir?xl=0`}
          onClose={() => {
            setOpenReporteExcel(false);
          }}
          filename={selected.co}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.ordenCompra.selected,
    processing: state.ordenCompra.processing,
    usuario: state.app.currentUser.np,
    empresa: state.app.currentUser.ie,
    perfil: state.app.currentUser.tr,
  };
};

export default connect(mapStateToProps, {
  closeModal,
  patchAnularOrdenCompraAction,
  patchRechazarOrdenCompraAction,
  getSucursalesAction,
})(OrdenCompraVer);
