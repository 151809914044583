import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { plantillaModel } from '../models/plantilla.model';

export const PLANTILLA_PROCESS = '[Plantilla] Plantilla procesando';
export const PLANTILLA_SAVE_SUCCESS = '[Plantilla] Plantilla guardar exito';
export const PLANTILLA_LIST_SUCCESS = '[Plantilla] Plantilla lista exito';
export const PLANTILLA_LIST_FAIL = '[Plantilla] Plantilla lista error';
export const PLANTILLA_SELECTED = '[Plantilla] Plantilla seleccionado';
export const PLANTILLA_CLOSE = '[Plantilla] Plantilla cerrar modal';
export const PLANTILLA_FILTER = '[Plantilla] Plantilla filtros';
export const PLANTILLA_FAIL = '[Plantilla] Plantilla proceso error';
export const PLANTILLA_PAGINATE = '[Plantilla] Plantilla paginado';
export const PLANTILLA_ROWS = '[Plantilla] Plantilla cantidad filas';

export const getPlantillasAction = (rw) => (dispatch, getState) => {
  if (rw) {
    dispatch(filas(rw));
  }

  let { search, page, rows } = getState().plantilla;
  let { currentUser } = getState().app;

  dispatch({
    type: PLANTILLA_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.PLANTILLA, { params })
    .then((response) => {
      dispatch({
        type: PLANTILLA_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: PLANTILLA_LIST_FAIL,
      });
    })
    .finally(() => {
      if (rw) {
        dispatch(filas(10));
      }
    });
};

export const getSeleccionarPlantillaAction = (item) => (dispatch) => {
  if (item) {
    dispatch({
      type: PLANTILLA_SELECTED,
      payload: item,
    });
  } else {
    dispatch({
      type: PLANTILLA_SELECTED,
      payload: plantillaModel,
    });
  }
};

export const postPlantillaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: PLANTILLA_PROCESS,
  });

  let plantilla = {
    pl: item.pl,
    ie: currentUser.ie,
    td: item.td,
    ec: item.ec,
    st: item.st,
    nd: item.nd,
    va: item.va,
  };

  api
    .post(servicios.PLANTILLA, plantilla)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(paginado(0));
      dispatch(getPlantillasAction());
      dispatch({ type: PLANTILLA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: PLANTILLA_FAIL,
        payload: error.data.errors ? error.data.errors : plantillaModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { search } = getState().plantilla;

  let item = {
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: PLANTILLA_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: PLANTILLA_PAGINATE,
    payload: page,
  });
};

export const filas = (rows) => (dispatch) => {
  dispatch({
    type: PLANTILLA_ROWS,
    payload: rows,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: PLANTILLA_CLOSE,
  });
};
