import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getSeleccionarCorrelativoAction,
  getCorrelativosAction,
} from '../../redux/actions/correlativo.action';

const CorrelativoFilter = () => {
  const dispatch = useDispatch();
  return (
    <Filtros
      onFilter={() => dispatch(getCorrelativosAction())}
      onNew={() => dispatch(getSeleccionarCorrelativoAction())}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(CorrelativoFilter);
