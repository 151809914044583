import { api } from '../axios/api';
import Swal from 'sweetalert2';

import { notificacionModel } from '../models/notificacion.model';
import { constantes, servicios } from '../helpers/index';
import { mensajeMini, mensaje } from '../../commons/Mensajes';
import {
  formatoFecha,
  compararFechas,
  encriptado,
} from '../../commons/Utilitarios';

export const ADD_HEADER = '[App] agregar header';
export const EXIST_HEADER = '[App] existe header';
export const SELECT_HEADER = '[App] seleccionar header';
export const SELECT_HOME = '[App] seleccionar inicio';
export const REMOVE_HEADER = '[App] eliminar header';
export const REMOVE_ALL_HEADER = '[App] eliminar todas las cabeceras';
export const OPEN_MENU = '[App] abrir menu';
export const LOGIN = '[App] login';
export const LOGIN_ON = '[App] login activo';
export const LOGIN_OFF = '[App] login inactivo';
export const LOGIN_SUCCESS = '[App] login existoso';
export const LOGIN_ERROR = '[App] login error';
export const LOGOUT = '[App] logout';
export const LOGOUT_SUCCESS = '[App] logout existoso';
export const LOGOUT_ERROR = '[App] logout';
export const CAPTCHA = '[App] captcha';
export const CAPTCHA_SUCCESS = '[App] captcha exitoso';
export const CAPTCHA_FAIL = '[App] captcha fail';
export const MENUES = '[App] menues';
export const MENUES_SUCCESS = '[App] menues exitoso';
export const MENUES_FAIL = '[App] menues error';
export const HEADER_AUDIT = '[App] headers auditoria';
export const USUARIO_FORGOT_SUCCESS = '[App] Usuario olvido exitoso';
export const REPORT_PROCESS = '[App] reporte procesando';
export const REPORT_VENTAS = '[App] reporte ventas';
export const EMPRESA_DATOS = '[App] Datos de empresa';
export const UPDATE_LOGO = '[App] update logo';
export const APLICACIONES_LIST = '[App] aplicaciones de la empresa';
export const EMPRESA_MODAL = '[App] modal notificacion';
export const EMPRESA_NOTIFICACION = '[App] notificacion existosa';
export const EMPRESA_NOTIFICACION_FAIL = '[App] error al notificar';

import * as fromCategoria from './categoria.action';
import { getTokenNotificacion } from '../helpers/firebase';

export const isLogginIn = () => (dispatch) => {
  let currentUser = localStorage.getItem('currentUser');
  if (currentUser) {
    currentUser = JSON.parse(currentUser);
    dispatch({
      type: LOGIN_ON,
      payload: currentUser,
    });
  } else {
    dispatch({
      type: LOGIN_OFF,
    });
  }
};

export const postLoginAction = (user) => (dispatch, getState) => {
  let { captcha } = getState().app;
  dispatch({
    type: LOGIN,
  });

  let usuario = {
    ap: constantes.APLICACION,
    ct: captcha.ct,
    pw: encriptado(user.pd),
    us: user.us,
    tx: user.tx,
  };

  api
    .post(`${servicios.LOGIN}/login`, usuario)
    .then((response) => {
      localStorage.setItem('currentUser', JSON.stringify(response));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response,
      });
      if (response.fr !== 0) {
        mensajeMini(
          `Su contraseña temporal ${
            compararFechas(response.fr) ? 'venció' : 'expira'
          } el ${formatoFecha(response.fr)}`,
          'warning',
          15000
        );
      }
    })
    .catch((error) => {
      if (error.data.detail) {
        dispatch({
          type: LOGIN_ERROR,
          payload: error.data.detail,
        });
        dispatch(getCaptchaAction());
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'Caracteres incorrectos',
        });
        dispatch({
          type: CAPTCHA_SUCCESS,
          payload: {
            ct: error.data.errors.ct,
            si: error.data.errors.si,
            img: error.data.errors.img,
          },
        });
      }
    });
};

export const getCaptchaAction = () => (dispatch) => {
  dispatch({
    type: CAPTCHA,
  });

  api
    .get(servicios.CAPTCHA)
    .then((response) => {
      dispatch({
        type: CAPTCHA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: CAPTCHA_FAIL,
        payload: error.data.detail,
      });
    });
};

export const putCaptchaAction = (ct) => (dispatch) => {
  dispatch({
    type: CAPTCHA,
  });

  api
    .put(`${servicios.CAPTCHA}/${ct}`)
    .then((response) => {
      dispatch({
        type: CAPTCHA_SUCCESS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: CAPTCHA_FAIL,
        payload: error.data.detail,
      });
    });
};

export const getMenuesPermitidosAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;
  dispatch({
    type: MENUES,
  });
  const params = {
    em: currentUser.ie,
    rl: currentUser.ir,
  };
  api
    .get(`${servicios.MENU}/${constantes.APLICACION}/aplicacion`, { params })
    .then((response) => {
      if (response && response.constructor === Array && response.length === 0) {
        mensajeMini('No hay menus asignados', 'warning');
      }
      dispatch({
        type: MENUES_SUCCESS,
        payload: response,
      });

      if (currentUser.fr != 0 && compararFechas(currentUser.fr)) {
        let hcp = response.find((x) => x.mm === 'SEGT_CAMB');
        if (hcp) {
          dispatch(agregarHeaderAction(hcp));
        }
      }
      let pedido = response.find((x) => x.mm === 'COM_PED');
      if (pedido) {
        getTokenNotificacion(currentUser.us);
      }
    })
    .catch((error) => {
      if (error.status === 400 && error.data.errorCode === 1008) {
        Swal.fire({
          title: 'Mensaje',
          text: error.data.detail,
          icon: 'warning',
          confirmButtonColor: 'orange',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            dispatch(logoutAction());
          }
        });
      } else {
        dispatch({
          type: MENUES_FAIL,
        });
      }
    });
};

export const logoutAction = () => (dispatch) => {
  localStorage.removeItem('currentUser');
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: fromCategoria.CATEGORIA_LIST_FAIL,
  });
};

export const userInactivoAction = (user) => (dispatch) => {
  api
    .patch(`${servicios.LOGIN}/${user}/logout`)
    .then(() => {})
    .catch(() => {});
};

export const eliminarHeaderAction = (id) => (dispatch, getState) => {
  let { headers } = getState().app;
  headers = headers.filter((item) => item.mm !== id);
  dispatch({
    type: REMOVE_HEADER,
    payload: [...headers],
  });
};

export const eliminarTodasHeaderAction = () => (dispatch) => {
  dispatch({
    type: REMOVE_ALL_HEADER,
  });
};

export const seleccionarHeaderAction = (id) => (dispatch, getState) => {
  let { headers } = getState().app;
  let head = headers.find((item) => item.mm === id);
  dispatch({
    type: SELECT_HEADER,
    payload: head,
  });
};

export const seleccionarInicioAction = () => (dispatch) => {
  dispatch({
    type: SELECT_HOME,
  });
};

export const agregarHeaderAction = (item) => (dispatch, getState) => {
  let { headers } = getState().app;
  let head = headers.find((h) => h.mm === item.mm);

  if (head) {
    dispatch({
      type: SELECT_HEADER,
      payload: item,
    });
  } else {
    dispatch({
      type: ADD_HEADER,
      payload: [...headers, item],
    });
    dispatch({
      type: SELECT_HEADER,
      payload: item,
    });
  }
};

export const abrirMenuAction = () => (dispatch, getState) => {
  let { closeMenu } = getState().app;
  dispatch({
    type: OPEN_MENU,
    payload: !closeMenu,
  });
};

export const patchOlvidoContraseniaAction = (item) => (dispatch, getState) => {
  let { captcha } = getState().app;
  dispatch({
    type: LOGIN,
  });

  let user = {
    us: item.us,
    ct: captcha.ct,
    tx: item.tx,
  };

  api
    .patch(`${servicios.USUARIO}/olvido`, user)
    .then(() => {
      mensaje('Operación exitosa, revise su correo electrónico');
      dispatch({
        type: USUARIO_FORGOT_SUCCESS,
      });
    })
    .catch((error) => {
      if (error.data.detail) {
        dispatch({
          type: LOGIN_ERROR,
          payload: error.data.detail,
        });
        dispatch(getCaptchaAction());
      } else {
        dispatch({
          type: LOGIN_ERROR,
          payload: 'Caracteres incorrectos',
        });
        dispatch({
          type: CAPTCHA_SUCCESS,
          payload: {
            ct: error.data.errors.ct,
            si: error.data.errors.si,
            img: error.data.errors.img,
          },
        });
      }
    });
};

export const patchRestablecerContraseniaAction =
  (item) => (dispatch, getState) => {
    let { captcha } = getState().app;
    dispatch({
      type: LOGIN,
    });

    let user = {
      us: item.us,
      co: encriptado(item.co),
      nu: encriptado(item.nu),
      nc: encriptado(item.nc),
      ct: captcha.ct,
      tx: item.tx,
    };

    api
      .patch(`${servicios.USUARIO}/restablecer`, user)
      .then(() => {
        mensaje(
          'Operación exitosa, ya puede iniciar sesión con su nueva contraseña'
        );
        dispatch({
          type: USUARIO_FORGOT_SUCCESS,
        });
      })
      .catch((error) => {
        if (error.data.detail) {
          dispatch({
            type: LOGIN_ERROR,
            payload: error.data.detail,
          });
          dispatch(getCaptchaAction());
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: 'Caracteres incorrectos',
          });
          dispatch({
            type: CAPTCHA_SUCCESS,
            payload: {
              ct: error.data.errors.ct,
              si: error.data.errors.si,
              img: error.data.errors.img,
            },
          });
        }
      });
  };

export const getReporteVentas = (tm, su) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  const params = {
    em: currentUser.ie,
    tm,
    su,
  };

  dispatch({
    type: REPORT_PROCESS,
    payload: true,
  });

  api
    .get(`${servicios.DOCUMENTO}/reporte`, { params })
    .then((response) => {
      dispatch({
        type: REPORT_VENTAS,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: REPORT_VENTAS,
        payload: [],
      });
    })
    .finally(() => {
      setTimeout(
        () =>
          dispatch({
            type: REPORT_PROCESS,
            payload: false,
          }),
        1000
      );
    });
};

export const finishProcessReport = () => (dispatch) => {
  dispatch({
    type: REPORT_PROCESS,
    payload: false,
  });
};

export const getDatos = () => (dispatch, getState) => {
  let { currentUser } = getState().app;
  dispatch({
    type: LOGIN,
    payload: false,
  });

  api
    .get(`${servicios.EMPRESA}/${currentUser.ie}/datos`)
    .then((response) => {
      dispatch({
        type: EMPRESA_DATOS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: EMPRESA_DATOS,
        payload: null,
      });
    });
};

export const openModalNotificacion = (valor) => (dispatch) => {
  dispatch({
    type: EMPRESA_MODAL,
    payload: valor,
  });
};

export const patchNotificacionAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  dispatch({
    type: LOGIN,
    payload: false,
  });

  let notificacion = {
    ...item,
    em: currentUser.ie,
  };

  api
    .patch(`${servicios.EMPRESA}/notificar`, notificacion)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch({
        type: EMPRESA_NOTIFICACION,
      });
      dispatch(getDatos());
    })
    .catch((error) => {
      dispatch({
        type: EMPRESA_NOTIFICACION_FAIL,
        payload: error.data.errors ? error.data.errors : notificacionModel,
      });
    });
};

export const patchUpdateLogo = (imagen) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  dispatch({
    type: LOGIN,
    payload: false,
  });

  let form_data = new FormData();
  form_data.append('ie', currentUser.ie);
  form_data.append('file', imagen);

  api
    .patch(`${servicios.EMPRESA}/logo`, form_data, {
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
    })
    .then(() => {
      dispatch({
        type: UPDATE_LOGO,
      });
      dispatch(getDatos());
    })
    .catch(() => {
      dispatch({
        type: UPDATE_LOGO,
      });
    });
};

export const getAplicacionesAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: LOGIN,
  });

  api
    .get(`${servicios.APLICACION}/${currentUser.ie}/empresa?ac=1`)
    .then((response) => {
      dispatch({
        type: APLICACIONES_LIST,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: APLICACIONES_LIST,
        response: [],
      });
    });
};
