export const ordenCompraModel = {
  oc: null,
  so: null,
  nu: null,
  co: null,
  pv: null,
  nr: null,
  rz: null,
  pc: null,
  pt: null,
  rc: null,
  rt: null,
  sd: '',
  ns: null,
  fa: null,
  fr: null,
  fe: null,
  es: null,
  de: null,
  su: null,
  im: null,
  to: null,
  mt: null,
  ua: null,
  up: null,
  tu: null,
  du: null,
  dp: null,
  ip: null,
  cp: null,
  su: null,
  ic: null,
  tc: null,
  bt: null,
  md: null,
  td: null,
  nd: null,
  ne: null,
  ia: null,
  cc: null,
  dd: null,
  fp: null,
  tt: null,
  adj: null,
  st: false,
  lst: [],
};
