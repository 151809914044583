import * as fromPlantilla from '../actions/plantilla.action';
import { plantillaModel } from '../models/plantilla.model';

let initialState = {
  plantillas: [],
  search: null,
  total: 0,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromPlantilla.PLANTILLA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: plantillaModel,
      };
    case fromPlantilla.PLANTILLA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        plantillas: action.payload.data,
        total: action.payload.count,
      };
    case fromPlantilla.PLANTILLA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        plantillas: [],
        total: 0,
      };
    case fromPlantilla.PLANTILLA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: plantillaModel,
        openModal: true,
      };
    case fromPlantilla.PLANTILLA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: plantillaModel,
      };
    case fromPlantilla.PLANTILLA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromPlantilla.PLANTILLA_FILTER:
      return {
        ...state,
        search: action.payload.search,
        page: 0,
      };
    case fromPlantilla.PLANTILLA_CLOSE:
      return {
        ...state,
        errors: plantillaModel,
        openModal: false,
      };
    case fromPlantilla.PLANTILLA_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    case fromPlantilla.PLANTILLA_ROWS:
      return {
        ...state,
        rows: action.payload,
      };
    default:
      return state;
  }
}
