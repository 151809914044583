import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getMaterialesAction,
  getSeleccionarMaterialAction,
  actualizarFiltros,
} from '../../redux/actions/material.action';

import EditIcon from '@material-ui/icons/Edit';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';

const MaterialList = ({
  materiales,
  processing,
  total,
  getMaterialesAction,
  getSeleccionarMaterialAction,
  actualizarFiltros,
}) => {
  const columns = [
    {
      key: 'mt',
      align: 'center',
      content: (item) => (
        <>
          <div title="Editar" style={{ display: 'inline-block' }}>
            <EditIcon
              onClick={() => getSeleccionarMaterialAction(item)}
              style={{ color: 'green', cursor: 'pointer' }}
            />
          </div>
          <div title="Imagen" style={{ display: 'inline-block' }}>
            <InsertPhotoIcon
              onClick={() => getSeleccionarMaterialAction(item, 'imagen')}
              style={{ color: '#3285a8', cursor: 'pointer' }}
            />
          </div>
        </>
      ),
    },
    {
      key: 'cm',
      label: 'Código',
    },
    {
      key: 'nm',
      label: 'Nombre',
    },
    {
      key: 'du',
      label: 'Unidad medida',
    },
    {
      key: 'pr',
      label: 'Producto / Insumo',
      content: (item) => `${item.pr ? 'Producto' : 'Insumo'}`,
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={materiales}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getMaterialesAction({ page, pageSize: rows })
        }
        onSearching={() => getMaterialesAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    materiales: state.material.materiales,
    processing: state.material.processing,
    total: state.material.total,
  };
};

export default connect(mapStateToProps, {
  getMaterialesAction,
  getSeleccionarMaterialAction,
  actualizarFiltros,
})(MaterialList);
