import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { actualizarFiltros } from '../../redux/actions/chica.operacion.action';

import ChicaOperacionList from './ChicaOperacionList';
import ChicaOperacionFilter from './ChicaOperacionFilter';
import ChicaOperacionItem from './ChicaOperacionItem';
import ChicaOperacionView from './ChicaOperacionView';

const ChicaOperacion = ({ openModal, openModalView, actualizarFiltros }) => {
  useEffect(() => {
    return () => {
      actualizarFiltros({ su: null, tc: null, tm: null, is: null });
    };
  }, []);

  return (
    <div className="op-component">
      <ChicaOperacionList />
      <ChicaOperacionFilter />
      {openModal && <ChicaOperacionItem />}
      {openModalView && <ChicaOperacionView />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.chicaOperacion.openModal,
    openModalView: state.chicaOperacion.openModalView,
    sucursal: state.chica.su,
  };
};

export default connect(mapStateToProps, {
  actualizarFiltros,
})(ChicaOperacion);
