import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postCategoriaAction,
} from '../../redux/actions/categoria.action';

import SaveIcon from '@material-ui/icons/Save';

const CategoriaItem = () => {
  const dispatch = useDispatch();
  const { selected, processing, tipos } = useSelector(
    (state) => state.categoria
  );

  const [categoria, setCategoria] = useState(selected);

  useEffect(() => {
    let find = tipos.find((x) => x.nc === selected.cte.nc);
    if (find) {
      handleChange({ name: 'ct', value: find.ct });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setCategoria((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${categoria.cte.ct ? 'Actualizar' : 'Nueva'} categoría`}
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${categoria.cte.ct ? 'Actualizar' : 'Una nueva'} categoría`
            )
              .then(() => dispatch(postCategoriaAction(categoria)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Combo
        name="ct"
        label="Tipo categorias"
        value={categoria.ct}
        tabIndex={10}
        data={tipos.map((i) => ({
          value: i.ct,
          label: i.nc,
        }))}
        onChange={handleChange}
      />
      <div className="op-form-group op-col-1 op-checkbox-group">
        <span>Aplica</span>
        <CheckBox name="ai" checked={categoria.ai} onChange={handleChange} />
      </div>
    </Modal>
  );
};

export default CategoriaItem;
