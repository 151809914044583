export const plantillaModel = {
  pl: null,
  td: null,
  nt: null,
  vt: null,
  nd: null,
  ec: null,
  de: null,
  or: null,
  vl: null,
  va: null,
  st: false,
};
