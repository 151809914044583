import { useState, useEffect } from 'react';
import { api } from '../redux/axios/api';
import { constantes, servicios } from '../redux/helpers';

const useCancelacion = () => {
  const [cancelations, setCancelations] = useState([]);
  const [processingCancelation, setProcessingCancelation] = useState(false);

  const getCancelations = () => {
    setProcessingCancelation(true);
    let params = {
      ap: constantes.LAVANDERIA,
      tc: 1,
      st: 1,
    };
    api
      .get(`${servicios.CANCELACION}`, { params })
      .then((response) =>
        setCancelations(response.map((x) => ({ ...x, co: `${x.co}` })))
      )
      .catch(() => setCancelations([]))
      .finally(() => setProcessingCancelation(false));
  };

  useEffect(() => {
    getCancelations();
  }, []);
  return {
    cancelations,
    processingCancelation,
  };
};

export default useCancelacion;
