import * as fromCajero from '../actions/cajero.action';
import { cajeroModel } from '../models/cajero.model';

let initialState = {
  cajeros: [],
  operaciones: [],
  su: null,
  suf: null,
  total: 0,
  processing: false,
  selected: cajeroModel,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCajero.CAJERO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: cajeroModel,
      };
    case fromCajero.CAJERO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        cajeros: action.payload.data,
        total: action.payload.count,
      };
    case fromCajero.CAJERO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        cajeros: [],
        total: 0,
      };
    case fromCajero.CAJERO_LIST_OPERATION:
      return {
        ...state,
        operaciones: action.payload,
        processing: false,
      };
    case fromCajero.CAJERO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: cajeroModel,
        processing: false,
      };
    case fromCajero.CAJERO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: cajeroModel,
        openModal: false,
      };
    case fromCajero.CAJERO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCajero.CAJERO_FILTER:
      return {
        ...state,
        su: action.payload.su,
        suf: action.payload.suf,
      };
    case fromCajero.CAJERO_OPEN:
      return {
        ...state,
        openModal: true,
        errors: cajeroModel,
      };
    case fromCajero.CAJERO_CLOSE:
      return {
        ...state,
        openModal: false,
        errors: cajeroModel,
      };
    default:
      return state;
  }
}
