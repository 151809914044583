import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getCorrelativosAction } from '../../redux/actions/correlativo.action';

import CorrelativoFilter from './CorrelativoFilter';
import CorrelativoList from './CorrelativoList';
import CorrelativoItem from './CorrelativoItem';
import CorrelativoNew from './CorrelativoNew';

const Correlativo = ({ openModal, openModalNew }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCorrelativosAction());
  }, []);

  return (
    <div className="op-component">
      <CorrelativoList />
      <CorrelativoFilter />
      {openModal && <CorrelativoItem />}
      {openModalNew && <CorrelativoNew />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.correlativo.openModal,
    openModalNew: state.correlativo.openModalNew,
  };
};

export default connect(mapStateToProps)(Correlativo);
