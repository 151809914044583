import React from 'react';
import { useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getSeleccionarCategoriaAction,
  getCategoriaAction,
} from '../../redux/actions/categoria.action';

const CategoriaFilter = () => {
  const dispatch = useDispatch();
  return (
    <Filtros
      showFilterButton={false}
      onFilter={() => dispatch(getCategoriaAction())}
      onNew={() => dispatch(getSeleccionarCategoriaAction())}
    ></Filtros>
  );
};

export default CategoriaFilter;
