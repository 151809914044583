import * as fromApp from '../actions/app.action';
import { notificacionModel } from '../models/notificacion.model';

let initialState = {
  currentUser: null,
  auditoria: null,
  captcha: null,
  headers: [],
  menues: [],
  aplicaciones: [],
  menuesProcessing: false,
  selected: null,
  closeMenu: false,
  processing: false,
  captchaProcessing: true,
  loggedIn: false,
  ventas: [],
  datos: null,
  notificacion: notificacionModel,
  errors: notificacionModel,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromApp.LOGIN_ON:
      return {
        ...state,
        currentUser: action.payload,
        loggedIn: true,
      };
    case fromApp.LOGIN:
      return {
        ...state,
        processing: true,
        errors: notificacionModel,
        error: null,
      };
    case fromApp.LOGIN_SUCCESS:
      return {
        ...state,
        processing: false,
        loggedIn: true,
        currentUser: action.payload,
        captcha: null,
        error: null,
      };
    case fromApp.USUARIO_FORGOT_SUCCESS: {
      return {
        ...state,
        processing: false,
        error: null,
      };
    }
    case fromApp.HEADER_AUDIT:
      return {
        ...state,
        auditoria: action.payload,
      };
    case fromApp.LOGIN_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case fromApp.LOGOUT:
      return {
        ...state,
        currentUser: null,
        auditoria: null,
        captcha: null,
        headers: [],
        menues: [],
        aplicaciones: [],
        menuesProcessing: false,
        selected: null,
        closeMenu: false,
        processing: false,
        captchaProcessing: true,
        loggedIn: false,
      };
    case fromApp.CAPTCHA:
      return {
        ...state,
        captchaProcessing: true,
        error: null,
      };
    case fromApp.CAPTCHA_SUCCESS:
      return {
        ...state,
        captchaProcessing: false,
        captcha: action.payload,
      };
    case fromApp.CAPTCHA_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case fromApp.MENUES:
      return {
        ...state,
        menuesProcessing: true,
      };
    case fromApp.MENUES_SUCCESS: {
      return {
        ...state,
        menues: action.payload,
        menuesProcessing: false,
      };
    }
    case fromApp.MENUES_FAIL:
      return {
        ...state,
        menuesProcessing: false,
      };
    case fromApp.REMOVE_HEADER:
      return {
        ...state,
        headers: action.payload,
      };
    case fromApp.REMOVE_ALL_HEADER:
      return {
        ...state,
        headers: [],
        selected: null,
      };
    case fromApp.SELECT_HEADER:
      return {
        ...state,
        selected: action.payload,
      };
    case fromApp.SELECT_HOME: {
      return {
        ...state,
        selected: null,
      };
    }
    case fromApp.ADD_HEADER:
      return {
        ...state,
        headers: action.payload,
      };
    case fromApp.OPEN_MENU:
      return {
        ...state,
        closeMenu: action.payload,
      };
    case fromApp.REPORT_PROCESS:
      return {
        ...state,
        processing: action.payload,
      };
    case fromApp.REPORT_VENTAS:
      return {
        ...state,
        ventas: action.payload,
      };
    case fromApp.EMPRESA_DATOS:
      return {
        ...state,
        datos: action.payload,
        processing: false,
      };
    case fromApp.EMPRESA_MODAL:
      return {
        ...state,
        openModal: action.payload,
        notificacion: notificacionModel,
      };
    case fromApp.EMPRESA_NOTIFICACION:
      return {
        ...state,
        processing: false,
        notificacion: notificacionModel,
        openModal: false,
      };
    case fromApp.EMPRESA_NOTIFICACION_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromApp.UPDATE_LOGO:
      return {
        ...state,
        processing: false,
        datos: null,
      };
    case fromApp.APLICACIONES_LIST:
      return {
        ...state,
        aplicaciones: action.payload,
      };
    default:
      return state;
  }
}
