import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';

import Texto from '../../commons/Texto';
import {
  obtenerColorAleatorio,
  formatearFecha,
} from '../../commons/Utilitarios';

import { getContratosReportAction } from '../../redux/actions/contrato.action';

import SyncIcon from '@material-ui/icons/Sync';

const ServicioReporte = () => {
  const [inicio, setInicio] = useState(`${new Date().getFullYear()}-01-01`);
  const [fin, setFin] = useState(formatearFecha(new Date(), 'YYYY-MM-DD'));
  const dispatch = useDispatch();
  const { contratosReportes } = useSelector((state) => state.contrato);

  useEffect(() => {
    dispatch(getContratosReportAction(inicio, fin));
  }, []);

  const handleGetContratoReport = () => {
    const colors = ['#f30412', '#f57e05', '#27a117', '#174aa1'];

    let cobranzaData = {
      labels: contratosReportes.map((x) => x.se),
      datasets: [
        {
          label: 'Servicios contratados',
          data: contratosReportes.map((x) => x.to),
          backgroundColor: contratosReportes.map(() => obtenerColorAleatorio()),
        },
      ],
      hoverOffset: 4,
    };
    return (
      <Doughnut
        data={cobranzaData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
          },
        }}
      />
    );
  };

  return (
    <div className="grid-item action servicios">
      <label className="title">Servicios contratados</label>
      <div className="sync" title="Refrescar">
        <SyncIcon
          onClick={() => dispatch(getContratosReportAction(inicio, fin))}
        />
      </div>
      <div className="actions">
        <Texto
          name="fi"
          value={inicio}
          label=""
          tabIndex={23}
          size={2}
          type={'date'}
          onChange={({ value }) => {
            setInicio(value);
            dispatch(getContratosReportAction(value, fin));
          }}
        />
        <Texto
          name="ff"
          value={fin}
          label=""
          tabIndex={23}
          size={2}
          type={'date'}
          onChange={({ value }) => {
            setFin(value);
            dispatch(getContratosReportAction(inicio, value));
          }}
        />
      </div>
      {handleGetContratoReport()}
    </div>
  );
};

export default ServicioReporte;
