import * as fromOperacion from '../actions/operacion.action';
import { operacionModel } from '../models/operacion.model';

let initialState = {
  operaciones: [],
  su: null,
  ct: null,
  is: null,
  fi: null,
  ff: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromOperacion.OPERACION_PROCESS:
      return {
        ...state,
        processing: true,
        errors: operacionModel,
      };
    case fromOperacion.OPERACION_LIST_SUCCESS:
      return {
        ...state,
        operaciones: action.payload.data,
        total: action.payload.count,
        processing: false,
      };
    case fromOperacion.OPERACION_LIST_FAIL:
      return {
        ...state,
        operaciones: [],
        total: 0,
        processing: false,
      };
    case fromOperacion.OPERACION_SELECTED:
      return {
        ...state,
        selected: action.payload,
        openModal: true,
        errors: operacionModel,
      };
    case fromOperacion.OPERACION_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: operacionModel,
        openModal: false,
      };
    case fromOperacion.OPERACION_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromOperacion.OPERACION_FILTER:
      return {
        ...state,
        su: action.payload.su,
        ct: action.payload.ct,
        is: action.payload.is,
        fi: action.payload.fi,
        ff: action.payload.ff,
        search: action.payload.search,
      };
    case fromOperacion.OPERACION_CLOSE:
      return {
        ...state,
        openModal: false,
        errors: operacionModel,
      };
    default:
      return state;
  }
}
