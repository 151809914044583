import React from 'react';
import { connect } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Texto from '../../commons/Texto';
import Bloque from '../../commons/Bloque';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';

import {
  getSeleccionarOperacionAction,
  getOperacionesCajaChicaAction,
  actualizarFiltros,
} from '../../redux/actions/chica.operacion.action';

import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const ChicaOperacionList = ({
  selected,
  operaciones,
  processing,
  total,
  getOperacionesCajaChicaAction,
  getSeleccionarOperacionAction,
  actualizarFiltros,
}) => {
  const handleClass = (item) => {
    if (!item.st) {
      return 'op-delete';
    } else if (item.is === 1) {
      return 'op-success';
    } else {
      return 'op-error';
    }
  };

  const columns = [
    {
      key: 'fo',
      label: 'Fecha',
      align: 'right',
      width: '160px',
      content: (item) => (
        <span className={handleClass(item)}>
          {formatoFecha(item.fo, 'Indefinido')}
        </span>
      ),
    },
    {
      key: 'dc',
      label: 'Tipo caja',
      content: (item) => <span className={handleClass(item)}>{item.dc}</span>,
    },
    {
      key: 'dd',
      content: (item) => (
        <span className={handleClass(item)}>
          {item.dd ? item.dd.charAt(0) : ''}
        </span>
      ),
    },
    {
      key: 'nd',
      label: 'Número',
      content: (item) => <span className={handleClass(item)}>{item.nd}</span>,
    },
    {
      key: 'rz',
      label: 'Razón social',
      content: (item) => <span className={handleClass(item)}>{item.rz}</span>,
    },
    {
      key: 'co',
      label: 'Concepto',
      content: (item) => (
        <div
          className="op-centrar-componentes"
          style={{ justifyContent: 'flex-start' }}
        >
          {item.is === 1 ? (
            <TrendingUpIcon fontSize="small" style={{ color: 'green' }} />
          ) : (
            <TrendingDownIcon fontSize="small" style={{ color: 'red' }} />
          )}
          <span className={handleClass(item)}>{item.co}</span>
        </div>
      ),
    },
    {
      key: 'mo',
      label: 'Monto',
      align: 'right',
      content: (item) => (
        <div
          className={`op-centrar-componentes op-arrow-${
            item.is === 1 ? 'up' : 'down'
          } ${handleClass(item)}`}
          style={{
            justifyContent: 'flex-end',
          }}
        >
          {item.tm === 0 ? 'S/' : '$'}
          {formatoMonetario(redondeoDecimales(item.mo))}
        </div>
      ),
    },
  ];

  return (
    <Bloque titulo="Operaciones de caja chica">
      <div className="op-form" style={{ padding: '20px' }}>
        <Texto
          name="ns"
          value={selected ? selected.ns : ''}
          label="Sucursal"
          size={3}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="sn"
          value={
            selected ? formatoMonetario(redondeoDecimales(selected.sn)) : ''
          }
          label="Saldo actual soles"
          size={3}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="se"
          value={
            selected ? formatoMonetario(redondeoDecimales(selected.se)) : ''
          }
          label="Saldo actual dolares"
          size={3}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fa"
          value={selected ? formatoFecha(selected.fa, '', false) : ''}
          label="Apertura"
          size={3}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="fc"
          value={selected ? formatoFecha(selected.fc, 'Pendiente', false) : ''}
          label="Cierre"
          size={3}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
        <Texto
          name="nr"
          value={selected ? selected.nr : ''}
          label="Responsable"
          size={3}
          tabIndex={30}
          onChange={() => {}}
          disabled
        />
      </div>
      <Tabla
        columns={columns}
        data={operaciones}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          getOperacionesCajaChicaAction({ page, pageSize: rows })
        }
        onSearching={() => getOperacionesCajaChicaAction({})}
        onChangeSearching={(search) => actualizarFiltros({ search })}
        onDoubleClick={(item) => getSeleccionarOperacionAction(item)}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.chica.selected,
    operaciones: state.chicaOperacion.operaciones,
    processing: state.chicaOperacion.processing,
    total: state.chicaOperacion.total,
  };
};

export default connect(mapStateToProps, {
  getOperacionesCajaChicaAction,
  getSeleccionarOperacionAction,
  actualizarFiltros,
})(ChicaOperacionList);
