import * as fromPedido from '../actions/pedido.action';
import { pedidoModel } from '../models/pedido.model';

let initialState = {
  pedidos: [],
  pedidosReporte: [],
  notificaciones: 0,
  total: 0,
  em: null,
  ep: null,
  su: null,
  search: null,
  page: 0,
  rows: 10,
  processing: false,
  selected: null,
  openModal: false,
  openModalNuevo: false,
  openModalPick: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromPedido.PEDIDO_PROCESS:
      return {
        ...state,
        processing: true,
      };
    case fromPedido.PEDIDO_ADD_ARRIVED:
      return {
        ...state,
        notificaciones: state.notificaciones + 1,
      };
    case fromPedido.PEDIDO_RESTARD_ARRIVED:
      return {
        ...state,
        notificaciones: 0,
      };
    case fromPedido.PEDIDO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        pedidos: action.payload.data,
        total: action.payload.count,
      };
    case fromPedido.PEDIDO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        pedidos: [],
        total: 0,
      };
    case fromPedido.PEDIDO_LIST_REPORT:
      return {
        ...state,
        pedidosReporte: action.payload,
        processing: false,
      };
    case fromPedido.PEDIDO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: pedidoModel,
        openModal: true,
      };
    case fromPedido.PEDIDO_NEW:
      return {
        ...state,
        selected: action.payload,
        errors: pedidoModel,
        openModalNuevo: true,
      };
    case fromPedido.PEDIDO_PICK:
      return {
        ...state,
        selected: action.payload,
        errors: pedidoModel,
        openModalPick: true,
      };
    case fromPedido.PEDIDO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: pedidoModel,
        openModal: false,
        openModalNuevo: false,
        openModalPick: false,
      };
    case fromPedido.PEDIDO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromPedido.PEDIDO_FILTER:
      return {
        ...state,
        em: action.payload.em,
        ep: action.payload.ep,
        su: action.payload.su,
        search: action.payload.search,
        page: 0,
      };
    case fromPedido.PEDIDO_PAGINATE:
      return {
        ...state,
        page: action.payload,
      };
    case fromPedido.PEDIDO_CLOSE:
      return {
        ...state,
        openModal: false,
        openModalNuevo: false,
        openModalPick: false,
      };
    default:
      return state;
  }
}
