import { api } from '../axios/api';
import { constantes, servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { materialModel } from '../models/material.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const MATERIAL_PROCESS = '[Material] Material procesando';
export const MATERIAL_SAVE_SUCCESS = '[Material] Material guardar exito';
export const MATERIAL_LIST_SUCCESS = '[Material] Material lista exito';
export const MATERIAL_LIST_FAIL = '[Material] Material lista error';
export const MATERIAL_SELECTED = '[Material] Material seleccionado';
export const MATERIAL_IMAGE = '[Material] Material imagen';
export const MATERIAL_CLOSE = '[Material] Material cerrar modal';
export const MATERIAL_FILTER = '[Material] Material filtros';
export const MATERIAL_FAIL = '[Material] Material proceso error';

export const getMaterialesAction =
  ({ page, pageSize }) =>
  (dispatch, getState) => {
    let { pr, um, st, search } = getState().material;
    let { currentUser } = getState().app;

    dispatch({
      type: MATERIAL_PROCESS,
    });

    const params = {
      em: currentUser.ie,
      pr,
      um,
      st,
      search,
      page,
      pageSize,
    };

    api
      .get(servicios.MATERIAL, { params })
      .then((response) => {
        dispatch({
          type: MATERIAL_LIST_SUCCESS,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: MATERIAL_LIST_FAIL,
        });
      });
  };

export const getSeleccionarMaterialAction =
  (item, imagen) => (dispatch, getState) => {
    let { currentUser } = getState().app;
    if (item) {
      api
        .get(`${servicios.MATERIAL}/${currentUser.ie}/empresa/${item.mt}`)
        .then((response) => {
          dispatch({
            type: imagen ? MATERIAL_IMAGE : MATERIAL_SELECTED,
            payload: response,
          });
        })
        .catch(() => {
          dispatch({
            type: MATERIAL_SELECTED,
            payload: materialModel,
          });
        });
    } else {
      dispatch({
        type: MATERIAL_SELECTED,
        payload: materialModel,
      });
    }
  };

export const postMaterialAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: MATERIAL_PROCESS,
  });

  let material = {
    mt: item.mt,
    em: currentUser.ie,
    iu: currentUser.us,
    cm: item.cm,
    nm: item.nm,
    um: item.um,
    gc: item.gc,
    pc: quitarFormatoMonetario(item.pc),
    pr: item.pr,
    st: item.st,
  };

  api
    .post(servicios.MATERIAL, material)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getMaterialesAction({}));
      dispatch({ type: MATERIAL_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: MATERIAL_FAIL,
        payload: error.data.errors ? error.data.errors : materialModel,
      });
    });
};

export const patchImagenMaterialAction =
  (item, imagen) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: MATERIAL_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('mt', item.mt);
    form_data.append('ie', currentUser.ie);
    form_data.append('file', imagen);

    api
      .patch(`${servicios.MATERIAL}/imagen`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getMaterialesAction({}));
        dispatch({
          type: MATERIAL_SAVE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: MATERIAL_FAIL,
          payload: error.data.errors ? error.data.errors : materialModel,
        });
      });
  };

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { pr, um, st, search } = getState().material;

  let item = {
    pr: filtros.pr !== undefined ? (filtros.pr === '' ? null : filtros.pr) : pr,
    um: filtros.um !== undefined ? (filtros.um === '' ? null : filtros.um) : um,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: MATERIAL_FILTER,
    payload: item,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: MATERIAL_CLOSE,
  });
};
