import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getEstadoContratoAction } from '../../redux/actions/adicional.action';
import { getPerfilesAction } from '../../redux/actions/perfiles.action';
import { getRolesAction } from '../../redux/actions/usuario.action';

import PerfilesFilter from './PerfilesFilter';
import PerfilesList from './PerfilesList';
import PerfilesItem from './PerfilesItem';
import PerfilesEdit from './PerfilesEdit';

const Perfiles = ({ openModal, openModalEdit }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPerfilesAction());
    dispatch(getRolesAction());
    dispatch(getEstadoContratoAction());
  }, []);

  return (
    <div className="op-component">
      <PerfilesList />
      <PerfilesFilter />
      {openModal && <PerfilesItem />}
      {openModalEdit && <PerfilesEdit />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.perfiles.openModal,
    openModalEdit: state.perfiles.openModalEdit,
  };
};

export default connect(mapStateToProps)(Perfiles);
