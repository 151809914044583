import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';

import {
  getPlantillasAction,
  getSeleccionarPlantillaAction,
} from '../../redux/actions/plantilla.action';

const PlantillaFilter = () => {
  const dispatch = useDispatch();

  return (
    <Filtros
      onFilter={() => dispatch(getPlantillasAction())}
      onNew={() => dispatch(getSeleccionarPlantillaAction())}
      showFilterButton={false}
    ></Filtros>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps)(PlantillaFilter);
