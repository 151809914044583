import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { contratoModel } from '../models/contrato.model';
import { quitarFormatoMonetario } from '../../commons/Utilitarios';

export const CONTRATO_PROCESS = '[Contrato] Contrato procesando';
export const CONTRATO_SAVE_SUCCESS = '[Contrato] Contrato guardar exito';
export const CONTRATO_LIST_SUCCESS = '[Contrato] Contrato lista exito';
export const CONTRATO_LIST_FAIL = '[Contrato] Contrato lista error';
export const CONTRATO_LIST_REPORT = '[Contrato] Contrato lista reportes';
export const CONTRATO_SELECTED = '[Contrato] Contrato seleccionado';
export const CONTRATO_SELECTED_VIEW = '[Contrato] Contrato ver seleccionado';
export const CONTRATO_SELECTED_EDIT = '[Contrato] Contrato editar seleccionado';
export const CONTRATO_CLOSE = '[Contrato] Contrato cerrar modal';
export const CONTRATO_FILTER = '[Contrato] Contrato filtros';
export const CONTRATO_FAIL = '[Contrato] Contrato proceso error';
export const CONTRATO_PAGINATE = '[Contrato] Contrato paginado';
export const CONTRATO_STATES = '[Contrato] Contrato lista estados contrato';

export const getContratosAction = () => (dispatch, getState) => {
  let { su, suf, us, ec, fi, ff, search, page, rows } = getState().contrato;
  let { currentUser } = getState().app;

  dispatch({
    type: CONTRATO_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    su,
    suf,
    us,
    ec,
    fi,
    ff,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.CONTRATO, { params })
    .then((response) => {
      dispatch({
        type: CONTRATO_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CONTRATO_LIST_FAIL,
      });
    });
};

export const getContratosReportAction = (fi, ff) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CONTRATO_PROCESS,
  });

  const params = {
    fi,
    ff,
  };

  api
    .get(`${servicios.CONTRATO}/${currentUser.ie}/reporte`, { params })
    .then((response) => {
      dispatch({
        type: CONTRATO_LIST_REPORT,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: CONTRATO_LIST_REPORT,
        payload: [],
      });
    });
};

export const getSeleccionarContratoAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  if (item) {
    dispatch({
      type: CONTRATO_PROCESS,
    });
    api
      .get(
        `${servicios.CONTRATO}/${item.co}/empresa/${currentUser.ie}/${currentUser.us}`
      )
      .then((response) => {
        dispatch({
          type: CONTRATO_SELECTED_VIEW,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CONTRATO_SELECTED,
          payload: contratoModel,
        });
      });
  } else {
    dispatch({
      type: CONTRATO_SELECTED,
      payload: contratoModel,
    });
  }
};

export const getEditarContratoAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;
  if (item) {
    dispatch({
      type: CONTRATO_PROCESS,
    });
    api
      .get(
        `${servicios.CONTRATO}/${item.co}/empresa/${currentUser.ie}/${currentUser.us}`
      )
      .then((response) => {
        response.sc = false;
        dispatch({
          type: CONTRATO_SELECTED_EDIT,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: CONTRATO_SELECTED,
          payload: contratoModel,
        });
      });
  } else {
    dispatch({
      type: CONTRATO_SELECTED,
      payload: contratoModel,
    });
  }
};

export const postContratoAction =
  (item, dtl, imagen) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: CONTRATO_PROCESS,
    });

    let contrato = {
      ie: currentUser.ie,
      su: currentUser.is,
      us: currentUser.us,
      cc: item.cc,
      cs: item.cs,
      ot: item.ot,
      gc: item.gc,
      mp: item.mp,
      dtl,
    };

    api
      .post(servicios.CONTRATO, contrato)
      .then((response) => {
        mensaje('Operación exitosa');
        dispatch(paginado(0));
        dispatch(getContratosAction({}));
        dispatch({ type: CONTRATO_SAVE_SUCCESS });
        if (imagen.length > 0) {
          let nuevo = {
            co: response,
            pd: item.pd,
          };
          dispatch(patchContratoAction(nuevo, imagen));
        }
      })
      .catch((error) => {
        dispatch({
          type: CONTRATO_FAIL,
          payload: error.data.errors ? error.data.errors : contratoModel,
        });
      });
  };

export const putContratoAction =
  (item, estado, accion, dtl, imagen, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: CONTRATO_PROCESS,
    });

    dtl = dtl
      .map((x) =>
        x.vt === '07' && x.va === '01' ? { ...x, ua: x.vd, vd: x.lb } : { ...x }
      )
      .map((x) =>
        x.vt === '07' && x.va === '02' ? { ...x, ad: x.lb } : { ...x }
      );

    let contrato = {
      co: item.co,
      ie: currentUser.ie,
      ua: currentUser.us,
      ec: estado,
      ac: accion,
      ci: item.ci,
      fi: item.fi,
      ff: item.ff,
      fr: item.fr,
      cu: item.cu,
      si: quitarFormatoMonetario(item.si),
      ic: item.ic,
      al: motivo,
      dtl,
    };

    api
      .put(servicios.CONTRATO, contrato)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(paginado(0));
        dispatch(getContratosAction({}));
        dispatch({ type: CONTRATO_SAVE_SUCCESS });
        if (imagen && imagen.length > 0) {
          dispatch(patchContratoAction(item, imagen));
        }
      })
      .catch((error) => {
        dispatch({
          type: CONTRATO_FAIL,
          payload: error.data.errors ? error.data.errors : contratoModel,
        });
      });
  };

export const patchEditarContratoAction =
  (item, dtl) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: CONTRATO_PROCESS,
    });

    dtl = dtl
      .map((x) =>
        x.vt === '07' && x.va === '01' ? { ...x, ua: x.vd, vd: x.lb } : { ...x }
      )
      .map((x) =>
        x.vt === '07' && x.va === '02' ? { ...x, ad: x.lb } : { ...x }
      );

    let contrato = {
      co: item.co,
      ie: currentUser.ie,
      ua: currentUser.us,
      ci: item.ci,
      fi: item.fi,
      ff: item.ff,
      cu: item.cu,
      si: quitarFormatoMonetario(item.si),
      ic: item.ic,
      mp: item.mp,
      dtl,
    };

    api
      .patch(`${servicios.CONTRATO}/editar`, contrato)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(paginado(0));
        dispatch(getContratosAction());
        dispatch({ type: CONTRATO_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: CONTRATO_FAIL,
          payload: error.data.errors ? error.data.errors : contratoModel,
        });
      });
  };

export const patchContratoAction = (item, imagen) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: CONTRATO_PROCESS,
  });

  let form_data = new FormData();
  if (imagen.length > 0) {
    form_data.append('file', imagen[0]);
  }
  if (imagen.length > 1) {
    form_data.append('file', imagen[1]);
  }
  form_data.append('ie', currentUser.ie);
  form_data.append('co', item.co);
  form_data.append('pd', item.pd);

  api
    .patch(`${servicios.CONTRATO}/archivo`, form_data, {
      headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
    })
    .then(() => {
      dispatch({ type: CONTRATO_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: CONTRATO_FAIL,
        payload: error.data.errors ? error.data.errors : contratoModel,
      });
    });
};

export const patchAnularContratoAction =
  (item, motivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: CONTRATO_PROCESS,
    });

    let contrato = {
      co: item.co,
      ie: currentUser.ie,
      un: currentUser.us,
      ma: motivo,
    };

    api
      .patch(`${servicios.CONTRATO}/anular`, contrato)
      .then(() => {
        dispatch({ type: CONTRATO_SAVE_SUCCESS });
        mensaje('Operación exitosa');
        dispatch(paginado(0));
        dispatch(getContratosAction({}));
      })
      .catch((error) => {
        dispatch({
          type: CONTRATO_FAIL,
          payload: error.data.errors ? error.data.errors : contratoModel,
        });
      });
  };

export const patchCambiarEstadoContratoAction =
  (item, estado) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: CONTRATO_PROCESS,
    });

    let contrato = {
      co: item.co,
      ie: currentUser.ie,
      se: estado,
    };

    api
      .patch(`${servicios.CONTRATO}/cambiar`, contrato)
      .then(() => {
        dispatch({ type: CONTRATO_SAVE_SUCCESS });
        mensaje('Operación exitosa');
        dispatch(paginado(0));
        dispatch(getContratosAction({}));
      })
      .catch((error) => {
        dispatch({
          type: CONTRATO_FAIL,
          payload: error.data.errors ? error.data.errors : contratoModel,
        });
      });
  };

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { su, suf, us, ec, fi, ff, search } = getState().contrato;

  let item = {
    su: filtros.su !== undefined ? (filtros.su === '' ? null : filtros.su) : su,
    suf:
      filtros.suf !== undefined
        ? filtros.suf === ''
          ? null
          : filtros.suf
        : suf,
    us: filtros.us !== undefined ? (filtros.us === '' ? null : filtros.us) : us,
    ec: filtros.ec !== undefined ? (filtros.ec === '' ? null : filtros.ec) : ec,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: CONTRATO_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: CONTRATO_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: CONTRATO_CLOSE,
  });
};
