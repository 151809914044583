import * as fromServicio from '../actions/servicio.action';
import { servicioModel } from '../models/servicio.model';

let initialState = {
  servicios: [],
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
  openModalImagen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromServicio.SERVICO_PROCESS:
      return {
        ...state,
        processing: true,
        errors: servicioModel,
      };
    case fromServicio.SERVICO_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        servicios: action.payload.data,
        total: action.payload.count,
      };
    case fromServicio.SERVICO_LIST_FAIL:
      return {
        ...state,
        processing: false,
        servicios: [],
        total: 0,
      };
    case fromServicio.SERVICO_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: servicioModel,
        openModal: true,
        openModalImagen: false,
      };
    case fromServicio.SERVICO_IMAGE:
      return {
        ...state,
        selected: action.payload,
        errors: servicioModel,
        openModal: false,
        openModalImagen: true,
      };
    case fromServicio.SERVICO_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: servicioModel,
        openModal: false,
        openModalImagen: false,
      };
    case fromServicio.SERVICO_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromServicio.SERVICO_FILTER:
      return {
        ...state,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromServicio.SERVICO_CLOSE:
      return {
        ...state,
        openModal: false,
        openModalImagen: false,
        errors: servicioModel,
      };
    default:
      return state;
  }
}
