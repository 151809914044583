import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import RedSocialList from './RedSocialList';
import RedSocialItem from './RedSocialItem';
import RedSocialFilter from './RedSocialFilter';

import { getRedesSocialesAction } from '../../redux/actions/redsocial.action';

import '../../css/components/pedido.css';

const RedSocial = ({ openModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRedesSocialesAction());
  }, []);

  return (
    <div className="op-component view-pedido">
      <RedSocialList />
      <RedSocialFilter />
      {openModal && <RedSocialItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.red.openModal,
  };
};

export default connect(mapStateToProps)(RedSocial);
