import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { adicionalModel } from '../models/adicional.model';

export const ADICIONAL_PROCESS = '[Adicional] Adicional procesando';
export const ADICIONAL_SAVE_SUCCESS = '[Adicional] Adicional guardar exito';
export const ADICIONAL_LIST_SUCCESS = '[Adicional] Adicional lista exito';
export const ADICIONAL_LIST_FAIL = '[Adicional] Adicional lista error';
export const ADICIONAL_SELECTED = '[Adicional] Adicional seleccionado';
export const ADICIONAL_CLOSE = '[Adicional] Adicional cerrar modal';
export const ADICIONAL_FILTER = '[Adicional] Adicional filtros';
export const ADICIONAL_FAIL = '[Adicional] Adicional proceso error';
export const ADICIONAL_PAGINATE = '[Adicional] Adicional paginado';
export const ADICIONAL_STATES = '[Adicional] Adicional lista estados contrato';

export const getAdiconalesAction = () => (dispatch, getState) => {
  let { ta, pa, st, search, page, rows } = getState().adicional;
  let { currentUser } = getState().app;

  dispatch({
    type: ADICIONAL_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    ta,
    pa,
    st,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.ADICIONAL, { params })
    .then((response) => {
      dispatch({
        type: ADICIONAL_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: ADICIONAL_LIST_FAIL,
      });
    });
};

export const getEstadoContratoAction = () => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: ADICIONAL_PROCESS,
  });

  const params = {
    ie: currentUser.ie,
  };

  api
    .get(`${servicios.DETALLE}/ESTADOCONTRATO/cabecera`, { params })
    .then((response) => {
      let first = response[0];
      if (first) {
        const params = {
          em: currentUser.ie,
          ta: first.dt,
          page: 0,
          pageSize: 30,
        };

        api
          .get(servicios.ADICIONAL, { params })
          .then((response) => {
            dispatch({
              type: ADICIONAL_STATES,
              payload: response.data,
            });
          })
          .catch(() => {
            dispatch({
              type: ADICIONAL_STATES,
              payload: [],
            });
          });
      }
    });
};

export const getSeleccionarAdicionalAction = (item) => (dispatch, getState) => {
  if (item) {
    let { currentUser } = getState().app;
    api
      .get(`${servicios.ADICIONAL}/${currentUser.ie}/empresa/${item.ad}`)
      .then((response) => {
        dispatch({
          type: ADICIONAL_SELECTED,
          payload: response,
        });
      })
      .catch(() => {
        dispatch({
          type: ADICIONAL_SELECTED,
          payload: adicionalModel,
        });
      });
  } else {
    dispatch({
      type: ADICIONAL_SELECTED,
      payload: adicionalModel,
    });
  }
};

export const postAdicionalAction = (item) => (dispatch, getState) => {
  let { ta, pa } = getState().adicional;
  let { currentUser } = getState().app;

  dispatch({
    type: ADICIONAL_PROCESS,
  });

  let adicional = {
    ad: item.ad,
    ie: currentUser.ie,
    ca: item.ca,
    na: item.na,
    pa: item.ad ? item.pa : pa,
    ta,
    va: item.va,
    st: item.st,
    gc: item.gc,
  };

  api
    .post(servicios.ADICIONAL, adicional)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(paginado(0));
      dispatch(getAdiconalesAction({}));
      dispatch({ type: ADICIONAL_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: ADICIONAL_FAIL,
        payload: error.data.errors ? error.data.errors : adicionalModel,
      });
    });
};

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { pa, pn, ta, nt, st, search } = getState().adicional;

  let item = {
    pa: filtros.pa !== undefined ? (filtros.pa === '' ? null : filtros.pa) : pa,
    pn: filtros.pn !== undefined ? (filtros.pn === '' ? null : filtros.pn) : pn,
    ta: filtros.ta !== undefined ? (filtros.ta === '' ? null : filtros.ta) : ta,
    nt: filtros.nt !== undefined ? (filtros.nt === '' ? null : filtros.nt) : nt,
    st: filtros.st !== undefined ? (filtros.st === '' ? null : filtros.st) : st,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: ADICIONAL_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: ADICIONAL_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: ADICIONAL_CLOSE,
  });
};
