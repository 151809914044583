import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Filtros from '../../commons/Filtros';
import Combo from '../../commons/Combo';

import {
  getMaquinasAction,
  getSeleccionarMaquinaAction,
  actualizarFiltros,
} from '../../redux/actions/maquina.action';

const MaquinaFilter = ({ sucursales }) => {
  const dispatch = useDispatch();
  const [su, setSu] = useState('');
  const [st, setSt] = useState('');
  return (
    <Filtros
      onFilter={() => dispatch(getMaquinasAction({}))}
      onNew={() => dispatch(getSeleccionarMaquinaAction())}
    >
      <Combo
        name="su"
        label="Sucursal"
        value={su}
        placeholder="Todos"
        tabIndex={20}
        size={3}
        data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
        onChange={({ value }) => {
          setSu(value);
          actualizarFiltros({ is: value });
        }}
      />
      <Combo
        name="st"
        value={st}
        label="Estado del registro"
        placeholder="Todos"
        size={3}
        tabIndex={22}
        data={[
          { value: '1', label: 'Vigente' },
          { value: '0', label: 'No vigente' },
        ]}
        onChange={({ value }) => {
          setSt(value);
          actualizarFiltros({ st: value });
        }}
      />
    </Filtros>
  );
};

const mapStateToProps = (state) => {
  return {
    sucursales: state.sucursal.sucursales,
  };
};

export default connect(mapStateToProps)(MaquinaFilter);
