import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
  obtenerDiasTranscurridos,
} from '../../commons/Utilitarios';

import {
  getCobranzasAction,
  paginado,
} from '../../redux/actions/cobranza.action';

const CobranzaList = ({ cobranzas, total, rows, processing }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'co',
      label: 'Contrato',
    },
    {
      key: 'dc',
      label: 'Descripción',
      content: (item) => {
        let dias = obtenerDiasTranscurridos(new Date(), item.fv);
        return (
          <span
            style={{
              color:
                item.te === 'PAGACOB' ? 'green' : dias > 0 ? 'red' : '#000000',
            }}
          >
            {item.dc}
          </span>
        );
      },
    },
    {
      key: 'fi',
      label: 'Inicio periodo',
      align: 'center',
      content: (item) => <>{formatoFecha(item.fi, 'Indefinido', false)}</>,
    },
    {
      key: 'ff',
      label: 'Fin periodo',
      align: 'center',
      content: (item) => <>{formatoFecha(item.ff, 'Indefinido', false)}</>,
    },
    {
      key: 'fv',
      label: 'Fecha vencimiento',
      align: 'center',
      content: (item) => {
        let dias = obtenerDiasTranscurridos(new Date(), item.fv);
        return (
          <span
            style={{
              color:
                item.te === 'PAGACOB' ? 'green' : dias > 0 ? 'red' : '#000000',
            }}
          >
            {formatoFecha(item.fv, 'Indefinido', false)}
          </span>
        );
      },
    },
    {
      key: 'dv',
      label: 'Días vencidos',
      align: 'center',
      content: (item) => {
        let dias = obtenerDiasTranscurridos(new Date(), item.fv);
        return (
          <span
            className={`${
              dias > 0 && item.te === 'PENDCOB' ? 'op-arrow-down' : ''
            }`}
            style={{
              color: dias > 0 && item.te === 'PENDCOB' ? 'red' : 'green',
            }}
          >
            {dias > 0 && item.te === 'PENDCOB' ? dias : 0}
          </span>
        );
      },
    },

    {
      key: 'mc',
      label: 'Monto',
      align: 'right',
      content: (item) => {
        let dias = obtenerDiasTranscurridos(new Date(), item.fv);
        return (
          <span
            style={{
              color:
                item.te === 'PAGACOB' ? 'green' : dias > 0 ? 'red' : '#000000',
            }}
          >
            {`S/ ${formatoMonetario(redondeoDecimales(item.mc))}`}
          </span>
        );
      },
    },
    {
      key: 'de',
      label: 'Estado',
      content: (item) => {
        let dias = obtenerDiasTranscurridos(new Date(), item.fv);
        return (
          <span
            style={{
              color:
                item.te === 'PAGACOB' ? 'green' : dias > 0 ? 'red' : '#000000',
            }}
          >
            {item.de}
          </span>
        );
      },
    },
    {
      key: 'fp',
      label: 'Fecha pago',
      align: 'center',
      content: (item) => <>{formatoFecha(item.fp, 'Pendiente')}</>,
    },
  ];

  return (
    <Bloque titulo={'Resultado de búsqueda'}>
      <Tabla
        columns={columns}
        data={cobranzas}
        processing={processing}
        total={total}
        rows={rows}
        indice={1}
        searcheable={false}
        onPagination={({ page }) => {
          dispatch(paginado(page));
          dispatch(getCobranzasAction());
        }}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    cobranzas: state.cobranza.cobranzas,
    total: state.cobranza.total,
    rows: state.cobranza.rows,
    processing: state.cobranza.processing,
  };
};

export default connect(mapStateToProps)(CobranzaList);
