import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import { servicios } from '.';
import { mensajeMini } from '../../commons/Mensajes';
import { api } from '../axios/api';

const firebaseConfig = {
  apiKey: process.env.FIREBASE_APIKEY,
  authDomain: process.env.FIREBASE_DOMAIN,
  projectId: process.env.FIREBASE_PROYECT,
  storageBucket: process.env.FIREBASE_STORAGE,
  messagingSenderId: process.env.FIREBASE_SENDER,
  appId: process.env.FIREBASE_APP,
  measurementId: process.env.FIREBASE_MEASURE,
};

const firebase = initializeApp(firebaseConfig);

export const messaging = getMessaging(firebase);
export const getTokenNotificacion = (us) => {
  getToken(messaging, {
    vapidKey: process.env.FIREBASE_KEY,
  })
    .then((token) => {
      if (token) {
        let user = {
          us,
          tk: token,
        };
        api
          .patch(`${servicios.USUARIO}/notificacion`, user)
          .then(() => {
            mensajeMini('Suscrito a las notificaciones', 'success');
          })
          .catch(() => {});
      }
    })
    .catch((error) => {
      console.log('error con token ', error);
    });
};
