export const servicioModel = {
  sv: null,
  cs: null,
  ns: null,
  ps: null,
  ds: null,
  dd: null,
  st: false,
  gc: true,
  ct: null,
};
