import * as fromMaquina from '../actions/maquina.action';
import { maquinModel } from '../models/maquina.model';

let initialState = {
  maquinas: [],
  is: null,
  isf: null,
  st: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  openModal: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromMaquina.MAQUINA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: maquinModel,
      };
    case fromMaquina.MAQUINA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        maquinas: action.payload.data,
        total: action.payload.count,
      };
    case fromMaquina.MAQUINA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        maquinas: [],
        total: 0,
      };
    case fromMaquina.MAQUINA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: maquinModel,
        openModal: true,
      };
    case fromMaquina.MAQUINA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        openModal: false,
        errors: maquinModel,
      };
    case fromMaquina.MAQUINA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromMaquina.MAQUINA_FILTER:
      return {
        ...state,
        is: action.payload.is,
        isf: action.payload.isf,
        st: action.payload.st,
        search: action.payload.search,
      };
    case fromMaquina.MAQUINA_CLOSE:
      return {
        ...state,
        errors: maquinModel,
        openModal: false,
      };
    default:
      return state;
  }
}
