import * as fromCaja from '../actions/caja.action';
import { cajaModel } from '../models/caja.model';

let initialState = {
  detalles: [],
  tc: null,
  tm: null,
  is: null,
  fo: null,
  fi: null,
  ff: null,
  search: null,
  total: 0,
  processing: false,
  selected: null,
  operacion: null,
  openModal: false,
  openModalEdit: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case fromCaja.CAJA_PROCESS:
      return {
        ...state,
        processing: true,
        errors: cajaModel,
      };
    case fromCaja.CAJA_LIST_SUCCESS:
      return {
        ...state,
        processing: false,
        detalles: action.payload.data,
        total: action.payload.count,
      };
    case fromCaja.CAJA_LIST_FAIL:
      return {
        ...state,
        processing: false,
        detalles: [],
        total: 0,
      };
    case fromCaja.CAJA_SELECTED:
      return {
        ...state,
        selected: action.payload,
        errors: cajaModel,
      };
    case fromCaja.CAJA_OPERATION:
      return {
        ...state,
        operacion: action.payload,
        openModal: true,
        openModalEdit: false,
        errors: cajaModel,
      };
    case fromCaja.CAJA_SAVE_SUCCESS:
      return {
        ...state,
        processing: false,
        errors: cajaModel,
        openModal: false,
        openModalEdit: false,
      };
    case fromCaja.CAJA_FAIL:
      return {
        ...state,
        processing: false,
        errors: action.payload,
      };
    case fromCaja.CAJA_FILTER:
      return {
        ...state,
        tc: action.payload.tc,
        tm: action.payload.tm,
        is: action.payload.is,
        fo: action.payload.fo,
        fi: action.payload.fi,
        ff: action.payload.ff,
        search: action.payload.search,
      };
    case fromCaja.CAJA_OPEN:
      return {
        ...state,
        openModal: true,
        errors: cajaModel,
      };
    case fromCaja.CAJA_VIEW:
      return {
        ...state,
        operacion: action.payload,
        openModalEdit: true,
        errors: cajaModel,
      };
    case fromCaja.CAJA_CLOSE:
      return {
        ...state,
        openModal: false,
        openModalEdit: false,
        errors: cajaModel,
      };
    default:
      return state;
  }
}
