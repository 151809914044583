export const operacionModel = {
  op: null,
  su: null,
  ns: null,
  fo: null,
  ct: null,
  cti: null,
  cto: null,
  dt: null,
  mo: null,
  nm: null,
  um: null,
  is: null,
  cm: null,
  pm: null,
  tm: null,
  cu: null,
  nu: null,
  st: false,
};
