import React from 'react';
import { connect } from 'react-redux';

import CobranzaFilter from './CobranzaFilter';
import CobranzaDebt from './CobranzaDebt';
import CobranzaList from './CobranzaList';

const Cobranza = ({ openModal }) => {
  return (
    <div className="op-component">
      <CobranzaList />
      <CobranzaFilter />
      {openModal && <CobranzaDebt />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.cobranza.openModal,
  };
};

export default connect(mapStateToProps)(Cobranza);
