import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Combo from '../../commons/Combo';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getCategoriaAction,
  getSeleccionarCategoriaAction,
  actualizarFiltros,
} from '../../redux/actions/categoria.action';

import EditIcon from '@material-ui/icons/Edit';

const CategoriaList = ({ tipos, categorias, processing }) => {
  const [tipo, setTipo] = useState('');
  const dispatch = useDispatch();

  const { aplicaciones } = useSelector((state) => state.app);
  const columns = [
    {
      key: 'cte.ct',
      align: 'center',
      content: (item) => (
        <EditIcon
          onClick={() => dispatch(getSeleccionarCategoriaAction(item))}
          style={{ color: 'green', cursor: 'pointer' }}
        />
      ),
    },
    {
      key: 'cte.nc',
      label: 'Categoría',
      content: (item) => item.cte.nc,
    },
    {
      key: 'cte.na',
      label: 'Aplicación',
      content: (item) => item.cte.na,
    },
    {
      key: 'ai',
      label: 'Aplica',
      align: 'center',
      content: (item) => <CheckBox checked={item.ai} disabled />,
    },
  ];
  return (
    <Bloque titulo="Categorias por aplicaci&oacute;n">
      <div className="op-form" style={{ padding: '20px' }}>
        <Combo
          name="tipos"
          label="Tipos categor&iacute;a"
          value={tipo}
          tabIndex={10}
          size={2}
          data={aplicaciones.map((i) => ({ value: i.ap, label: i.na }))}
          onChange={({ value }) => {
            setTipo(value);
            if (value) {
              dispatch(actualizarFiltros(value));
              dispatch(getCategoriaAction());
            }
          }}
        />
      </div>
      <Tabla
        columns={columns}
        data={categorias}
        processing={processing}
        pagination={false}
        searcheable={false}
        onDoubleClick={(item) => dispatch(getSeleccionarCategoriaAction(item))}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    tipos: state.categoria.tipos,
    categorias: state.categoria.categorias,
    processing: state.categoria.processing,
  };
};

export default connect(mapStateToProps)(CategoriaList);
