import { api } from '../axios/api';
import { servicios } from '../helpers/index';
import { mensaje } from '../../commons/Mensajes';
import { guiaModel } from '../models/guia.model';
import { documentoModel } from '../models/documento.model';

export const GUIA_PROCESS = '[Guia] Guia procesando';
export const GUIA_SAVE_SUCCESS = '[Guia] Guia guardar exito';
export const GUIA_LIST_SUCCESS = '[Guia] Guia lista exito';
export const GUIA_LIST_FAIL = '[Guia] Guia lista error';
export const GUIA_SELECTED = '[Guia] Guia seleccionado';
export const GUIA_VIEW = '[Guia] Guia ver';
export const GUIA_CLOSE = '[Guia] Guia cerrar modal';
export const GUIA_FILTER = '[Guia] Guia filtros';
export const GUIA_PAGINATE = '[Guia] Guia paginado';
export const GUIA_FAIL = '[Guia] Guia proceso error';

export const getGuiasAction = () => (dispatch, getState) => {
  let { iu, sp, fi, ff, search, page, rows } = getState().guia;
  let { currentUser } = getState().app;

  dispatch({
    type: GUIA_PROCESS,
  });

  const params = {
    em: currentUser.ie,
    iu,
    sp,
    fi,
    ff,
    search,
    page,
    pageSize: rows,
  };

  api
    .get(servicios.GUIA, { params })
    .then((response) => {
      dispatch({
        type: GUIA_LIST_SUCCESS,
        payload: response,
      });
    })
    .catch(() => {
      dispatch({
        type: GUIA_LIST_FAIL,
      });
    });
};

export const postGenerarGuiaAction = (item, detail) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: GUIA_PROCESS,
  });

  let guia = {
    ...item,
    ie: currentUser.ie,
    is: currentUser.is,
    iu: currentUser.us,
    us: currentUser.np,
    ins: detail,
  };
  api
    .post(servicios.GUIA, guia)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getGuiasAction());
      dispatch({ type: GUIA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: GUIA_FAIL,
        payload: error.data.errors ? error.data.errors : guiaModel,
      });
    });
};

export const putRectificarGuiaAction =
  (item, documento, detail) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: GUIA_PROCESS,
    });

    let guia = {
      ...item,
      dc: documento,
      ie: currentUser.ie,
      ins: detail,
    };
    api
      .put(servicios.GUIA, guia)
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getGuiasAction());
        dispatch({ type: GUIA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: GUIA_FAIL,
          payload: error.data.errors ? error.data.errors : guiaModel,
        });
      });
  };

export const patchAprobarGuiaAction = (item) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: GUIA_PROCESS,
  });

  let guia = {
    ...item,
    ie: currentUser.ie,
    us: currentUser.us,
  };
  api
    .patch(`${servicios.GUIA}/aprobar`, guia)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getGuiasAction());
      dispatch({ type: GUIA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: GUIA_FAIL,
        payload: error.data.errors ? error.data.errors : guiaModel,
      });
    });
};

export const patchRechazarGuiaAction = (item, ma) => (dispatch, getState) => {
  let { currentUser } = getState().app;

  dispatch({
    type: GUIA_PROCESS,
  });

  let guia = {
    ...item,
    ie: currentUser.ie,
    us: currentUser.us,
    ma,
  };
  api
    .patch(`${servicios.GUIA}/rechazar`, guia)
    .then(() => {
      mensaje('Operación exitosa');
      dispatch(getGuiasAction());
      dispatch({ type: GUIA_SAVE_SUCCESS });
    })
    .catch((error) => {
      dispatch({
        type: GUIA_FAIL,
        payload: error.data.errors ? error.data.errors : guiaModel,
      });
    });
};

export const getSeleccionarGuiaAction =
  (item, edit = false) =>
  (dispatch, getState) => {
    if (item) {
      let { currentUser } = getState().app;
      let { tiposTraslados, tipoDocumentoIdentidad } = getState().detalle;
      let { sucursales } = getState().sucursal;

      dispatch({
        type: GUIA_PROCESS,
      });

      api
        .get(`${servicios.GUIA}/${item.dc}/empresa/${currentUser.ie}`)
        .then((response) => {
          if (edit) {
            dispatch({
              type: GUIA_SELECTED,
              payload: {
                ...response,
                gui: {
                  ...response.gui,
                  mt: tiposTraslados.find((t) => t.de === response.gui.dm).dt,
                  to: response.gui.ts
                    ? tipoDocumentoIdentidad.find(
                        (t) => t.de === response.gui.ts
                      ).dt
                    : null,
                  td: tipoDocumentoIdentidad.find(
                    (t) => t.de === response.gui.te
                  ).dt,
                  sp: sucursales.find((s) => s.ns === response.gui.pp).su,
                  sl: response.gui.ll
                    ? sucursales.find((s) => s.ns === response.gui.ll).su
                    : null,
                },
              },
            });
          } else {
            dispatch({
              type: GUIA_VIEW,
              payload: response,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: GUIA_SELECTED,
            payload: guiaModel,
          });
        });
    } else {
      dispatch({
        type: GUIA_SELECTED,
        payload: guiaModel,
      });
    }
  };

export const patchAdjuntarArchivoGuiaAction =
  (item, archivo) => (dispatch, getState) => {
    let { currentUser } = getState().app;

    dispatch({
      type: GUIA_PROCESS,
    });

    let form_data = new FormData();
    form_data.append('file', archivo);
    form_data.append('dc', item.dc);
    form_data.append('ie', currentUser.ie);

    api
      .patch(`${servicios.GUIA}/adjuntar`, form_data, {
        headers: { 'content-type': 'multipart/form-data; charset=utf-8' },
      })
      .then(() => {
        mensaje('Operación exitosa');
        dispatch(getGuiasAction());
        dispatch({ type: GUIA_SAVE_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: GUIA_FAIL,
          payload: error.data.errors ? error.data.errors : guiaModel,
        });
      });
  };

export const actualizarFiltros = (filtros) => (dispatch, getState) => {
  let { iu, sp, fi, ff, search } = getState().guia;

  let item = {
    iu: filtros.iu !== undefined ? (filtros.iu === '' ? null : filtros.iu) : iu,
    sp: filtros.sp !== undefined ? (filtros.sp === '' ? null : filtros.sp) : sp,
    fi: filtros.fi !== undefined ? (filtros.fi === '' ? null : filtros.fi) : fi,
    ff: filtros.ff !== undefined ? (filtros.ff === '' ? null : filtros.ff) : ff,
    search:
      filtros.search !== undefined
        ? filtros.search === ''
          ? null
          : filtros.search
        : search,
  };

  dispatch({
    type: GUIA_FILTER,
    payload: item,
  });
};

export const paginado = (page) => (dispatch) => {
  dispatch({
    type: GUIA_PAGINATE,
    payload: page,
  });
};

export const closeModal = () => (dispatch) => {
  dispatch({
    type: GUIA_CLOSE,
  });
};
