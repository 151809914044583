import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Tabla from '../../commons/Tabla';
import Bloque from '../../commons/Bloque';
import CheckBox from '../../commons/CheckBox';

import {
  getMaquinasAction,
  getSeleccionarMaquinaAction,
  actualizarFiltros,
} from '../../redux/actions/maquina.action';

import EditIcon from '@material-ui/icons/Edit';

const MaquinaList = ({ maquinas, processing, total }) => {
  const dispatch = useDispatch();

  const columns = [
    {
      key: 'pv',
      align: 'center',
      content: (item) => (
        <div title="Editar" style={{ display: 'inline-block' }}>
          <EditIcon
            onClick={() => dispatch(getSeleccionarMaquinaAction(item))}
            style={{ color: 'green', cursor: 'pointer' }}
          />
        </div>
      ),
    },
    {
      key: 'ns',
      label: 'Sucursal',
    },
    {
      key: 'np',
      label: 'Nombre',
    },
    {
      key: 'st',
      label: 'Estado de registro',
      align: 'center',
      content: (item) => <CheckBox checked={item.st} disabled />,
    },
  ];

  return (
    <Bloque titulo="Resultado de b&uacute;squeda">
      <Tabla
        columns={columns}
        data={maquinas}
        processing={processing}
        total={total}
        onPagination={({ page, rows }) =>
          dispatch(getMaquinasAction({ page, pageSize: rows }))
        }
        onSearching={() => dispatch(getMaquinasAction({}))}
        onChangeSearching={(search) => actualizarFiltros({ search })}
      />
    </Bloque>
  );
};

const mapStateToProps = (state) => {
  return {
    maquinas: state.maquina.maquinas,
    processing: state.maquina.processing,
    total: state.maquina.total,
  };
};

export default connect(mapStateToProps)(MaquinaList);
