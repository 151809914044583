import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import CountUp from 'react-countup';

import { getReporteVentas } from '../../redux/actions/app.action';

const IngresosReport = () => {
  const [tm, setTm] = useState(0);
  const [su, setSu] = useState('');
  const dispatch = useDispatch();
  const { ventas } = useSelector((state) => state.app);

  useEffect(() => {
    dispatch(getReporteVentas(tm, su));
  }, []);

  const handleGetComparativo = () => {
    let last = ventas.length > 0 ? ventas[ventas.length - 1].to : 0;
    let compa = ventas.length > 1 ? ventas[ventas.length - 2].to : 0;
    let comparar = last > 0 ? parseFloat((last * 100) / compa).toFixed(2) : 0;

    return (
      <div className="actual">
        <CountUp
          prefix={`${tm === 0 ? 'S/' : '$'}`}
          end={last}
          decimals={2}
          duration={2}
        >
          {({ countUpRef }) => <span title="venta del mes" ref={countUpRef} />}
        </CountUp>

        <CountUp suffix={'%'} end={comparar} decimals={2} duration={2}>
          {({ countUpRef }) => (
            <div>
              <span className={`op-arrow-${comparar > 100 ? 'up' : 'down'}`} />
              <span title="Comparado con el mes anterior" ref={countUpRef} />
            </div>
          )}
        </CountUp>
      </div>
    );
  };

  const handleGetIngresos = () => {
    let ingresosData = {
      labels: ventas.map((x) => x.ms),
      datasets: [
        {
          label: 'Ingresos',
          data: ventas.map((x) => x.to),
          backgroundColor: '#4fc4f6',
        },
        {
          label: 'Descuentos globales',
          data: ventas.map((x) => x.ds),
          backgroundColor: 'red',
        },
        {
          label: 'Descuentos items',
          data: ventas.map((x) => x.di),
          backgroundColor: 'orange',
        },
      ],
    };
    return (
      <Bar
        data={ingresosData}
        options={{
          responsive: true,
          plugins: {
            title: {
              display: false,
            },
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
          },
          scales: {
            x: {
              display: true,
              stacked: true,
            },
            y: {
              display: true,
              stacked: true,
            },
          },
        }}
      />
    );
  };

  return (
    <div className="grid-item action ingresos">
      <label className="title">Ingresos</label>
      {handleGetComparativo()}
      {handleGetIngresos()}
      <div className="actions">
        <span
          className={`tipo ${tm === 0 ? 'activo' : ''}`}
          onClick={() => {
            setTm(0);
            dispatch(getReporteVentas(0, su));
          }}
        >
          Soles
        </span>
        <span
          className={`tipo ${tm === 1 ? 'activo' : ''}`}
          onClick={() => {
            setTm(1);
            dispatch(getReporteVentas(1, su));
          }}
        >
          Dolares
        </span>
      </div>
    </div>
  );
};

export default IngresosReport;
