import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getAplicacionesAction } from '../../redux/actions/app.action';
import { getTipoCategoriaAction } from '../../redux/actions/categoria.action';

import CategoriaList from './CategoriaList';
import CategoriaItem from './CategoriaItem';
import CategoriaFilter from './CategoriaFilter';

const Categoria = ({ openModal }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAplicacionesAction());
    dispatch(getTipoCategoriaAction());
  }, []);

  return (
    <div className="op-component">
      <CategoriaList />
      <CategoriaFilter />
      {openModal && <CategoriaItem />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openModal: state.categoria.openModal,
  };
};

export default connect(mapStateToProps)(Categoria);
