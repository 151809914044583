import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Boton from '../../commons/Boton';
import Tabla from '../../commons/Tabla';
import Combo from '../../commons/Combo';
import CheckBox from '../../commons/CheckBox';

import {
  formatoFecha,
  formatoMonetario,
  redondeoDecimales,
} from '../../commons/Utilitarios';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  putInventarioAction,
} from '../../redux/actions/inventario.action';

import SaveIcon from '@material-ui/icons/Save';

const InventarioItem = ({
  selected,
  processing,
  errors,
  tipoCategorias,
  closeModal,
  putInventarioAction,
}) => {
  const [inventario, setInventario] = useState(selected);

  useEffect(() => {
    let searchTipo = tipoCategorias.find((s) => s.de === selected.dt);
    if (searchTipo) {
      handleChange({ name: 'ct', value: searchTipo.dt });
    }
    handleChange({ name: 'pv', value: formatoMonetario(selected.pv) });
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setInventario((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const columns = [
    {
      key: 'na',
      label: 'Almacen',
    },
    {
      key: 'lt',
      align: 'center',
      label: 'Lote',
    },
    {
      key: 'cc',
      align: 'center',
      label: 'Orden de compra',
    },
    {
      key: 'fi',
      align: 'right',
      label: 'Fecha de ingreso',
      content: (item) => formatoFecha(item.fi),
    },
    {
      key: 'sd',
      align: 'center',
      label: 'Stock disponible',
    },
    {
      key: 'pc',
      align: 'right',
      label: 'Precio compra',
      content: (item) => formatoMonetario(item.pc),
    },
  ];

  return (
    <Modal
      title={`${selected.nm}`}
      processing={processing}
      onActions={() => (
        <>
          <Boton
            className="op-grabar"
            disabled={processing}
            icon={() => <SaveIcon />}
            tabIndex={21}
            onClick={() =>
              confirmacion(
                '¿Está seguro?',
                `Actualizar los datos de inventario`
              )
                .then(() => putInventarioAction(inventario))
                .catch(() => {})
            }
          >
            Guardar
          </Boton>
        </>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            closeModal();
          })
          .catch(() => {})
      }
    >
      <Texto
        name="cm"
        value={inventario.cm}
        label="C&oacute;digo"
        size={3}
        tabIndex={10}
        error={errors.cm}
        onChange={() => {}}
        disabled
      />
      <Texto
        name="um"
        value={inventario.um}
        label="Unidad de medida"
        size={3}
        tabIndex={11}
        error={errors.um}
        onChange={() => {}}
        disabled
      />
      <Texto
        name="ns"
        value={inventario.ns}
        label="Sucursal"
        size={3}
        tabIndex={12}
        error={errors.ns}
        onChange={() => {}}
        disabled
      />
      <Combo
        name="mg"
        label="M&eacute;todo de gesti&oacute;n"
        value={inventario.mg}
        size={3}
        tabIndex={14}
        data={[
          { value: '0', label: 'FIFO' },
          { value: '1', label: 'LIFO' },
        ]}
        error={errors.mg}
        onChange={handleChange}
        info="Con el sistema de gesti&oacute;n de stock tipo FIFO las primeras mercanc&iacute;as 
        en salir de las estanter&iacute;as del almac&eacute;n ser&aacute;n las primeras que entraron. 
        Permite una &oacute;ptima rotaci&oacute;n de stock y se adapta perfectamente al almacenamiento 
        de productos perecederos, y con el m&eacute;todo de gesti&oacute;n de inventarios tipo 
        LIFO, la &uacute;ltima unidad de carga en entrar al almac&eacute;n ser&aacute; la primera 
        en salir del mismo. Se trata de un m&eacute;todo ideal para productos no perecederos, 
        que ni caducan ni pierden valor con el paso del tiempo."
      />
      <Combo
        name="tm"
        label="Tipo moneda"
        value={inventario.tm}
        size={3}
        tabIndex={14}
        data={[
          { value: '0', label: 'Soles' },
          { value: '1', label: 'Dolares' },
        ]}
        error={errors.tm}
        onChange={handleChange}
      />
      <Texto
        name="pv"
        value={inventario.pv}
        label="Precio de venta"
        size={3}
        tabIndex={15}
        error={errors.pv}
        onChange={handleChange}
        restrict={'money'}
        onBlur={(e) => {
          handleChange({
            name: 'pv',
            value: formatoMonetario(redondeoDecimales(e.target.value)),
          });
        }}
      />
      <Combo
        name="ct"
        label="Tipo categoria"
        value={inventario.ct}
        size={3}
        tabIndex={16}
        data={tipoCategorias.map((i) => ({ value: i.dt, label: i.de }))}
        error={errors.ct}
        onChange={handleChange}
        info="Te dar&aacute;n un panorama m&aacute;s amplio del tipo de productos con los que 
        cuentas y c&oacute;mo deber&aacute;n ser gestionados cada uno."
      />
      <Texto
        name="sd"
        value={inventario.sd}
        label="Stock disponible"
        size={3}
        tabIndex={17}
        error={errors.sd}
        onChange={() => {}}
        restrict={'int'}
        disabled
      />
      <Texto
        name="sx"
        value={inventario.sx === 0 ? null : inventario.sx}
        label="Stock m&aacute;ximo"
        size={3}
        tabIndex={18}
        error={errors.sx}
        onChange={handleChange}
        restrict={'int'}
        info="Calcula el stock m&aacute;ximo de un producto tomando como referencia los 60 d&iacute;as 
        de ventas en unidades de ese producto durante el periodo de mayor demanda, es decir la 
        cantidad mayor permitida de ese producto en tu inventario."
      />
      <Texto
        name="si"
        value={inventario.si === 0 ? null : inventario.si}
        label="Stock m&iacute;nimo"
        size={3}
        tabIndex={19}
        error={errors.si}
        onChange={handleChange}
        restrict={'int'}
        info="Calcula el stock m&iacute;nimo de un producto tomando como referencia los 60 d&iacute;as 
        de ventas en unidades de ese producto durante el periodo de menos demanda, las cuales 
        representan la cantidad m&iacute;nima a tener de tu producto para no correr el riesgo de 
        desabasto."
      />
      <Texto
        name="te"
        value={inventario.te === 0 ? null : inventario.te}
        label="Tiempo de entrega del proveedor"
        size={3}
        tabIndex={20}
        error={errors.te}
        onChange={handleChange}
        restrict={'int'}
        info="Este concepto se refiere al tiempo (d&iacute;as) que una vez colocado el pedido, 
        tarda el producto en llegar a tu bodega y est&aacute; listo para ofrecerse al cliente. 
        Este dato lo puedes obtener de manera f&aacute;cil viendo el hist&oacute;rico de entrega 
        de los productos por parte de tu proveedor. Es importante que si tu producto lleva alguna 
        adecuaci&oacute;n o preparaci&oacute;n (etiquetado, embalaje, ensamblado, etc,) previa a 
        poder ser ofrecida al cliente, sea considerado en este tiempo."
      />
      <Texto
        name="fc"
        value={inventario.fc === 0 ? null : inventario.fc}
        label="Frecuencia de compra"
        size={3}
        tabIndex={21}
        error={errors.fc}
        onChange={handleChange}
        restrict={'int'}
        info="Este concepto se refiere a cada cu&aacute;ntos d&iacute;as puedes o quieres 
        estar colocando una orden de compra a este proveedor por este producto. 
        Para obtener este dato, revisa en tu hist&oacute;rico de ventas cu&aacute;nto tarda 
        en venderse en promedio el producto que est&aacute;s ofreciendo por cada compra 
        realizada as&iacute; podr&aacute;s definir qu&eacute; cantidad es la que se debe 
        comprar. Considerando las dimensiones, costos y la rotaci&oacute;n del producto 
        puedes decidir comprar m&aacute;s frecuente o menos frecuentemente."
      />
      <Texto
        name="pr"
        value={inventario.pr === 0 ? null : inventario.pr}
        label="Punto de re-orden"
        size={3}
        tabIndex={19}
        error={errors.pr}
        onChange={handleChange}
        restrict={'int'}
        info="Define el punto de re-orden, es decir el nivel de existencias en el cual es necesario realizar un pedido, 
        lo que debes hacer es obtener el promedio de tu m&aacute;ximo y tu m&iacute;nimo, 
        por ejemplo si tienes definido el stock m&aacute;ximo en 100 unidades y el stock m&iacute;nimo 
        en 22 unidades, el valor promedio ser&iacute;a de (100 + 22) / 2 = 61 unidades, esto quiere decir que cuando tengas esta cantidad en existencia es momento de colocar una orden."
      />
      <div className="op-form-group op-col-2 op-checkbox-group">
        <span>¿Deseas ser notificado en el punto de re-orden?</span>
        <CheckBox name="np" checked={inventario.np} onChange={handleChange} />
      </div>
      <div className="op-form-group op-col-1">
        <Tabla
          columns={columns}
          data={selected.lts}
          pagination={false}
          searcheable={false}
          hoverSelectedRow={false}
          style={{ padding: '0px' }}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.inventario.selected,
    processing: state.inventario.processing,
    errors: state.inventario.errors,
    tipoCategorias: state.detalle.tipoCategorias,
  };
};

export default connect(mapStateToProps, { closeModal, putInventarioAction })(
  InventarioItem
);
