import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Texto from '../../commons/Texto';
import Modal from '../../commons/Modal';
import Combo from '../../commons/Combo';
import Boton from '../../commons/Boton';
import CheckBox from '../../commons/CheckBox';
import { confirmacion } from '../../commons/Mensajes';

import {
  closeModal,
  postMaquinaAction,
} from '../../redux/actions/maquina.action';

import SaveIcon from '@material-ui/icons/Save';

const MaquinaItem = ({ selected, processing, errors, sucursales }) => {
  const dispatch = useDispatch();
  const [maquina, setMaquina] = useState(selected);

  useEffect(() => {
    let sucursal = sucursales.find((s) => s.ns === selected.ns);
    if (sucursal) {
      handleChange({ name: 'is', value: sucursal.su });
    }
  }, []);

  const handleChange = (item) => {
    const { name, value } = item;
    setMaquina((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal
      title={`${maquina.pv ? 'Actualizar' : 'Nuevo'} punto de venta`}
      processing={processing}
      size="small"
      onActions={() => (
        <Boton
          className="op-grabar"
          disabled={processing}
          icon={() => <SaveIcon />}
          tabIndex={21}
          onClick={() =>
            confirmacion(
              '¿Desea guardar?',
              `${maquina.pv ? 'Actualizar' : 'Un nuevo'} punto de venta`
            )
              .then(() => dispatch(postMaquinaAction(maquina)))
              .catch(() => {})
          }
        >
          Guardar
        </Boton>
      )}
      onClose={() =>
        confirmacion(
          '¿Deseas cerrar?',
          'La información ingresada se perdera al cerrar la ventana'
        )
          .then(() => {
            dispatch(closeModal());
          })
          .catch(() => {})
      }
    >
      <Combo
        name="is"
        label="Sucursal"
        value={maquina.is}
        tabIndex={10}
        data={sucursales.map((i) => ({ value: i.su, label: i.ns }))}
        error={errors.is}
        onChange={handleChange}
      />
      <Texto
        name="np"
        value={maquina.np}
        label="Nombre"
        tabIndex={11}
        error={errors.np}
        onChange={handleChange}
        autoFocus
      />
      <Texto
        name="ne"
        value={maquina.ne}
        label="N&uacute;mero de serie"
        tabIndex={12}
        error={errors.ne}
        onChange={handleChange}
      />
      {maquina.pv && (
        <div className="op-form-group op-col-1 op-checkbox-group">
          <span>Estado del registro</span>
          <CheckBox name="st" checked={maquina.st} onChange={handleChange} />
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    selected: state.maquina.selected,
    processing: state.maquina.processing,
    errors: state.maquina.errors,
    sucursales: state.sucursal.sucursales,
  };
};

export default connect(mapStateToProps)(MaquinaItem);
